@import 'scss/variables';

.form-control {
    border: none;
    border-bottom: 2px solid $color-black;
    background: none;
    padding: 15px 0;
    color: $color-black;
    border-radius: 0;

    &.white {
        border-bottom: 2px solid $color-white;
        color: $color-white;
    }
}

textarea {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 2px solid $color-black;
    color: $color-black;
    border-radius: 0;

    ::placeholder {
        color: $color-black;
    }

    &.white {
        border-bottom: 2px solid $color-white;
        color: $color-white;
    }
}

::placeholder {
    color: $color-black;
    opacity: 1;
    padding: 0;
}

.white::placeholder {
    color: $color-white;
}

.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-color: transparent;
    background-size: 32px 32px;
    background-position: right 0 center;

    font-size: 15px;
    text-align: left;
    border-color: $color-black;
    border-width: 0 0 2px 0;
    border-radius: 0;

    padding: 16px 30px 16px 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.white {
        background-color: transparent;
        color: $color-white;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        border-color: $color-white;
    }
}

[type='checkbox'] + label {
    position: absolute;
    vertical-align: middle;
}
[type='checkbox'] {
    position: relative;
    left: 15px;
    top: -60px;
    z-index: 0;
    -webkit-appearance: none;
}
[type='checkbox'] + label::before {
    width: 20px;
    height: 20px;
    border: 1px solid $color-black;
    display: block;
    content: '';
    float: left;
    margin-right: 15px;
    z-index: 5;
    position: relative;
}

[type='checkbox']:checked + label::before {
    box-shadow: inset 0 0 0 3px #fff;
    background-color: $color-black;
}
