$color-white: #ffffff;
$color-black: #000000;
$color-grey-dark: #666666;
$color-grey: #969696;
$color-grey-light: #f5f5f5;
$color-pink: #fd717d;

$colors: (
    'white': $color-white,
    'black': $color-black,
    'grey': $color-grey,
    'grey-light': $color-grey-light,
    'grey-dark': $color-grey-dark,
    'pink': $color-pink
);

//*{
//    --tenant-color: #fd717d;
//}

.tenant-background {
    background-color: var(--tenant-color);
}

.tenant-color {
    color: var(--tenant-color);
}
