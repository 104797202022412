@import 'scss/bootstrap';
@import 'scss/colors';
@import 'scss/typography';
@import 'scss/variables';
@import 'scss/buttons';
@import 'scss/forms';
@import 'scss/aos';
@import 'scss/flat-icons';
@import "@flaticon/flaticon-uicons/css/all/all";

.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


    .theme-dynamic {
        --tenant-color: #000;
        & .bs-datepicker-head {
            background-color: var(--tenant-color);
        }

        & .bs-datepicker-body table td.week span {
            color: var(--tenant-color);
        }

        & .bs-datepicker-body .selected {
            background-color: var(--tenant-color);
        }
    }

.icon-medium {
    height: 25px;
}
