@import 'scss/typography/icons/uicons-thin.straight';
@import 'scss/typography/icons/uicons-round.regular';
@import 'scss/typography/icons/uicons-thin.rounded';


i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
    font-family: uicons-regular-rounded !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i[class^="fi-ts-"]:before, i[class*=" fi-ts-"]:before, span[class^="fi-ts-"]:before, span[class*="fi-ts-"]:before {
    font-family: uicons-thin-straight !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i[class^="fi-tr-"]:before, i[class*=" fi-tr-"]:before, span[class^="fi-tr-"]:before, span[class*="fi-tr-"]:before {
    font-family: uicons-thin-rounded !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fi-ts-0:before {
    content: "\f101";
}
.fi-ts-1:before {
    content: "\f102";
}
.fi-ts-2:before {
    content: "\f103";
}
.fi-ts-3:before {
    content: "\f104";
}
.fi-ts-360-degrees:before {
    content: "\f105";
}
.fi-ts-4:before {
    content: "\f106";
}
.fi-ts-404:before {
    content: "\f107";
}
.fi-ts-5:before {
    content: "\f108";
}
.fi-ts-6:before {
    content: "\f109";
}
.fi-ts-7:before {
    content: "\f10a";
}
.fi-ts-8:before {
    content: "\f10b";
}
.fi-ts-9:before {
    content: "\f10c";
}
.fi-ts-a:before {
    content: "\f10d";
}
.fi-ts-accident:before {
    content: "\f10e";
}
.fi-ts-acorn:before {
    content: "\f10f";
}
.fi-ts-ad-paid:before {
    content: "\f110";
}
.fi-ts-ad:before {
    content: "\f111";
}
.fi-ts-add-image:before {
    content: "\f112";
}
.fi-ts-address-book:before {
    content: "\f113";
}
.fi-ts-address-card:before {
    content: "\f114";
}
.fi-ts-admin-alt:before {
    content: "\f115";
}
.fi-ts-admin:before {
    content: "\f116";
}
.fi-ts-age-restriction-eighteen:before {
    content: "\f117";
}
.fi-ts-age-restriction-seven:before {
    content: "\f118";
}
.fi-ts-age-restriction-six:before {
    content: "\f119";
}
.fi-ts-age-restriction-sixteen:before {
    content: "\f11a";
}
.fi-ts-age-restriction-thirteen:before {
    content: "\f11b";
}
.fi-ts-age-restriction-three:before {
    content: "\f11c";
}
.fi-ts-age-restriction-twelve:before {
    content: "\f11d";
}
.fi-ts-age-restriction-twenty-one:before {
    content: "\f11e";
}
.fi-ts-age-restriction-zero:before {
    content: "\f11f";
}
.fi-ts-air-conditioner:before {
    content: "\f120";
}
.fi-ts-air-freshener:before {
    content: "\f121";
}
.fi-ts-air-pollution:before {
    content: "\f122";
}
.fi-ts-airplane-journey:before {
    content: "\f123";
}
.fi-ts-airplay:before {
    content: "\f124";
}
.fi-ts-alarm-exclamation:before {
    content: "\f125";
}
.fi-ts-alarm-plus:before {
    content: "\f126";
}
.fi-ts-alarm-snooze:before {
    content: "\f127";
}
.fi-ts-album-circle-plus:before {
    content: "\f128";
}
.fi-ts-album-circle-user:before {
    content: "\f129";
}
.fi-ts-album-collection:before {
    content: "\f12a";
}
.fi-ts-album:before {
    content: "\f12b";
}
.fi-ts-algorithm:before {
    content: "\f12c";
}
.fi-ts-alicorn:before {
    content: "\f12d";
}
.fi-ts-alien:before {
    content: "\f12e";
}
.fi-ts-align-slash:before {
    content: "\f12f";
}
.fi-ts-analyse-alt:before {
    content: "\f130";
}
.fi-ts-analyse:before {
    content: "\f131";
}
.fi-ts-anchor:before {
    content: "\f132";
}
.fi-ts-angel:before {
    content: "\f133";
}
.fi-ts-angle-90:before {
    content: "\f134";
}
.fi-ts-angle-circle-down:before {
    content: "\f135";
}
.fi-ts-angle-circle-left:before {
    content: "\f136";
}
.fi-ts-angle-circle-right:before {
    content: "\f137";
}
.fi-ts-angle-circle-up:before {
    content: "\f138";
}
.fi-ts-angle-double-left:before {
    content: "\f139";
}
.fi-ts-angle-double-right:before {
    content: "\f13a";
}
.fi-ts-angle-double-small-down:before {
    content: "\f13b";
}
.fi-ts-angle-double-small-left:before {
    content: "\f13c";
}
.fi-ts-angle-double-small-right:before {
    content: "\f13d";
}
.fi-ts-angle-double-small-up:before {
    content: "\f13e";
}
.fi-ts-angle-down:before {
    content: "\f13f";
}
.fi-ts-angle-left:before {
    content: "\f140";
}
.fi-ts-angle-right:before {
    content: "\f141";
}
.fi-ts-angle-small-down:before {
    content: "\f142";
}
.fi-ts-angle-small-left:before {
    content: "\f143";
}
.fi-ts-angle-small-right:before {
    content: "\f144";
}
.fi-ts-angle-small-up:before {
    content: "\f145";
}
.fi-ts-angle-square-down:before {
    content: "\f146";
}
.fi-ts-angle-square-left:before {
    content: "\f147";
}
.fi-ts-angle-square-right:before {
    content: "\f148";
}
.fi-ts-angle-square-up:before {
    content: "\f149";
}
.fi-ts-angle-up:before {
    content: "\f14a";
}
.fi-ts-angle:before {
    content: "\f14b";
}
.fi-ts-angry:before {
    content: "\f14c";
}
.fi-ts-animated-icon:before {
    content: "\f14d";
}
.fi-ts-ankh:before {
    content: "\f14e";
}
.fi-ts-answer-alt:before {
    content: "\f14f";
}
.fi-ts-answer:before {
    content: "\f150";
}
.fi-ts-apartment:before {
    content: "\f151";
}
.fi-ts-aperture:before {
    content: "\f152";
}
.fi-ts-api:before {
    content: "\f153";
}
.fi-ts-apple-core:before {
    content: "\f154";
}
.fi-ts-apple-crate:before {
    content: "\f155";
}
.fi-ts-apple:before {
    content: "\f156";
}
.fi-ts-archway:before {
    content: "\f157";
}
.fi-ts-arrow-alt-circle-down:before {
    content: "\f158";
}
.fi-ts-arrow-alt-circle-left:before {
    content: "\f159";
}
.fi-ts-arrow-alt-circle-right:before {
    content: "\f15a";
}
.fi-ts-arrow-alt-circle-up:before {
    content: "\f15b";
}
.fi-ts-arrow-alt-down:before {
    content: "\f15c";
}
.fi-ts-arrow-alt-from-bottom:before {
    content: "\f15d";
}
.fi-ts-arrow-alt-from-left:before {
    content: "\f15e";
}
.fi-ts-arrow-alt-from-right:before {
    content: "\f15f";
}
.fi-ts-arrow-alt-from-top:before {
    content: "\f160";
}
.fi-ts-arrow-alt-left:before {
    content: "\f161";
}
.fi-ts-arrow-alt-right:before {
    content: "\f162";
}
.fi-ts-arrow-alt-square-down:before {
    content: "\f163";
}
.fi-ts-arrow-alt-square-left:before {
    content: "\f164";
}
.fi-ts-arrow-alt-square-right:before {
    content: "\f165";
}
.fi-ts-arrow-alt-square-up:before {
    content: "\f166";
}
.fi-ts-arrow-alt-to-bottom:before {
    content: "\f167";
}
.fi-ts-arrow-alt-to-left:before {
    content: "\f168";
}
.fi-ts-arrow-alt-to-right:before {
    content: "\f169";
}
.fi-ts-arrow-alt-to-top:before {
    content: "\f16a";
}
.fi-ts-arrow-alt-up:before {
    content: "\f16b";
}
.fi-ts-arrow-circle-down:before {
    content: "\f16c";
}
.fi-ts-arrow-circle-left:before {
    content: "\f16d";
}
.fi-ts-arrow-circle-right:before {
    content: "\f16e";
}
.fi-ts-arrow-circle-up:before {
    content: "\f16f";
}
.fi-ts-arrow-comparison:before {
    content: "\f170";
}
.fi-ts-arrow-down-from-dotted-line:before {
    content: "\f171";
}
.fi-ts-arrow-down-left:before {
    content: "\f172";
}
.fi-ts-arrow-down-small-big:before {
    content: "\f173";
}
.fi-ts-arrow-down-to-dotted-line:before {
    content: "\f174";
}
.fi-ts-arrow-down-to-square:before {
    content: "\f175";
}
.fi-ts-arrow-down-triangle-square:before {
    content: "\f176";
}
.fi-ts-arrow-down:before {
    content: "\f177";
}
.fi-ts-arrow-from-bottom:before {
    content: "\f178";
}
.fi-ts-arrow-from-left:before {
    content: "\f179";
}
.fi-ts-arrow-from-right:before {
    content: "\f17a";
}
.fi-ts-arrow-from-top:before {
    content: "\f17b";
}
.fi-ts-arrow-left-from-line:before {
    content: "\f17c";
}
.fi-ts-arrow-left:before {
    content: "\f17d";
}
.fi-ts-arrow-progress:before {
    content: "\f17e";
}
.fi-ts-arrow-right-to-bracket:before {
    content: "\f17f";
}
.fi-ts-arrow-right:before {
    content: "\f180";
}
.fi-ts-arrow-small-down:before {
    content: "\f181";
}
.fi-ts-arrow-small-left:before {
    content: "\f182";
}
.fi-ts-arrow-small-right:before {
    content: "\f183";
}
.fi-ts-arrow-small-up:before {
    content: "\f184";
}
.fi-ts-arrow-square-down:before {
    content: "\f185";
}
.fi-ts-arrow-square-left:before {
    content: "\f186";
}
.fi-ts-arrow-square-right:before {
    content: "\f187";
}
.fi-ts-arrow-square-up:before {
    content: "\f188";
}
.fi-ts-arrow-to-bottom:before {
    content: "\f189";
}
.fi-ts-arrow-to-left:before {
    content: "\f18a";
}
.fi-ts-arrow-to-right:before {
    content: "\f18b";
}
.fi-ts-arrow-to-top:before {
    content: "\f18c";
}
.fi-ts-arrow-trend-down:before {
    content: "\f18d";
}
.fi-ts-arrow-trend-up:before {
    content: "\f18e";
}
.fi-ts-arrow-turn-down-left:before {
    content: "\f18f";
}
.fi-ts-arrow-turn-down-right:before {
    content: "\f190";
}
.fi-ts-arrow-up-from-dotted-line:before {
    content: "\f191";
}
.fi-ts-arrow-up-from-square:before {
    content: "\f192";
}
.fi-ts-arrow-up-left-from-circle:before {
    content: "\f193";
}
.fi-ts-arrow-up-left:before {
    content: "\f194";
}
.fi-ts-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f195";
}
.fi-ts-arrow-up-right-from-square:before {
    content: "\f196";
}
.fi-ts-arrow-up-right:before {
    content: "\f197";
}
.fi-ts-arrow-up-small-big:before {
    content: "\f198";
}
.fi-ts-arrow-up-square-triangle:before {
    content: "\f199";
}
.fi-ts-arrow-up-to-dotted-line:before {
    content: "\f19a";
}
.fi-ts-arrow-up:before {
    content: "\f19b";
}
.fi-ts-arrows-alt-h:before {
    content: "\f19c";
}
.fi-ts-arrows-alt-v:before {
    content: "\f19d";
}
.fi-ts-arrows-alt:before {
    content: "\f19e";
}
.fi-ts-arrows-cross:before {
    content: "\f19f";
}
.fi-ts-arrows-from-dotted-line:before {
    content: "\f1a0";
}
.fi-ts-arrows-from-line:before {
    content: "\f1a1";
}
.fi-ts-arrows-h-copy:before {
    content: "\f1a2";
}
.fi-ts-arrows-h:before {
    content: "\f1a3";
}
.fi-ts-arrows-repeat-1:before {
    content: "\f1a4";
}
.fi-ts-arrows-repeat:before {
    content: "\f1a5";
}
.fi-ts-arrows-retweet:before {
    content: "\f1a6";
}
.fi-ts-arrows-to-dotted-line:before {
    content: "\f1a7";
}
.fi-ts-arrows-to-eye:before {
    content: "\f1a8";
}
.fi-ts-arrows-to-line:before {
    content: "\f1a9";
}
.fi-ts-arrows:before {
    content: "\f1aa";
}
.fi-ts-artificial-intelligence:before {
    content: "\f1ab";
}
.fi-ts-assessment-alt:before {
    content: "\f1ac";
}
.fi-ts-assessment:before {
    content: "\f1ad";
}
.fi-ts-assign:before {
    content: "\f1ae";
}
.fi-ts-assistive-listening-systems:before {
    content: "\f1af";
}
.fi-ts-at:before {
    content: "\f1b0";
}
.fi-ts-attribution-pen:before {
    content: "\f1b1";
}
.fi-ts-attribution-pencil:before {
    content: "\f1b2";
}
.fi-ts-aubergine:before {
    content: "\f1b3";
}
.fi-ts-auction:before {
    content: "\f1b4";
}
.fi-ts-audio-description-slash:before {
    content: "\f1b5";
}
.fi-ts-audit-alt:before {
    content: "\f1b6";
}
.fi-ts-audit:before {
    content: "\f1b7";
}
.fi-ts-austral-sign:before {
    content: "\f1b8";
}
.fi-ts-avocado:before {
    content: "\f1b9";
}
.fi-ts-award:before {
    content: "\f1ba";
}
.fi-ts-axe-battle:before {
    content: "\f1bb";
}
.fi-ts-axe:before {
    content: "\f1bc";
}
.fi-ts-b:before {
    content: "\f1bd";
}
.fi-ts-baby-carriage:before {
    content: "\f1be";
}
.fi-ts-baby:before {
    content: "\f1bf";
}
.fi-ts-background:before {
    content: "\f1c0";
}
.fi-ts-bacon:before {
    content: "\f1c1";
}
.fi-ts-bacteria:before {
    content: "\f1c2";
}
.fi-ts-bacterium:before {
    content: "\f1c3";
}
.fi-ts-badge-check:before {
    content: "\f1c4";
}
.fi-ts-badge-dollar:before {
    content: "\f1c5";
}
.fi-ts-badge-leaf:before {
    content: "\f1c6";
}
.fi-ts-badge-percent:before {
    content: "\f1c7";
}
.fi-ts-badge-sheriff:before {
    content: "\f1c8";
}
.fi-ts-badger-honey:before {
    content: "\f1c9";
}
.fi-ts-badget-check-alt:before {
    content: "\f1ca";
}
.fi-ts-badminton:before {
    content: "\f1cb";
}
.fi-ts-bag-seedling:before {
    content: "\f1cc";
}
.fi-ts-bags-shopping:before {
    content: "\f1cd";
}
.fi-ts-baguette:before {
    content: "\f1ce";
}
.fi-ts-bahai:before {
    content: "\f1cf";
}
.fi-ts-baht-sign:before {
    content: "\f1d0";
}
.fi-ts-balance-scale-left:before {
    content: "\f1d1";
}
.fi-ts-balance-scale-right:before {
    content: "\f1d2";
}
.fi-ts-ball-pile:before {
    content: "\f1d3";
}
.fi-ts-balloon:before {
    content: "\f1d4";
}
.fi-ts-balloons:before {
    content: "\f1d5";
}
.fi-ts-ballot-check:before {
    content: "\f1d6";
}
.fi-ts-ballot:before {
    content: "\f1d7";
}
.fi-ts-ban-bug:before {
    content: "\f1d8";
}
.fi-ts-banana:before {
    content: "\f1d9";
}
.fi-ts-bangladeshi-taka-sign:before {
    content: "\f1da";
}
.fi-ts-banjo:before {
    content: "\f1db";
}
.fi-ts-bank:before {
    content: "\f1dc";
}
.fi-ts-banner-2:before {
    content: "\f1dd";
}
.fi-ts-banner-3:before {
    content: "\f1de";
}
.fi-ts-banner-4:before {
    content: "\f1df";
}
.fi-ts-banner-5:before {
    content: "\f1e0";
}
.fi-ts-banner:before {
    content: "\f1e1";
}
.fi-ts-barber-pole:before {
    content: "\f1e2";
}
.fi-ts-barcode-read:before {
    content: "\f1e3";
}
.fi-ts-barcode-scan:before {
    content: "\f1e4";
}
.fi-ts-barcode:before {
    content: "\f1e5";
}
.fi-ts-bars-filter:before {
    content: "\f1e6";
}
.fi-ts-bars-progress:before {
    content: "\f1e7";
}
.fi-ts-bars-sort:before {
    content: "\f1e8";
}
.fi-ts-bars-staggered:before {
    content: "\f1e9";
}
.fi-ts-baseball-alt:before {
    content: "\f1ea";
}
.fi-ts-basket-shopping-simple:before {
    content: "\f1eb";
}
.fi-ts-basketball-hoop:before {
    content: "\f1ec";
}
.fi-ts-bat:before {
    content: "\f1ed";
}
.fi-ts-bath:before {
    content: "\f1ee";
}
.fi-ts-battery-bolt:before {
    content: "\f1ef";
}
.fi-ts-battery-empty:before {
    content: "\f1f0";
}
.fi-ts-battery-exclamation:before {
    content: "\f1f1";
}
.fi-ts-battery-full:before {
    content: "\f1f2";
}
.fi-ts-battery-half:before {
    content: "\f1f3";
}
.fi-ts-battery-quarter:before {
    content: "\f1f4";
}
.fi-ts-battery-slash:before {
    content: "\f1f5";
}
.fi-ts-battery-three-quarters:before {
    content: "\f1f6";
}
.fi-ts-beacon:before {
    content: "\f1f7";
}
.fi-ts-bed-alt:before {
    content: "\f1f8";
}
.fi-ts-bed-bunk:before {
    content: "\f1f9";
}
.fi-ts-bed-empty:before {
    content: "\f1fa";
}
.fi-ts-bee:before {
    content: "\f1fb";
}
.fi-ts-beer-mug-empty:before {
    content: "\f1fc";
}
.fi-ts-beer:before {
    content: "\f1fd";
}
.fi-ts-bell-concierge:before {
    content: "\f1fe";
}
.fi-ts-bell-school-slash:before {
    content: "\f1ff";
}
.fi-ts-bell-slash:before {
    content: "\f200";
}
.fi-ts-bells:before {
    content: "\f201";
}
.fi-ts-bench-tree:before {
    content: "\f202";
}
.fi-ts-benefit-porcent:before {
    content: "\f203";
}
.fi-ts-betamax:before {
    content: "\f204";
}
.fi-ts-bible:before {
    content: "\f205";
}
.fi-ts-bicycle-journey:before {
    content: "\f206";
}
.fi-ts-biking-mountain:before {
    content: "\f207";
}
.fi-ts-biking:before {
    content: "\f208";
}
.fi-ts-bill-arrow:before {
    content: "\f209";
}
.fi-ts-binary-circle-check:before {
    content: "\f20a";
}
.fi-ts-binary-lock:before {
    content: "\f20b";
}
.fi-ts-binary-slash:before {
    content: "\f20c";
}
.fi-ts-binary:before {
    content: "\f20d";
}
.fi-ts-binoculars:before {
    content: "\f20e";
}
.fi-ts-bio-leaves:before {
    content: "\f20f";
}
.fi-ts-bio:before {
    content: "\f210";
}
.fi-ts-biohazard:before {
    content: "\f211";
}
.fi-ts-bird:before {
    content: "\f212";
}
.fi-ts-bitcoin-sign:before {
    content: "\f213";
}
.fi-ts-blanket:before {
    content: "\f214";
}
.fi-ts-blender-phone:before {
    content: "\f215";
}
.fi-ts-blender:before {
    content: "\f216";
}
.fi-ts-blinds-open:before {
    content: "\f217";
}
.fi-ts-blinds-raised:before {
    content: "\f218";
}
.fi-ts-blinds:before {
    content: "\f219";
}
.fi-ts-block-brick:before {
    content: "\f21a";
}
.fi-ts-block-question:before {
    content: "\f21b";
}
.fi-ts-block-quote:before {
    content: "\f21c";
}
.fi-ts-block:before {
    content: "\f21d";
}
.fi-ts-blockchain-3:before {
    content: "\f21e";
}
.fi-ts-blog-pencil:before {
    content: "\f21f";
}
.fi-ts-blog-text:before {
    content: "\f220";
}
.fi-ts-blueberries:before {
    content: "\f221";
}
.fi-ts-blueprint:before {
    content: "\f222";
}
.fi-ts-bolt-auto:before {
    content: "\f223";
}
.fi-ts-bolt-slash:before {
    content: "\f224";
}
.fi-ts-bolt:before {
    content: "\f225";
}
.fi-ts-bomb:before {
    content: "\f226";
}
.fi-ts-bone-break:before {
    content: "\f227";
}
.fi-ts-bone:before {
    content: "\f228";
}
.fi-ts-bong:before {
    content: "\f229";
}
.fi-ts-bonus-star:before {
    content: "\f22a";
}
.fi-ts-bonus:before {
    content: "\f22b";
}
.fi-ts-book-arrow-right:before {
    content: "\f22c";
}
.fi-ts-book-arrow-up:before {
    content: "\f22d";
}
.fi-ts-book-atlas:before {
    content: "\f22e";
}
.fi-ts-book-bookmark:before {
    content: "\f22f";
}
.fi-ts-book-circle-arrow-right:before {
    content: "\f230";
}
.fi-ts-book-circle-arrow-up:before {
    content: "\f231";
}
.fi-ts-book-copy:before {
    content: "\f232";
}
.fi-ts-book-dead:before {
    content: "\f233";
}
.fi-ts-book-font:before {
    content: "\f234";
}
.fi-ts-book-heart:before {
    content: "\f235";
}
.fi-ts-book-medical:before {
    content: "\f236";
}
.fi-ts-book-open-cover:before {
    content: "\f237";
}
.fi-ts-book-open-reader:before {
    content: "\f238";
}
.fi-ts-book-quran:before {
    content: "\f239";
}
.fi-ts-book-section:before {
    content: "\f23a";
}
.fi-ts-book-spells:before {
    content: "\f23b";
}
.fi-ts-book-tanakh:before {
    content: "\f23c";
}
.fi-ts-book-user:before {
    content: "\f23d";
}
.fi-ts-Booking:before {
    content: "\f23e";
}
.fi-ts-bookmark-slash:before {
    content: "\f23f";
}
.fi-ts-books-medical:before {
    content: "\f240";
}
.fi-ts-books:before {
    content: "\f241";
}
.fi-ts-boot-heeled:before {
    content: "\f242";
}
.fi-ts-boot:before {
    content: "\f243";
}
.fi-ts-booth-curtain:before {
    content: "\f244";
}
.fi-ts-border-all:before {
    content: "\f245";
}
.fi-ts-border-bottom:before {
    content: "\f246";
}
.fi-ts-border-center-h:before {
    content: "\f247";
}
.fi-ts-border-center-v:before {
    content: "\f248";
}
.fi-ts-border-inner:before {
    content: "\f249";
}
.fi-ts-border-left:before {
    content: "\f24a";
}
.fi-ts-border-none:before {
    content: "\f24b";
}
.fi-ts-border-outer:before {
    content: "\f24c";
}
.fi-ts-border-right:before {
    content: "\f24d";
}
.fi-ts-border-style-alt:before {
    content: "\f24e";
}
.fi-ts-border-style:before {
    content: "\f24f";
}
.fi-ts-border-top:before {
    content: "\f250";
}
.fi-ts-boss:before {
    content: "\f251";
}
.fi-ts-bottle-droplet:before {
    content: "\f252";
}
.fi-ts-bottle:before {
    content: "\f253";
}
.fi-ts-bow-arrow:before {
    content: "\f254";
}
.fi-ts-bowl-chopsticks-noodles:before {
    content: "\f255";
}
.fi-ts-bowl-chopsticks:before {
    content: "\f256";
}
.fi-ts-bowl-rice:before {
    content: "\f257";
}
.fi-ts-bowl-scoop:before {
    content: "\f258";
}
.fi-ts-bowl-scoops:before {
    content: "\f259";
}
.fi-ts-bowl-soft-serve:before {
    content: "\f25a";
}
.fi-ts-bowl-spoon:before {
    content: "\f25b";
}
.fi-ts-bowling-ball:before {
    content: "\f25c";
}
.fi-ts-bowling-pins:before {
    content: "\f25d";
}
.fi-ts-box-alt:before {
    content: "\f25e";
}
.fi-ts-box-ballot:before {
    content: "\f25f";
}
.fi-ts-box-check:before {
    content: "\f260";
}
.fi-ts-box-circle-check:before {
    content: "\f261";
}
.fi-ts-box-dollar:before {
    content: "\f262";
}
.fi-ts-box-fragile:before {
    content: "\f263";
}
.fi-ts-box-heart:before {
    content: "\f264";
}
.fi-ts-box-open-full:before {
    content: "\f265";
}
.fi-ts-box-open:before {
    content: "\f266";
}
.fi-ts-box-tissue:before {
    content: "\f267";
}
.fi-ts-box-up:before {
    content: "\f268";
}
.fi-ts-boxes:before {
    content: "\f269";
}
.fi-ts-boxing-glove:before {
    content: "\f26a";
}
.fi-ts-bracket-curly-right:before {
    content: "\f26b";
}
.fi-ts-bracket-curly:before {
    content: "\f26c";
}
.fi-ts-bracket-round-right:before {
    content: "\f26d";
}
.fi-ts-bracket-round:before {
    content: "\f26e";
}
.fi-ts-bracket-square-right:before {
    content: "\f26f";
}
.fi-ts-bracket-square:before {
    content: "\f270";
}
.fi-ts-brackets-curly:before {
    content: "\f271";
}
.fi-ts-brackets-round:before {
    content: "\f272";
}
.fi-ts-brackets-square:before {
    content: "\f273";
}
.fi-ts-braille-a:before {
    content: "\f274";
}
.fi-ts-braille-b:before {
    content: "\f275";
}
.fi-ts-braille-c:before {
    content: "\f276";
}
.fi-ts-braille-d:before {
    content: "\f277";
}
.fi-ts-braille-e:before {
    content: "\f278";
}
.fi-ts-braille-g:before {
    content: "\f279";
}
.fi-ts-braille-h:before {
    content: "\f27a";
}
.fi-ts-braille-i:before {
    content: "\f27b";
}
.fi-ts-braille-j:before {
    content: "\f27c";
}
.fi-ts-braille-k:before {
    content: "\f27d";
}
.fi-ts-braille-l:before {
    content: "\f27e";
}
.fi-ts-braille-m:before {
    content: "\f27f";
}
.fi-ts-braille-n-alt:before {
    content: "\f280";
}
.fi-ts-braille-n:before {
    content: "\f281";
}
.fi-ts-braille-o:before {
    content: "\f282";
}
.fi-ts-braille-p:before {
    content: "\f283";
}
.fi-ts-braille-q:before {
    content: "\f284";
}
.fi-ts-braille-r:before {
    content: "\f285";
}
.fi-ts-braille-s:before {
    content: "\f286";
}
.fi-ts-braille-t:before {
    content: "\f287";
}
.fi-ts-braille-u:before {
    content: "\f288";
}
.fi-ts-braille-v:before {
    content: "\f289";
}
.fi-ts-braille-w:before {
    content: "\f28a";
}
.fi-ts-braille-x:before {
    content: "\f28b";
}
.fi-ts-braille-y:before {
    content: "\f28c";
}
.fi-ts-braille-z:before {
    content: "\f28d";
}
.fi-ts-braille:before {
    content: "\f28e";
}
.fi-ts-brain-circuit:before {
    content: "\f28f";
}
.fi-ts-brain:before {
    content: "\f290";
}
.fi-ts-brand:before {
    content: "\f291";
}
.fi-ts-branding:before {
    content: "\f292";
}
.fi-ts-bread-loaf:before {
    content: "\f293";
}
.fi-ts-bread-slice-butter:before {
    content: "\f294";
}
.fi-ts-bread-slice:before {
    content: "\f295";
}
.fi-ts-bread:before {
    content: "\f296";
}
.fi-ts-briefcase-arrow-right:before {
    content: "\f297";
}
.fi-ts-briefcase-blank:before {
    content: "\f298";
}
.fi-ts-brightness-low:before {
    content: "\f299";
}
.fi-ts-brightness:before {
    content: "\f29a";
}
.fi-ts-bring-forward:before {
    content: "\f29b";
}
.fi-ts-bring-front:before {
    content: "\f29c";
}
.fi-ts-broadcast-tower:before {
    content: "\f29d";
}
.fi-ts-broccoli:before {
    content: "\f29e";
}
.fi-ts-broken-image:before {
    content: "\f29f";
}
.fi-ts-broom-ball:before {
    content: "\f2a0";
}
.fi-ts-browser-ui:before {
    content: "\f2a1";
}
.fi-ts-browser:before {
    content: "\f2a2";
}
.fi-ts-browsers:before {
    content: "\f2a3";
}
.fi-ts-bucket:before {
    content: "\f2a4";
}
.fi-ts-budget-alt:before {
    content: "\f2a5";
}
.fi-ts-budget:before {
    content: "\f2a6";
}
.fi-ts-bug-slash:before {
    content: "\f2a7";
}
.fi-ts-bugs:before {
    content: "\f2a8";
}
.fi-ts-build-alt:before {
    content: "\f2a9";
}
.fi-ts-build:before {
    content: "\f2aa";
}
.fi-ts-builder:before {
    content: "\f2ab";
}
.fi-ts-building-circle-arrow-right:before {
    content: "\f2ac";
}
.fi-ts-building-ngo:before {
    content: "\f2ad";
}
.fi-ts-building:before {
    content: "\f2ae";
}
.fi-ts-bulb:before {
    content: "\f2af";
}
.fi-ts-bullet:before {
    content: "\f2b0";
}
.fi-ts-bullhorn:before {
    content: "\f2b1";
}
.fi-ts-bullseye-arrow:before {
    content: "\f2b2";
}
.fi-ts-bullseye-pointer:before {
    content: "\f2b3";
}
.fi-ts-bullseye:before {
    content: "\f2b4";
}
.fi-ts-burger-alt:before {
    content: "\f2b5";
}
.fi-ts-burger-fries:before {
    content: "\f2b6";
}
.fi-ts-burger-glass:before {
    content: "\f2b7";
}
.fi-ts-burrito:before {
    content: "\f2b8";
}
.fi-ts-bus-alt:before {
    content: "\f2b9";
}
.fi-ts-bus:before {
    content: "\f2ba";
}
.fi-ts-business-time:before {
    content: "\f2bb";
}
.fi-ts-Butter:before {
    content: "\f2bc";
}
.fi-ts-butterfly:before {
    content: "\f2bd";
}
.fi-ts-c:before {
    content: "\f2be";
}
.fi-ts-cabin:before {
    content: "\f2bf";
}
.fi-ts-cactus:before {
    content: "\f2c0";
}
.fi-ts-cake-birthday:before {
    content: "\f2c1";
}
.fi-ts-cake-slice:before {
    content: "\f2c2";
}
.fi-ts-cake-wedding:before {
    content: "\f2c3";
}
.fi-ts-calculator-bill:before {
    content: "\f2c4";
}
.fi-ts-calculator-money:before {
    content: "\f2c5";
}
.fi-ts-calculator-simple:before {
    content: "\f2c6";
}
.fi-ts-calculator:before {
    content: "\f2c7";
}
.fi-ts-calendar-arrow-down:before {
    content: "\f2c8";
}
.fi-ts-calendar-arrow-up:before {
    content: "\f2c9";
}
.fi-ts-calendar-check:before {
    content: "\f2ca";
}
.fi-ts-calendar-clock:before {
    content: "\f2cb";
}
.fi-ts-calendar-day:before {
    content: "\f2cc";
}
.fi-ts-calendar-days:before {
    content: "\f2cd";
}
.fi-ts-calendar-exclamation:before {
    content: "\f2ce";
}
.fi-ts-calendar-heart:before {
    content: "\f2cf";
}
.fi-ts-calendar-image:before {
    content: "\f2d0";
}
.fi-ts-calendar-lines-pen:before {
    content: "\f2d1";
}
.fi-ts-calendar-lines:before {
    content: "\f2d2";
}
.fi-ts-calendar-minus:before {
    content: "\f2d3";
}
.fi-ts-calendar-pen:before {
    content: "\f2d4";
}
.fi-ts-calendar-plus:before {
    content: "\f2d5";
}
.fi-ts-calendar-salary:before {
    content: "\f2d6";
}
.fi-ts-calendar-star:before {
    content: "\f2d7";
}
.fi-ts-calendar-week:before {
    content: "\f2d8";
}
.fi-ts-calendar-xmark:before {
    content: "\f2d9";
}
.fi-ts-calendars:before {
    content: "\f2da";
}
.fi-ts-call-duration:before {
    content: "\f2db";
}
.fi-ts-call-history:before {
    content: "\f2dc";
}
.fi-ts-call-incoming:before {
    content: "\f2dd";
}
.fi-ts-call-missed:before {
    content: "\f2de";
}
.fi-ts-call-outgoing:before {
    content: "\f2df";
}
.fi-ts-camcorder:before {
    content: "\f2e0";
}
.fi-ts-camera-cctv:before {
    content: "\f2e1";
}
.fi-ts-camera-movie:before {
    content: "\f2e2";
}
.fi-ts-camera-retro:before {
    content: "\f2e3";
}
.fi-ts-camera-rotate:before {
    content: "\f2e4";
}
.fi-ts-camera-security:before {
    content: "\f2e5";
}
.fi-ts-camera-slash:before {
    content: "\f2e6";
}
.fi-ts-camera-viewfinder:before {
    content: "\f2e7";
}
.fi-ts-campfire:before {
    content: "\f2e8";
}
.fi-ts-can-food:before {
    content: "\f2e9";
}
.fi-ts-candle-holder:before {
    content: "\f2ea";
}
.fi-ts-candy-alt:before {
    content: "\f2eb";
}
.fi-ts-candy-bar:before {
    content: "\f2ec";
}
.fi-ts-candy-cane:before {
    content: "\f2ed";
}
.fi-ts-candy-corn:before {
    content: "\f2ee";
}
.fi-ts-candy-sweet:before {
    content: "\f2ef";
}
.fi-ts-candy:before {
    content: "\f2f0";
}
.fi-ts-Cannabis:before {
    content: "\f2f1";
}
.fi-ts-canned-food:before {
    content: "\f2f2";
}
.fi-ts-capsules:before {
    content: "\f2f3";
}
.fi-ts-car-alt:before {
    content: "\f2f4";
}
.fi-ts-car-battery:before {
    content: "\f2f5";
}
.fi-ts-car-bolt:before {
    content: "\f2f6";
}
.fi-ts-car-building:before {
    content: "\f2f7";
}
.fi-ts-car-bump:before {
    content: "\f2f8";
}
.fi-ts-car-bus:before {
    content: "\f2f9";
}
.fi-ts-car-circle-bolt:before {
    content: "\f2fa";
}
.fi-ts-car-crash:before {
    content: "\f2fb";
}
.fi-ts-car-garage:before {
    content: "\f2fc";
}
.fi-ts-car-journey:before {
    content: "\f2fd";
}
.fi-ts-car-mechanic:before {
    content: "\f2fe";
}
.fi-ts-car-rear:before {
    content: "\f2ff";
}
.fi-ts-car-side-bolt:before {
    content: "\f300";
}
.fi-ts-car-side:before {
    content: "\f301";
}
.fi-ts-car-tilt:before {
    content: "\f302";
}
.fi-ts-car-wash:before {
    content: "\f303";
}
.fi-ts-caravan-alt:before {
    content: "\f304";
}
.fi-ts-caravan:before {
    content: "\f305";
}
.fi-ts-card-club:before {
    content: "\f306";
}
.fi-ts-card-diamond:before {
    content: "\f307";
}
.fi-ts-card-heart:before {
    content: "\f308";
}
.fi-ts-card-spade:before {
    content: "\f309";
}
.fi-ts-caret-circle-down:before {
    content: "\f30a";
}
.fi-ts-caret-circle-right:before {
    content: "\f30b";
}
.fi-ts-caret-circle-up:before {
    content: "\f30c";
}
.fi-ts-caret-down:before {
    content: "\f30d";
}
.fi-ts-caret-left:before {
    content: "\f30e";
}
.fi-ts-caret-right:before {
    content: "\f30f";
}
.fi-ts-caret-square-down:before {
    content: "\f310";
}
.fi-ts-caret-square-left_1:before {
    content: "\f311";
}
.fi-ts-caret-square-left:before {
    content: "\f312";
}
.fi-ts-caret-square-right:before {
    content: "\f313";
}
.fi-ts-caret-square-up:before {
    content: "\f314";
}
.fi-ts-caret-up:before {
    content: "\f315";
}
.fi-ts-carrot:before {
    content: "\f316";
}
.fi-ts-cars-crash:before {
    content: "\f317";
}
.fi-ts-cars:before {
    content: "\f318";
}
.fi-ts-cart-arrow-down:before {
    content: "\f319";
}
.fi-ts-cart-minus:before {
    content: "\f31a";
}
.fi-ts-cart-shopping-fast:before {
    content: "\f31b";
}
.fi-ts-cash-register:before {
    content: "\f31c";
}
.fi-ts-cassette-tape:before {
    content: "\f31d";
}
.fi-ts-cassette-vhs:before {
    content: "\f31e";
}
.fi-ts-castle:before {
    content: "\f31f";
}
.fi-ts-cat-head:before {
    content: "\f320";
}
.fi-ts-cat-space:before {
    content: "\f321";
}
.fi-ts-cat:before {
    content: "\f322";
}
.fi-ts-catalog-alt:before {
    content: "\f323";
}
.fi-ts-catalog-magazine:before {
    content: "\f324";
}
.fi-ts-catalog:before {
    content: "\f325";
}
.fi-ts-category-alt:before {
    content: "\f326";
}
.fi-ts-category:before {
    content: "\f327";
}
.fi-ts-cauldron:before {
    content: "\f328";
}
.fi-ts-cedi-sign:before {
    content: "\f329";
}
.fi-ts-cello:before {
    content: "\f32a";
}
.fi-ts-cent-sign:before {
    content: "\f32b";
}
.fi-ts-chair-office:before {
    content: "\f32c";
}
.fi-ts-chair:before {
    content: "\f32d";
}
.fi-ts-chalkboard-user:before {
    content: "\f32e";
}
.fi-ts-chalkboard:before {
    content: "\f32f";
}
.fi-ts-challenge-alt:before {
    content: "\f330";
}
.fi-ts-challenge:before {
    content: "\f331";
}
.fi-ts-channel:before {
    content: "\f332";
}
.fi-ts-charging-station:before {
    content: "\f333";
}
.fi-ts-chart-area:before {
    content: "\f334";
}
.fi-ts-chart-arrow-down:before {
    content: "\f335";
}
.fi-ts-chart-arrow-grow:before {
    content: "\f336";
}
.fi-ts-chart-bullet:before {
    content: "\f337";
}
.fi-ts-chart-candlestick:before {
    content: "\f338";
}
.fi-ts-chart-connected:before {
    content: "\f339";
}
.fi-ts-chart-gantt:before {
    content: "\f33a";
}
.fi-ts-chart-histogram:before {
    content: "\f33b";
}
.fi-ts-chart-line-up:before {
    content: "\f33c";
}
.fi-ts-chart-mixed:before {
    content: "\f33d";
}
.fi-ts-chart-network:before {
    content: "\f33e";
}
.fi-ts-chart-pie-alt:before {
    content: "\f33f";
}
.fi-ts-chart-pie:before {
    content: "\f340";
}
.fi-ts-chart-pyramid:before {
    content: "\f341";
}
.fi-ts-chart-radar:before {
    content: "\f342";
}
.fi-ts-chart-scatter-3d:before {
    content: "\f343";
}
.fi-ts-chart-scatter-bubble:before {
    content: "\f344";
}
.fi-ts-chart-scatter:before {
    content: "\f345";
}
.fi-ts-chart-set-theory:before {
    content: "\f346";
}
.fi-ts-chart-simple-horizontal:before {
    content: "\f347";
}
.fi-ts-chart-simple:before {
    content: "\f348";
}
.fi-ts-chart-tree-map:before {
    content: "\f349";
}
.fi-ts-chart-tree:before {
    content: "\f34a";
}
.fi-ts-chart-user:before {
    content: "\f34b";
}
.fi-ts-chart-waterfall:before {
    content: "\f34c";
}
.fi-ts-chatbot-speech-bubble:before {
    content: "\f34d";
}
.fi-ts-chatbot:before {
    content: "\f34e";
}
.fi-ts-cheap-bill:before {
    content: "\f34f";
}
.fi-ts-cheap-dollar:before {
    content: "\f350";
}
.fi-ts-cheap-stack-dollar:before {
    content: "\f351";
}
.fi-ts-cheap-stack:before {
    content: "\f352";
}
.fi-ts-cheap:before {
    content: "\f353";
}
.fi-ts-check-circle:before {
    content: "\f354";
}
.fi-ts-check-double:before {
    content: "\f355";
}
.fi-ts-check-in-calendar:before {
    content: "\f356";
}
.fi-ts-check-out-calendar:before {
    content: "\f357";
}
.fi-ts-cheese-alt:before {
    content: "\f358";
}
.fi-ts-cheese:before {
    content: "\f359";
}
.fi-ts-cheeseburger:before {
    content: "\f35a";
}
.fi-ts-cherry:before {
    content: "\f35b";
}
.fi-ts-chess-bishop:before {
    content: "\f35c";
}
.fi-ts-chess-board:before {
    content: "\f35d";
}
.fi-ts-chess-clock-alt:before {
    content: "\f35e";
}
.fi-ts-chess-clock:before {
    content: "\f35f";
}
.fi-ts-chess-king-alt:before {
    content: "\f360";
}
.fi-ts-chess-king:before {
    content: "\f361";
}
.fi-ts-chess-knight-alt:before {
    content: "\f362";
}
.fi-ts-chess-knight:before {
    content: "\f363";
}
.fi-ts-chess-pawn-alt:before {
    content: "\f364";
}
.fi-ts-chess-pawn:before {
    content: "\f365";
}
.fi-ts-chess-queen-alt:before {
    content: "\f366";
}
.fi-ts-chess-queen:before {
    content: "\f367";
}
.fi-ts-chess-rook-alt:before {
    content: "\f368";
}
.fi-ts-chess-rook:before {
    content: "\f369";
}
.fi-ts-chess:before {
    content: "\f36a";
}
.fi-ts-chevron-double-down:before {
    content: "\f36b";
}
.fi-ts-chevron-double-up:before {
    content: "\f36c";
}
.fi-ts-child-head:before {
    content: "\f36d";
}
.fi-ts-child:before {
    content: "\f36e";
}
.fi-ts-chimney:before {
    content: "\f36f";
}
.fi-ts-chocolate-bar:before {
    content: "\f370";
}
.fi-ts-choose-alt:before {
    content: "\f371";
}
.fi-ts-choose:before {
    content: "\f372";
}
.fi-ts-church:before {
    content: "\f373";
}
.fi-ts-circle-0:before {
    content: "\f374";
}
.fi-ts-circle-1:before {
    content: "\f375";
}
.fi-ts-circle-2:before {
    content: "\f376";
}
.fi-ts-circle-3:before {
    content: "\f377";
}
.fi-ts-circle-4:before {
    content: "\f378";
}
.fi-ts-circle-5:before {
    content: "\f379";
}
.fi-ts-circle-6:before {
    content: "\f37a";
}
.fi-ts-circle-7:before {
    content: "\f37b";
}
.fi-ts-circle-8:before {
    content: "\f37c";
}
.fi-ts-circle-9:before {
    content: "\f37d";
}
.fi-ts-circle-a:before {
    content: "\f37e";
}
.fi-ts-circle-b:before {
    content: "\f37f";
}
.fi-ts-circle-bolt:before {
    content: "\f380";
}
.fi-ts-circle-book-open:before {
    content: "\f381";
}
.fi-ts-circle-bookmark:before {
    content: "\f382";
}
.fi-ts-circle-c:before {
    content: "\f383";
}
.fi-ts-circle-calendar:before {
    content: "\f384";
}
.fi-ts-circle-camera:before {
    content: "\f385";
}
.fi-ts-circle-d:before {
    content: "\f386";
}
.fi-ts-circle-dashed:before {
    content: "\f387";
}
.fi-ts-circle-divide:before {
    content: "\f388";
}
.fi-ts-circle-e:before {
    content: "\f389";
}
.fi-ts-circle-ellipsis-vertical:before {
    content: "\f38a";
}
.fi-ts-circle-ellipsis:before {
    content: "\f38b";
}
.fi-ts-circle-envelope:before {
    content: "\f38c";
}
.fi-ts-circle-exclamation-check:before {
    content: "\f38d";
}
.fi-ts-circle-f:before {
    content: "\f38e";
}
.fi-ts-circle-g:before {
    content: "\f38f";
}
.fi-ts-circle-h:before {
    content: "\f390";
}
.fi-ts-circle-half-stroke:before {
    content: "\f391";
}
.fi-ts-circle-half:before {
    content: "\f392";
}
.fi-ts-circle-heart:before {
    content: "\f393";
}
.fi-ts-circle-i:before {
    content: "\f394";
}
.fi-ts-circle-j:before {
    content: "\f395";
}
.fi-ts-circle-k:before {
    content: "\f396";
}
.fi-ts-circle-l:before {
    content: "\f397";
}
.fi-ts-circle-m:before {
    content: "\f398";
}
.fi-ts-circle-microphone-lines:before {
    content: "\f399";
}
.fi-ts-circle-microphone:before {
    content: "\f39a";
}
.fi-ts-circle-n:before {
    content: "\f39b";
}
.fi-ts-circle-o:before {
    content: "\f39c";
}
.fi-ts-circle-p:before {
    content: "\f39d";
}
.fi-ts-circle-phone-flip:before {
    content: "\f39e";
}
.fi-ts-circle-phone-hangup:before {
    content: "\f39f";
}
.fi-ts-circle-phone:before {
    content: "\f3a0";
}
.fi-ts-circle-q:before {
    content: "\f3a1";
}
.fi-ts-circle-quarter:before {
    content: "\f3a2";
}
.fi-ts-circle-quarters-alt:before {
    content: "\f3a3";
}
.fi-ts-circle-r:before {
    content: "\f3a4";
}
.fi-ts-circle-s:before {
    content: "\f3a5";
}
.fi-ts-circle-small:before {
    content: "\f3a6";
}
.fi-ts-circle-star:before {
    content: "\f3a7";
}
.fi-ts-circle-t:before {
    content: "\f3a8";
}
.fi-ts-circle-three-quarters:before {
    content: "\f3a9";
}
.fi-ts-circle-trash:before {
    content: "\f3aa";
}
.fi-ts-circle-u:before {
    content: "\f3ab";
}
.fi-ts-circle-user:before {
    content: "\f3ac";
}
.fi-ts-circle-v:before {
    content: "\f3ad";
}
.fi-ts-circle-video:before {
    content: "\f3ae";
}
.fi-ts-circle-w:before {
    content: "\f3af";
}
.fi-ts-circle-waveform-lines:before {
    content: "\f3b0";
}
.fi-ts-circle-x:before {
    content: "\f3b1";
}
.fi-ts-circle-xmark:before {
    content: "\f3b2";
}
.fi-ts-circle-y:before {
    content: "\f3b3";
}
.fi-ts-circle-z:before {
    content: "\f3b4";
}
.fi-ts-circle:before {
    content: "\f3b5";
}
.fi-ts-citrus-slice:before {
    content: "\f3b6";
}
.fi-ts-citrus:before {
    content: "\f3b7";
}
.fi-ts-city:before {
    content: "\f3b8";
}
.fi-ts-clapperboard-play:before {
    content: "\f3b9";
}
.fi-ts-clapperboard:before {
    content: "\f3ba";
}
.fi-ts-clarinet:before {
    content: "\f3bb";
}
.fi-ts-claw-marks:before {
    content: "\f3bc";
}
.fi-ts-clear-alt:before {
    content: "\f3bd";
}
.fi-ts-clipboard-check:before {
    content: "\f3be";
}
.fi-ts-clipboard-exclamation:before {
    content: "\f3bf";
}
.fi-ts-clipboard-list-check:before {
    content: "\f3c0";
}
.fi-ts-clipboard-list:before {
    content: "\f3c1";
}
.fi-ts-clipboard-prescription:before {
    content: "\f3c2";
}
.fi-ts-clipboard-user:before {
    content: "\f3c3";
}
.fi-ts-clipboard:before {
    content: "\f3c4";
}
.fi-ts-clock-desk:before {
    content: "\f3c5";
}
.fi-ts-clock-eight-thirty:before {
    content: "\f3c6";
}
.fi-ts-clock-eleven-thirty:before {
    content: "\f3c7";
}
.fi-ts-clock-eleven:before {
    content: "\f3c8";
}
.fi-ts-clock-five-thirty:before {
    content: "\f3c9";
}
.fi-ts-clock-five:before {
    content: "\f3ca";
}
.fi-ts-clock-four-thirty:before {
    content: "\f3cb";
}
.fi-ts-clock-nine-thirty:before {
    content: "\f3cc";
}
.fi-ts-clock-nine:before {
    content: "\f3cd";
}
.fi-ts-clock-one-thirty:before {
    content: "\f3ce";
}
.fi-ts-clock-one:before {
    content: "\f3cf";
}
.fi-ts-clock-seven-thirty:before {
    content: "\f3d0";
}
.fi-ts-clock-seven:before {
    content: "\f3d1";
}
.fi-ts-clock-six-thirty:before {
    content: "\f3d2";
}
.fi-ts-clock-six:before {
    content: "\f3d3";
}
.fi-ts-clock-ten-thirty:before {
    content: "\f3d4";
}
.fi-ts-clock-ten:before {
    content: "\f3d5";
}
.fi-ts-clock-three-thirty:before {
    content: "\f3d6";
}
.fi-ts-clock-three:before {
    content: "\f3d7";
}
.fi-ts-clock-twelve-thirty:before {
    content: "\f3d8";
}
.fi-ts-clock-twelve:before {
    content: "\f3d9";
}
.fi-ts-clock-two-thirty:before {
    content: "\f3da";
}
.fi-ts-clock-two:before {
    content: "\f3db";
}
.fi-ts-clock-up-arrow:before {
    content: "\f3dc";
}
.fi-ts-clone:before {
    content: "\f3dd";
}
.fi-ts-closed-captioning-slash:before {
    content: "\f3de";
}
.fi-ts-clothes-hanger:before {
    content: "\f3df";
}
.fi-ts-cloud-check:before {
    content: "\f3e0";
}
.fi-ts-cloud-code:before {
    content: "\f3e1";
}
.fi-ts-cloud-disabled:before {
    content: "\f3e2";
}
.fi-ts-cloud-download-alt:before {
    content: "\f3e3";
}
.fi-ts-cloud-download:before {
    content: "\f3e4";
}
.fi-ts-cloud-drizzle:before {
    content: "\f3e5";
}
.fi-ts-cloud-exclamation:before {
    content: "\f3e6";
}
.fi-ts-cloud-hail-mixed:before {
    content: "\f3e7";
}
.fi-ts-cloud-hail:before {
    content: "\f3e8";
}
.fi-ts-cloud-meatball:before {
    content: "\f3e9";
}
.fi-ts-cloud-moon-rain:before {
    content: "\f3ea";
}
.fi-ts-cloud-moon:before {
    content: "\f3eb";
}
.fi-ts-cloud-question:before {
    content: "\f3ec";
}
.fi-ts-cloud-rain:before {
    content: "\f3ed";
}
.fi-ts-cloud-rainbow:before {
    content: "\f3ee";
}
.fi-ts-cloud-share:before {
    content: "\f3ef";
}
.fi-ts-cloud-showers-heavy:before {
    content: "\f3f0";
}
.fi-ts-cloud-showers:before {
    content: "\f3f1";
}
.fi-ts-cloud-sleet:before {
    content: "\f3f2";
}
.fi-ts-cloud-snow:before {
    content: "\f3f3";
}
.fi-ts-cloud-sun-rain:before {
    content: "\f3f4";
}
.fi-ts-cloud-sun:before {
    content: "\f3f5";
}
.fi-ts-cloud-upload-alt:before {
    content: "\f3f6";
}
.fi-ts-clouds-moon:before {
    content: "\f3f7";
}
.fi-ts-clouds-sun:before {
    content: "\f3f8";
}
.fi-ts-clouds:before {
    content: "\f3f9";
}
.fi-ts-clover-alt:before {
    content: "\f3fa";
}
.fi-ts-club:before {
    content: "\f3fb";
}
.fi-ts-cocktail:before {
    content: "\f3fc";
}
.fi-ts-coconut:before {
    content: "\f3fd";
}
.fi-ts-code-branch:before {
    content: "\f3fe";
}
.fi-ts-code-commit:before {
    content: "\f3ff";
}
.fi-ts-code-compare:before {
    content: "\f400";
}
.fi-ts-code-fork:before {
    content: "\f401";
}
.fi-ts-code-merge:before {
    content: "\f402";
}
.fi-ts-code-pull-request-closed:before {
    content: "\f403";
}
.fi-ts-code-pull-request-draft:before {
    content: "\f404";
}
.fi-ts-code-pull-request:before {
    content: "\f405";
}
.fi-ts-code-simple:before {
    content: "\f406";
}
.fi-ts-coffee-bean:before {
    content: "\f407";
}
.fi-ts-coffee-beans:before {
    content: "\f408";
}
.fi-ts-coffee-pot:before {
    content: "\f409";
}
.fi-ts-coffee:before {
    content: "\f40a";
}
.fi-ts-coffin-cross:before {
    content: "\f40b";
}
.fi-ts-coffin:before {
    content: "\f40c";
}
.fi-ts-coin-up-arrow:before {
    content: "\f40d";
}
.fi-ts-coin:before {
    content: "\f40e";
}
.fi-ts-coins:before {
    content: "\f40f";
}
.fi-ts-colon-sign:before {
    content: "\f410";
}
.fi-ts-columns-3:before {
    content: "\f411";
}
.fi-ts-comet:before {
    content: "\f412";
}
.fi-ts-command:before {
    content: "\f413";
}
.fi-ts-comment-alt-check:before {
    content: "\f414";
}
.fi-ts-comment-alt-dots:before {
    content: "\f415";
}
.fi-ts-comment-alt-edit:before {
    content: "\f416";
}
.fi-ts-comment-alt-medical:before {
    content: "\f417";
}
.fi-ts-comment-alt-middle-top:before {
    content: "\f418";
}
.fi-ts-comment-alt-middle:before {
    content: "\f419";
}
.fi-ts-comment-alt-minus:before {
    content: "\f41a";
}
.fi-ts-comment-alt-music:before {
    content: "\f41b";
}
.fi-ts-comment-arrow-down:before {
    content: "\f41c";
}
.fi-ts-comment-arrow-up-right:before {
    content: "\f41d";
}
.fi-ts-comment-arrow-up:before {
    content: "\f41e";
}
.fi-ts-comment-check:before {
    content: "\f41f";
}
.fi-ts-comment-code:before {
    content: "\f420";
}
.fi-ts-comment-dollar:before {
    content: "\f421";
}
.fi-ts-comment-dots:before {
    content: "\f422";
}
.fi-ts-comment-exclamation:before {
    content: "\f423";
}
.fi-ts-comment-heart:before {
    content: "\f424";
}
.fi-ts-comment-image:before {
    content: "\f425";
}
.fi-ts-comment-info:before {
    content: "\f426";
}
.fi-ts-comment-medical:before {
    content: "\f427";
}
.fi-ts-comment-minus:before {
    content: "\f428";
}
.fi-ts-comment-pen:before {
    content: "\f429";
}
.fi-ts-comment-question:before {
    content: "\f42a";
}
.fi-ts-comment-quote:before {
    content: "\f42b";
}
.fi-ts-comment-slash:before {
    content: "\f42c";
}
.fi-ts-comment-smile:before {
    content: "\f42d";
}
.fi-ts-comment-sms:before {
    content: "\f42e";
}
.fi-ts-comment-text:before {
    content: "\f42f";
}
.fi-ts-comment-user:before {
    content: "\f430";
}
.fi-ts-comment-xmark:before {
    content: "\f431";
}
.fi-ts-comments-dollar:before {
    content: "\f432";
}
.fi-ts-comments-question-check:before {
    content: "\f433";
}
.fi-ts-comments-question:before {
    content: "\f434";
}
.fi-ts-comments:before {
    content: "\f435";
}
.fi-ts-compass-slash:before {
    content: "\f436";
}
.fi-ts-completed:before {
    content: "\f437";
}
.fi-ts-compliance-clipboard:before {
    content: "\f438";
}
.fi-ts-compliance-document:before {
    content: "\f439";
}
.fi-ts-compliance:before {
    content: "\f43a";
}
.fi-ts-compress-alt:before {
    content: "\f43b";
}
.fi-ts-computer-classic:before {
    content: "\f43c";
}
.fi-ts-computer-mouse:before {
    content: "\f43d";
}
.fi-ts-computer-speaker:before {
    content: "\f43e";
}
.fi-ts-concierge-bell:before {
    content: "\f43f";
}
.fi-ts-condition-alt:before {
    content: "\f440";
}
.fi-ts-condition:before {
    content: "\f441";
}
.fi-ts-confetti:before {
    content: "\f442";
}
.fi-ts-constellation:before {
    content: "\f443";
}
.fi-ts-container-storage:before {
    content: "\f444";
}
.fi-ts-convert-document:before {
    content: "\f445";
}
.fi-ts-conveyor-belt-alt:before {
    content: "\f446";
}
.fi-ts-conveyor-belt-empty:before {
    content: "\f447";
}
.fi-ts-conveyor-belt:before {
    content: "\f448";
}
.fi-ts-cookie-alt:before {
    content: "\f449";
}
.fi-ts-copy-image:before {
    content: "\f44a";
}
.fi-ts-corn:before {
    content: "\f44b";
}
.fi-ts-corporate-alt:before {
    content: "\f44c";
}
.fi-ts-corporate:before {
    content: "\f44d";
}
.fi-ts-couch:before {
    content: "\f44e";
}
.fi-ts-cow-alt:before {
    content: "\f44f";
}
.fi-ts-cow:before {
    content: "\f450";
}
.fi-ts-cowbell-circle-plus:before {
    content: "\f451";
}
.fi-ts-cowbell-more:before {
    content: "\f452";
}
.fi-ts-cowbell:before {
    content: "\f453";
}
.fi-ts-crab:before {
    content: "\f454";
}
.fi-ts-crate-empty:before {
    content: "\f455";
}
.fi-ts-cricket:before {
    content: "\f456";
}
.fi-ts-crm-alt:before {
    content: "\f457";
}
.fi-ts-crm-computer:before {
    content: "\f458";
}
.fi-ts-CRM:before {
    content: "\f459";
}
.fi-ts-croissant:before {
    content: "\f45a";
}
.fi-ts-cross-religion:before {
    content: "\f45b";
}
.fi-ts-crow:before {
    content: "\f45c";
}
.fi-ts-crutch:before {
    content: "\f45d";
}
.fi-ts-crutches:before {
    content: "\f45e";
}
.fi-ts-cruzeiro-sign:before {
    content: "\f45f";
}
.fi-ts-cryptocurrency:before {
    content: "\f460";
}
.fi-ts-crystal-ball:before {
    content: "\f461";
}
.fi-ts-cube:before {
    content: "\f462";
}
.fi-ts-cubes-stacked:before {
    content: "\f463";
}
.fi-ts-cubes:before {
    content: "\f464";
}
.fi-ts-cucumber:before {
    content: "\f465";
}
.fi-ts-cup-straw-swoosh:before {
    content: "\f466";
}
.fi-ts-cup-straw:before {
    content: "\f467";
}
.fi-ts-cup-togo:before {
    content: "\f468";
}
.fi-ts-cupcake-alt:before {
    content: "\f469";
}
.fi-ts-cupcake:before {
    content: "\f46a";
}
.fi-ts-curling:before {
    content: "\f46b";
}
.fi-ts-curve-alt:before {
    content: "\f46c";
}
.fi-ts-curve-arrow:before {
    content: "\f46d";
}
.fi-ts-curve:before {
    content: "\f46e";
}
.fi-ts-custard:before {
    content: "\f46f";
}
.fi-ts-customer-care:before {
    content: "\f470";
}
.fi-ts-customer-service:before {
    content: "\f471";
}
.fi-ts-customization-cogwheel:before {
    content: "\f472";
}
.fi-ts-customization:before {
    content: "\f473";
}
.fi-ts-customize-computer:before {
    content: "\f474";
}
.fi-ts-customize-edit:before {
    content: "\f475";
}
.fi-ts-customize:before {
    content: "\f476";
}
.fi-ts-CV:before {
    content: "\f477";
}
.fi-ts-d:before {
    content: "\f478";
}
.fi-ts-dagger:before {
    content: "\f479";
}
.fi-ts-daily-calendar:before {
    content: "\f47a";
}
.fi-ts-damage:before {
    content: "\f47b";
}
.fi-ts-dashboard:before {
    content: "\f47c";
}
.fi-ts-database:before {
    content: "\f47d";
}
.fi-ts-debt:before {
    content: "\f47e";
}
.fi-ts-deer-rudolph:before {
    content: "\f47f";
}
.fi-ts-deer:before {
    content: "\f480";
}
.fi-ts-delete-right:before {
    content: "\f481";
}
.fi-ts-democrat:before {
    content: "\f482";
}
.fi-ts-department-structure:before {
    content: "\f483";
}
.fi-ts-department:before {
    content: "\f484";
}
.fi-ts-deposit-alt:before {
    content: "\f485";
}
.fi-ts-deposit:before {
    content: "\f486";
}
.fi-ts-description-alt:before {
    content: "\f487";
}
.fi-ts-description:before {
    content: "\f488";
}
.fi-ts-desktop-arrow-down:before {
    content: "\f489";
}
.fi-ts-desktop-wallpaper:before {
    content: "\f48a";
}
.fi-ts-devices:before {
    content: "\f48b";
}
.fi-ts-dewpoint:before {
    content: "\f48c";
}
.fi-ts-dharmachakra:before {
    content: "\f48d";
}
.fi-ts-diagram-cells:before {
    content: "\f48e";
}
.fi-ts-diagram-lean-canvas:before {
    content: "\f48f";
}
.fi-ts-diagram-nested:before {
    content: "\f490";
}
.fi-ts-diagram-next:before {
    content: "\f491";
}
.fi-ts-diagram-predecessor:before {
    content: "\f492";
}
.fi-ts-diagram-previous:before {
    content: "\f493";
}
.fi-ts-diagram-project:before {
    content: "\f494";
}
.fi-ts-diagram-sankey:before {
    content: "\f495";
}
.fi-ts-diagram-subtask:before {
    content: "\f496";
}
.fi-ts-diagram-successor:before {
    content: "\f497";
}
.fi-ts-diagram-venn:before {
    content: "\f498";
}
.fi-ts-dial-high:before {
    content: "\f499";
}
.fi-ts-dial-low:before {
    content: "\f49a";
}
.fi-ts-dial-max:before {
    content: "\f49b";
}
.fi-ts-dial-med-low:before {
    content: "\f49c";
}
.fi-ts-dial-med:before {
    content: "\f49d";
}
.fi-ts-dial-min:before {
    content: "\f49e";
}
.fi-ts-dial-off:before {
    content: "\f49f";
}
.fi-ts-dial:before {
    content: "\f4a0";
}
.fi-ts-diamond-exclamation:before {
    content: "\f4a1";
}
.fi-ts-diamond-turn-right:before {
    content: "\f4a2";
}
.fi-ts-diamond:before {
    content: "\f4a3";
}
.fi-ts-diary-bookmark-down:before {
    content: "\f4a4";
}
.fi-ts-diary-bookmarks:before {
    content: "\f4a5";
}
.fi-ts-diary-clasp:before {
    content: "\f4a6";
}
.fi-ts-dice-alt:before {
    content: "\f4a7";
}
.fi-ts-dice-d10:before {
    content: "\f4a8";
}
.fi-ts-dice-d12:before {
    content: "\f4a9";
}
.fi-ts-dice-d20:before {
    content: "\f4aa";
}
.fi-ts-dice-d4:before {
    content: "\f4ab";
}
.fi-ts-dice-d6:before {
    content: "\f4ac";
}
.fi-ts-dice-d8:before {
    content: "\f4ad";
}
.fi-ts-dice-four:before {
    content: "\f4ae";
}
.fi-ts-dice-one:before {
    content: "\f4af";
}
.fi-ts-dice-six:before {
    content: "\f4b0";
}
.fi-ts-dice-three:before {
    content: "\f4b1";
}
.fi-ts-dice-two:before {
    content: "\f4b2";
}
.fi-ts-digging:before {
    content: "\f4b3";
}
.fi-ts-digital-tachograph:before {
    content: "\f4b4";
}
.fi-ts-dinner:before {
    content: "\f4b5";
}
.fi-ts-direction-signal-arrow:before {
    content: "\f4b6";
}
.fi-ts-direction-signal:before {
    content: "\f4b7";
}
.fi-ts-disc-drive:before {
    content: "\f4b8";
}
.fi-ts-disco-ball:before {
    content: "\f4b9";
}
.fi-ts-discover:before {
    content: "\f4ba";
}
.fi-ts-disease:before {
    content: "\f4bb";
}
.fi-ts-display-arrow-down:before {
    content: "\f4bc";
}
.fi-ts-display-code:before {
    content: "\f4bd";
}
.fi-ts-display-medical:before {
    content: "\f4be";
}
.fi-ts-display-slash:before {
    content: "\f4bf";
}
.fi-ts-distribute-spacing-horizontal:before {
    content: "\f4c0";
}
.fi-ts-distribute-spacing-vertical:before {
    content: "\f4c1";
}
.fi-ts-divide:before {
    content: "\f4c2";
}
.fi-ts-dizzy:before {
    content: "\f4c3";
}
.fi-ts-dna:before {
    content: "\f4c4";
}
.fi-ts-do-not-enter:before {
    content: "\f4c5";
}
.fi-ts-document-paid:before {
    content: "\f4c6";
}
.fi-ts-document-signed:before {
    content: "\f4c7";
}
.fi-ts-dog-leashed:before {
    content: "\f4c8";
}
.fi-ts-dog:before {
    content: "\f4c9";
}
.fi-ts-dolly-flatbed-alt:before {
    content: "\f4ca";
}
.fi-ts-dolly-flatbed-empty:before {
    content: "\f4cb";
}
.fi-ts-dolly-flatbed:before {
    content: "\f4cc";
}
.fi-ts-dolphin:before {
    content: "\f4cd";
}
.fi-ts-domino-effect:before {
    content: "\f4ce";
}
.fi-ts-donate:before {
    content: "\f4cf";
}
.fi-ts-dong-sign:before {
    content: "\f4d0";
}
.fi-ts-donut:before {
    content: "\f4d1";
}
.fi-ts-door-closed:before {
    content: "\f4d2";
}
.fi-ts-door-open:before {
    content: "\f4d3";
}
.fi-ts-dot-circle:before {
    content: "\f4d4";
}
.fi-ts-dot-pending:before {
    content: "\f4d5";
}
.fi-ts-dove:before {
    content: "\f4d6";
}
.fi-ts-down-left:before {
    content: "\f4d7";
}
.fi-ts-down-right:before {
    content: "\f4d8";
}
.fi-ts-down-to-line:before {
    content: "\f4d9";
}
.fi-ts-down:before {
    content: "\f4da";
}
.fi-ts-drafting-compass:before {
    content: "\f4db";
}
.fi-ts-dragon:before {
    content: "\f4dc";
}
.fi-ts-draw-polygon:before {
    content: "\f4dd";
}
.fi-ts-draw-square:before {
    content: "\f4de";
}
.fi-ts-drawer-alt:before {
    content: "\f4df";
}
.fi-ts-drawer:before {
    content: "\f4e0";
}
.fi-ts-dreidel:before {
    content: "\f4e1";
}
.fi-ts-drink-alt:before {
    content: "\f4e2";
}
.fi-ts-drink:before {
    content: "\f4e3";
}
.fi-ts-driver-man:before {
    content: "\f4e4";
}
.fi-ts-driver-woman:before {
    content: "\f4e5";
}
.fi-ts-drone-alt:before {
    content: "\f4e6";
}
.fi-ts-drone-front:before {
    content: "\f4e7";
}
.fi-ts-drone:before {
    content: "\f4e8";
}
.fi-ts-drop-down:before {
    content: "\f4e9";
}
.fi-ts-drum-steelpan:before {
    content: "\f4ea";
}
.fi-ts-drum:before {
    content: "\f4eb";
}
.fi-ts-drumstick-bite:before {
    content: "\f4ec";
}
.fi-ts-drumstick:before {
    content: "\f4ed";
}
.fi-ts-dryer-alt:before {
    content: "\f4ee";
}
.fi-ts-dryer:before {
    content: "\f4ef";
}
.fi-ts-duck:before {
    content: "\f4f0";
}
.fi-ts-dumpster-fire:before {
    content: "\f4f1";
}
.fi-ts-dumpster:before {
    content: "\f4f2";
}
.fi-ts-dungeon:before {
    content: "\f4f3";
}
.fi-ts-duration-alt:before {
    content: "\f4f4";
}
.fi-ts-duration:before {
    content: "\f4f5";
}
.fi-ts-e:before {
    content: "\f4f6";
}
.fi-ts-ear-deaf:before {
    content: "\f4f7";
}
.fi-ts-ear-muffs:before {
    content: "\f4f8";
}
.fi-ts-ear:before {
    content: "\f4f9";
}
.fi-ts-earth-africa:before {
    content: "\f4fa";
}
.fi-ts-earth-americas:before {
    content: "\f4fb";
}
.fi-ts-earth-asia:before {
    content: "\f4fc";
}
.fi-ts-earth-europa:before {
    content: "\f4fd";
}
.fi-ts-eclipse-alt:before {
    content: "\f4fe";
}
.fi-ts-eclipse:before {
    content: "\f4ff";
}
.fi-ts-effect:before {
    content: "\f500";
}
.fi-ts-egg-fried:before {
    content: "\f501";
}
.fi-ts-egg:before {
    content: "\f502";
}
.fi-ts-eject:before {
    content: "\f503";
}
.fi-ts-elephant:before {
    content: "\f504";
}
.fi-ts-elevator:before {
    content: "\f505";
}
.fi-ts-employee-alt:before {
    content: "\f506";
}
.fi-ts-employee-man-alt:before {
    content: "\f507";
}
.fi-ts-employee-man:before {
    content: "\f508";
}
.fi-ts-employees-woman-man:before {
    content: "\f509";
}
.fi-ts-employees:before {
    content: "\f50a";
}
.fi-ts-empty-set:before {
    content: "\f50b";
}
.fi-ts-engine-warning:before {
    content: "\f50c";
}
.fi-ts-engine:before {
    content: "\f50d";
}
.fi-ts-envelope-ban:before {
    content: "\f50e";
}
.fi-ts-envelope-bulk:before {
    content: "\f50f";
}
.fi-ts-envelope-dot:before {
    content: "\f510";
}
.fi-ts-envelope-download:before {
    content: "\f511";
}
.fi-ts-envelope-marker:before {
    content: "\f512";
}
.fi-ts-envelope-open-dollar:before {
    content: "\f513";
}
.fi-ts-envelope-open-text:before {
    content: "\f514";
}
.fi-ts-envelope-open:before {
    content: "\f515";
}
.fi-ts-envelope-plus:before {
    content: "\f516";
}
.fi-ts-envelopes:before {
    content: "\f517";
}
.fi-ts-equality:before {
    content: "\f518";
}
.fi-ts-equals:before {
    content: "\f519";
}
.fi-ts-eraser:before {
    content: "\f51a";
}
.fi-ts-escalator:before {
    content: "\f51b";
}
.fi-ts-ethernet:before {
    content: "\f51c";
}
.fi-ts-euro:before {
    content: "\f51d";
}
.fi-ts-exchange-alt:before {
    content: "\f51e";
}
.fi-ts-exchange-cryptocurrency:before {
    content: "\f51f";
}
.fi-ts-exchange:before {
    content: "\f520";
}
.fi-ts-exit-alt:before {
    content: "\f521";
}
.fi-ts-expand-arrows-alt:before {
    content: "\f522";
}
.fi-ts-expand-arrows:before {
    content: "\f523";
}
.fi-ts-expense-bill:before {
    content: "\f524";
}
.fi-ts-expense:before {
    content: "\f525";
}
.fi-ts-external-hard-drive:before {
    content: "\f526";
}
.fi-ts-external-world:before {
    content: "\f527";
}
.fi-ts-eye-dropper-half:before {
    content: "\f528";
}
.fi-ts-eyes:before {
    content: "\f529";
}
.fi-ts-f:before {
    content: "\f52a";
}
.fi-ts-fabric:before {
    content: "\f52b";
}
.fi-ts-face-angry-horns:before {
    content: "\f52c";
}
.fi-ts-face-anguished:before {
    content: "\f52d";
}
.fi-ts-face-anxious-sweat:before {
    content: "\f52e";
}
.fi-ts-face-astonished:before {
    content: "\f52f";
}
.fi-ts-face-awesome:before {
    content: "\f530";
}
.fi-ts-face-beam-hand-over-mouth:before {
    content: "\f531";
}
.fi-ts-face-confounded:before {
    content: "\f532";
}
.fi-ts-face-confused:before {
    content: "\f533";
}
.fi-ts-face-cowboy-hat:before {
    content: "\f534";
}
.fi-ts-face-disappointed:before {
    content: "\f535";
}
.fi-ts-face-disguise:before {
    content: "\f536";
}
.fi-ts-face-downcast-sweat:before {
    content: "\f537";
}
.fi-ts-face-drooling:before {
    content: "\f538";
}
.fi-ts-face-explode:before {
    content: "\f539";
}
.fi-ts-face-expressionless:before {
    content: "\f53a";
}
.fi-ts-face-eyes-xmarks:before {
    content: "\f53b";
}
.fi-ts-face-fearful:before {
    content: "\f53c";
}
.fi-ts-face-glasses:before {
    content: "\f53d";
}
.fi-ts-face-grin-tongue-wink:before {
    content: "\f53e";
}
.fi-ts-face-hand-yawn:before {
    content: "\f53f";
}
.fi-ts-face-head-bandage:before {
    content: "\f540";
}
.fi-ts-face-hushed:before {
    content: "\f541";
}
.fi-ts-face-icicles:before {
    content: "\f542";
}
.fi-ts-face-lying:before {
    content: "\f543";
}
.fi-ts-face-mask:before {
    content: "\f544";
}
.fi-ts-face-monocle:before {
    content: "\f545";
}
.fi-ts-face-nauseated:before {
    content: "\f546";
}
.fi-ts-face-nose-steam:before {
    content: "\f547";
}
.fi-ts-face-party:before {
    content: "\f548";
}
.fi-ts-face-pensive:before {
    content: "\f549";
}
.fi-ts-face-persevering:before {
    content: "\f54a";
}
.fi-ts-face-pleading:before {
    content: "\f54b";
}
.fi-ts-face-raised-eyebrow:before {
    content: "\f54c";
}
.fi-ts-face-relieved:before {
    content: "\f54d";
}
.fi-ts-face-sad-sweat:before {
    content: "\f54e";
}
.fi-ts-face-scream:before {
    content: "\f54f";
}
.fi-ts-face-shush:before {
    content: "\f550";
}
.fi-ts-face-sleeping:before {
    content: "\f551";
}
.fi-ts-face-sleepy:before {
    content: "\f552";
}
.fi-ts-face-smile-halo:before {
    content: "\f553";
}
.fi-ts-face-smile-hearts:before {
    content: "\f554";
}
.fi-ts-face-smile-horns:before {
    content: "\f555";
}
.fi-ts-face-smile-tear:before {
    content: "\f556";
}
.fi-ts-face-smile-tongue:before {
    content: "\f557";
}
.fi-ts-face-smile-upside-down:before {
    content: "\f558";
}
.fi-ts-face-smiling-hands:before {
    content: "\f559";
}
.fi-ts-face-smirking:before {
    content: "\f55a";
}
.fi-ts-face-sunglasses-alt:before {
    content: "\f55b";
}
.fi-ts-face-sunglasses:before {
    content: "\f55c";
}
.fi-ts-face-swear:before {
    content: "\f55d";
}
.fi-ts-face-thermometer:before {
    content: "\f55e";
}
.fi-ts-face-thinking:before {
    content: "\f55f";
}
.fi-ts-face-tissue:before {
    content: "\f560";
}
.fi-ts-face-tongue-money:before {
    content: "\f561";
}
.fi-ts-face-tongue-sweat:before {
    content: "\f562";
}
.fi-ts-face-unamused:before {
    content: "\f563";
}
.fi-ts-face-viewfinder:before {
    content: "\f564";
}
.fi-ts-face-vomit:before {
    content: "\f565";
}
.fi-ts-face-weary:before {
    content: "\f566";
}
.fi-ts-face-woozy:before {
    content: "\f567";
}
.fi-ts-face-worried:before {
    content: "\f568";
}
.fi-ts-face-zany:before {
    content: "\f569";
}
.fi-ts-face-zipper:before {
    content: "\f56a";
}
.fi-ts-fail:before {
    content: "\f56b";
}
.fi-ts-falafel:before {
    content: "\f56c";
}
.fi-ts-family-dress:before {
    content: "\f56d";
}
.fi-ts-family-pants:before {
    content: "\f56e";
}
.fi-ts-family:before {
    content: "\f56f";
}
.fi-ts-fan-table:before {
    content: "\f570";
}
.fi-ts-fan:before {
    content: "\f571";
}
.fi-ts-farm:before {
    content: "\f572";
}
.fi-ts-faucet-drip:before {
    content: "\f573";
}
.fi-ts-faucet:before {
    content: "\f574";
}
.fi-ts-fax:before {
    content: "\f575";
}
.fi-ts-feather-pointed:before {
    content: "\f576";
}
.fi-ts-features-alt:before {
    content: "\f577";
}
.fi-ts-features:before {
    content: "\f578";
}
.fi-ts-fee-receipt:before {
    content: "\f579";
}
.fi-ts-fee:before {
    content: "\f57a";
}
.fi-ts-feedback-alt:before {
    content: "\f57b";
}
.fi-ts-feedback-hand:before {
    content: "\f57c";
}
.fi-ts-feedback-review:before {
    content: "\f57d";
}
.fi-ts-feedback:before {
    content: "\f57e";
}
.fi-ts-fence:before {
    content: "\f57f";
}
.fi-ts-ferris-wheel:before {
    content: "\f580";
}
.fi-ts-field-hockey:before {
    content: "\f581";
}
.fi-ts-fighter-jet:before {
    content: "\f582";
}
.fi-ts-file-audio:before {
    content: "\f583";
}
.fi-ts-file-binary:before {
    content: "\f584";
}
.fi-ts-file-chart-line:before {
    content: "\f585";
}
.fi-ts-file-chart-pie:before {
    content: "\f586";
}
.fi-ts-file-circle-info:before {
    content: "\f587";
}
.fi-ts-file-code:before {
    content: "\f588";
}
.fi-ts-file-csv:before {
    content: "\f589";
}
.fi-ts-file-download:before {
    content: "\f58a";
}
.fi-ts-file-edit:before {
    content: "\f58b";
}
.fi-ts-file-excel:before {
    content: "\f58c";
}
.fi-ts-file-exclamation:before {
    content: "\f58d";
}
.fi-ts-file-export:before {
    content: "\f58e";
}
.fi-ts-file-image:before {
    content: "\f58f";
}
.fi-ts-file-import:before {
    content: "\f590";
}
.fi-ts-file-invoice-dollar:before {
    content: "\f591";
}
.fi-ts-file-invoice:before {
    content: "\f592";
}
.fi-ts-file-medical-alt:before {
    content: "\f593";
}
.fi-ts-file-medical:before {
    content: "\f594";
}
.fi-ts-file-minus:before {
    content: "\f595";
}
.fi-ts-file-pdf:before {
    content: "\f596";
}
.fi-ts-file-powerpoint:before {
    content: "\f597";
}
.fi-ts-file-prescription:before {
    content: "\f598";
}
.fi-ts-file-signature:before {
    content: "\f599";
}
.fi-ts-file-spreadsheet:before {
    content: "\f59a";
}
.fi-ts-file-upload:before {
    content: "\f59b";
}
.fi-ts-file-user:before {
    content: "\f59c";
}
.fi-ts-file-video:before {
    content: "\f59d";
}
.fi-ts-file-word:before {
    content: "\f59e";
}
.fi-ts-file-zipper:before {
    content: "\f59f";
}
.fi-ts-files-medical:before {
    content: "\f5a0";
}
.fi-ts-film-canister:before {
    content: "\f5a1";
}
.fi-ts-film-slash:before {
    content: "\f5a2";
}
.fi-ts-films:before {
    content: "\f5a3";
}
.fi-ts-filter-slash:before {
    content: "\f5a4";
}
.fi-ts-filters:before {
    content: "\f5a5";
}
.fi-ts-fingerprint:before {
    content: "\f5a6";
}
.fi-ts-fire-burner:before {
    content: "\f5a7";
}
.fi-ts-fire-extinguisher:before {
    content: "\f5a8";
}
.fi-ts-fire-flame-curved:before {
    content: "\f5a9";
}
.fi-ts-fire-flame-simple:before {
    content: "\f5aa";
}
.fi-ts-fire-hydrant:before {
    content: "\f5ab";
}
.fi-ts-fire-smoke:before {
    content: "\f5ac";
}
.fi-ts-fireplace:before {
    content: "\f5ad";
}
.fi-ts-first-award:before {
    content: "\f5ae";
}
.fi-ts-first-laurel:before {
    content: "\f5af";
}
.fi-ts-first-medal:before {
    content: "\f5b0";
}
.fi-ts-first:before {
    content: "\f5b1";
}
.fi-ts-fish-bones:before {
    content: "\f5b2";
}
.fi-ts-fish-cooked:before {
    content: "\f5b3";
}
.fi-ts-fish:before {
    content: "\f5b4";
}
.fi-ts-fishing-rod:before {
    content: "\f5b5";
}
.fi-ts-flag-alt:before {
    content: "\f5b6";
}
.fi-ts-flag-checkered:before {
    content: "\f5b7";
}
.fi-ts-flag-usa:before {
    content: "\f5b8";
}
.fi-ts-flag:before {
    content: "\f5b9";
}
.fi-ts-flashlight:before {
    content: "\f5ba";
}
.fi-ts-flask-poison:before {
    content: "\f5bb";
}
.fi-ts-flask-potion:before {
    content: "\f5bc";
}
.fi-ts-flask:before {
    content: "\f5bd";
}
.fi-ts-flatbread-stuffed:before {
    content: "\f5be";
}
.fi-ts-flatbread:before {
    content: "\f5bf";
}
.fi-ts-floor-alt:before {
    content: "\f5c0";
}
.fi-ts-floor-layer:before {
    content: "\f5c1";
}
.fi-ts-floor:before {
    content: "\f5c2";
}
.fi-ts-floppy-disk-circle-arrow-right:before {
    content: "\f5c3";
}
.fi-ts-floppy-disk-circle-xmark:before {
    content: "\f5c4";
}
.fi-ts-floppy-disk-pen:before {
    content: "\f5c5";
}
.fi-ts-floppy-disks:before {
    content: "\f5c6";
}
.fi-ts-florin-sign:before {
    content: "\f5c7";
}
.fi-ts-flower-bouquet:before {
    content: "\f5c8";
}
.fi-ts-flower-butterfly:before {
    content: "\f5c9";
}
.fi-ts-flower-daffodil:before {
    content: "\f5ca";
}
.fi-ts-flower-tulip:before {
    content: "\f5cb";
}
.fi-ts-flower:before {
    content: "\f5cc";
}
.fi-ts-flushed:before {
    content: "\f5cd";
}
.fi-ts-flute:before {
    content: "\f5ce";
}
.fi-ts-flux-capacitor:before {
    content: "\f5cf";
}
.fi-ts-fly-insect:before {
    content: "\f5d0";
}
.fi-ts-flying-disc:before {
    content: "\f5d1";
}
.fi-ts-fog:before {
    content: "\f5d2";
}
.fi-ts-folder-download:before {
    content: "\f5d3";
}
.fi-ts-folder-minus:before {
    content: "\f5d4";
}
.fi-ts-folder-open:before {
    content: "\f5d5";
}
.fi-ts-folder-times:before {
    content: "\f5d6";
}
.fi-ts-folder-tree:before {
    content: "\f5d7";
}
.fi-ts-folder-upload:before {
    content: "\f5d8";
}
.fi-ts-folder-xmark:before {
    content: "\f5d9";
}
.fi-ts-folders:before {
    content: "\f5da";
}
.fi-ts-follow-folder:before {
    content: "\f5db";
}
.fi-ts-followcollection:before {
    content: "\f5dc";
}
.fi-ts-fondue-pot:before {
    content: "\f5dd";
}
.fi-ts-fork:before {
    content: "\f5de";
}
.fi-ts-forklift:before {
    content: "\f5df";
}
.fi-ts-fort:before {
    content: "\f5e0";
}
.fi-ts-forward-fast:before {
    content: "\f5e1";
}
.fi-ts-fox:before {
    content: "\f5e2";
}
.fi-ts-frame:before {
    content: "\f5e3";
}
.fi-ts-franc-sign:before {
    content: "\f5e4";
}
.fi-ts-french-fries:before {
    content: "\f5e5";
}
.fi-ts-friday:before {
    content: "\f5e6";
}
.fi-ts-frog:before {
    content: "\f5e7";
}
.fi-ts-frown:before {
    content: "\f5e8";
}
.fi-ts-ftp:before {
    content: "\f5e9";
}
.fi-ts-function-process:before {
    content: "\f5ea";
}
.fi-ts-funnel-dollar:before {
    content: "\f5eb";
}
.fi-ts-g:before {
    content: "\f5ec";
}
.fi-ts-galaxy-alt:before {
    content: "\f5ed";
}
.fi-ts-galaxy-planet:before {
    content: "\f5ee";
}
.fi-ts-galaxy-star:before {
    content: "\f5ef";
}
.fi-ts-galaxy:before {
    content: "\f5f0";
}
.fi-ts-gallery-thumbnails:before {
    content: "\f5f1";
}
.fi-ts-game-board-alt:before {
    content: "\f5f2";
}
.fi-ts-gamepad:before {
    content: "\f5f3";
}
.fi-ts-garage-car:before {
    content: "\f5f4";
}
.fi-ts-garage-open:before {
    content: "\f5f5";
}
.fi-ts-garage:before {
    content: "\f5f6";
}
.fi-ts-garlic-alt:before {
    content: "\f5f7";
}
.fi-ts-garlic:before {
    content: "\f5f8";
}
.fi-ts-gas-pump-slash:before {
    content: "\f5f9";
}
.fi-ts-gas-pump:before {
    content: "\f5fa";
}
.fi-ts-gauge-circle-bolt:before {
    content: "\f5fb";
}
.fi-ts-gauge-circle-minus:before {
    content: "\f5fc";
}
.fi-ts-gauge-circle-plus:before {
    content: "\f5fd";
}
.fi-ts-gavel:before {
    content: "\f5fe";
}
.fi-ts-gears:before {
    content: "\f5ff";
}
.fi-ts-gem:before {
    content: "\f600";
}
.fi-ts-ghost:before {
    content: "\f601";
}
.fi-ts-gif-square:before {
    content: "\f602";
}
.fi-ts-gift-card:before {
    content: "\f603";
}
.fi-ts-gift:before {
    content: "\f604";
}
.fi-ts-gifts:before {
    content: "\f605";
}
.fi-ts-gingerbread-man:before {
    content: "\f606";
}
.fi-ts-glass-champagne:before {
    content: "\f607";
}
.fi-ts-glass-cheers:before {
    content: "\f608";
}
.fi-ts-glass-citrus:before {
    content: "\f609";
}
.fi-ts-glass-empty:before {
    content: "\f60a";
}
.fi-ts-glass-half:before {
    content: "\f60b";
}
.fi-ts-glass-water-droplet:before {
    content: "\f60c";
}
.fi-ts-glass-whiskey-rocks:before {
    content: "\f60d";
}
.fi-ts-glass-whiskey:before {
    content: "\f60e";
}
.fi-ts-glass:before {
    content: "\f60f";
}
.fi-ts-globe-snow:before {
    content: "\f610";
}
.fi-ts-goal-net:before {
    content: "\f611";
}
.fi-ts-golf-ball:before {
    content: "\f612";
}
.fi-ts-golf-club:before {
    content: "\f613";
}
.fi-ts-gopuram:before {
    content: "\f614";
}
.fi-ts-government-flag:before {
    content: "\f615";
}
.fi-ts-government-user:before {
    content: "\f616";
}
.fi-ts-gramophone:before {
    content: "\f617";
}
.fi-ts-grape:before {
    content: "\f618";
}
.fi-ts-graph-curve:before {
    content: "\f619";
}
.fi-ts-graphic-style:before {
    content: "\f61a";
}
.fi-ts-grate-droplet:before {
    content: "\f61b";
}
.fi-ts-grate:before {
    content: "\f61c";
}
.fi-ts-greater-than-equal:before {
    content: "\f61d";
}
.fi-ts-greater-than:before {
    content: "\f61e";
}
.fi-ts-grid-dividers:before {
    content: "\f61f";
}
.fi-ts-grill-hot-alt:before {
    content: "\f620";
}
.fi-ts-grill:before {
    content: "\f621";
}
.fi-ts-grimace:before {
    content: "\f622";
}
.fi-ts-grin-alt:before {
    content: "\f623";
}
.fi-ts-grin-beam-sweat:before {
    content: "\f624";
}
.fi-ts-grin-beam:before {
    content: "\f625";
}
.fi-ts-grin-hearts:before {
    content: "\f626";
}
.fi-ts-grin-squint-tears:before {
    content: "\f627";
}
.fi-ts-grin-squint:before {
    content: "\f628";
}
.fi-ts-grin-stars:before {
    content: "\f629";
}
.fi-ts-grin-tears:before {
    content: "\f62a";
}
.fi-ts-grin-tongue-squint:before {
    content: "\f62b";
}
.fi-ts-grin-tongue-wink:before {
    content: "\f62c";
}
.fi-ts-grin-tongue:before {
    content: "\f62d";
}
.fi-ts-grin-wink:before {
    content: "\f62e";
}
.fi-ts-grin:before {
    content: "\f62f";
}
.fi-ts-grip-dots-vertical:before {
    content: "\f630";
}
.fi-ts-grip-dots:before {
    content: "\f631";
}
.fi-ts-grip-horizontal:before {
    content: "\f632";
}
.fi-ts-grip-lines-vertical:before {
    content: "\f633";
}
.fi-ts-grip-lines:before {
    content: "\f634";
}
.fi-ts-grip-vertical:before {
    content: "\f635";
}
.fi-ts-grocery-bag:before {
    content: "\f636";
}
.fi-ts-grocery-basket:before {
    content: "\f637";
}
.fi-ts-guarani-sign:before {
    content: "\f638";
}
.fi-ts-guide:before {
    content: "\f639";
}
.fi-ts-guitar-electric:before {
    content: "\f63a";
}
.fi-ts-guitar:before {
    content: "\f63b";
}
.fi-ts-guitars:before {
    content: "\f63c";
}
.fi-ts-gun-squirt:before {
    content: "\f63d";
}
.fi-ts-h-square:before {
    content: "\f63e";
}
.fi-ts-h:before {
    content: "\f63f";
}
.fi-ts-h1:before {
    content: "\f640";
}
.fi-ts-h2:before {
    content: "\f641";
}
.fi-ts-h3:before {
    content: "\f642";
}
.fi-ts-h4:before {
    content: "\f643";
}
.fi-ts-hair-clipper:before {
    content: "\f644";
}
.fi-ts-hamburger-soda:before {
    content: "\f645";
}
.fi-ts-hamburger:before {
    content: "\f646";
}
.fi-ts-hammer-crash:before {
    content: "\f647";
}
.fi-ts-hammer-war:before {
    content: "\f648";
}
.fi-ts-hammer:before {
    content: "\f649";
}
.fi-ts-hamsa:before {
    content: "\f64a";
}
.fi-ts-hand-back-fist:before {
    content: "\f64b";
}
.fi-ts-hand-back-point-down:before {
    content: "\f64c";
}
.fi-ts-hand-back-point-left:before {
    content: "\f64d";
}
.fi-ts-hand-back-point-ribbon:before {
    content: "\f64e";
}
.fi-ts-hand-back-point-right:before {
    content: "\f64f";
}
.fi-ts-hand-bill:before {
    content: "\f650";
}
.fi-ts-hand-dots:before {
    content: "\f651";
}
.fi-ts-hand-fingers-crossed:before {
    content: "\f652";
}
.fi-ts-hand-fist:before {
    content: "\f653";
}
.fi-ts-hand-heart:before {
    content: "\f654";
}
.fi-ts-hand-holding-box:before {
    content: "\f655";
}
.fi-ts-hand-holding-droplet:before {
    content: "\f656";
}
.fi-ts-hand-holding-heart:before {
    content: "\f657";
}
.fi-ts-hand-holding-magic:before {
    content: "\f658";
}
.fi-ts-hand-holding-medical:before {
    content: "\f659";
}
.fi-ts-hand-holding-seeding:before {
    content: "\f65a";
}
.fi-ts-hand-holding-skull:before {
    content: "\f65b";
}
.fi-ts-hand-holding-usd:before {
    content: "\f65c";
}
.fi-ts-hand-holding-water:before {
    content: "\f65d";
}
.fi-ts-hand-horns:before {
    content: "\f65e";
}
.fi-ts-hand-lizard:before {
    content: "\f65f";
}
.fi-ts-hand-love:before {
    content: "\f660";
}
.fi-ts-hand-middle-finger:before {
    content: "\f661";
}
.fi-ts-hand-paper:before {
    content: "\f662";
}
.fi-ts-hand-peace:before {
    content: "\f663";
}
.fi-ts-hand-point-ribbon:before {
    content: "\f664";
}
.fi-ts-hand-scissors:before {
    content: "\f665";
}
.fi-ts-hand-sparkles:before {
    content: "\f666";
}
.fi-ts-hand-spock:before {
    content: "\f667";
}
.fi-ts-hand-wave:before {
    content: "\f668";
}
.fi-ts-handmade:before {
    content: "\f669";
}
.fi-ts-hands-bubbles:before {
    content: "\f66a";
}
.fi-ts-hands-clapping:before {
    content: "\f66b";
}
.fi-ts-hands-heart:before {
    content: "\f66c";
}
.fi-ts-hands-holding-diamond:before {
    content: "\f66d";
}
.fi-ts-hands-holding:before {
    content: "\f66e";
}
.fi-ts-hands-usd:before {
    content: "\f66f";
}
.fi-ts-handshake-angle:before {
    content: "\f670";
}
.fi-ts-handshake-simple-slash:before {
    content: "\f671";
}
.fi-ts-handshake:before {
    content: "\f672";
}
.fi-ts-hard-hat:before {
    content: "\f673";
}
.fi-ts-hashtag-lock:before {
    content: "\f674";
}
.fi-ts-hashtag:before {
    content: "\f675";
}
.fi-ts-hat-birthday:before {
    content: "\f676";
}
.fi-ts-hat-chef:before {
    content: "\f677";
}
.fi-ts-hat-cowboy-side:before {
    content: "\f678";
}
.fi-ts-hat-cowboy:before {
    content: "\f679";
}
.fi-ts-hat-santa:before {
    content: "\f67a";
}
.fi-ts-hat-winter:before {
    content: "\f67b";
}
.fi-ts-hat-witch:before {
    content: "\f67c";
}
.fi-ts-hat-wizard:before {
    content: "\f67d";
}
.fi-ts-hdd:before {
    content: "\f67e";
}
.fi-ts-head-side-brain:before {
    content: "\f67f";
}
.fi-ts-head-side-cough-slash:before {
    content: "\f680";
}
.fi-ts-head-side-cough:before {
    content: "\f681";
}
.fi-ts-head-side-headphones:before {
    content: "\f682";
}
.fi-ts-head-side-heart:before {
    content: "\f683";
}
.fi-ts-head-side-mask:before {
    content: "\f684";
}
.fi-ts-head-side-medical:before {
    content: "\f685";
}
.fi-ts-head-side-thinking:before {
    content: "\f686";
}
.fi-ts-head-side-virus:before {
    content: "\f687";
}
.fi-ts-head-side:before {
    content: "\f688";
}
.fi-ts-head-vr:before {
    content: "\f689";
}
.fi-ts-heading:before {
    content: "\f68a";
}
.fi-ts-heart-arrow:before {
    content: "\f68b";
}
.fi-ts-heart-crack:before {
    content: "\f68c";
}
.fi-ts-heart-half-stroke:before {
    content: "\f68d";
}
.fi-ts-heart-half:before {
    content: "\f68e";
}
.fi-ts-heart-rate:before {
    content: "\f68f";
}
.fi-ts-heat:before {
    content: "\f690";
}
.fi-ts-helicopter-side:before {
    content: "\f691";
}
.fi-ts-helmet-battle:before {
    content: "\f692";
}
.fi-ts-hexagon-check:before {
    content: "\f693";
}
.fi-ts-hexagon-divide:before {
    content: "\f694";
}
.fi-ts-hexagon-exclamation:before {
    content: "\f695";
}
.fi-ts-hexagon:before {
    content: "\f696";
}
.fi-ts-high-definition:before {
    content: "\f697";
}
.fi-ts-highlighter-line:before {
    content: "\f698";
}
.fi-ts-highlighter:before {
    content: "\f699";
}
.fi-ts-hiking:before {
    content: "\f69a";
}
.fi-ts-hippo:before {
    content: "\f69b";
}
.fi-ts-hockey-mask:before {
    content: "\f69c";
}
.fi-ts-hockey-puck:before {
    content: "\f69d";
}
.fi-ts-hockey-stick-puck:before {
    content: "\f69e";
}
.fi-ts-hockey-sticks:before {
    content: "\f69f";
}
.fi-ts-holly-berry:before {
    content: "\f6a0";
}
.fi-ts-home-heart:before {
    content: "\f6a1";
}
.fi-ts-honey-pot:before {
    content: "\f6a2";
}
.fi-ts-hood-cloak:before {
    content: "\f6a3";
}
.fi-ts-horizontal-rule:before {
    content: "\f6a4";
}
.fi-ts-horse-head:before {
    content: "\f6a5";
}
.fi-ts-horse-saddle:before {
    content: "\f6a6";
}
.fi-ts-horse:before {
    content: "\f6a7";
}
.fi-ts-hose-reel:before {
    content: "\f6a8";
}
.fi-ts-hose:before {
    content: "\f6a9";
}
.fi-ts-hospital-symbol:before {
    content: "\f6aa";
}
.fi-ts-hospital-user:before {
    content: "\f6ab";
}
.fi-ts-hospital:before {
    content: "\f6ac";
}
.fi-ts-hospitals:before {
    content: "\f6ad";
}
.fi-ts-hot-tub:before {
    content: "\f6ae";
}
.fi-ts-hotdog:before {
    content: "\f6af";
}
.fi-ts-hotel:before {
    content: "\f6b0";
}
.fi-ts-hourglass-start:before {
    content: "\f6b1";
}
.fi-ts-house-blank:before {
    content: "\f6b2";
}
.fi-ts-house-building:before {
    content: "\f6b3";
}
.fi-ts-house-chimney-blank:before {
    content: "\f6b4";
}
.fi-ts-house-chimney-crack:before {
    content: "\f6b5";
}
.fi-ts-house-chimney-heart:before {
    content: "\f6b6";
}
.fi-ts-house-chimney-medical:before {
    content: "\f6b7";
}
.fi-ts-house-chimney-user:before {
    content: "\f6b8";
}
.fi-ts-house-chimney-window:before {
    content: "\f6b9";
}
.fi-ts-house-chimney:before {
    content: "\f6ba";
}
.fi-ts-house-crack:before {
    content: "\f6bb";
}
.fi-ts-house-day:before {
    content: "\f6bc";
}
.fi-ts-house-flood:before {
    content: "\f6bd";
}
.fi-ts-house-laptop:before {
    content: "\f6be";
}
.fi-ts-house-leave:before {
    content: "\f6bf";
}
.fi-ts-house-medical:before {
    content: "\f6c0";
}
.fi-ts-house-night:before {
    content: "\f6c1";
}
.fi-ts-house-return:before {
    content: "\f6c2";
}
.fi-ts-house-signal:before {
    content: "\f6c3";
}
.fi-ts-house-tree:before {
    content: "\f6c4";
}
.fi-ts-house-tsunami:before {
    content: "\f6c5";
}
.fi-ts-house-turret:before {
    content: "\f6c6";
}
.fi-ts-house-user:before {
    content: "\f6c7";
}
.fi-ts-house-window:before {
    content: "\f6c8";
}
.fi-ts-hr-group:before {
    content: "\f6c9";
}
.fi-ts-hr-person:before {
    content: "\f6ca";
}
.fi-ts-hr:before {
    content: "\f6cb";
}
.fi-ts-hryvnia:before {
    content: "\f6cc";
}
.fi-ts-humidity:before {
    content: "\f6cd";
}
.fi-ts-hundred-points:before {
    content: "\f6ce";
}
.fi-ts-hurricane:before {
    content: "\f6cf";
}
.fi-ts-i:before {
    content: "\f6d0";
}
.fi-ts-ice-cream:before {
    content: "\f6d1";
}
.fi-ts-icicles:before {
    content: "\f6d2";
}
.fi-ts-icon-star:before {
    content: "\f6d3";
}
.fi-ts-id-badge:before {
    content: "\f6d4";
}
.fi-ts-id-card-clip-alt:before {
    content: "\f6d5";
}
.fi-ts-igloo:before {
    content: "\f6d6";
}
.fi-ts-image-slash:before {
    content: "\f6d7";
}
.fi-ts-images-user:before {
    content: "\f6d8";
}
.fi-ts-images:before {
    content: "\f6d9";
}
.fi-ts-inbox-in:before {
    content: "\f6da";
}
.fi-ts-inbox-out:before {
    content: "\f6db";
}
.fi-ts-inboxes:before {
    content: "\f6dc";
}
.fi-ts-incognito:before {
    content: "\f6dd";
}
.fi-ts-indian-rupee-sign:before {
    content: "\f6de";
}
.fi-ts-industry-alt:before {
    content: "\f6df";
}
.fi-ts-industry-windows:before {
    content: "\f6e0";
}
.fi-ts-information:before {
    content: "\f6e1";
}
.fi-ts-inhaler:before {
    content: "\f6e2";
}
.fi-ts-input-numeric:before {
    content: "\f6e3";
}
.fi-ts-input-pipe:before {
    content: "\f6e4";
}
.fi-ts-input-text:before {
    content: "\f6e5";
}
.fi-ts-insert-alt:before {
    content: "\f6e6";
}
.fi-ts-insert-arrows:before {
    content: "\f6e7";
}
.fi-ts-insert-square:before {
    content: "\f6e8";
}
.fi-ts-insert:before {
    content: "\f6e9";
}
.fi-ts-insight-alt:before {
    content: "\f6ea";
}
.fi-ts-insight-head:before {
    content: "\f6eb";
}
.fi-ts-insight:before {
    content: "\f6ec";
}
.fi-ts-integral:before {
    content: "\f6ed";
}
.fi-ts-intersection:before {
    content: "\f6ee";
}
.fi-ts-inventory-alt:before {
    content: "\f6ef";
}
.fi-ts-invest:before {
    content: "\f6f0";
}
.fi-ts-investment:before {
    content: "\f6f1";
}
.fi-ts-invite-alt:before {
    content: "\f6f2";
}
.fi-ts-invite:before {
    content: "\f6f3";
}
.fi-ts-island-tropical:before {
    content: "\f6f4";
}
.fi-ts-issue-loupe:before {
    content: "\f6f5";
}
.fi-ts-it-alt:before {
    content: "\f6f6";
}
.fi-ts-it-computer:before {
    content: "\f6f7";
}
.fi-ts-it:before {
    content: "\f6f8";
}
.fi-ts-italian-lira-sign:before {
    content: "\f6f9";
}
.fi-ts-j:before {
    content: "\f6fa";
}
.fi-ts-jam:before {
    content: "\f6fb";
}
.fi-ts-jar-alt:before {
    content: "\f6fc";
}
.fi-ts-jar-wheat:before {
    content: "\f6fd";
}
.fi-ts-javascript:before {
    content: "\f6fe";
}
.fi-ts-joint:before {
    content: "\f6ff";
}
.fi-ts-journal-alt:before {
    content: "\f700";
}
.fi-ts-journal:before {
    content: "\f701";
}
.fi-ts-journey:before {
    content: "\f702";
}
.fi-ts-joystick:before {
    content: "\f703";
}
.fi-ts-jug-alt:before {
    content: "\f704";
}
.fi-ts-jug-bottle:before {
    content: "\f705";
}
.fi-ts-jug:before {
    content: "\f706";
}
.fi-ts-k:before {
    content: "\f707";
}
.fi-ts-kaaba:before {
    content: "\f708";
}
.fi-ts-kazoo:before {
    content: "\f709";
}
.fi-ts-kerning:before {
    content: "\f70a";
}
.fi-ts-key-skeleton-left-right:before {
    content: "\f70b";
}
.fi-ts-key:before {
    content: "\f70c";
}
.fi-ts-keyboard-brightness-low:before {
    content: "\f70d";
}
.fi-ts-keyboard-brightness:before {
    content: "\f70e";
}
.fi-ts-keyboard-down:before {
    content: "\f70f";
}
.fi-ts-keyboard-left:before {
    content: "\f710";
}
.fi-ts-keynote:before {
    content: "\f711";
}
.fi-ts-kidneys:before {
    content: "\f712";
}
.fi-ts-kip-sign:before {
    content: "\f713";
}
.fi-ts-kiss-beam:before {
    content: "\f714";
}
.fi-ts-kiss-wink-heart:before {
    content: "\f715";
}
.fi-ts-kiss:before {
    content: "\f716";
}
.fi-ts-kite:before {
    content: "\f717";
}
.fi-ts-kiwi-bird:before {
    content: "\f718";
}
.fi-ts-kiwi-fruit:before {
    content: "\f719";
}
.fi-ts-knife-kitchen:before {
    content: "\f71a";
}
.fi-ts-knife:before {
    content: "\f71b";
}
.fi-ts-knitting:before {
    content: "\f71c";
}
.fi-ts-kpi-evaluation:before {
    content: "\f71d";
}
.fi-ts-kpi:before {
    content: "\f71e";
}
.fi-ts-l:before {
    content: "\f71f";
}
.fi-ts-lacrosse-stick-ball:before {
    content: "\f720";
}
.fi-ts-lacrosse-stick:before {
    content: "\f721";
}
.fi-ts-lambda:before {
    content: "\f722";
}
.fi-ts-lamp-desk:before {
    content: "\f723";
}
.fi-ts-lamp-floor:before {
    content: "\f724";
}
.fi-ts-lamp-street:before {
    content: "\f725";
}
.fi-ts-lamp:before {
    content: "\f726";
}
.fi-ts-land-layer-location:before {
    content: "\f727";
}
.fi-ts-land-layers:before {
    content: "\f728";
}
.fi-ts-land-location:before {
    content: "\f729";
}
.fi-ts-landmark-alt:before {
    content: "\f72a";
}
.fi-ts-language:before {
    content: "\f72b";
}
.fi-ts-laptop-arrow-down:before {
    content: "\f72c";
}
.fi-ts-laptop-code:before {
    content: "\f72d";
}
.fi-ts-laptop-medical:before {
    content: "\f72e";
}
.fi-ts-laptop-mobile:before {
    content: "\f72f";
}
.fi-ts-laptop-slash:before {
    content: "\f730";
}
.fi-ts-lari-sign:before {
    content: "\f731";
}
.fi-ts-lasso-sparkles:before {
    content: "\f732";
}
.fi-ts-laugh-beam:before {
    content: "\f733";
}
.fi-ts-laugh-squint:before {
    content: "\f734";
}
.fi-ts-laugh-wink:before {
    content: "\f735";
}
.fi-ts-laugh:before {
    content: "\f736";
}
.fi-ts-lawyer-man:before {
    content: "\f737";
}
.fi-ts-lawyer-woman:before {
    content: "\f738";
}
.fi-ts-layer-minus:before {
    content: "\f739";
}
.fi-ts-layer-plus:before {
    content: "\f73a";
}
.fi-ts-leader-alt:before {
    content: "\f73b";
}
.fi-ts-leader:before {
    content: "\f73c";
}
.fi-ts-leaderboard-alt:before {
    content: "\f73d";
}
.fi-ts-leaderboard-trophy:before {
    content: "\f73e";
}
.fi-ts-leaderboard:before {
    content: "\f73f";
}
.fi-ts-leadership-alt:before {
    content: "\f740";
}
.fi-ts-leadership:before {
    content: "\f741";
}
.fi-ts-leaf-heart:before {
    content: "\f742";
}
.fi-ts-leaf-maple:before {
    content: "\f743";
}
.fi-ts-leaf-oak:before {
    content: "\f744";
}
.fi-ts-leaf:before {
    content: "\f745";
}
.fi-ts-leafy-green:before {
    content: "\f746";
}
.fi-ts-leave:before {
    content: "\f747";
}
.fi-ts-left:before {
    content: "\f748";
}
.fi-ts-legal:before {
    content: "\f749";
}
.fi-ts-lemon:before {
    content: "\f74a";
}
.fi-ts-less-than-equal:before {
    content: "\f74b";
}
.fi-ts-less-than:before {
    content: "\f74c";
}
.fi-ts-lettuce:before {
    content: "\f74d";
}
.fi-ts-level-down-alt:before {
    content: "\f74e";
}
.fi-ts-level-down:before {
    content: "\f74f";
}
.fi-ts-level-up-alt:before {
    content: "\f750";
}
.fi-ts-level-up:before {
    content: "\f751";
}
.fi-ts-license:before {
    content: "\f752";
}
.fi-ts-light-ceiling:before {
    content: "\f753";
}
.fi-ts-light-emergency-on:before {
    content: "\f754";
}
.fi-ts-light-emergency:before {
    content: "\f755";
}
.fi-ts-light-switch-off:before {
    content: "\f756";
}
.fi-ts-light-switch-on:before {
    content: "\f757";
}
.fi-ts-light-switch:before {
    content: "\f758";
}
.fi-ts-lightbulb-dollar:before {
    content: "\f759";
}
.fi-ts-lightbulb-exclamation:before {
    content: "\f75a";
}
.fi-ts-lightbulb-head:before {
    content: "\f75b";
}
.fi-ts-lightbulb-on:before {
    content: "\f75c";
}
.fi-ts-lightbulb-question:before {
    content: "\f75d";
}
.fi-ts-lightbulb-setting:before {
    content: "\f75e";
}
.fi-ts-lightbulb-slash:before {
    content: "\f75f";
}
.fi-ts-lights-holiday:before {
    content: "\f760";
}
.fi-ts-limit-speedometer:before {
    content: "\f761";
}
.fi-ts-link-alt:before {
    content: "\f762";
}
.fi-ts-link-horizontal-slash:before {
    content: "\f763";
}
.fi-ts-link-horizontal:before {
    content: "\f764";
}
.fi-ts-link-slash-alt:before {
    content: "\f765";
}
.fi-ts-link-slash:before {
    content: "\f766";
}
.fi-ts-lion-head:before {
    content: "\f767";
}
.fi-ts-lion:before {
    content: "\f768";
}
.fi-ts-lips:before {
    content: "\f769";
}
.fi-ts-lira-sign:before {
    content: "\f76a";
}
.fi-ts-list-check:before {
    content: "\f76b";
}
.fi-ts-list-dropdown:before {
    content: "\f76c";
}
.fi-ts-list-music:before {
    content: "\f76d";
}
.fi-ts-list-timeline:before {
    content: "\f76e";
}
.fi-ts-litecoin-sign:before {
    content: "\f76f";
}
.fi-ts-live-alt:before {
    content: "\f770";
}
.fi-ts-live:before {
    content: "\f771";
}
.fi-ts-loan:before {
    content: "\f772";
}
.fi-ts-lobster:before {
    content: "\f773";
}
.fi-ts-location-alt:before {
    content: "\f774";
}
.fi-ts-location-arrow:before {
    content: "\f775";
}
.fi-ts-location-crosshairs-slash:before {
    content: "\f776";
}
.fi-ts-location-crosshairs:before {
    content: "\f777";
}
.fi-ts-location-dot-slash:before {
    content: "\f778";
}
.fi-ts-location-exclamation:before {
    content: "\f779";
}
.fi-ts-lock-alt:before {
    content: "\f77a";
}
.fi-ts-lock-hashtag:before {
    content: "\f77b";
}
.fi-ts-lock-open-alt:before {
    content: "\f77c";
}
.fi-ts-locust:before {
    content: "\f77d";
}
.fi-ts-loveseat:before {
    content: "\f77e";
}
.fi-ts-low-vision:before {
    content: "\f77f";
}
.fi-ts-luchador:before {
    content: "\f780";
}
.fi-ts-luggage-cart:before {
    content: "\f781";
}
.fi-ts-lungs-virus:before {
    content: "\f782";
}
.fi-ts-lungs:before {
    content: "\f783";
}
.fi-ts-m:before {
    content: "\f784";
}
.fi-ts-mace:before {
    content: "\f785";
}
.fi-ts-magnet-user:before {
    content: "\f786";
}
.fi-ts-magnet:before {
    content: "\f787";
}
.fi-ts-mailbox:before {
    content: "\f788";
}
.fi-ts-man-head:before {
    content: "\f789";
}
.fi-ts-man-scientist:before {
    content: "\f78a";
}
.fi-ts-manat-sign:before {
    content: "\f78b";
}
.fi-ts-mandolin:before {
    content: "\f78c";
}
.fi-ts-mango:before {
    content: "\f78d";
}
.fi-ts-manhole:before {
    content: "\f78e";
}
.fi-ts-map-marker-check:before {
    content: "\f78f";
}
.fi-ts-map-marker-edit:before {
    content: "\f790";
}
.fi-ts-map-marker-question:before {
    content: "\f791";
}
.fi-ts-map-marker-slash:before {
    content: "\f792";
}
.fi-ts-map-marker-smile:before {
    content: "\f793";
}
.fi-ts-map-pin:before {
    content: "\f794";
}
.fi-ts-marketplace-alt:before {
    content: "\f795";
}
.fi-ts-marketplace-store:before {
    content: "\f796";
}
.fi-ts-marketplace:before {
    content: "\f797";
}
.fi-ts-mars-double:before {
    content: "\f798";
}
.fi-ts-mars-stroke-right:before {
    content: "\f799";
}
.fi-ts-mars-stroke-up:before {
    content: "\f79a";
}
.fi-ts-mars:before {
    content: "\f79b";
}
.fi-ts-martini-glass-citrus:before {
    content: "\f79c";
}
.fi-ts-martini-glass-empty:before {
    content: "\f79d";
}
.fi-ts-mask-carnival:before {
    content: "\f79e";
}
.fi-ts-mask-face:before {
    content: "\f79f";
}
.fi-ts-mask-snorkel:before {
    content: "\f7a0";
}
.fi-ts-massage:before {
    content: "\f7a1";
}
.fi-ts-match-fire:before {
    content: "\f7a2";
}
.fi-ts-mattress-pillow:before {
    content: "\f7a3";
}
.fi-ts-meat:before {
    content: "\f7a4";
}
.fi-ts-medal:before {
    content: "\f7a5";
}
.fi-ts-medical-star:before {
    content: "\f7a6";
}
.fi-ts-meeting-alt:before {
    content: "\f7a7";
}
.fi-ts-meeting:before {
    content: "\f7a8";
}
.fi-ts-megaphone:before {
    content: "\f7a9";
}
.fi-ts-meh-blank:before {
    content: "\f7aa";
}
.fi-ts-meh-rolling-eyes:before {
    content: "\f7ab";
}
.fi-ts-meh:before {
    content: "\f7ac";
}
.fi-ts-melon-alt:before {
    content: "\f7ad";
}
.fi-ts-melon:before {
    content: "\f7ae";
}
.fi-ts-membership-vip:before {
    content: "\f7af";
}
.fi-ts-membership:before {
    content: "\f7b0";
}
.fi-ts-memo-circle-check:before {
    content: "\f7b1";
}
.fi-ts-memo-pad:before {
    content: "\f7b2";
}
.fi-ts-memo:before {
    content: "\f7b3";
}
.fi-ts-memory:before {
    content: "\f7b4";
}
.fi-ts-mercury:before {
    content: "\f7b5";
}
.fi-ts-message-arrow-down:before {
    content: "\f7b6";
}
.fi-ts-message-arrow-up-right:before {
    content: "\f7b7";
}
.fi-ts-message-arrow-up:before {
    content: "\f7b8";
}
.fi-ts-message-bot:before {
    content: "\f7b9";
}
.fi-ts-message-code:before {
    content: "\f7ba";
}
.fi-ts-message-dollar:before {
    content: "\f7bb";
}
.fi-ts-message-image:before {
    content: "\f7bc";
}
.fi-ts-message-question:before {
    content: "\f7bd";
}
.fi-ts-message-quote:before {
    content: "\f7be";
}
.fi-ts-message-slash:before {
    content: "\f7bf";
}
.fi-ts-message-sms:before {
    content: "\f7c0";
}
.fi-ts-message-text:before {
    content: "\f7c1";
}
.fi-ts-message-xmark:before {
    content: "\f7c2";
}
.fi-ts-messages-dollar:before {
    content: "\f7c3";
}
.fi-ts-messages-question:before {
    content: "\f7c4";
}
.fi-ts-messages:before {
    content: "\f7c5";
}
.fi-ts-meteor:before {
    content: "\f7c6";
}
.fi-ts-meter-bolt:before {
    content: "\f7c7";
}
.fi-ts-meter-droplet:before {
    content: "\f7c8";
}
.fi-ts-meter-fire:before {
    content: "\f7c9";
}
.fi-ts-meter:before {
    content: "\f7ca";
}
.fi-ts-method:before {
    content: "\f7cb";
}
.fi-ts-microchip-ai:before {
    content: "\f7cc";
}
.fi-ts-microchip:before {
    content: "\f7cd";
}
.fi-ts-microphone-alt:before {
    content: "\f7ce";
}
.fi-ts-microphone-slash:before {
    content: "\f7cf";
}
.fi-ts-microscope:before {
    content: "\f7d0";
}
.fi-ts-microwave:before {
    content: "\f7d1";
}
.fi-ts-milk-alt:before {
    content: "\f7d2";
}
.fi-ts-milk:before {
    content: "\f7d3";
}
.fi-ts-mill-sign:before {
    content: "\f7d4";
}
.fi-ts-mind-share:before {
    content: "\f7d5";
}
.fi-ts-minus-circle:before {
    content: "\f7d6";
}
.fi-ts-minus-hexagon:before {
    content: "\f7d7";
}
.fi-ts-mistletoe:before {
    content: "\f7d8";
}
.fi-ts-mix:before {
    content: "\f7d9";
}
.fi-ts-mobile-button:before {
    content: "\f7da";
}
.fi-ts-mobile-notch:before {
    content: "\f7db";
}
.fi-ts-mockup:before {
    content: "\f7dc";
}
.fi-ts-mode-alt:before {
    content: "\f7dd";
}
.fi-ts-mode:before {
    content: "\f7de";
}
.fi-ts-module:before {
    content: "\f7df";
}
.fi-ts-monday:before {
    content: "\f7e0";
}
.fi-ts-money-bill-simple:before {
    content: "\f7e1";
}
.fi-ts-money-bill-transfer:before {
    content: "\f7e2";
}
.fi-ts-money-bill-wave-alt:before {
    content: "\f7e3";
}
.fi-ts-money-bill-wave:before {
    content: "\f7e4";
}
.fi-ts-money-bills-simple:before {
    content: "\f7e5";
}
.fi-ts-money-bills:before {
    content: "\f7e6";
}
.fi-ts-money-check-edit-alt:before {
    content: "\f7e7";
}
.fi-ts-money-check-edit:before {
    content: "\f7e8";
}
.fi-ts-money-check:before {
    content: "\f7e9";
}
.fi-ts-money-coin-transfer:before {
    content: "\f7ea";
}
.fi-ts-money-from-bracket:before {
    content: "\f7eb";
}
.fi-ts-money-simple-from-bracket:before {
    content: "\f7ec";
}
.fi-ts-money-transfer-alt:before {
    content: "\f7ed";
}
.fi-ts-money-transfer-coin-arrow:before {
    content: "\f7ee";
}
.fi-ts-money-transfer-smartphone:before {
    content: "\f7ef";
}
.fi-ts-money:before {
    content: "\f7f0";
}
.fi-ts-monkey:before {
    content: "\f7f1";
}
.fi-ts-monument:before {
    content: "\f7f2";
}
.fi-ts-moon-stars:before {
    content: "\f7f3";
}
.fi-ts-moon:before {
    content: "\f7f4";
}
.fi-ts-moped:before {
    content: "\f7f5";
}
.fi-ts-mortar-pestle:before {
    content: "\f7f6";
}
.fi-ts-mosque-alt:before {
    content: "\f7f7";
}
.fi-ts-mosque-moon:before {
    content: "\f7f8";
}
.fi-ts-mosque:before {
    content: "\f7f9";
}
.fi-ts-mosquito-net:before {
    content: "\f7fa";
}
.fi-ts-mosquito:before {
    content: "\f7fb";
}
.fi-ts-motorcycle:before {
    content: "\f7fc";
}
.fi-ts-mound:before {
    content: "\f7fd";
}
.fi-ts-mountain-city:before {
    content: "\f7fe";
}
.fi-ts-mountain:before {
    content: "\f7ff";
}
.fi-ts-mountains:before {
    content: "\f800";
}
.fi-ts-move-to-folder-2:before {
    content: "\f801";
}
.fi-ts-move-to-folder:before {
    content: "\f802";
}
.fi-ts-mp3-player:before {
    content: "\f803";
}
.fi-ts-mug-alt:before {
    content: "\f804";
}
.fi-ts-mug-hot-alt:before {
    content: "\f805";
}
.fi-ts-mug-hot:before {
    content: "\f806";
}
.fi-ts-mug-marshmallows:before {
    content: "\f807";
}
.fi-ts-mug-tea-saucer:before {
    content: "\f808";
}
.fi-ts-mug-tea:before {
    content: "\f809";
}
.fi-ts-mug:before {
    content: "\f80a";
}
.fi-ts-multiple-alt:before {
    content: "\f80b";
}
.fi-ts-multiple:before {
    content: "\f80c";
}
.fi-ts-mushroom-alt:before {
    content: "\f80d";
}
.fi-ts-mushroom:before {
    content: "\f80e";
}
.fi-ts-music-note-slash:before {
    content: "\f80f";
}
.fi-ts-music-note:before {
    content: "\f810";
}
.fi-ts-music-slash:before {
    content: "\f811";
}
.fi-ts-n:before {
    content: "\f812";
}
.fi-ts-naira-sign:before {
    content: "\f813";
}
.fi-ts-narwhal:before {
    content: "\f814";
}
.fi-ts-nesting-dolls:before {
    content: "\f815";
}
.fi-ts-network-analytic:before {
    content: "\f816";
}
.fi-ts-network-cloud:before {
    content: "\f817";
}
.fi-ts-network:before {
    content: "\f818";
}
.fi-ts-neuter:before {
    content: "\f819";
}
.fi-ts-newspaper-open:before {
    content: "\f81a";
}
.fi-ts-newspaper:before {
    content: "\f81b";
}
.fi-ts-nfc-lock:before {
    content: "\f81c";
}
.fi-ts-nfc-magnifying-glass:before {
    content: "\f81d";
}
.fi-ts-nfc-pen:before {
    content: "\f81e";
}
.fi-ts-nfc-slash:before {
    content: "\f81f";
}
.fi-ts-nfc-trash:before {
    content: "\f820";
}
.fi-ts-nfc:before {
    content: "\f821";
}
.fi-ts-no-fee:before {
    content: "\f822";
}
.fi-ts-no-people:before {
    content: "\f823";
}
.fi-ts-noodles:before {
    content: "\f824";
}
.fi-ts-nose:before {
    content: "\f825";
}
.fi-ts-not-equal:before {
    content: "\f826";
}
.fi-ts-not-found-alt:before {
    content: "\f827";
}
.fi-ts-not-found-magnifying-glass:before {
    content: "\f828";
}
.fi-ts-not-found:before {
    content: "\f829";
}
.fi-ts-notdef:before {
    content: "\f82a";
}
.fi-ts-note-medical:before {
    content: "\f82b";
}
.fi-ts-note-sticky:before {
    content: "\f82c";
}
.fi-ts-note:before {
    content: "\f82d";
}
.fi-ts-notebook-alt:before {
    content: "\f82e";
}
.fi-ts-notes-medical:before {
    content: "\f82f";
}
.fi-ts-notes:before {
    content: "\f830";
}
.fi-ts-o:before {
    content: "\f831";
}
.fi-ts-object-exclude:before {
    content: "\f832";
}
.fi-ts-object-group:before {
    content: "\f833";
}
.fi-ts-object-intersect:before {
    content: "\f834";
}
.fi-ts-object-subtract:before {
    content: "\f835";
}
.fi-ts-object-ungroup:before {
    content: "\f836";
}
.fi-ts-object-union:before {
    content: "\f837";
}
.fi-ts-objects-column:before {
    content: "\f838";
}
.fi-ts-octagon-check:before {
    content: "\f839";
}
.fi-ts-octagon-divide:before {
    content: "\f83a";
}
.fi-ts-octagon-exclamation:before {
    content: "\f83b";
}
.fi-ts-octagon-minus:before {
    content: "\f83c";
}
.fi-ts-octagon-plus:before {
    content: "\f83d";
}
.fi-ts-octagon-xmark:before {
    content: "\f83e";
}
.fi-ts-octagon:before {
    content: "\f83f";
}
.fi-ts-oil-can:before {
    content: "\f840";
}
.fi-ts-oil-temp:before {
    content: "\f841";
}
.fi-ts-olive-oil:before {
    content: "\f842";
}
.fi-ts-olive:before {
    content: "\f843";
}
.fi-ts-olives:before {
    content: "\f844";
}
.fi-ts-om:before {
    content: "\f845";
}
.fi-ts-omega:before {
    content: "\f846";
}
.fi-ts-onboarding:before {
    content: "\f847";
}
.fi-ts-onion:before {
    content: "\f848";
}
.fi-ts-operation:before {
    content: "\f849";
}
.fi-ts-organization-chart:before {
    content: "\f84a";
}
.fi-ts-ornament:before {
    content: "\f84b";
}
.fi-ts-otp:before {
    content: "\f84c";
}
.fi-ts-otter:before {
    content: "\f84d";
}
.fi-ts-outdent:before {
    content: "\f84e";
}
.fi-ts-oval-alt:before {
    content: "\f84f";
}
.fi-ts-oval:before {
    content: "\f850";
}
.fi-ts-oven:before {
    content: "\f851";
}
.fi-ts-overline:before {
    content: "\f852";
}
.fi-ts-overview:before {
    content: "\f853";
}
.fi-ts-p:before {
    content: "\f854";
}
.fi-ts-package:before {
    content: "\f855";
}
.fi-ts-padlock-check:before {
    content: "\f856";
}
.fi-ts-page-break:before {
    content: "\f857";
}
.fi-ts-pager:before {
    content: "\f858";
}
.fi-ts-paid:before {
    content: "\f859";
}
.fi-ts-paint-roller:before {
    content: "\f85a";
}
.fi-ts-paintbrush-pencil:before {
    content: "\f85b";
}
.fi-ts-pallet-alt:before {
    content: "\f85c";
}
.fi-ts-pallet:before {
    content: "\f85d";
}
.fi-ts-pan-food:before {
    content: "\f85e";
}
.fi-ts-pan-frying:before {
    content: "\f85f";
}
.fi-ts-pan:before {
    content: "\f860";
}
.fi-ts-pancakes:before {
    content: "\f861";
}
.fi-ts-panorama:before {
    content: "\f862";
}
.fi-ts-paper-plane-top:before {
    content: "\f863";
}
.fi-ts-paperclip-vertical:before {
    content: "\f864";
}
.fi-ts-parachute-box:before {
    content: "\f865";
}
.fi-ts-paragraph-left:before {
    content: "\f866";
}
.fi-ts-paragraph:before {
    content: "\f867";
}
.fi-ts-parking-circle-slash:before {
    content: "\f868";
}
.fi-ts-parking-circle:before {
    content: "\f869";
}
.fi-ts-parking-slash:before {
    content: "\f86a";
}
.fi-ts-parking:before {
    content: "\f86b";
}
.fi-ts-party-bell:before {
    content: "\f86c";
}
.fi-ts-party-horn:before {
    content: "\f86d";
}
.fi-ts-passport:before {
    content: "\f86e";
}
.fi-ts-password-alt:before {
    content: "\f86f";
}
.fi-ts-password-computer:before {
    content: "\f870";
}
.fi-ts-password-email:before {
    content: "\f871";
}
.fi-ts-password-smartphone:before {
    content: "\f872";
}
.fi-ts-password:before {
    content: "\f873";
}
.fi-ts-paste:before {
    content: "\f874";
}
.fi-ts-pattern:before {
    content: "\f875";
}
.fi-ts-pause-circle:before {
    content: "\f876";
}
.fi-ts-paw-claws:before {
    content: "\f877";
}
.fi-ts-paw-heart:before {
    content: "\f878";
}
.fi-ts-paw:before {
    content: "\f879";
}
.fi-ts-payment-pos:before {
    content: "\f87a";
}
.fi-ts-payroll-calendar:before {
    content: "\f87b";
}
.fi-ts-payroll:before {
    content: "\f87c";
}
.fi-ts-peace:before {
    content: "\f87d";
}
.fi-ts-peach:before {
    content: "\f87e";
}
.fi-ts-peanut:before {
    content: "\f87f";
}
.fi-ts-peanuts:before {
    content: "\f880";
}
.fi-ts-peapod:before {
    content: "\f881";
}
.fi-ts-pear:before {
    content: "\f882";
}
.fi-ts-pedestal:before {
    content: "\f883";
}
.fi-ts-pen-circle:before {
    content: "\f884";
}
.fi-ts-pen-clip-slash:before {
    content: "\f885";
}
.fi-ts-pen-clip:before {
    content: "\f886";
}
.fi-ts-pen-fancy-slash:before {
    content: "\f887";
}
.fi-ts-pen-fancy:before {
    content: "\f888";
}
.fi-ts-pen-field:before {
    content: "\f889";
}
.fi-ts-pen-nib-slash:before {
    content: "\f88a";
}
.fi-ts-pen-nib:before {
    content: "\f88b";
}
.fi-ts-pen-slash:before {
    content: "\f88c";
}
.fi-ts-pen-square:before {
    content: "\f88d";
}
.fi-ts-pen-swirl:before {
    content: "\f88e";
}
.fi-ts-pencil-paintbrush:before {
    content: "\f88f";
}
.fi-ts-pencil-ruler:before {
    content: "\f890";
}
.fi-ts-pencil-slash:before {
    content: "\f891";
}
.fi-ts-pending:before {
    content: "\f892";
}
.fi-ts-pennant:before {
    content: "\f893";
}
.fi-ts-people-arrows-left-right:before {
    content: "\f894";
}
.fi-ts-people-carry-box:before {
    content: "\f895";
}
.fi-ts-people-dress:before {
    content: "\f896";
}
.fi-ts-people-pants:before {
    content: "\f897";
}
.fi-ts-people-poll:before {
    content: "\f898";
}
.fi-ts-people:before {
    content: "\f899";
}
.fi-ts-pepper-alt:before {
    content: "\f89a";
}
.fi-ts-pepper-hot:before {
    content: "\f89b";
}
.fi-ts-pepper:before {
    content: "\f89c";
}
.fi-ts-percent-10:before {
    content: "\f89d";
}
.fi-ts-percent-100:before {
    content: "\f89e";
}
.fi-ts-percent-20:before {
    content: "\f89f";
}
.fi-ts-percent-25:before {
    content: "\f8a0";
}
.fi-ts-percent-30:before {
    content: "\f8a1";
}
.fi-ts-percent-40:before {
    content: "\f8a2";
}
.fi-ts-percent-50:before {
    content: "\f8a3";
}
.fi-ts-percent-60:before {
    content: "\f8a4";
}
.fi-ts-percent-70:before {
    content: "\f8a5";
}
.fi-ts-percent-75:before {
    content: "\f8a6";
}
.fi-ts-percent-80:before {
    content: "\f8a7";
}
.fi-ts-percent-90:before {
    content: "\f8a8";
}
.fi-ts-percentage:before {
    content: "\f8a9";
}
.fi-ts-person-carry-box:before {
    content: "\f8aa";
}
.fi-ts-person-circle-check:before {
    content: "\f8ab";
}
.fi-ts-person-circle-exclamation:before {
    content: "\f8ac";
}
.fi-ts-person-circle-minus:before {
    content: "\f8ad";
}
.fi-ts-person-circle-plus:before {
    content: "\f8ae";
}
.fi-ts-person-circle-question:before {
    content: "\f8af";
}
.fi-ts-person-circle-xmark:before {
    content: "\f8b0";
}
.fi-ts-person-cv:before {
    content: "\f8b1";
}
.fi-ts-person-dolly-empty:before {
    content: "\f8b2";
}
.fi-ts-person-dolly:before {
    content: "\f8b3";
}
.fi-ts-person-dress-simple:before {
    content: "\f8b4";
}
.fi-ts-person-dress:before {
    content: "\f8b5";
}
.fi-ts-person-praying:before {
    content: "\f8b6";
}
.fi-ts-person-pregnant:before {
    content: "\f8b7";
}
.fi-ts-person-seat-reclined:before {
    content: "\f8b8";
}
.fi-ts-person-seat:before {
    content: "\f8b9";
}
.fi-ts-person-sign:before {
    content: "\f8ba";
}
.fi-ts-person-simple:before {
    content: "\f8bb";
}
.fi-ts-person-walking-with-cane:before {
    content: "\f8bc";
}
.fi-ts-peseta-sign:before {
    content: "\f8bd";
}
.fi-ts-peso-sign:before {
    content: "\f8be";
}
.fi-ts-pets:before {
    content: "\f8bf";
}
.fi-ts-phone-call:before {
    content: "\f8c0";
}
.fi-ts-phone-cross:before {
    content: "\f8c1";
}
.fi-ts-phone-flip:before {
    content: "\f8c2";
}
.fi-ts-phone-office:before {
    content: "\f8c3";
}
.fi-ts-phone-pause:before {
    content: "\f8c4";
}
.fi-ts-phone-plus:before {
    content: "\f8c5";
}
.fi-ts-phone-rotary:before {
    content: "\f8c6";
}
.fi-ts-phone-slash:before {
    content: "\f8c7";
}
.fi-ts-photo-capture:before {
    content: "\f8c8";
}
.fi-ts-photo-film-music:before {
    content: "\f8c9";
}
.fi-ts-photo-video:before {
    content: "\f8ca";
}
.fi-ts-Pi:before {
    content: "\f8cb";
}
.fi-ts-piano-keyboard:before {
    content: "\f8cc";
}
.fi-ts-piano:before {
    content: "\f8cd";
}
.fi-ts-pickaxe:before {
    content: "\f8ce";
}
.fi-ts-picking:before {
    content: "\f8cf";
}
.fi-ts-picnic:before {
    content: "\f8d0";
}
.fi-ts-picpeople-filled:before {
    content: "\f8d1";
}
.fi-ts-picpeople:before {
    content: "\f8d2";
}
.fi-ts-pie:before {
    content: "\f8d3";
}
.fi-ts-pig-face:before {
    content: "\f8d4";
}
.fi-ts-pig:before {
    content: "\f8d5";
}
.fi-ts-piggy-bank:before {
    content: "\f8d6";
}
.fi-ts-pills:before {
    content: "\f8d7";
}
.fi-ts-pinata:before {
    content: "\f8d8";
}
.fi-ts-pineapple-alt:before {
    content: "\f8d9";
}
.fi-ts-pineapple:before {
    content: "\f8da";
}
.fi-ts-pipe-smoking:before {
    content: "\f8db";
}
.fi-ts-pizza-slice:before {
    content: "\f8dc";
}
.fi-ts-place-of-worship:before {
    content: "\f8dd";
}
.fi-ts-plan-strategy:before {
    content: "\f8de";
}
.fi-ts-plan:before {
    content: "\f8df";
}
.fi-ts-plane-alt:before {
    content: "\f8e0";
}
.fi-ts-plane-arrival:before {
    content: "\f8e1";
}
.fi-ts-plane-departure:before {
    content: "\f8e2";
}
.fi-ts-plane-prop:before {
    content: "\f8e3";
}
.fi-ts-plane-slash:before {
    content: "\f8e4";
}
.fi-ts-plane-tail:before {
    content: "\f8e5";
}
.fi-ts-planet-moon:before {
    content: "\f8e6";
}
.fi-ts-planet-ringed:before {
    content: "\f8e7";
}
.fi-ts-plant-wilt:before {
    content: "\f8e8";
}
.fi-ts-plate-utensils:before {
    content: "\f8e9";
}
.fi-ts-plate-wheat:before {
    content: "\f8ea";
}
.fi-ts-plate:before {
    content: "\f8eb";
}
.fi-ts-play-circle:before {
    content: "\f8ec";
}
.fi-ts-play-pause:before {
    content: "\f8ed";
}
.fi-ts-plug-alt:before {
    content: "\f8ee";
}
.fi-ts-plug-cable:before {
    content: "\f8ef";
}
.fi-ts-plug-connection:before {
    content: "\f8f0";
}
.fi-ts-plug:before {
    content: "\f8f1";
}
.fi-ts-plus-hexagon:before {
    content: "\f8f2";
}
.fi-ts-plus-minus:before {
    content: "\f8f3";
}
.fi-ts-podcast:before {
    content: "\f8f4";
}
.fi-ts-podium-star:before {
    content: "\f8f5";
}
.fi-ts-podium:before {
    content: "\f8f6";
}
.fi-ts-point-of-sale-bill:before {
    content: "\f8f7";
}
.fi-ts-point-of-sale-signal:before {
    content: "\f8f8";
}
.fi-ts-point-of-sale:before {
    content: "\f8f9";
}
.fi-ts-police-box:before {
    content: "\f8fa";
}
.fi-ts-poll-h:before {
    content: "\f8fb";
}
.fi-ts-pollution:before {
    content: "\f8fc";
}
.fi-ts-pompebled:before {
    content: "\f8fd";
}
.fi-ts-poo-bolt:before {
    content: "\f8fe";
}
.fi-ts-poo:before {
    content: "\f8ff";
}
.fi-ts-pool-8-ball:before {
    content: "\f900";
}
.fi-ts-poop:before {
    content: "\f901";
}
.fi-ts-popcorn:before {
    content: "\f902";
}
.fi-ts-popsicle:before {
    content: "\f903";
}
.fi-ts-portal-enter:before {
    content: "\f904";
}
.fi-ts-portal-exit:before {
    content: "\f905";
}
.fi-ts-pot:before {
    content: "\f906";
}
.fi-ts-potato:before {
    content: "\f907";
}
.fi-ts-pound:before {
    content: "\f908";
}
.fi-ts-practice:before {
    content: "\f909";
}
.fi-ts-praying-hands:before {
    content: "\f90a";
}
.fi-ts-prescription-bottle-alt:before {
    content: "\f90b";
}
.fi-ts-prescription-bottle-pill:before {
    content: "\f90c";
}
.fi-ts-prescription-bottle:before {
    content: "\f90d";
}
.fi-ts-prescription:before {
    content: "\f90e";
}
.fi-ts-preview:before {
    content: "\f90f";
}
.fi-ts-print-magnifying-glass:before {
    content: "\f910";
}
.fi-ts-print-slash:before {
    content: "\f911";
}
.fi-ts-priority-arrow:before {
    content: "\f912";
}
.fi-ts-priority-arrows:before {
    content: "\f913";
}
.fi-ts-priority-importance:before {
    content: "\f914";
}
.fi-ts-problem-solving:before {
    content: "\f915";
}
.fi-ts-procedures:before {
    content: "\f916";
}
.fi-ts-process:before {
    content: "\f917";
}
.fi-ts-productivity:before {
    content: "\f918";
}
.fi-ts-projector:before {
    content: "\f919";
}
.fi-ts-pump-medical:before {
    content: "\f91a";
}
.fi-ts-pump:before {
    content: "\f91b";
}
.fi-ts-pumpkin-alt-2:before {
    content: "\f91c";
}
.fi-ts-pumpkin-alt:before {
    content: "\f91d";
}
.fi-ts-pumpkin:before {
    content: "\f91e";
}
.fi-ts-puzzle-alt:before {
    content: "\f91f";
}
.fi-ts-puzzle-piece:before {
    content: "\f920";
}
.fi-ts-puzzle-pieces:before {
    content: "\f921";
}
.fi-ts-pyramid:before {
    content: "\f922";
}
.fi-ts-q:before {
    content: "\f923";
}
.fi-ts-qr-scan:before {
    content: "\f924";
}
.fi-ts-QR:before {
    content: "\f925";
}
.fi-ts-qrcode:before {
    content: "\f926";
}
.fi-ts-question-square:before {
    content: "\f927";
}
.fi-ts-question:before {
    content: "\f928";
}
.fi-ts-queue-alt:before {
    content: "\f929";
}
.fi-ts-queue-line:before {
    content: "\f92a";
}
.fi-ts-queue-signal:before {
    content: "\f92b";
}
.fi-ts-queue:before {
    content: "\f92c";
}
.fi-ts-quiz-alt:before {
    content: "\f92d";
}
.fi-ts-quiz:before {
    content: "\f92e";
}
.fi-ts-r:before {
    content: "\f92f";
}
.fi-ts-rabbit-fast:before {
    content: "\f930";
}
.fi-ts-rabbit:before {
    content: "\f931";
}
.fi-ts-racquet:before {
    content: "\f932";
}
.fi-ts-radar:before {
    content: "\f933";
}
.fi-ts-radiation-alt:before {
    content: "\f934";
}
.fi-ts-radiation:before {
    content: "\f935";
}
.fi-ts-radio-alt:before {
    content: "\f936";
}
.fi-ts-radio:before {
    content: "\f937";
}
.fi-ts-radish:before {
    content: "\f938";
}
.fi-ts-rainbow:before {
    content: "\f939";
}
.fi-ts-raindrops:before {
    content: "\f93a";
}
.fi-ts-ram:before {
    content: "\f93b";
}
.fi-ts-ramp-loading:before {
    content: "\f93c";
}
.fi-ts-rank:before {
    content: "\f93d";
}
.fi-ts-ranking-podium-empty:before {
    content: "\f93e";
}
.fi-ts-ranking-podium:before {
    content: "\f93f";
}
.fi-ts-ranking-star:before {
    content: "\f940";
}
.fi-ts-ranking-stars:before {
    content: "\f941";
}
.fi-ts-raygun:before {
    content: "\f942";
}
.fi-ts-razor-barber:before {
    content: "\f943";
}
.fi-ts-react:before {
    content: "\f944";
}
.fi-ts-recipe-book:before {
    content: "\f945";
}
.fi-ts-recipe:before {
    content: "\f946";
}
.fi-ts-record-vinyl:before {
    content: "\f947";
}
.fi-ts-rectangle-barcode:before {
    content: "\f948";
}
.fi-ts-rectangle-code:before {
    content: "\f949";
}
.fi-ts-rectangle-history-circle-plus:before {
    content: "\f94a";
}
.fi-ts-rectangle-horizontal:before {
    content: "\f94b";
}
.fi-ts-rectangle-list:before {
    content: "\f94c";
}
.fi-ts-rectangle-panoramic:before {
    content: "\f94d";
}
.fi-ts-rectangle-pro:before {
    content: "\f94e";
}
.fi-ts-rectangle-vertical-history:before {
    content: "\f94f";
}
.fi-ts-rectangle-vertical:before {
    content: "\f950";
}
.fi-ts-rectangle-xmark:before {
    content: "\f951";
}
.fi-ts-rectangles-mixed:before {
    content: "\f952";
}
.fi-ts-recycle:before {
    content: "\f953";
}
.fi-ts-redo-alt:before {
    content: "\f954";
}
.fi-ts-reel:before {
    content: "\f955";
}
.fi-ts-refer-arrow:before {
    content: "\f956";
}
.fi-ts-refer:before {
    content: "\f957";
}
.fi-ts-reflect-horizontal:before {
    content: "\f958";
}
.fi-ts-reflect-vertical:before {
    content: "\f959";
}
.fi-ts-refrigerator:before {
    content: "\f95a";
}
.fi-ts-refund-alt:before {
    content: "\f95b";
}
.fi-ts-refund:before {
    content: "\f95c";
}
.fi-ts-region-pin-alt:before {
    content: "\f95d";
}
.fi-ts-region-pin:before {
    content: "\f95e";
}
.fi-ts-registered:before {
    content: "\f95f";
}
.fi-ts-registration-paper:before {
    content: "\f960";
}
.fi-ts-remove-folder:before {
    content: "\f961";
}
.fi-ts-rent-signal:before {
    content: "\f962";
}
.fi-ts-rent:before {
    content: "\f963";
}
.fi-ts-replace:before {
    content: "\f964";
}
.fi-ts-reply-all:before {
    content: "\f965";
}
.fi-ts-republican:before {
    content: "\f966";
}
.fi-ts-reservation-smartphone:before {
    content: "\f967";
}
.fi-ts-reservation-table:before {
    content: "\f968";
}
.fi-ts-resources:before {
    content: "\f969";
}
.fi-ts-restaurant:before {
    content: "\f96a";
}
.fi-ts-restock:before {
    content: "\f96b";
}
.fi-ts-restroom-simple:before {
    content: "\f96c";
}
.fi-ts-revenue-alt:before {
    content: "\f96d";
}
.fi-ts-Revenue:before {
    content: "\f96e";
}
.fi-ts-review:before {
    content: "\f96f";
}
.fi-ts-rhombus:before {
    content: "\f970";
}
.fi-ts-ribbon:before {
    content: "\f971";
}
.fi-ts-right:before {
    content: "\f972";
}
.fi-ts-ring-diamond:before {
    content: "\f973";
}
.fi-ts-ring:before {
    content: "\f974";
}
.fi-ts-rings-wedding:before {
    content: "\f975";
}
.fi-ts-risk-alt:before {
    content: "\f976";
}
.fi-ts-risk:before {
    content: "\f977";
}
.fi-ts-robot:before {
    content: "\f978";
}
.fi-ts-rocket-lunch:before {
    content: "\f979";
}
.fi-ts-rocket:before {
    content: "\f97a";
}
.fi-ts-roller-coaster:before {
    content: "\f97b";
}
.fi-ts-room-service:before {
    content: "\f97c";
}
.fi-ts-rotate-exclamation:before {
    content: "\f97d";
}
.fi-ts-rotate-square:before {
    content: "\f97e";
}
.fi-ts-route-highway:before {
    content: "\f97f";
}
.fi-ts-route-interstate:before {
    content: "\f980";
}
.fi-ts-route:before {
    content: "\f981";
}
.fi-ts-router:before {
    content: "\f982";
}
.fi-ts-rss-alt:before {
    content: "\f983";
}
.fi-ts-rss:before {
    content: "\f984";
}
.fi-ts-ruble-sign:before {
    content: "\f985";
}
.fi-ts-rugby-helmet:before {
    content: "\f986";
}
.fi-ts-ruler-combined:before {
    content: "\f987";
}
.fi-ts-ruler-horizontal:before {
    content: "\f988";
}
.fi-ts-ruler-triangle:before {
    content: "\f989";
}
.fi-ts-ruler-vertical:before {
    content: "\f98a";
}
.fi-ts-running:before {
    content: "\f98b";
}
.fi-ts-rupee-sign:before {
    content: "\f98c";
}
.fi-ts-rupiah-sign:before {
    content: "\f98d";
}
.fi-ts-rv:before {
    content: "\f98e";
}
.fi-ts-s:before {
    content: "\f98f";
}
.fi-ts-sack-dollar:before {
    content: "\f990";
}
.fi-ts-sack:before {
    content: "\f991";
}
.fi-ts-sad-cry:before {
    content: "\f992";
}
.fi-ts-sad-tear:before {
    content: "\f993";
}
.fi-ts-sad:before {
    content: "\f994";
}
.fi-ts-safe-box:before {
    content: "\f995";
}
.fi-ts-sailboat:before {
    content: "\f996";
}
.fi-ts-salad:before {
    content: "\f997";
}
.fi-ts-salary-alt:before {
    content: "\f998";
}
.fi-ts-salt-pepper:before {
    content: "\f999";
}
.fi-ts-salt-shaker:before {
    content: "\f99a";
}
.fi-ts-sandwich-alt:before {
    content: "\f99b";
}
.fi-ts-sandwich:before {
    content: "\f99c";
}
.fi-ts-satellite-dish:before {
    content: "\f99d";
}
.fi-ts-satellite:before {
    content: "\f99e";
}
.fi-ts-saturday:before {
    content: "\f99f";
}
.fi-ts-sauce:before {
    content: "\f9a0";
}
.fi-ts-sausage:before {
    content: "\f9a1";
}
.fi-ts-sax-hot:before {
    content: "\f9a2";
}
.fi-ts-saxophone:before {
    content: "\f9a3";
}
.fi-ts-scale-comparison-alt:before {
    content: "\f9a4";
}
.fi-ts-scale-comparison:before {
    content: "\f9a5";
}
.fi-ts-scalpel-path:before {
    content: "\f9a6";
}
.fi-ts-scalpel:before {
    content: "\f9a7";
}
.fi-ts-scanner-gun:before {
    content: "\f9a8";
}
.fi-ts-scanner-image:before {
    content: "\f9a9";
}
.fi-ts-scanner-keyboard:before {
    content: "\f9aa";
}
.fi-ts-scanner-touchscreen:before {
    content: "\f9ab";
}
.fi-ts-scarecrow:before {
    content: "\f9ac";
}
.fi-ts-scarf:before {
    content: "\f9ad";
}
.fi-ts-screencast:before {
    content: "\f9ae";
}
.fi-ts-screwdriver:before {
    content: "\f9af";
}
.fi-ts-scribble:before {
    content: "\f9b0";
}
.fi-ts-script:before {
    content: "\f9b1";
}
.fi-ts-scroll-old:before {
    content: "\f9b2";
}
.fi-ts-scroll-torah:before {
    content: "\f9b3";
}
.fi-ts-scroll:before {
    content: "\f9b4";
}
.fi-ts-scrubber:before {
    content: "\f9b5";
}
.fi-ts-scythe:before {
    content: "\f9b6";
}
.fi-ts-sd-card:before {
    content: "\f9b7";
}
.fi-ts-sd-cards:before {
    content: "\f9b8";
}
.fi-ts-seal-exclamation:before {
    content: "\f9b9";
}
.fi-ts-seal-question:before {
    content: "\f9ba";
}
.fi-ts-seal:before {
    content: "\f9bb";
}
.fi-ts-search-dollar:before {
    content: "\f9bc";
}
.fi-ts-search-heart:before {
    content: "\f9bd";
}
.fi-ts-search-location:before {
    content: "\f9be";
}
.fi-ts-seat-airline:before {
    content: "\f9bf";
}
.fi-ts-second-award:before {
    content: "\f9c0";
}
.fi-ts-second-laurel:before {
    content: "\f9c1";
}
.fi-ts-second-medal:before {
    content: "\f9c2";
}
.fi-ts-second:before {
    content: "\f9c3";
}
.fi-ts-seedling:before {
    content: "\f9c4";
}
.fi-ts-selection:before {
    content: "\f9c5";
}
.fi-ts-sell:before {
    content: "\f9c6";
}
.fi-ts-seller-store:before {
    content: "\f9c7";
}
.fi-ts-seller:before {
    content: "\f9c8";
}
.fi-ts-selling:before {
    content: "\f9c9";
}
.fi-ts-send-back:before {
    content: "\f9ca";
}
.fi-ts-send-backward:before {
    content: "\f9cb";
}
.fi-ts-send-money-smartphone:before {
    content: "\f9cc";
}
.fi-ts-send-money:before {
    content: "\f9cd";
}
.fi-ts-sensor-alert:before {
    content: "\f9ce";
}
.fi-ts-sensor-fire:before {
    content: "\f9cf";
}
.fi-ts-sensor-on:before {
    content: "\f9d0";
}
.fi-ts-sensor-smoke:before {
    content: "\f9d1";
}
.fi-ts-sensor:before {
    content: "\f9d2";
}
.fi-ts-share-alt-square:before {
    content: "\f9d3";
}
.fi-ts-share-square:before {
    content: "\f9d4";
}
.fi-ts-sheep:before {
    content: "\f9d5";
}
.fi-ts-shekel-sign:before {
    content: "\f9d6";
}
.fi-ts-shelves:before {
    content: "\f9d7";
}
.fi-ts-shield-alt:before {
    content: "\f9d8";
}
.fi-ts-shield-cat:before {
    content: "\f9d9";
}
.fi-ts-shield-cross:before {
    content: "\f9da";
}
.fi-ts-shield-dog:before {
    content: "\f9db";
}
.fi-ts-shield-exclamation:before {
    content: "\f9dc";
}
.fi-ts-shield-interrogation:before {
    content: "\f9dd";
}
.fi-ts-shield-keyhole:before {
    content: "\f9de";
}
.fi-ts-shield-minus:before {
    content: "\f9df";
}
.fi-ts-shield-slash:before {
    content: "\f9e0";
}
.fi-ts-shield-virus:before {
    content: "\f9e1";
}
.fi-ts-shield-xmark:before {
    content: "\f9e2";
}
.fi-ts-shipping-fast:before {
    content: "\f9e3";
}
.fi-ts-shipping-timed:before {
    content: "\f9e4";
}
.fi-ts-shirt-long-sleeve:before {
    content: "\f9e5";
}
.fi-ts-shirt-running:before {
    content: "\f9e6";
}
.fi-ts-shirt-tank-top:before {
    content: "\f9e7";
}
.fi-ts-shirt:before {
    content: "\f9e8";
}
.fi-ts-shish-kebab:before {
    content: "\f9e9";
}
.fi-ts-shoe-prints:before {
    content: "\f9ea";
}
.fi-ts-shop-lock:before {
    content: "\f9eb";
}
.fi-ts-shop-slash:before {
    content: "\f9ec";
}
.fi-ts-shopping-basket:before {
    content: "\f9ed";
}
.fi-ts-shovel-snow:before {
    content: "\f9ee";
}
.fi-ts-shovel:before {
    content: "\f9ef";
}
.fi-ts-shower-down:before {
    content: "\f9f0";
}
.fi-ts-shower:before {
    content: "\f9f1";
}
.fi-ts-shredder:before {
    content: "\f9f2";
}
.fi-ts-shrimp:before {
    content: "\f9f3";
}
.fi-ts-shuttle-van:before {
    content: "\f9f4";
}
.fi-ts-shuttlecock:before {
    content: "\f9f5";
}
.fi-ts-Sickle:before {
    content: "\f9f6";
}
.fi-ts-sidebar-flip:before {
    content: "\f9f7";
}
.fi-ts-sidebar:before {
    content: "\f9f8";
}
.fi-ts-sigma:before {
    content: "\f9f9";
}
.fi-ts-sign-hanging:before {
    content: "\f9fa";
}
.fi-ts-sign-in-alt:before {
    content: "\f9fb";
}
.fi-ts-sign-out-alt:before {
    content: "\f9fc";
}
.fi-ts-sign-up:before {
    content: "\f9fd";
}
.fi-ts-signal-alt-1:before {
    content: "\f9fe";
}
.fi-ts-signal-alt-2:before {
    content: "\f9ff";
}
.fi-ts-signal-alt-slash:before {
    content: "\fa00";
}
.fi-ts-signal-alt:before {
    content: "\fa01";
}
.fi-ts-signal-bars-fair:before {
    content: "\fa02";
}
.fi-ts-signal-bars-good:before {
    content: "\fa03";
}
.fi-ts-signal-bars-weak:before {
    content: "\fa04";
}
.fi-ts-signal-stream-slash:before {
    content: "\fa05";
}
.fi-ts-signal-stream:before {
    content: "\fa06";
}
.fi-ts-signature-lock:before {
    content: "\fa07";
}
.fi-ts-signature-slash:before {
    content: "\fa08";
}
.fi-ts-signature:before {
    content: "\fa09";
}
.fi-ts-sim-card:before {
    content: "\fa0a";
}
.fi-ts-sim-cards:before {
    content: "\fa0b";
}
.fi-ts-sink:before {
    content: "\fa0c";
}
.fi-ts-siren-on:before {
    content: "\fa0d";
}
.fi-ts-Siren:before {
    content: "\fa0e";
}
.fi-ts-site-alt:before {
    content: "\fa0f";
}
.fi-ts-site-browser:before {
    content: "\fa10";
}
.fi-ts-site:before {
    content: "\fa11";
}
.fi-ts-sitemap:before {
    content: "\fa12";
}
.fi-ts-skating:before {
    content: "\fa13";
}
.fi-ts-skeleton:before {
    content: "\fa14";
}
.fi-ts-skewer:before {
    content: "\fa15";
}
.fi-ts-ski-boot-ski:before {
    content: "\fa16";
}
.fi-ts-ski-jump:before {
    content: "\fa17";
}
.fi-ts-ski-lift:before {
    content: "\fa18";
}
.fi-ts-skiing-nordic:before {
    content: "\fa19";
}
.fi-ts-skiing:before {
    content: "\fa1a";
}
.fi-ts-skull-cow:before {
    content: "\fa1b";
}
.fi-ts-skull-crossbones:before {
    content: "\fa1c";
}
.fi-ts-skull:before {
    content: "\fa1d";
}
.fi-ts-slash:before {
    content: "\fa1e";
}
.fi-ts-sledding:before {
    content: "\fa1f";
}
.fi-ts-sleigh:before {
    content: "\fa20";
}
.fi-ts-sliders-h-square:before {
    content: "\fa21";
}
.fi-ts-sliders-v-square:before {
    content: "\fa22";
}
.fi-ts-sliders-v:before {
    content: "\fa23";
}
.fi-ts-smile-beam:before {
    content: "\fa24";
}
.fi-ts-smile-plus:before {
    content: "\fa25";
}
.fi-ts-smile-wink:before {
    content: "\fa26";
}
.fi-ts-smile:before {
    content: "\fa27";
}
.fi-ts-smiley-comment-alt:before {
    content: "\fa28";
}
.fi-ts-smog:before {
    content: "\fa29";
}
.fi-ts-smoke:before {
    content: "\fa2a";
}
.fi-ts-smoking-ban:before {
    content: "\fa2b";
}
.fi-ts-smoking:before {
    content: "\fa2c";
}
.fi-ts-snake:before {
    content: "\fa2d";
}
.fi-ts-snooze:before {
    content: "\fa2e";
}
.fi-ts-snow-blowing:before {
    content: "\fa2f";
}
.fi-ts-snowboarding:before {
    content: "\fa30";
}
.fi-ts-snowflake-droplets:before {
    content: "\fa31";
}
.fi-ts-snowflake:before {
    content: "\fa32";
}
.fi-ts-snowflakes:before {
    content: "\fa33";
}
.fi-ts-snowman-alt:before {
    content: "\fa34";
}
.fi-ts-snowman-head:before {
    content: "\fa35";
}
.fi-ts-snowmobile:before {
    content: "\fa36";
}
.fi-ts-snowplow:before {
    content: "\fa37";
}
.fi-ts-soap-alt:before {
    content: "\fa38";
}
.fi-ts-socks:before {
    content: "\fa39";
}
.fi-ts-sofa-size:before {
    content: "\fa3a";
}
.fi-ts-sofa:before {
    content: "\fa3b";
}
.fi-ts-solar-panel:before {
    content: "\fa3c";
}
.fi-ts-solar-system:before {
    content: "\fa3d";
}
.fi-ts-sold-house:before {
    content: "\fa3e";
}
.fi-ts-sold-signal:before {
    content: "\fa3f";
}
.fi-ts-sort-alpha-down-alt:before {
    content: "\fa40";
}
.fi-ts-sort-alpha-down:before {
    content: "\fa41";
}
.fi-ts-sort-alpha-up-alt:before {
    content: "\fa42";
}
.fi-ts-sort-alpha-up:before {
    content: "\fa43";
}
.fi-ts-sort-alt:before {
    content: "\fa44";
}
.fi-ts-sort-amount-down-alt:before {
    content: "\fa45";
}
.fi-ts-sort-amount-down:before {
    content: "\fa46";
}
.fi-ts-sort-amount-up-alt:before {
    content: "\fa47";
}
.fi-ts-sort-amount-up:before {
    content: "\fa48";
}
.fi-ts-sort-circle-down:before {
    content: "\fa49";
}
.fi-ts-sort-circle-up:before {
    content: "\fa4a";
}
.fi-ts-sort-circle:before {
    content: "\fa4b";
}
.fi-ts-sort-down:before {
    content: "\fa4c";
}
.fi-ts-sort-numeric-down-alt:before {
    content: "\fa4d";
}
.fi-ts-sort-numeric-down:before {
    content: "\fa4e";
}
.fi-ts-sort-shapes-down:before {
    content: "\fa4f";
}
.fi-ts-sort-shapes-up:before {
    content: "\fa50";
}
.fi-ts-sort-size-down:before {
    content: "\fa51";
}
.fi-ts-sort-size-up:before {
    content: "\fa52";
}
.fi-ts-sort:before {
    content: "\fa53";
}
.fi-ts-soup:before {
    content: "\fa54";
}
.fi-ts-source-data:before {
    content: "\fa55";
}
.fi-ts-space-shuttle:before {
    content: "\fa56";
}
.fi-ts-space-station-moon-alt:before {
    content: "\fa57";
}
.fi-ts-space-station-moon:before {
    content: "\fa58";
}
.fi-ts-spade:before {
    content: "\fa59";
}
.fi-ts-spaghetti-monster-flying:before {
    content: "\fa5a";
}
.fi-ts-sparkles:before {
    content: "\fa5b";
}
.fi-ts-speakers:before {
    content: "\fa5c";
}
.fi-ts-speedometer-arrow:before {
    content: "\fa5d";
}
.fi-ts-speedometer-kpi:before {
    content: "\fa5e";
}
.fi-ts-sphere:before {
    content: "\fa5f";
}
.fi-ts-spider-black-widow:before {
    content: "\fa60";
}
.fi-ts-spider-web:before {
    content: "\fa61";
}
.fi-ts-spider:before {
    content: "\fa62";
}
.fi-ts-split:before {
    content: "\fa63";
}
.fi-ts-splotch:before {
    content: "\fa64";
}
.fi-ts-spoon:before {
    content: "\fa65";
}
.fi-ts-spray-can-sparkles:before {
    content: "\fa66";
}
.fi-ts-spray-can:before {
    content: "\fa67";
}
.fi-ts-spring-calendar:before {
    content: "\fa68";
}
.fi-ts-sprinkler:before {
    content: "\fa69";
}
.fi-ts-spy:before {
    content: "\fa6a";
}
.fi-ts-square-0:before {
    content: "\fa6b";
}
.fi-ts-square-1:before {
    content: "\fa6c";
}
.fi-ts-square-2:before {
    content: "\fa6d";
}
.fi-ts-square-3:before {
    content: "\fa6e";
}
.fi-ts-square-4:before {
    content: "\fa6f";
}
.fi-ts-square-5:before {
    content: "\fa70";
}
.fi-ts-square-6:before {
    content: "\fa71";
}
.fi-ts-square-7:before {
    content: "\fa72";
}
.fi-ts-square-8:before {
    content: "\fa73";
}
.fi-ts-square-9:before {
    content: "\fa74";
}
.fi-ts-square-a:before {
    content: "\fa75";
}
.fi-ts-square-b:before {
    content: "\fa76";
}
.fi-ts-square-bolt:before {
    content: "\fa77";
}
.fi-ts-square-c:before {
    content: "\fa78";
}
.fi-ts-square-code:before {
    content: "\fa79";
}
.fi-ts-square-d:before {
    content: "\fa7a";
}
.fi-ts-square-dashed:before {
    content: "\fa7b";
}
.fi-ts-square-divide:before {
    content: "\fa7c";
}
.fi-ts-square-e:before {
    content: "\fa7d";
}
.fi-ts-square-ellipsis-vertical:before {
    content: "\fa7e";
}
.fi-ts-square-ellipsis:before {
    content: "\fa7f";
}
.fi-ts-square-exclamation:before {
    content: "\fa80";
}
.fi-ts-square-f:before {
    content: "\fa81";
}
.fi-ts-square-g:before {
    content: "\fa82";
}
.fi-ts-square-h:before {
    content: "\fa83";
}
.fi-ts-square-heart:before {
    content: "\fa84";
}
.fi-ts-square-i:before {
    content: "\fa85";
}
.fi-ts-square-info:before {
    content: "\fa86";
}
.fi-ts-square-j:before {
    content: "\fa87";
}
.fi-ts-square-k:before {
    content: "\fa88";
}
.fi-ts-square-kanban:before {
    content: "\fa89";
}
.fi-ts-square-l:before {
    content: "\fa8a";
}
.fi-ts-square-m:before {
    content: "\fa8b";
}
.fi-ts-square-minus:before {
    content: "\fa8c";
}
.fi-ts-square-n:before {
    content: "\fa8d";
}
.fi-ts-square-o:before {
    content: "\fa8e";
}
.fi-ts-square-p:before {
    content: "\fa8f";
}
.fi-ts-square-phone-hangup:before {
    content: "\fa90";
}
.fi-ts-square-plus:before {
    content: "\fa91";
}
.fi-ts-square-poll-horizontal:before {
    content: "\fa92";
}
.fi-ts-square-poll-vertical:before {
    content: "\fa93";
}
.fi-ts-square-q:before {
    content: "\fa94";
}
.fi-ts-square-quote:before {
    content: "\fa95";
}
.fi-ts-square-r:before {
    content: "\fa96";
}
.fi-ts-square-s:before {
    content: "\fa97";
}
.fi-ts-square-small:before {
    content: "\fa98";
}
.fi-ts-square-star:before {
    content: "\fa99";
}
.fi-ts-square-t:before {
    content: "\fa9a";
}
.fi-ts-square-terminal:before {
    content: "\fa9b";
}
.fi-ts-square-u:before {
    content: "\fa9c";
}
.fi-ts-square-up-right:before {
    content: "\fa9d";
}
.fi-ts-square-v:before {
    content: "\fa9e";
}
.fi-ts-square-w:before {
    content: "\fa9f";
}
.fi-ts-square-x:before {
    content: "\faa0";
}
.fi-ts-square-y:before {
    content: "\faa1";
}
.fi-ts-square-z:before {
    content: "\faa2";
}
.fi-ts-square:before {
    content: "\faa3";
}
.fi-ts-squid:before {
    content: "\faa4";
}
.fi-ts-squircle:before {
    content: "\faa5";
}
.fi-ts-squirrel:before {
    content: "\faa6";
}
.fi-ts-staff:before {
    content: "\faa7";
}
.fi-ts-stage-concert:before {
    content: "\faa8";
}
.fi-ts-stage-theatre:before {
    content: "\faa9";
}
.fi-ts-stage:before {
    content: "\faaa";
}
.fi-ts-stairs:before {
    content: "\faab";
}
.fi-ts-stamp:before {
    content: "\faac";
}
.fi-ts-standard-definition:before {
    content: "\faad";
}
.fi-ts-star-and-crescent:before {
    content: "\faae";
}
.fi-ts-star-christmas:before {
    content: "\faaf";
}
.fi-ts-star-comment-alt:before {
    content: "\fab0";
}
.fi-ts-star-exclamation:before {
    content: "\fab1";
}
.fi-ts-star-octogram:before {
    content: "\fab2";
}
.fi-ts-star-of-david:before {
    content: "\fab3";
}
.fi-ts-star-sharp-half-stroke:before {
    content: "\fab4";
}
.fi-ts-star-sharp-half:before {
    content: "\fab5";
}
.fi-ts-star-shooting:before {
    content: "\fab6";
}
.fi-ts-starfighter:before {
    content: "\fab7";
}
.fi-ts-stars:before {
    content: "\fab8";
}
.fi-ts-state-country:before {
    content: "\fab9";
}
.fi-ts-stats:before {
    content: "\faba";
}
.fi-ts-steak:before {
    content: "\fabb";
}
.fi-ts-steering-wheel:before {
    content: "\fabc";
}
.fi-ts-step-backward:before {
    content: "\fabd";
}
.fi-ts-step-forward:before {
    content: "\fabe";
}
.fi-ts-sterling-sign:before {
    content: "\fabf";
}
.fi-ts-sticker:before {
    content: "\fac0";
}
.fi-ts-stocking:before {
    content: "\fac1";
}
.fi-ts-stomach:before {
    content: "\fac2";
}
.fi-ts-stop-circle:before {
    content: "\fac3";
}
.fi-ts-store-alt:before {
    content: "\fac4";
}
.fi-ts-store-lock:before {
    content: "\fac5";
}
.fi-ts-store-slash:before {
    content: "\fac6";
}
.fi-ts-strawberry:before {
    content: "\fac7";
}
.fi-ts-street-view:before {
    content: "\fac8";
}
.fi-ts-stretcher:before {
    content: "\fac9";
}
.fi-ts-strikethrough:before {
    content: "\faca";
}
.fi-ts-stroopwafel:before {
    content: "\facb";
}
.fi-ts-subscript:before {
    content: "\facc";
}
.fi-ts-subscription-alt:before {
    content: "\facd";
}
.fi-ts-subscription:before {
    content: "\face";
}
.fi-ts-subway:before {
    content: "\facf";
}
.fi-ts-suitcase-alt:before {
    content: "\fad0";
}
.fi-ts-summer:before {
    content: "\fad1";
}
.fi-ts-sun-dust:before {
    content: "\fad2";
}
.fi-ts-sun-plant-wilt:before {
    content: "\fad3";
}
.fi-ts-sun:before {
    content: "\fad4";
}
.fi-ts-sunday:before {
    content: "\fad5";
}
.fi-ts-sunglasses-alt:before {
    content: "\fad6";
}
.fi-ts-sunglasses:before {
    content: "\fad7";
}
.fi-ts-sunrise-alt:before {
    content: "\fad8";
}
.fi-ts-sunrise:before {
    content: "\fad9";
}
.fi-ts-sunset:before {
    content: "\fada";
}
.fi-ts-superscript:before {
    content: "\fadb";
}
.fi-ts-supplier-alt:before {
    content: "\fadc";
}
.fi-ts-supplier:before {
    content: "\fadd";
}
.fi-ts-surprise:before {
    content: "\fade";
}
.fi-ts-sushi-alt:before {
    content: "\fadf";
}
.fi-ts-sushi-roll:before {
    content: "\fae0";
}
.fi-ts-sushi:before {
    content: "\fae1";
}
.fi-ts-svg:before {
    content: "\fae2";
}
.fi-ts-swatchbook:before {
    content: "\fae3";
}
.fi-ts-swimmer:before {
    content: "\fae4";
}
.fi-ts-swimming-pool:before {
    content: "\fae5";
}
.fi-ts-swipe-down:before {
    content: "\fae6";
}
.fi-ts-swipe-left:before {
    content: "\fae7";
}
.fi-ts-swipe-right:before {
    content: "\fae8";
}
.fi-ts-swipe-up:before {
    content: "\fae9";
}
.fi-ts-symbols:before {
    content: "\faea";
}
.fi-ts-synagogue:before {
    content: "\faeb";
}
.fi-ts-t:before {
    content: "\faec";
}
.fi-ts-tab-folder:before {
    content: "\faed";
}
.fi-ts-table-columns:before {
    content: "\faee";
}
.fi-ts-table-layout:before {
    content: "\faef";
}
.fi-ts-table-list:before {
    content: "\faf0";
}
.fi-ts-table-picnic:before {
    content: "\faf1";
}
.fi-ts-table-pivot:before {
    content: "\faf2";
}
.fi-ts-table-rows:before {
    content: "\faf3";
}
.fi-ts-table-tree:before {
    content: "\faf4";
}
.fi-ts-table:before {
    content: "\faf5";
}
.fi-ts-tablet-android-alt:before {
    content: "\faf6";
}
.fi-ts-tablet-android:before {
    content: "\faf7";
}
.fi-ts-tablet-rugged:before {
    content: "\faf8";
}
.fi-ts-tachometer-alt-average:before {
    content: "\faf9";
}
.fi-ts-tachometer-alt-fastest:before {
    content: "\fafa";
}
.fi-ts-tachometer-alt-slow:before {
    content: "\fafb";
}
.fi-ts-tachometer-alt-slowest:before {
    content: "\fafc";
}
.fi-ts-tachometer-average:before {
    content: "\fafd";
}
.fi-ts-tachometer-fast:before {
    content: "\fafe";
}
.fi-ts-tachometer-fastest:before {
    content: "\faff";
}
.fi-ts-tachometer-slow:before {
    content: "\fb00";
}
.fi-ts-tachometer-slowest:before {
    content: "\fb01";
}
.fi-ts-tachometer:before {
    content: "\fb02";
}
.fi-ts-taco:before {
    content: "\fb03";
}
.fi-ts-tags:before {
    content: "\fb04";
}
.fi-ts-tally-1:before {
    content: "\fb05";
}
.fi-ts-tally-2:before {
    content: "\fb06";
}
.fi-ts-tally-3:before {
    content: "\fb07";
}
.fi-ts-tally-4:before {
    content: "\fb08";
}
.fi-ts-tally:before {
    content: "\fb09";
}
.fi-ts-tamale:before {
    content: "\fb0a";
}
.fi-ts-tank-water:before {
    content: "\fb0b";
}
.fi-ts-tap:before {
    content: "\fb0c";
}
.fi-ts-tape:before {
    content: "\fb0d";
}
.fi-ts-target-audience:before {
    content: "\fb0e";
}
.fi-ts-target:before {
    content: "\fb0f";
}
.fi-ts-tax-alt:before {
    content: "\fb10";
}
.fi-ts-tax:before {
    content: "\fb11";
}
.fi-ts-taxi-bus:before {
    content: "\fb12";
}
.fi-ts-team-check-alt:before {
    content: "\fb13";
}
.fi-ts-team-check:before {
    content: "\fb14";
}
.fi-ts-teddy-bear:before {
    content: "\fb15";
}
.fi-ts-teeth-open:before {
    content: "\fb16";
}
.fi-ts-telescope:before {
    content: "\fb17";
}
.fi-ts-temperature-down:before {
    content: "\fb18";
}
.fi-ts-temperature-frigid:before {
    content: "\fb19";
}
.fi-ts-temperature-high:before {
    content: "\fb1a";
}
.fi-ts-temperature-list:before {
    content: "\fb1b";
}
.fi-ts-temperature-low:before {
    content: "\fb1c";
}
.fi-ts-temperature-up:before {
    content: "\fb1d";
}
.fi-ts-template-alt:before {
    content: "\fb1e";
}
.fi-ts-template:before {
    content: "\fb1f";
}
.fi-ts-tenge:before {
    content: "\fb20";
}
.fi-ts-tent-arrow-down-to-line:before {
    content: "\fb21";
}
.fi-ts-tent-arrow-left-right:before {
    content: "\fb22";
}
.fi-ts-tent-arrow-turn-left:before {
    content: "\fb23";
}
.fi-ts-tent-arrows-down:before {
    content: "\fb24";
}
.fi-ts-tents:before {
    content: "\fb25";
}
.fi-ts-terminal:before {
    content: "\fb26";
}
.fi-ts-terrace:before {
    content: "\fb27";
}
.fi-ts-text-box-dots:before {
    content: "\fb28";
}
.fi-ts-text-box-edit:before {
    content: "\fb29";
}
.fi-ts-text-box:before {
    content: "\fb2a";
}
.fi-ts-text-height:before {
    content: "\fb2b";
}
.fi-ts-text-shadow:before {
    content: "\fb2c";
}
.fi-ts-text-size:before {
    content: "\fb2d";
}
.fi-ts-text-slash:before {
    content: "\fb2e";
}
.fi-ts-text-width:before {
    content: "\fb2f";
}
.fi-ts-theater-masks:before {
    content: "\fb30";
}
.fi-ts-thermometer-alt:before {
    content: "\fb31";
}
.fi-ts-thermometer-empty:before {
    content: "\fb32";
}
.fi-ts-thermometer-full:before {
    content: "\fb33";
}
.fi-ts-thermometer-half:before {
    content: "\fb34";
}
.fi-ts-thermometer-quarter:before {
    content: "\fb35";
}
.fi-ts-thermometer-three-quarters:before {
    content: "\fb36";
}
.fi-ts-theta:before {
    content: "\fb37";
}
.fi-ts-third-award:before {
    content: "\fb38";
}
.fi-ts-third-laurel:before {
    content: "\fb39";
}
.fi-ts-third-medal:before {
    content: "\fb3a";
}
.fi-ts-third:before {
    content: "\fb3b";
}
.fi-ts-thought-bubble:before {
    content: "\fb3c";
}
.fi-ts-thunderstorm-moon:before {
    content: "\fb3d";
}
.fi-ts-thunderstorm-sun:before {
    content: "\fb3e";
}
.fi-ts-thunderstorm:before {
    content: "\fb3f";
}
.fi-ts-thursday:before {
    content: "\fb40";
}
.fi-ts-ticket-airline:before {
    content: "\fb41";
}
.fi-ts-ticket-alt:before {
    content: "\fb42";
}
.fi-ts-tickets-airline:before {
    content: "\fb43";
}
.fi-ts-tie:before {
    content: "\fb44";
}
.fi-ts-tilde:before {
    content: "\fb45";
}
.fi-ts-times-hexagon:before {
    content: "\fb46";
}
.fi-ts-tint-slash:before {
    content: "\fb47";
}
.fi-ts-tip-coin:before {
    content: "\fb48";
}
.fi-ts-tire-flat:before {
    content: "\fb49";
}
.fi-ts-tire-pressure-warning:before {
    content: "\fb4a";
}
.fi-ts-tire-rugged:before {
    content: "\fb4b";
}
.fi-ts-tire:before {
    content: "\fb4c";
}
.fi-ts-tired:before {
    content: "\fb4d";
}
.fi-ts-to-do-alt:before {
    content: "\fb4e";
}
.fi-ts-to-do:before {
    content: "\fb4f";
}
.fi-ts-together-people:before {
    content: "\fb50";
}
.fi-ts-toggle-off:before {
    content: "\fb51";
}
.fi-ts-toggle-on:before {
    content: "\fb52";
}
.fi-ts-toilet-paper-blank-under:before {
    content: "\fb53";
}
.fi-ts-toilet-paper-blank:before {
    content: "\fb54";
}
.fi-ts-toilet-paper-slash:before {
    content: "\fb55";
}
.fi-ts-toilet-paper-under-slash:before {
    content: "\fb56";
}
.fi-ts-toilet-paper-under:before {
    content: "\fb57";
}
.fi-ts-toilet:before {
    content: "\fb58";
}
.fi-ts-token:before {
    content: "\fb59";
}
.fi-ts-tomato:before {
    content: "\fb5a";
}
.fi-ts-tombstone-alt:before {
    content: "\fb5b";
}
.fi-ts-tombstone:before {
    content: "\fb5c";
}
.fi-ts-tool-box:before {
    content: "\fb5d";
}
.fi-ts-tools:before {
    content: "\fb5e";
}
.fi-ts-toothbrush:before {
    content: "\fb5f";
}
.fi-ts-torii-gate:before {
    content: "\fb60";
}
.fi-ts-tornado:before {
    content: "\fb61";
}
.fi-ts-total:before {
    content: "\fb62";
}
.fi-ts-tour-guide-people:before {
    content: "\fb63";
}
.fi-ts-tour-virtual:before {
    content: "\fb64";
}
.fi-ts-tower-control:before {
    content: "\fb65";
}
.fi-ts-track:before {
    content: "\fb66";
}
.fi-ts-tractor:before {
    content: "\fb67";
}
.fi-ts-trademark:before {
    content: "\fb68";
}
.fi-ts-traffic-cone:before {
    content: "\fb69";
}
.fi-ts-traffic-light-go:before {
    content: "\fb6a";
}
.fi-ts-traffic-light-slow:before {
    content: "\fb6b";
}
.fi-ts-traffic-light-stop:before {
    content: "\fb6c";
}
.fi-ts-traffic-light:before {
    content: "\fb6d";
}
.fi-ts-trailer:before {
    content: "\fb6e";
}
.fi-ts-train-journey:before {
    content: "\fb6f";
}
.fi-ts-train-station-building:before {
    content: "\fb70";
}
.fi-ts-train-station:before {
    content: "\fb71";
}
.fi-ts-train-subway-tunnel:before {
    content: "\fb72";
}
.fi-ts-train-tram:before {
    content: "\fb73";
}
.fi-ts-tram:before {
    content: "\fb74";
}
.fi-ts-transformation-block:before {
    content: "\fb75";
}
.fi-ts-transformation-circle:before {
    content: "\fb76";
}
.fi-ts-transformation-design:before {
    content: "\fb77";
}
.fi-ts-transformation-shapes:before {
    content: "\fb78";
}
.fi-ts-transformer-bolt:before {
    content: "\fb79";
}
.fi-ts-transgender:before {
    content: "\fb7a";
}
.fi-ts-transporter-1:before {
    content: "\fb7b";
}
.fi-ts-transporter-2:before {
    content: "\fb7c";
}
.fi-ts-transporter-3:before {
    content: "\fb7d";
}
.fi-ts-transporter-4:before {
    content: "\fb7e";
}
.fi-ts-transporter-5:before {
    content: "\fb7f";
}
.fi-ts-transporter-6:before {
    content: "\fb80";
}
.fi-ts-transporter-7:before {
    content: "\fb81";
}
.fi-ts-transporter-empty:before {
    content: "\fb82";
}
.fi-ts-transporter:before {
    content: "\fb83";
}
.fi-ts-trash-can-check:before {
    content: "\fb84";
}
.fi-ts-trash-can-clock:before {
    content: "\fb85";
}
.fi-ts-trash-can-list:before {
    content: "\fb86";
}
.fi-ts-trash-can-plus:before {
    content: "\fb87";
}
.fi-ts-trash-can-slash:before {
    content: "\fb88";
}
.fi-ts-trash-check:before {
    content: "\fb89";
}
.fi-ts-trash-clock:before {
    content: "\fb8a";
}
.fi-ts-trash-list:before {
    content: "\fb8b";
}
.fi-ts-trash-plus:before {
    content: "\fb8c";
}
.fi-ts-trash-restore-alt:before {
    content: "\fb8d";
}
.fi-ts-trash-restore:before {
    content: "\fb8e";
}
.fi-ts-trash-slash:before {
    content: "\fb8f";
}
.fi-ts-trash-undo-alt:before {
    content: "\fb90";
}
.fi-ts-trash-undo:before {
    content: "\fb91";
}
.fi-ts-trash-xmark:before {
    content: "\fb92";
}
.fi-ts-treasure-chest:before {
    content: "\fb93";
}
.fi-ts-tree-alt:before {
    content: "\fb94";
}
.fi-ts-tree-christmas:before {
    content: "\fb95";
}
.fi-ts-tree-deciduous:before {
    content: "\fb96";
}
.fi-ts-tree:before {
    content: "\fb97";
}
.fi-ts-trees-alt:before {
    content: "\fb98";
}
.fi-ts-trees:before {
    content: "\fb99";
}
.fi-ts-triangle-music:before {
    content: "\fb9a";
}
.fi-ts-triangle-person-digging:before {
    content: "\fb9b";
}
.fi-ts-triangle-warning:before {
    content: "\fb9c";
}
.fi-ts-triangle:before {
    content: "\fb9d";
}
.fi-ts-trillium:before {
    content: "\fb9e";
}
.fi-ts-trophy-star:before {
    content: "\fb9f";
}
.fi-ts-truck-arrow-left:before {
    content: "\fba0";
}
.fi-ts-truck-arrow-right:before {
    content: "\fba1";
}
.fi-ts-truck-bolt:before {
    content: "\fba2";
}
.fi-ts-truck-box:before {
    content: "\fba3";
}
.fi-ts-truck-check:before {
    content: "\fba4";
}
.fi-ts-truck-container-empty:before {
    content: "\fba5";
}
.fi-ts-truck-container:before {
    content: "\fba6";
}
.fi-ts-truck-couch:before {
    content: "\fba7";
}
.fi-ts-truck-flatbed:before {
    content: "\fba8";
}
.fi-ts-truck-front:before {
    content: "\fba9";
}
.fi-ts-truck-loading:before {
    content: "\fbaa";
}
.fi-ts-truck-medical:before {
    content: "\fbab";
}
.fi-ts-truck-monster:before {
    content: "\fbac";
}
.fi-ts-truck-moving:before {
    content: "\fbad";
}
.fi-ts-truck-pickup:before {
    content: "\fbae";
}
.fi-ts-truck-plow:before {
    content: "\fbaf";
}
.fi-ts-truck-ramp:before {
    content: "\fbb0";
}
.fi-ts-truck-tow:before {
    content: "\fbb1";
}
.fi-ts-trumpet:before {
    content: "\fbb2";
}
.fi-ts-tshirt:before {
    content: "\fbb3";
}
.fi-ts-tty-answer:before {
    content: "\fbb4";
}
.fi-ts-tty:before {
    content: "\fbb5";
}
.fi-ts-tubes:before {
    content: "\fbb6";
}
.fi-ts-tuesday:before {
    content: "\fbb7";
}
.fi-ts-tugrik-sign:before {
    content: "\fbb8";
}
.fi-ts-turkey:before {
    content: "\fbb9";
}
.fi-ts-turntable:before {
    content: "\fbba";
}
.fi-ts-turtle:before {
    content: "\fbbb";
}
.fi-ts-tv-music:before {
    content: "\fbbc";
}
.fi-ts-tv-retro:before {
    content: "\fbbd";
}
.fi-ts-typewriter:before {
    content: "\fbbe";
}
.fi-ts-u:before {
    content: "\fbbf";
}
.fi-ts-ufo-beam:before {
    content: "\fbc0";
}
.fi-ts-ufo:before {
    content: "\fbc1";
}
.fi-ts-ui-ux:before {
    content: "\fbc2";
}
.fi-ts-umbrella-beach:before {
    content: "\fbc3";
}
.fi-ts-umbrella:before {
    content: "\fbc4";
}
.fi-ts-undo-alt:before {
    content: "\fbc5";
}
.fi-ts-undo:before {
    content: "\fbc6";
}
.fi-ts-unicorn:before {
    content: "\fbc7";
}
.fi-ts-uniform-martial-arts:before {
    content: "\fbc8";
}
.fi-ts-universal-access:before {
    content: "\fbc9";
}
.fi-ts-up-left:before {
    content: "\fbca";
}
.fi-ts-up-right-from-square:before {
    content: "\fbcb";
}
.fi-ts-up-right:before {
    content: "\fbcc";
}
.fi-ts-up:before {
    content: "\fbcd";
}
.fi-ts-url:before {
    content: "\fbce";
}
.fi-ts-usd-circle:before {
    content: "\fbcf";
}
.fi-ts-usd-square:before {
    content: "\fbd0";
}
.fi-ts-user-alien:before {
    content: "\fbd1";
}
.fi-ts-user-astronaut:before {
    content: "\fbd2";
}
.fi-ts-user-check:before {
    content: "\fbd3";
}
.fi-ts-user-chef:before {
    content: "\fbd4";
}
.fi-ts-user-cowboy:before {
    content: "\fbd5";
}
.fi-ts-user-crown:before {
    content: "\fbd6";
}
.fi-ts-user-gear:before {
    content: "\fbd7";
}
.fi-ts-user-graduate:before {
    content: "\fbd8";
}
.fi-ts-user-headset:before {
    content: "\fbd9";
}
.fi-ts-user-helmet-safety:before {
    content: "\fbda";
}
.fi-ts-user-injured:before {
    content: "\fbdb";
}
.fi-ts-user-interface-ui:before {
    content: "\fbdc";
}
.fi-ts-user-key:before {
    content: "\fbdd";
}
.fi-ts-user-lock:before {
    content: "\fbde";
}
.fi-ts-user-md-chat:before {
    content: "\fbdf";
}
.fi-ts-user-md:before {
    content: "\fbe0";
}
.fi-ts-user-minus:before {
    content: "\fbe1";
}
.fi-ts-user-music:before {
    content: "\fbe2";
}
.fi-ts-user-ninja:before {
    content: "\fbe3";
}
.fi-ts-user-nurse:before {
    content: "\fbe4";
}
.fi-ts-user-pen:before {
    content: "\fbe5";
}
.fi-ts-user-pilot-tie:before {
    content: "\fbe6";
}
.fi-ts-user-pilot:before {
    content: "\fbe7";
}
.fi-ts-user-police:before {
    content: "\fbe8";
}
.fi-ts-user-robot-xmarks:before {
    content: "\fbe9";
}
.fi-ts-user-robot:before {
    content: "\fbea";
}
.fi-ts-user-salary:before {
    content: "\fbeb";
}
.fi-ts-user-shield:before {
    content: "\fbec";
}
.fi-ts-user-slash:before {
    content: "\fbed";
}
.fi-ts-user-tag:before {
    content: "\fbee";
}
.fi-ts-user-unlock:before {
    content: "\fbef";
}
.fi-ts-user-visor:before {
    content: "\fbf0";
}
.fi-ts-user-xmark:before {
    content: "\fbf1";
}
.fi-ts-users-alt:before {
    content: "\fbf2";
}
.fi-ts-users-class:before {
    content: "\fbf3";
}
.fi-ts-users-gear:before {
    content: "\fbf4";
}
.fi-ts-users-medical:before {
    content: "\fbf5";
}
.fi-ts-users-slash:before {
    content: "\fbf6";
}
.fi-ts-utensils-slash:before {
    content: "\fbf7";
}
.fi-ts-utensils:before {
    content: "\fbf8";
}
.fi-ts-utility-pole-double:before {
    content: "\fbf9";
}
.fi-ts-utility-pole:before {
    content: "\fbfa";
}
.fi-ts-ux-browser:before {
    content: "\fbfb";
}
.fi-ts-ux:before {
    content: "\fbfc";
}
.fi-ts-v:before {
    content: "\fbfd";
}
.fi-ts-vacuum-robot:before {
    content: "\fbfe";
}
.fi-ts-vacuum:before {
    content: "\fbff";
}
.fi-ts-value-absolute:before {
    content: "\fc00";
}
.fi-ts-vault:before {
    content: "\fc01";
}
.fi-ts-vector-circle:before {
    content: "\fc02";
}
.fi-ts-vector-polygon:before {
    content: "\fc03";
}
.fi-ts-venus-double:before {
    content: "\fc04";
}
.fi-ts-venus-mars:before {
    content: "\fc05";
}
.fi-ts-venus:before {
    content: "\fc06";
}
.fi-ts-vest-patches:before {
    content: "\fc07";
}
.fi-ts-vest:before {
    content: "\fc08";
}
.fi-ts-video-arrow-down-left:before {
    content: "\fc09";
}
.fi-ts-video-arrow-up-right:before {
    content: "\fc0a";
}
.fi-ts-video-camera-alt:before {
    content: "\fc0b";
}
.fi-ts-video-duration:before {
    content: "\fc0c";
}
.fi-ts-video-plus:before {
    content: "\fc0d";
}
.fi-ts-video-slash:before {
    content: "\fc0e";
}
.fi-ts-videoconference:before {
    content: "\fc0f";
}
.fi-ts-vihara:before {
    content: "\fc10";
}
.fi-ts-violin:before {
    content: "\fc11";
}
.fi-ts-virus-slash:before {
    content: "\fc12";
}
.fi-ts-virus:before {
    content: "\fc13";
}
.fi-ts-viruses:before {
    content: "\fc14";
}
.fi-ts-visit:before {
    content: "\fc15";
}
.fi-ts-voicemail:before {
    content: "\fc16";
}
.fi-ts-volcano:before {
    content: "\fc17";
}
.fi-ts-volume-down:before {
    content: "\fc18";
}
.fi-ts-volume-mute:before {
    content: "\fc19";
}
.fi-ts-volume-off:before {
    content: "\fc1a";
}
.fi-ts-volume-slash:before {
    content: "\fc1b";
}
.fi-ts-vote-nay:before {
    content: "\fc1c";
}
.fi-ts-vote-yea:before {
    content: "\fc1d";
}
.fi-ts-vr-cardboard:before {
    content: "\fc1e";
}
.fi-ts-w:before {
    content: "\fc1f";
}
.fi-ts-waffle:before {
    content: "\fc20";
}
.fi-ts-wagon-covered:before {
    content: "\fc21";
}
.fi-ts-walker:before {
    content: "\fc22";
}
.fi-ts-walkie-talkie:before {
    content: "\fc23";
}
.fi-ts-walking:before {
    content: "\fc24";
}
.fi-ts-wallet-arrow:before {
    content: "\fc25";
}
.fi-ts-wallet:before {
    content: "\fc26";
}
.fi-ts-warehouse-alt:before {
    content: "\fc27";
}
.fi-ts-warranty:before {
    content: "\fc28";
}
.fi-ts-washer:before {
    content: "\fc29";
}
.fi-ts-waste-pollution:before {
    content: "\fc2a";
}
.fi-ts-waste:before {
    content: "\fc2b";
}
.fi-ts-watch-calculator:before {
    content: "\fc2c";
}
.fi-ts-watch-fitness:before {
    content: "\fc2d";
}
.fi-ts-watch-smart:before {
    content: "\fc2e";
}
.fi-ts-watch:before {
    content: "\fc2f";
}
.fi-ts-water-bottle:before {
    content: "\fc30";
}
.fi-ts-water-ladder:before {
    content: "\fc31";
}
.fi-ts-water-lower:before {
    content: "\fc32";
}
.fi-ts-water-rise:before {
    content: "\fc33";
}
.fi-ts-water:before {
    content: "\fc34";
}
.fi-ts-watermelon:before {
    content: "\fc35";
}
.fi-ts-wave-sine:before {
    content: "\fc36";
}
.fi-ts-wave-square:before {
    content: "\fc37";
}
.fi-ts-wave-triangle:before {
    content: "\fc38";
}
.fi-ts-waveform-path:before {
    content: "\fc39";
}
.fi-ts-waveform:before {
    content: "\fc3a";
}
.fi-ts-web:before {
    content: "\fc3b";
}
.fi-ts-webcam-slash:before {
    content: "\fc3c";
}
.fi-ts-webcam:before {
    content: "\fc3d";
}
.fi-ts-wednesday:before {
    content: "\fc3e";
}
.fi-ts-whale:before {
    content: "\fc3f";
}
.fi-ts-wheat-awn-circle-exclamation:before {
    content: "\fc40";
}
.fi-ts-wheat-awn-slash:before {
    content: "\fc41";
}
.fi-ts-wheat-awn:before {
    content: "\fc42";
}
.fi-ts-wheat-slash:before {
    content: "\fc43";
}
.fi-ts-wheat:before {
    content: "\fc44";
}
.fi-ts-wheelchair-move:before {
    content: "\fc45";
}
.fi-ts-wheelchair:before {
    content: "\fc46";
}
.fi-ts-whistle:before {
    content: "\fc47";
}
.fi-ts-wifi-1:before {
    content: "\fc48";
}
.fi-ts-wifi-2:before {
    content: "\fc49";
}
.fi-ts-wifi-alt:before {
    content: "\fc4a";
}
.fi-ts-wifi-exclamation:before {
    content: "\fc4b";
}
.fi-ts-wifi-slash:before {
    content: "\fc4c";
}
.fi-ts-wifi:before {
    content: "\fc4d";
}
.fi-ts-wind-turbine:before {
    content: "\fc4e";
}
.fi-ts-wind-warning:before {
    content: "\fc4f";
}
.fi-ts-wind:before {
    content: "\fc50";
}
.fi-ts-window-alt:before {
    content: "\fc51";
}
.fi-ts-window-frame-open:before {
    content: "\fc52";
}
.fi-ts-window-frame:before {
    content: "\fc53";
}
.fi-ts-window-maximize:before {
    content: "\fc54";
}
.fi-ts-window-minimize:before {
    content: "\fc55";
}
.fi-ts-window-restore:before {
    content: "\fc56";
}
.fi-ts-windsock:before {
    content: "\fc57";
}
.fi-ts-wine-bottle:before {
    content: "\fc58";
}
.fi-ts-wine-glass-crack:before {
    content: "\fc59";
}
.fi-ts-wine-glass-empty:before {
    content: "\fc5a";
}
.fi-ts-wishlist-heart:before {
    content: "\fc5b";
}
.fi-ts-wishlist-star:before {
    content: "\fc5c";
}
.fi-ts-woman-head:before {
    content: "\fc5d";
}
.fi-ts-woman-scientist:before {
    content: "\fc5e";
}
.fi-ts-won-sign:before {
    content: "\fc5f";
}
.fi-ts-work-in-progress:before {
    content: "\fc60";
}
.fi-ts-workflow-alt:before {
    content: "\fc61";
}
.fi-ts-workflow-setting-alt:before {
    content: "\fc62";
}
.fi-ts-workflow-setting:before {
    content: "\fc63";
}
.fi-ts-workflow:before {
    content: "\fc64";
}
.fi-ts-workshop:before {
    content: "\fc65";
}
.fi-ts-worm:before {
    content: "\fc66";
}
.fi-ts-wreath:before {
    content: "\fc67";
}
.fi-ts-wrench-alt:before {
    content: "\fc68";
}
.fi-ts-wrench-simple:before {
    content: "\fc69";
}
.fi-ts-x-ray:before {
    content: "\fc6a";
}
.fi-ts-x:before {
    content: "\fc6b";
}
.fi-ts-y:before {
    content: "\fc6c";
}
.fi-ts-yen:before {
    content: "\fc6d";
}
.fi-ts-yin-yang:before {
    content: "\fc6e";
}
.fi-ts-z:before {
    content: "\fc6f";
}

.fi-rr-0:before {
    content: "\f101";
}
.fi-rr-1:before {
    content: "\f102";
}
.fi-rr-2:before {
    content: "\f103";
}
.fi-rr-3:before {
    content: "\f104";
}
.fi-rr-360-degrees:before {
    content: "\f105";
}
.fi-rr-4:before {
    content: "\f106";
}
.fi-rr-404:before {
    content: "\f107";
}
.fi-rr-5:before {
    content: "\f108";
}
.fi-rr-6:before {
    content: "\f109";
}
.fi-rr-7:before {
    content: "\f10a";
}
.fi-rr-8:before {
    content: "\f10b";
}
.fi-rr-9:before {
    content: "\f10c";
}
.fi-rr-a:before {
    content: "\f10d";
}
.fi-rr-accident:before {
    content: "\f10e";
}
.fi-rr-acorn:before {
    content: "\f10f";
}
.fi-rr-ad-paid:before {
    content: "\f110";
}
.fi-rr-ad:before {
    content: "\f111";
}
.fi-rr-add-document:before {
    content: "\f112";
}
.fi-rr-add-folder:before {
    content: "\f113";
}
.fi-rr-add-image:before {
    content: "\f114";
}
.fi-rr-add:before {
    content: "\f115";
}
.fi-rr-address-book:before {
    content: "\f116";
}
.fi-rr-address-card:before {
    content: "\f117";
}
.fi-rr-admin-alt:before {
    content: "\f118";
}
.fi-rr-admin:before {
    content: "\f119";
}
.fi-rr-age-restriction-eighteen:before {
    content: "\f11a";
}
.fi-rr-age-restriction-seven:before {
    content: "\f11b";
}
.fi-rr-age-restriction-six:before {
    content: "\f11c";
}
.fi-rr-age-restriction-sixteen:before {
    content: "\f11d";
}
.fi-rr-age-restriction-thirteen:before {
    content: "\f11e";
}
.fi-rr-age-restriction-three:before {
    content: "\f11f";
}
.fi-rr-age-restriction-twelve:before {
    content: "\f120";
}
.fi-rr-age-restriction-twenty-one:before {
    content: "\f121";
}
.fi-rr-age-restriction-zero:before {
    content: "\f122";
}
.fi-rr-air-conditioner:before {
    content: "\f123";
}
.fi-rr-air-freshener:before {
    content: "\f124";
}
.fi-rr-air-pollution:before {
    content: "\f125";
}
.fi-rr-airplane-journey:before {
    content: "\f126";
}
.fi-rr-airplay:before {
    content: "\f127";
}
.fi-rr-alarm-clock:before {
    content: "\f128";
}
.fi-rr-alarm-exclamation:before {
    content: "\f129";
}
.fi-rr-alarm-plus:before {
    content: "\f12a";
}
.fi-rr-alarm-snooze:before {
    content: "\f12b";
}
.fi-rr-album-circle-plus:before {
    content: "\f12c";
}
.fi-rr-album-circle-user:before {
    content: "\f12d";
}
.fi-rr-album-collection:before {
    content: "\f12e";
}
.fi-rr-album:before {
    content: "\f12f";
}
.fi-rr-algorithm:before {
    content: "\f130";
}
.fi-rr-alicorn:before {
    content: "\f131";
}
.fi-rr-alien:before {
    content: "\f132";
}
.fi-rr-align-center:before {
    content: "\f133";
}
.fi-rr-align-justify:before {
    content: "\f134";
}
.fi-rr-align-left:before {
    content: "\f135";
}
.fi-rr-align-slash:before {
    content: "\f136";
}
.fi-rr-ambulance:before {
    content: "\f137";
}
.fi-rr-analyse-alt:before {
    content: "\f138";
}
.fi-rr-analyse:before {
    content: "\f139";
}
.fi-rr-anchor:before {
    content: "\f13a";
}
.fi-rr-angel:before {
    content: "\f13b";
}
.fi-rr-angle-90:before {
    content: "\f13c";
}
.fi-rr-angle-circle-down:before {
    content: "\f13d";
}
.fi-rr-angle-circle-left:before {
    content: "\f13e";
}
.fi-rr-angle-circle-right:before {
    content: "\f13f";
}
.fi-rr-angle-circle-up:before {
    content: "\f140";
}
.fi-rr-angle-double-left:before {
    content: "\f141";
}
.fi-rr-angle-double-right:before {
    content: "\f142";
}
.fi-rr-angle-double-small-down:before {
    content: "\f143";
}
.fi-rr-angle-double-small-left:before {
    content: "\f144";
}
.fi-rr-angle-double-small-right:before {
    content: "\f145";
}
.fi-rr-angle-double-small-up:before {
    content: "\f146";
}
.fi-rr-angle-down:before {
    content: "\f147";
}
.fi-rr-angle-left:before {
    content: "\f148";
}
.fi-rr-angle-right:before {
    content: "\f149";
}
.fi-rr-angle-small-down:before {
    content: "\f14a";
}
.fi-rr-angle-small-left:before {
    content: "\f14b";
}
.fi-rr-angle-small-right:before {
    content: "\f14c";
}
.fi-rr-angle-small-up:before {
    content: "\f14d";
}
.fi-rr-angle-square-down:before {
    content: "\f14e";
}
.fi-rr-angle-square-left:before {
    content: "\f14f";
}
.fi-rr-angle-square-right:before {
    content: "\f150";
}
.fi-rr-angle-square-up:before {
    content: "\f151";
}
.fi-rr-angle-up:before {
    content: "\f152";
}
.fi-rr-angle:before {
    content: "\f153";
}
.fi-rr-angry:before {
    content: "\f154";
}
.fi-rr-animated-icon:before {
    content: "\f155";
}
.fi-rr-ankh:before {
    content: "\f156";
}
.fi-rr-answer-alt:before {
    content: "\f157";
}
.fi-rr-answer:before {
    content: "\f158";
}
.fi-rr-apartment:before {
    content: "\f159";
}
.fi-rr-aperture:before {
    content: "\f15a";
}
.fi-rr-api:before {
    content: "\f15b";
}
.fi-rr-apple-core:before {
    content: "\f15c";
}
.fi-rr-apple-crate:before {
    content: "\f15d";
}
.fi-rr-apple-whole:before {
    content: "\f15e";
}
.fi-rr-apps-add:before {
    content: "\f15f";
}
.fi-rr-apps-delete:before {
    content: "\f160";
}
.fi-rr-apps-sort:before {
    content: "\f161";
}
.fi-rr-apps:before {
    content: "\f162";
}
.fi-rr-archive:before {
    content: "\f163";
}
.fi-rr-archway:before {
    content: "\f164";
}
.fi-rr-arrow-alt-circle-down:before {
    content: "\f165";
}
.fi-rr-arrow-alt-circle-left:before {
    content: "\f166";
}
.fi-rr-arrow-alt-circle-right:before {
    content: "\f167";
}
.fi-rr-arrow-alt-circle-up:before {
    content: "\f168";
}
.fi-rr-arrow-alt-down:before {
    content: "\f169";
}
.fi-rr-arrow-alt-from-bottom:before {
    content: "\f16a";
}
.fi-rr-arrow-alt-from-left:before {
    content: "\f16b";
}
.fi-rr-arrow-alt-from-right:before {
    content: "\f16c";
}
.fi-rr-arrow-alt-from-top:before {
    content: "\f16d";
}
.fi-rr-arrow-alt-left:before {
    content: "\f16e";
}
.fi-rr-arrow-alt-right:before {
    content: "\f16f";
}
.fi-rr-arrow-alt-square-down:before {
    content: "\f170";
}
.fi-rr-arrow-alt-square-left:before {
    content: "\f171";
}
.fi-rr-arrow-alt-square-right:before {
    content: "\f172";
}
.fi-rr-arrow-alt-square-up:before {
    content: "\f173";
}
.fi-rr-arrow-alt-to-bottom:before {
    content: "\f174";
}
.fi-rr-arrow-alt-to-left:before {
    content: "\f175";
}
.fi-rr-arrow-alt-to-right:before {
    content: "\f176";
}
.fi-rr-arrow-alt-to-top:before {
    content: "\f177";
}
.fi-rr-arrow-alt-up:before {
    content: "\f178";
}
.fi-rr-arrow-circle-down:before {
    content: "\f179";
}
.fi-rr-arrow-circle-left:before {
    content: "\f17a";
}
.fi-rr-arrow-circle-right:before {
    content: "\f17b";
}
.fi-rr-arrow-circle-up:before {
    content: "\f17c";
}
.fi-rr-arrow-comparison:before {
    content: "\f17d";
}
.fi-rr-arrow-down-from-dotted-line:before {
    content: "\f17e";
}
.fi-rr-arrow-down-left:before {
    content: "\f17f";
}
.fi-rr-arrow-down-small-big:before {
    content: "\f180";
}
.fi-rr-arrow-down-to-dotted-line:before {
    content: "\f181";
}
.fi-rr-arrow-down-to-square:before {
    content: "\f182";
}
.fi-rr-arrow-down-triangle-square:before {
    content: "\f183";
}
.fi-rr-arrow-down:before {
    content: "\f184";
}
.fi-rr-arrow-from-bottom:before {
    content: "\f185";
}
.fi-rr-arrow-from-left:before {
    content: "\f186";
}
.fi-rr-arrow-from-right:before {
    content: "\f187";
}
.fi-rr-arrow-from-top:before {
    content: "\f188";
}
.fi-rr-arrow-left-from-line:before {
    content: "\f189";
}
.fi-rr-arrow-left:before {
    content: "\f18a";
}
.fi-rr-arrow-progress:before {
    content: "\f18b";
}
.fi-rr-arrow-right-to-bracket:before {
    content: "\f18c";
}
.fi-rr-arrow-right:before {
    content: "\f18d";
}
.fi-rr-arrow-small-down:before {
    content: "\f18e";
}
.fi-rr-arrow-small-left:before {
    content: "\f18f";
}
.fi-rr-arrow-small-right:before {
    content: "\f190";
}
.fi-rr-arrow-small-up:before {
    content: "\f191";
}
.fi-rr-arrow-square-down:before {
    content: "\f192";
}
.fi-rr-arrow-square-left:before {
    content: "\f193";
}
.fi-rr-arrow-square-right:before {
    content: "\f194";
}
.fi-rr-arrow-square-up:before {
    content: "\f195";
}
.fi-rr-arrow-to-bottom:before {
    content: "\f196";
}
.fi-rr-arrow-to-left:before {
    content: "\f197";
}
.fi-rr-arrow-to-right:before {
    content: "\f198";
}
.fi-rr-arrow-to-top:before {
    content: "\f199";
}
.fi-rr-arrow-trend-down:before {
    content: "\f19a";
}
.fi-rr-arrow-trend-up:before {
    content: "\f19b";
}
.fi-rr-arrow-turn-down-left:before {
    content: "\f19c";
}
.fi-rr-arrow-turn-down-right:before {
    content: "\f19d";
}
.fi-rr-arrow-up-from-dotted-line:before {
    content: "\f19e";
}
.fi-rr-arrow-up-from-square:before {
    content: "\f19f";
}
.fi-rr-arrow-up-left-from-circle:before {
    content: "\f1a0";
}
.fi-rr-arrow-up-left:before {
    content: "\f1a1";
}
.fi-rr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f1a2";
}
.fi-rr-arrow-up-right-from-square:before {
    content: "\f1a3";
}
.fi-rr-arrow-up-right:before {
    content: "\f1a4";
}
.fi-rr-arrow-up-small-big:before {
    content: "\f1a5";
}
.fi-rr-arrow-up-square-triangle:before {
    content: "\f1a6";
}
.fi-rr-arrow-up-to-dotted-line:before {
    content: "\f1a7";
}
.fi-rr-arrow-up:before {
    content: "\f1a8";
}
.fi-rr-arrows-alt-h:before {
    content: "\f1a9";
}
.fi-rr-arrows-alt-v:before {
    content: "\f1aa";
}
.fi-rr-arrows-alt:before {
    content: "\f1ab";
}
.fi-rr-arrows-cross:before {
    content: "\f1ac";
}
.fi-rr-arrows-from-dotted-line:before {
    content: "\f1ad";
}
.fi-rr-arrows-from-line:before {
    content: "\f1ae";
}
.fi-rr-arrows-h-copy:before {
    content: "\f1af";
}
.fi-rr-arrows-h:before {
    content: "\f1b0";
}
.fi-rr-arrows-repeat-1:before {
    content: "\f1b1";
}
.fi-rr-arrows-repeat:before {
    content: "\f1b2";
}
.fi-rr-arrows-retweet:before {
    content: "\f1b3";
}
.fi-rr-arrows-to-dotted-line:before {
    content: "\f1b4";
}
.fi-rr-arrows-to-eye:before {
    content: "\f1b5";
}
.fi-rr-arrows-to-line:before {
    content: "\f1b6";
}
.fi-rr-arrows:before {
    content: "\f1b7";
}
.fi-rr-artificial-intelligence:before {
    content: "\f1b8";
}
.fi-rr-assept-document:before {
    content: "\f1b9";
}
.fi-rr-assessment-alt:before {
    content: "\f1ba";
}
.fi-rr-assessment:before {
    content: "\f1bb";
}
.fi-rr-assign:before {
    content: "\f1bc";
}
.fi-rr-assistive-listening-systems:before {
    content: "\f1bd";
}
.fi-rr-asterik:before {
    content: "\f1be";
}
.fi-rr-at:before {
    content: "\f1bf";
}
.fi-rr-attribution-pen:before {
    content: "\f1c0";
}
.fi-rr-attribution-pencil:before {
    content: "\f1c1";
}
.fi-rr-aubergine:before {
    content: "\f1c2";
}
.fi-rr-auction:before {
    content: "\f1c3";
}
.fi-rr-audio-description-slash:before {
    content: "\f1c4";
}
.fi-rr-audit-alt:before {
    content: "\f1c5";
}
.fi-rr-audit:before {
    content: "\f1c6";
}
.fi-rr-austral-sign:before {
    content: "\f1c7";
}
.fi-rr-avocado:before {
    content: "\f1c8";
}
.fi-rr-award:before {
    content: "\f1c9";
}
.fi-rr-axe-battle:before {
    content: "\f1ca";
}
.fi-rr-axe:before {
    content: "\f1cb";
}
.fi-rr-b:before {
    content: "\f1cc";
}
.fi-rr-baby-carriage:before {
    content: "\f1cd";
}
.fi-rr-baby:before {
    content: "\f1ce";
}
.fi-rr-background:before {
    content: "\f1cf";
}
.fi-rr-backpack:before {
    content: "\f1d0";
}
.fi-rr-bacon:before {
    content: "\f1d1";
}
.fi-rr-bacteria:before {
    content: "\f1d2";
}
.fi-rr-bacterium:before {
    content: "\f1d3";
}
.fi-rr-badge-check:before {
    content: "\f1d4";
}
.fi-rr-badge-dollar:before {
    content: "\f1d5";
}
.fi-rr-badge-leaf:before {
    content: "\f1d6";
}
.fi-rr-badge-percent:before {
    content: "\f1d7";
}
.fi-rr-badge-sheriff:before {
    content: "\f1d8";
}
.fi-rr-badge:before {
    content: "\f1d9";
}
.fi-rr-badger-honey:before {
    content: "\f1da";
}
.fi-rr-badget-check-alt:before {
    content: "\f1db";
}
.fi-rr-badminton:before {
    content: "\f1dc";
}
.fi-rr-bag-seedling:before {
    content: "\f1dd";
}
.fi-rr-bags-shopping:before {
    content: "\f1de";
}
.fi-rr-baguette:before {
    content: "\f1df";
}
.fi-rr-bahai:before {
    content: "\f1e0";
}
.fi-rr-baht-sign:before {
    content: "\f1e1";
}
.fi-rr-balance-scale-left:before {
    content: "\f1e2";
}
.fi-rr-balance-scale-right:before {
    content: "\f1e3";
}
.fi-rr-ball-pile:before {
    content: "\f1e4";
}
.fi-rr-ball:before {
    content: "\f1e5";
}
.fi-rr-balloon:before {
    content: "\f1e6";
}
.fi-rr-balloons:before {
    content: "\f1e7";
}
.fi-rr-ballot-check:before {
    content: "\f1e8";
}
.fi-rr-ballot:before {
    content: "\f1e9";
}
.fi-rr-ban-bug:before {
    content: "\f1ea";
}
.fi-rr-ban:before {
    content: "\f1eb";
}
.fi-rr-banana:before {
    content: "\f1ec";
}
.fi-rr-band-aid:before {
    content: "\f1ed";
}
.fi-rr-bangladeshi-taka-sign:before {
    content: "\f1ee";
}
.fi-rr-banjo:before {
    content: "\f1ef";
}
.fi-rr-bank:before {
    content: "\f1f0";
}
.fi-rr-banner-2:before {
    content: "\f1f1";
}
.fi-rr-banner-3:before {
    content: "\f1f2";
}
.fi-rr-banner-4:before {
    content: "\f1f3";
}
.fi-rr-banner-5:before {
    content: "\f1f4";
}
.fi-rr-banner:before {
    content: "\f1f5";
}
.fi-rr-barber-pole:before {
    content: "\f1f6";
}
.fi-rr-barber-shop:before {
    content: "\f1f7";
}
.fi-rr-barcode-read:before {
    content: "\f1f8";
}
.fi-rr-barcode-scan:before {
    content: "\f1f9";
}
.fi-rr-barcode:before {
    content: "\f1fa";
}
.fi-rr-bars-filter:before {
    content: "\f1fb";
}
.fi-rr-bars-progress:before {
    content: "\f1fc";
}
.fi-rr-bars-sort:before {
    content: "\f1fd";
}
.fi-rr-bars-staggered:before {
    content: "\f1fe";
}
.fi-rr-baseball-alt:before {
    content: "\f1ff";
}
.fi-rr-baseball:before {
    content: "\f200";
}
.fi-rr-basket-shopping-simple:before {
    content: "\f201";
}
.fi-rr-basket:before {
    content: "\f202";
}
.fi-rr-basketball:before {
    content: "\f203";
}
.fi-rr-bat:before {
    content: "\f204";
}
.fi-rr-bath:before {
    content: "\f205";
}
.fi-rr-battery-bolt:before {
    content: "\f206";
}
.fi-rr-battery-empty:before {
    content: "\f207";
}
.fi-rr-battery-exclamation:before {
    content: "\f208";
}
.fi-rr-battery-full:before {
    content: "\f209";
}
.fi-rr-battery-half:before {
    content: "\f20a";
}
.fi-rr-battery-quarter:before {
    content: "\f20b";
}
.fi-rr-battery-slash:before {
    content: "\f20c";
}
.fi-rr-battery-three-quarters:before {
    content: "\f20d";
}
.fi-rr-beacon:before {
    content: "\f20e";
}
.fi-rr-bed-alt:before {
    content: "\f20f";
}
.fi-rr-bed-bunk:before {
    content: "\f210";
}
.fi-rr-bed-empty:before {
    content: "\f211";
}
.fi-rr-bed:before {
    content: "\f212";
}
.fi-rr-bee:before {
    content: "\f213";
}
.fi-rr-beer-mug-empty:before {
    content: "\f214";
}
.fi-rr-beer:before {
    content: "\f215";
}
.fi-rr-bell-concierge:before {
    content: "\f216";
}
.fi-rr-bell-ring:before {
    content: "\f217";
}
.fi-rr-bell-school-slash:before {
    content: "\f218";
}
.fi-rr-bell-school:before {
    content: "\f219";
}
.fi-rr-bell-slash:before {
    content: "\f21a";
}
.fi-rr-bell:before {
    content: "\f21b";
}
.fi-rr-bells:before {
    content: "\f21c";
}
.fi-rr-bench-tree:before {
    content: "\f21d";
}
.fi-rr-benefit-porcent:before {
    content: "\f21e";
}
.fi-rr-betamax:before {
    content: "\f21f";
}
.fi-rr-bible:before {
    content: "\f220";
}
.fi-rr-bicycle-journey:before {
    content: "\f221";
}
.fi-rr-bike:before {
    content: "\f222";
}
.fi-rr-biking-mountain:before {
    content: "\f223";
}
.fi-rr-biking:before {
    content: "\f224";
}
.fi-rr-bill-arrow:before {
    content: "\f225";
}
.fi-rr-billiard:before {
    content: "\f226";
}
.fi-rr-binary-circle-check:before {
    content: "\f227";
}
.fi-rr-binary-lock:before {
    content: "\f228";
}
.fi-rr-binary-slash:before {
    content: "\f229";
}
.fi-rr-binary:before {
    content: "\f22a";
}
.fi-rr-binoculars:before {
    content: "\f22b";
}
.fi-rr-bio-leaves:before {
    content: "\f22c";
}
.fi-rr-bio:before {
    content: "\f22d";
}
.fi-rr-biohazard:before {
    content: "\f22e";
}
.fi-rr-bird:before {
    content: "\f22f";
}
.fi-rr-bitcoin-sign:before {
    content: "\f230";
}
.fi-rr-blanket:before {
    content: "\f231";
}
.fi-rr-blender-phone:before {
    content: "\f232";
}
.fi-rr-blender:before {
    content: "\f233";
}
.fi-rr-blinds-open:before {
    content: "\f234";
}
.fi-rr-blinds-raised:before {
    content: "\f235";
}
.fi-rr-blinds:before {
    content: "\f236";
}
.fi-rr-block-brick:before {
    content: "\f237";
}
.fi-rr-block-question:before {
    content: "\f238";
}
.fi-rr-block-quote:before {
    content: "\f239";
}
.fi-rr-block:before {
    content: "\f23a";
}
.fi-rr-blockchain-3:before {
    content: "\f23b";
}
.fi-rr-blog-pencil:before {
    content: "\f23c";
}
.fi-rr-blog-text:before {
    content: "\f23d";
}
.fi-rr-blueberries:before {
    content: "\f23e";
}
.fi-rr-blueprint:before {
    content: "\f23f";
}
.fi-rr-bold:before {
    content: "\f240";
}
.fi-rr-bolt-auto:before {
    content: "\f241";
}
.fi-rr-bolt-slash:before {
    content: "\f242";
}
.fi-rr-bolt:before {
    content: "\f243";
}
.fi-rr-bomb:before {
    content: "\f244";
}
.fi-rr-bone-break:before {
    content: "\f245";
}
.fi-rr-bone:before {
    content: "\f246";
}
.fi-rr-bong:before {
    content: "\f247";
}
.fi-rr-bonus-star:before {
    content: "\f248";
}
.fi-rr-bonus:before {
    content: "\f249";
}
.fi-rr-book-alt:before {
    content: "\f24a";
}
.fi-rr-book-arrow-right:before {
    content: "\f24b";
}
.fi-rr-book-arrow-up:before {
    content: "\f24c";
}
.fi-rr-book-atlas:before {
    content: "\f24d";
}
.fi-rr-book-bookmark:before {
    content: "\f24e";
}
.fi-rr-book-circle-arrow-right:before {
    content: "\f24f";
}
.fi-rr-book-circle-arrow-up:before {
    content: "\f250";
}
.fi-rr-book-copy:before {
    content: "\f251";
}
.fi-rr-book-dead:before {
    content: "\f252";
}
.fi-rr-book-font:before {
    content: "\f253";
}
.fi-rr-book-heart:before {
    content: "\f254";
}
.fi-rr-book-medical:before {
    content: "\f255";
}
.fi-rr-book-open-cover:before {
    content: "\f256";
}
.fi-rr-book-open-reader:before {
    content: "\f257";
}
.fi-rr-book-quran:before {
    content: "\f258";
}
.fi-rr-book-section:before {
    content: "\f259";
}
.fi-rr-book-spells:before {
    content: "\f25a";
}
.fi-rr-book-tanakh:before {
    content: "\f25b";
}
.fi-rr-book-user:before {
    content: "\f25c";
}
.fi-rr-book:before {
    content: "\f25d";
}
.fi-rr-Booking:before {
    content: "\f25e";
}
.fi-rr-bookmark-slash:before {
    content: "\f25f";
}
.fi-rr-bookmark:before {
    content: "\f260";
}
.fi-rr-books-medical:before {
    content: "\f261";
}
.fi-rr-books:before {
    content: "\f262";
}
.fi-rr-boot-heeled:before {
    content: "\f263";
}
.fi-rr-boot:before {
    content: "\f264";
}
.fi-rr-booth-curtain:before {
    content: "\f265";
}
.fi-rr-border-all:before {
    content: "\f266";
}
.fi-rr-border-bottom:before {
    content: "\f267";
}
.fi-rr-border-center-h:before {
    content: "\f268";
}
.fi-rr-border-center-v:before {
    content: "\f269";
}
.fi-rr-border-inner:before {
    content: "\f26a";
}
.fi-rr-border-left:before {
    content: "\f26b";
}
.fi-rr-border-none:before {
    content: "\f26c";
}
.fi-rr-border-outer:before {
    content: "\f26d";
}
.fi-rr-border-right:before {
    content: "\f26e";
}
.fi-rr-border-style-alt:before {
    content: "\f26f";
}
.fi-rr-border-style:before {
    content: "\f270";
}
.fi-rr-border-top:before {
    content: "\f271";
}
.fi-rr-boss:before {
    content: "\f272";
}
.fi-rr-bottle-droplet:before {
    content: "\f273";
}
.fi-rr-bottle:before {
    content: "\f274";
}
.fi-rr-bow-arrow:before {
    content: "\f275";
}
.fi-rr-bowl-chopsticks-noodles:before {
    content: "\f276";
}
.fi-rr-bowl-chopsticks:before {
    content: "\f277";
}
.fi-rr-bowl-rice:before {
    content: "\f278";
}
.fi-rr-bowl-scoop:before {
    content: "\f279";
}
.fi-rr-bowl-scoops:before {
    content: "\f27a";
}
.fi-rr-bowl-soft-serve:before {
    content: "\f27b";
}
.fi-rr-bowl-spoon:before {
    content: "\f27c";
}
.fi-rr-bowling-ball:before {
    content: "\f27d";
}
.fi-rr-bowling-pins:before {
    content: "\f27e";
}
.fi-rr-bowling:before {
    content: "\f27f";
}
.fi-rr-box-alt:before {
    content: "\f280";
}
.fi-rr-box-ballot:before {
    content: "\f281";
}
.fi-rr-box-check:before {
    content: "\f282";
}
.fi-rr-box-circle-check:before {
    content: "\f283";
}
.fi-rr-box-dollar:before {
    content: "\f284";
}
.fi-rr-box-fragile:before {
    content: "\f285";
}
.fi-rr-box-heart:before {
    content: "\f286";
}
.fi-rr-box-open-full:before {
    content: "\f287";
}
.fi-rr-box-open:before {
    content: "\f288";
}
.fi-rr-box-tissue:before {
    content: "\f289";
}
.fi-rr-box-up:before {
    content: "\f28a";
}
.fi-rr-box:before {
    content: "\f28b";
}
.fi-rr-boxes:before {
    content: "\f28c";
}
.fi-rr-boxing-glove:before {
    content: "\f28d";
}
.fi-rr-bracket-curly-right:before {
    content: "\f28e";
}
.fi-rr-bracket-curly:before {
    content: "\f28f";
}
.fi-rr-bracket-round-right:before {
    content: "\f290";
}
.fi-rr-bracket-round:before {
    content: "\f291";
}
.fi-rr-bracket-square-right:before {
    content: "\f292";
}
.fi-rr-bracket-square:before {
    content: "\f293";
}
.fi-rr-brackets-curly:before {
    content: "\f294";
}
.fi-rr-brackets-round:before {
    content: "\f295";
}
.fi-rr-brackets-square:before {
    content: "\f296";
}
.fi-rr-braille-a:before {
    content: "\f297";
}
.fi-rr-braille-b:before {
    content: "\f298";
}
.fi-rr-braille-c:before {
    content: "\f299";
}
.fi-rr-braille-d:before {
    content: "\f29a";
}
.fi-rr-braille-e:before {
    content: "\f29b";
}
.fi-rr-braille-g:before {
    content: "\f29c";
}
.fi-rr-braille-h:before {
    content: "\f29d";
}
.fi-rr-braille-i:before {
    content: "\f29e";
}
.fi-rr-braille-j:before {
    content: "\f29f";
}
.fi-rr-braille-k:before {
    content: "\f2a0";
}
.fi-rr-braille-l:before {
    content: "\f2a1";
}
.fi-rr-braille-m:before {
    content: "\f2a2";
}
.fi-rr-braille-n-alt:before {
    content: "\f2a3";
}
.fi-rr-braille-n:before {
    content: "\f2a4";
}
.fi-rr-braille-o:before {
    content: "\f2a5";
}
.fi-rr-braille-p:before {
    content: "\f2a6";
}
.fi-rr-braille-q:before {
    content: "\f2a7";
}
.fi-rr-braille-r:before {
    content: "\f2a8";
}
.fi-rr-braille-s:before {
    content: "\f2a9";
}
.fi-rr-braille-t:before {
    content: "\f2aa";
}
.fi-rr-braille-u:before {
    content: "\f2ab";
}
.fi-rr-braille-v:before {
    content: "\f2ac";
}
.fi-rr-braille-w:before {
    content: "\f2ad";
}
.fi-rr-braille-x:before {
    content: "\f2ae";
}
.fi-rr-braille-y:before {
    content: "\f2af";
}
.fi-rr-braille-z:before {
    content: "\f2b0";
}
.fi-rr-braille:before {
    content: "\f2b1";
}
.fi-rr-brain-circuit:before {
    content: "\f2b2";
}
.fi-rr-brain:before {
    content: "\f2b3";
}
.fi-rr-brand:before {
    content: "\f2b4";
}
.fi-rr-branding:before {
    content: "\f2b5";
}
.fi-rr-bread-loaf:before {
    content: "\f2b6";
}
.fi-rr-bread-slice-butter:before {
    content: "\f2b7";
}
.fi-rr-bread-slice:before {
    content: "\f2b8";
}
.fi-rr-bread:before {
    content: "\f2b9";
}
.fi-rr-briefcase-arrow-right:before {
    content: "\f2ba";
}
.fi-rr-briefcase-blank:before {
    content: "\f2bb";
}
.fi-rr-briefcase:before {
    content: "\f2bc";
}
.fi-rr-brightness-low:before {
    content: "\f2bd";
}
.fi-rr-brightness:before {
    content: "\f2be";
}
.fi-rr-bring-forward:before {
    content: "\f2bf";
}
.fi-rr-bring-front:before {
    content: "\f2c0";
}
.fi-rr-broadcast-tower:before {
    content: "\f2c1";
}
.fi-rr-broccoli:before {
    content: "\f2c2";
}
.fi-rr-broken-image:before {
    content: "\f2c3";
}
.fi-rr-broom-ball:before {
    content: "\f2c4";
}
.fi-rr-broom:before {
    content: "\f2c5";
}
.fi-rr-browser-ui:before {
    content: "\f2c6";
}
.fi-rr-browser:before {
    content: "\f2c7";
}
.fi-rr-browsers:before {
    content: "\f2c8";
}
.fi-rr-brush:before {
    content: "\f2c9";
}
.fi-rr-bucket:before {
    content: "\f2ca";
}
.fi-rr-budget-alt:before {
    content: "\f2cb";
}
.fi-rr-budget:before {
    content: "\f2cc";
}
.fi-rr-bug-slash:before {
    content: "\f2cd";
}
.fi-rr-bug:before {
    content: "\f2ce";
}
.fi-rr-bugs:before {
    content: "\f2cf";
}
.fi-rr-build-alt:before {
    content: "\f2d0";
}
.fi-rr-build:before {
    content: "\f2d1";
}
.fi-rr-builder:before {
    content: "\f2d2";
}
.fi-rr-building-circle-arrow-right:before {
    content: "\f2d3";
}
.fi-rr-building-ngo:before {
    content: "\f2d4";
}
.fi-rr-building:before {
    content: "\f2d5";
}
.fi-rr-bulb:before {
    content: "\f2d6";
}
.fi-rr-bullet:before {
    content: "\f2d7";
}
.fi-rr-bullhorn:before {
    content: "\f2d8";
}
.fi-rr-bullseye-arrow:before {
    content: "\f2d9";
}
.fi-rr-bullseye-pointer:before {
    content: "\f2da";
}
.fi-rr-bullseye:before {
    content: "\f2db";
}
.fi-rr-burger-alt:before {
    content: "\f2dc";
}
.fi-rr-burger-fries:before {
    content: "\f2dd";
}
.fi-rr-burger-glass:before {
    content: "\f2de";
}
.fi-rr-burrito:before {
    content: "\f2df";
}
.fi-rr-bus-alt:before {
    content: "\f2e0";
}
.fi-rr-bus:before {
    content: "\f2e1";
}
.fi-rr-business-time:before {
    content: "\f2e2";
}
.fi-rr-Butter:before {
    content: "\f2e3";
}
.fi-rr-butterfly:before {
    content: "\f2e4";
}
.fi-rr-c:before {
    content: "\f2e5";
}
.fi-rr-cabin:before {
    content: "\f2e6";
}
.fi-rr-cactus:before {
    content: "\f2e7";
}
.fi-rr-cake-birthday:before {
    content: "\f2e8";
}
.fi-rr-cake-slice:before {
    content: "\f2e9";
}
.fi-rr-cake-wedding:before {
    content: "\f2ea";
}
.fi-rr-calculator-bill:before {
    content: "\f2eb";
}
.fi-rr-calculator-money:before {
    content: "\f2ec";
}
.fi-rr-calculator-simple:before {
    content: "\f2ed";
}
.fi-rr-calculator:before {
    content: "\f2ee";
}
.fi-rr-calendar-arrow-down:before {
    content: "\f2ef";
}
.fi-rr-calendar-arrow-up:before {
    content: "\f2f0";
}
.fi-rr-calendar-check:before {
    content: "\f2f1";
}
.fi-rr-calendar-clock:before {
    content: "\f2f2";
}
.fi-rr-calendar-day:before {
    content: "\f2f3";
}
.fi-rr-calendar-days:before {
    content: "\f2f4";
}
.fi-rr-calendar-exclamation:before {
    content: "\f2f5";
}
.fi-rr-calendar-heart:before {
    content: "\f2f6";
}
.fi-rr-calendar-image:before {
    content: "\f2f7";
}
.fi-rr-calendar-lines-pen:before {
    content: "\f2f8";
}
.fi-rr-calendar-lines:before {
    content: "\f2f9";
}
.fi-rr-calendar-minus:before {
    content: "\f2fa";
}
.fi-rr-calendar-pen:before {
    content: "\f2fb";
}
.fi-rr-calendar-plus:before {
    content: "\f2fc";
}
.fi-rr-calendar-salary:before {
    content: "\f2fd";
}
.fi-rr-calendar-star:before {
    content: "\f2fe";
}
.fi-rr-calendar-week:before {
    content: "\f2ff";
}
.fi-rr-calendar-xmark:before {
    content: "\f300";
}
.fi-rr-calendar:before {
    content: "\f301";
}
.fi-rr-calendars:before {
    content: "\f302";
}
.fi-rr-call-duration:before {
    content: "\f303";
}
.fi-rr-call-history:before {
    content: "\f304";
}
.fi-rr-call-incoming:before {
    content: "\f305";
}
.fi-rr-call-missed:before {
    content: "\f306";
}
.fi-rr-call-outgoing:before {
    content: "\f307";
}
.fi-rr-camcorder:before {
    content: "\f308";
}
.fi-rr-camera-cctv:before {
    content: "\f309";
}
.fi-rr-camera-movie:before {
    content: "\f30a";
}
.fi-rr-camera-retro:before {
    content: "\f30b";
}
.fi-rr-camera-rotate:before {
    content: "\f30c";
}
.fi-rr-camera-security:before {
    content: "\f30d";
}
.fi-rr-camera-slash:before {
    content: "\f30e";
}
.fi-rr-camera-viewfinder:before {
    content: "\f30f";
}
.fi-rr-camera:before {
    content: "\f310";
}
.fi-rr-campfire:before {
    content: "\f311";
}
.fi-rr-camping:before {
    content: "\f312";
}
.fi-rr-can-food:before {
    content: "\f313";
}
.fi-rr-candle-holder:before {
    content: "\f314";
}
.fi-rr-candy-alt:before {
    content: "\f315";
}
.fi-rr-candy-bar:before {
    content: "\f316";
}
.fi-rr-candy-cane:before {
    content: "\f317";
}
.fi-rr-candy-corn:before {
    content: "\f318";
}
.fi-rr-candy-sweet:before {
    content: "\f319";
}
.fi-rr-candy:before {
    content: "\f31a";
}
.fi-rr-Cannabis:before {
    content: "\f31b";
}
.fi-rr-canned-food:before {
    content: "\f31c";
}
.fi-rr-capsules:before {
    content: "\f31d";
}
.fi-rr-car-alt:before {
    content: "\f31e";
}
.fi-rr-car-battery:before {
    content: "\f31f";
}
.fi-rr-car-bolt:before {
    content: "\f320";
}
.fi-rr-car-building:before {
    content: "\f321";
}
.fi-rr-car-bump:before {
    content: "\f322";
}
.fi-rr-car-bus:before {
    content: "\f323";
}
.fi-rr-car-circle-bolt:before {
    content: "\f324";
}
.fi-rr-car-crash:before {
    content: "\f325";
}
.fi-rr-car-garage:before {
    content: "\f326";
}
.fi-rr-car-journey:before {
    content: "\f327";
}
.fi-rr-car-mechanic:before {
    content: "\f328";
}
.fi-rr-car-rear:before {
    content: "\f329";
}
.fi-rr-car-side-bolt:before {
    content: "\f32a";
}
.fi-rr-car-side:before {
    content: "\f32b";
}
.fi-rr-car-tilt:before {
    content: "\f32c";
}
.fi-rr-car-wash:before {
    content: "\f32d";
}
.fi-rr-car:before {
    content: "\f32e";
}
.fi-rr-caravan-alt:before {
    content: "\f32f";
}
.fi-rr-caravan:before {
    content: "\f330";
}
.fi-rr-card-club:before {
    content: "\f331";
}
.fi-rr-card-diamond:before {
    content: "\f332";
}
.fi-rr-card-heart:before {
    content: "\f333";
}
.fi-rr-card-spade:before {
    content: "\f334";
}
.fi-rr-caret-circle-down:before {
    content: "\f335";
}
.fi-rr-caret-circle-right:before {
    content: "\f336";
}
.fi-rr-caret-circle-up:before {
    content: "\f337";
}
.fi-rr-caret-down:before {
    content: "\f338";
}
.fi-rr-caret-left:before {
    content: "\f339";
}
.fi-rr-caret-quare-up:before {
    content: "\f33a";
}
.fi-rr-caret-right:before {
    content: "\f33b";
}
.fi-rr-caret-square-down:before {
    content: "\f33c";
}
.fi-rr-caret-square-left_1:before {
    content: "\f33d";
}
.fi-rr-caret-square-left:before {
    content: "\f33e";
}
.fi-rr-caret-square-right:before {
    content: "\f33f";
}
.fi-rr-caret-up:before {
    content: "\f340";
}
.fi-rr-carrot:before {
    content: "\f341";
}
.fi-rr-cars-crash:before {
    content: "\f342";
}
.fi-rr-cars:before {
    content: "\f343";
}
.fi-rr-cart-arrow-down:before {
    content: "\f344";
}
.fi-rr-cart-minus:before {
    content: "\f345";
}
.fi-rr-cart-shopping-fast:before {
    content: "\f346";
}
.fi-rr-cash-register:before {
    content: "\f347";
}
.fi-rr-cassette-tape:before {
    content: "\f348";
}
.fi-rr-cassette-vhs:before {
    content: "\f349";
}
.fi-rr-castle:before {
    content: "\f34a";
}
.fi-rr-cat-head:before {
    content: "\f34b";
}
.fi-rr-cat-space:before {
    content: "\f34c";
}
.fi-rr-cat:before {
    content: "\f34d";
}
.fi-rr-catalog-alt:before {
    content: "\f34e";
}
.fi-rr-catalog-magazine:before {
    content: "\f34f";
}
.fi-rr-catalog:before {
    content: "\f350";
}
.fi-rr-category-alt:before {
    content: "\f351";
}
.fi-rr-category:before {
    content: "\f352";
}
.fi-rr-cauldron:before {
    content: "\f353";
}
.fi-rr-cedi-sign:before {
    content: "\f354";
}
.fi-rr-cello:before {
    content: "\f355";
}
.fi-rr-cent-sign:before {
    content: "\f356";
}
.fi-rr-chair-office:before {
    content: "\f357";
}
.fi-rr-chair:before {
    content: "\f358";
}
.fi-rr-chalkboard-user:before {
    content: "\f359";
}
.fi-rr-chalkboard:before {
    content: "\f35a";
}
.fi-rr-challenge-alt:before {
    content: "\f35b";
}
.fi-rr-challenge:before {
    content: "\f35c";
}
.fi-rr-channel:before {
    content: "\f35d";
}
.fi-rr-charging-station:before {
    content: "\f35e";
}
.fi-rr-chart-area:before {
    content: "\f35f";
}
.fi-rr-chart-bullet:before {
    content: "\f360";
}
.fi-rr-chart-candlestick:before {
    content: "\f361";
}
.fi-rr-chart-connected:before {
    content: "\f362";
}
.fi-rr-chart-gantt:before {
    content: "\f363";
}
.fi-rr-chart-histogram:before {
    content: "\f364";
}
.fi-rr-chart-line-up:before {
    content: "\f365";
}
.fi-rr-chart-mixed:before {
    content: "\f366";
}
.fi-rr-chart-network:before {
    content: "\f367";
}
.fi-rr-chart-pie-alt:before {
    content: "\f368";
}
.fi-rr-chart-pie:before {
    content: "\f369";
}
.fi-rr-chart-pyramid:before {
    content: "\f36a";
}
.fi-rr-chart-radar:before {
    content: "\f36b";
}
.fi-rr-chart-scatter-3d:before {
    content: "\f36c";
}
.fi-rr-chart-scatter-bubble:before {
    content: "\f36d";
}
.fi-rr-chart-scatter:before {
    content: "\f36e";
}
.fi-rr-chart-set-theory:before {
    content: "\f36f";
}
.fi-rr-chart-simple-horizontal:before {
    content: "\f370";
}
.fi-rr-chart-simple:before {
    content: "\f371";
}
.fi-rr-chart-tree-map:before {
    content: "\f372";
}
.fi-rr-chart-tree:before {
    content: "\f373";
}
.fi-rr-chart-user:before {
    content: "\f374";
}
.fi-rr-chart-waterfall:before {
    content: "\f375";
}
.fi-rr-chat-arrow-down:before {
    content: "\f376";
}
.fi-rr-chat-arrow-grow:before {
    content: "\f377";
}
.fi-rr-chatbot-speech-bubble:before {
    content: "\f378";
}
.fi-rr-chatbot:before {
    content: "\f379";
}
.fi-rr-cheap-bill:before {
    content: "\f37a";
}
.fi-rr-cheap-dollar:before {
    content: "\f37b";
}
.fi-rr-cheap-stack-dollar:before {
    content: "\f37c";
}
.fi-rr-cheap-stack:before {
    content: "\f37d";
}
.fi-rr-cheap:before {
    content: "\f37e";
}
.fi-rr-check-circle:before {
    content: "\f37f";
}
.fi-rr-check-double:before {
    content: "\f380";
}
.fi-rr-check-in-calendar:before {
    content: "\f381";
}
.fi-rr-check-out-calendar:before {
    content: "\f382";
}
.fi-rr-check:before {
    content: "\f383";
}
.fi-rr-checkbox:before {
    content: "\f384";
}
.fi-rr-cheese-alt:before {
    content: "\f385";
}
.fi-rr-cheese:before {
    content: "\f386";
}
.fi-rr-cheeseburger:before {
    content: "\f387";
}
.fi-rr-cherry:before {
    content: "\f388";
}
.fi-rr-chess-bishop:before {
    content: "\f389";
}
.fi-rr-chess-board:before {
    content: "\f38a";
}
.fi-rr-chess-clock-alt:before {
    content: "\f38b";
}
.fi-rr-chess-clock:before {
    content: "\f38c";
}
.fi-rr-chess-king-alt:before {
    content: "\f38d";
}
.fi-rr-chess-king:before {
    content: "\f38e";
}
.fi-rr-chess-knight-alt:before {
    content: "\f38f";
}
.fi-rr-chess-knight:before {
    content: "\f390";
}
.fi-rr-chess-pawn-alt:before {
    content: "\f391";
}
.fi-rr-chess-piece:before {
    content: "\f392";
}
.fi-rr-chess-queen-alt:before {
    content: "\f393";
}
.fi-rr-chess-queen:before {
    content: "\f394";
}
.fi-rr-chess-rook-alt:before {
    content: "\f395";
}
.fi-rr-chess-rook:before {
    content: "\f396";
}
.fi-rr-chess:before {
    content: "\f397";
}
.fi-rr-chevron-double-down:before {
    content: "\f398";
}
.fi-rr-chevron-double-up:before {
    content: "\f399";
}
.fi-rr-child-head:before {
    content: "\f39a";
}
.fi-rr-child:before {
    content: "\f39b";
}
.fi-rr-chimney:before {
    content: "\f39c";
}
.fi-rr-chocolate:before {
    content: "\f39d";
}
.fi-rr-choose-alt:before {
    content: "\f39e";
}
.fi-rr-choose:before {
    content: "\f39f";
}
.fi-rr-church:before {
    content: "\f3a0";
}
.fi-rr-circle-0:before {
    content: "\f3a1";
}
.fi-rr-circle-1:before {
    content: "\f3a2";
}
.fi-rr-circle-2:before {
    content: "\f3a3";
}
.fi-rr-circle-3:before {
    content: "\f3a4";
}
.fi-rr-circle-4:before {
    content: "\f3a5";
}
.fi-rr-circle-5:before {
    content: "\f3a6";
}
.fi-rr-circle-6:before {
    content: "\f3a7";
}
.fi-rr-circle-7:before {
    content: "\f3a8";
}
.fi-rr-circle-8:before {
    content: "\f3a9";
}
.fi-rr-circle-9:before {
    content: "\f3aa";
}
.fi-rr-circle-a:before {
    content: "\f3ab";
}
.fi-rr-circle-b:before {
    content: "\f3ac";
}
.fi-rr-circle-bolt:before {
    content: "\f3ad";
}
.fi-rr-circle-book-open:before {
    content: "\f3ae";
}
.fi-rr-circle-bookmark:before {
    content: "\f3af";
}
.fi-rr-circle-c:before {
    content: "\f3b0";
}
.fi-rr-circle-calendar:before {
    content: "\f3b1";
}
.fi-rr-circle-camera:before {
    content: "\f3b2";
}
.fi-rr-circle-d:before {
    content: "\f3b3";
}
.fi-rr-circle-dashed:before {
    content: "\f3b4";
}
.fi-rr-circle-divide:before {
    content: "\f3b5";
}
.fi-rr-circle-e:before {
    content: "\f3b6";
}
.fi-rr-circle-ellipsis-vertical:before {
    content: "\f3b7";
}
.fi-rr-circle-ellipsis:before {
    content: "\f3b8";
}
.fi-rr-circle-envelope:before {
    content: "\f3b9";
}
.fi-rr-circle-exclamation-check:before {
    content: "\f3ba";
}
.fi-rr-circle-f:before {
    content: "\f3bb";
}
.fi-rr-circle-g:before {
    content: "\f3bc";
}
.fi-rr-circle-h:before {
    content: "\f3bd";
}
.fi-rr-circle-half-stroke:before {
    content: "\f3be";
}
.fi-rr-circle-half:before {
    content: "\f3bf";
}
.fi-rr-circle-heart:before {
    content: "\f3c0";
}
.fi-rr-circle-i:before {
    content: "\f3c1";
}
.fi-rr-circle-j:before {
    content: "\f3c2";
}
.fi-rr-circle-k:before {
    content: "\f3c3";
}
.fi-rr-circle-l:before {
    content: "\f3c4";
}
.fi-rr-circle-m:before {
    content: "\f3c5";
}
.fi-rr-circle-microphone-lines:before {
    content: "\f3c6";
}
.fi-rr-circle-microphone:before {
    content: "\f3c7";
}
.fi-rr-circle-n:before {
    content: "\f3c8";
}
.fi-rr-circle-o:before {
    content: "\f3c9";
}
.fi-rr-circle-p:before {
    content: "\f3ca";
}
.fi-rr-circle-phone-flip:before {
    content: "\f3cb";
}
.fi-rr-circle-phone-hangup:before {
    content: "\f3cc";
}
.fi-rr-circle-phone:before {
    content: "\f3cd";
}
.fi-rr-circle-q:before {
    content: "\f3ce";
}
.fi-rr-circle-quarter:before {
    content: "\f3cf";
}
.fi-rr-circle-quarters-alt:before {
    content: "\f3d0";
}
.fi-rr-circle-r:before {
    content: "\f3d1";
}
.fi-rr-circle-s:before {
    content: "\f3d2";
}
.fi-rr-circle-small:before {
    content: "\f3d3";
}
.fi-rr-circle-star:before {
    content: "\f3d4";
}
.fi-rr-circle-t:before {
    content: "\f3d5";
}
.fi-rr-circle-three-quarters:before {
    content: "\f3d6";
}
.fi-rr-circle-trash:before {
    content: "\f3d7";
}
.fi-rr-circle-u:before {
    content: "\f3d8";
}
.fi-rr-circle-user:before {
    content: "\f3d9";
}
.fi-rr-circle-v:before {
    content: "\f3da";
}
.fi-rr-circle-video:before {
    content: "\f3db";
}
.fi-rr-circle-w:before {
    content: "\f3dc";
}
.fi-rr-circle-waveform-lines:before {
    content: "\f3dd";
}
.fi-rr-circle-x:before {
    content: "\f3de";
}
.fi-rr-circle-xmark:before {
    content: "\f3df";
}
.fi-rr-circle-y:before {
    content: "\f3e0";
}
.fi-rr-circle-z:before {
    content: "\f3e1";
}
.fi-rr-circle:before {
    content: "\f3e2";
}
.fi-rr-citrus-slice:before {
    content: "\f3e3";
}
.fi-rr-citrus:before {
    content: "\f3e4";
}
.fi-rr-city:before {
    content: "\f3e5";
}
.fi-rr-clapperboard-play:before {
    content: "\f3e6";
}
.fi-rr-clapperboard:before {
    content: "\f3e7";
}
.fi-rr-clarinet:before {
    content: "\f3e8";
}
.fi-rr-claw-marks:before {
    content: "\f3e9";
}
.fi-rr-clear-alt:before {
    content: "\f3ea";
}
.fi-rr-clip:before {
    content: "\f3eb";
}
.fi-rr-clipboard-check:before {
    content: "\f3ec";
}
.fi-rr-clipboard-exclamation:before {
    content: "\f3ed";
}
.fi-rr-clipboard-list-check:before {
    content: "\f3ee";
}
.fi-rr-clipboard-list:before {
    content: "\f3ef";
}
.fi-rr-clipboard-prescription:before {
    content: "\f3f0";
}
.fi-rr-clipboard-user:before {
    content: "\f3f1";
}
.fi-rr-clipboard:before {
    content: "\f3f2";
}
.fi-rr-clock-desk:before {
    content: "\f3f3";
}
.fi-rr-clock-eight-thirty:before {
    content: "\f3f4";
}
.fi-rr-clock-eleven-thirty:before {
    content: "\f3f5";
}
.fi-rr-clock-eleven:before {
    content: "\f3f6";
}
.fi-rr-clock-five-thirty:before {
    content: "\f3f7";
}
.fi-rr-clock-five:before {
    content: "\f3f8";
}
.fi-rr-clock-four-thirty:before {
    content: "\f3f9";
}
.fi-rr-clock-nine-thirty:before {
    content: "\f3fa";
}
.fi-rr-clock-nine:before {
    content: "\f3fb";
}
.fi-rr-clock-one-thirty:before {
    content: "\f3fc";
}
.fi-rr-clock-one:before {
    content: "\f3fd";
}
.fi-rr-clock-seven-thirty:before {
    content: "\f3fe";
}
.fi-rr-clock-seven:before {
    content: "\f3ff";
}
.fi-rr-clock-six-thirty:before {
    content: "\f400";
}
.fi-rr-clock-six:before {
    content: "\f401";
}
.fi-rr-clock-ten-thirty:before {
    content: "\f402";
}
.fi-rr-clock-ten:before {
    content: "\f403";
}
.fi-rr-clock-three-thirty:before {
    content: "\f404";
}
.fi-rr-clock-three:before {
    content: "\f405";
}
.fi-rr-clock-twelve-thirty:before {
    content: "\f406";
}
.fi-rr-clock-twelve:before {
    content: "\f407";
}
.fi-rr-clock-two-thirty:before {
    content: "\f408";
}
.fi-rr-clock-two:before {
    content: "\f409";
}
.fi-rr-clock-up-arrow:before {
    content: "\f40a";
}
.fi-rr-clock:before {
    content: "\f40b";
}
.fi-rr-clone:before {
    content: "\f40c";
}
.fi-rr-closed-captioning-slash:before {
    content: "\f40d";
}
.fi-rr-clothes-hanger:before {
    content: "\f40e";
}
.fi-rr-cloud-check:before {
    content: "\f40f";
}
.fi-rr-cloud-code:before {
    content: "\f410";
}
.fi-rr-cloud-disabled:before {
    content: "\f411";
}
.fi-rr-cloud-download-alt:before {
    content: "\f412";
}
.fi-rr-cloud-download:before {
    content: "\f413";
}
.fi-rr-cloud-drizzle:before {
    content: "\f414";
}
.fi-rr-cloud-exclamation:before {
    content: "\f415";
}
.fi-rr-cloud-hail-mixed:before {
    content: "\f416";
}
.fi-rr-cloud-hail:before {
    content: "\f417";
}
.fi-rr-cloud-meatball:before {
    content: "\f418";
}
.fi-rr-cloud-moon-rain:before {
    content: "\f419";
}
.fi-rr-cloud-moon:before {
    content: "\f41a";
}
.fi-rr-cloud-question:before {
    content: "\f41b";
}
.fi-rr-cloud-rain:before {
    content: "\f41c";
}
.fi-rr-cloud-rainbow:before {
    content: "\f41d";
}
.fi-rr-cloud-share:before {
    content: "\f41e";
}
.fi-rr-cloud-showers-heavy:before {
    content: "\f41f";
}
.fi-rr-cloud-showers:before {
    content: "\f420";
}
.fi-rr-cloud-sleet:before {
    content: "\f421";
}
.fi-rr-cloud-snow:before {
    content: "\f422";
}
.fi-rr-cloud-sun-rain:before {
    content: "\f423";
}
.fi-rr-cloud-sun:before {
    content: "\f424";
}
.fi-rr-cloud-upload-alt:before {
    content: "\f425";
}
.fi-rr-cloud-upload:before {
    content: "\f426";
}
.fi-rr-cloud:before {
    content: "\f427";
}
.fi-rr-clouds-moon:before {
    content: "\f428";
}
.fi-rr-clouds-sun:before {
    content: "\f429";
}
.fi-rr-clouds:before {
    content: "\f42a";
}
.fi-rr-clover-alt:before {
    content: "\f42b";
}
.fi-rr-club:before {
    content: "\f42c";
}
.fi-rr-cocktail-alt:before {
    content: "\f42d";
}
.fi-rr-cocktail:before {
    content: "\f42e";
}
.fi-rr-coconut:before {
    content: "\f42f";
}
.fi-rr-code-branch:before {
    content: "\f430";
}
.fi-rr-code-commit:before {
    content: "\f431";
}
.fi-rr-code-compare:before {
    content: "\f432";
}
.fi-rr-code-fork:before {
    content: "\f433";
}
.fi-rr-code-merge:before {
    content: "\f434";
}
.fi-rr-code-pull-request-closed:before {
    content: "\f435";
}
.fi-rr-code-pull-request-draft:before {
    content: "\f436";
}
.fi-rr-code-pull-request:before {
    content: "\f437";
}
.fi-rr-code-simple:before {
    content: "\f438";
}
.fi-rr-coffee-bean:before {
    content: "\f439";
}
.fi-rr-coffee-beans:before {
    content: "\f43a";
}
.fi-rr-coffee-pot:before {
    content: "\f43b";
}
.fi-rr-coffee:before {
    content: "\f43c";
}
.fi-rr-coffin-cross:before {
    content: "\f43d";
}
.fi-rr-coffin:before {
    content: "\f43e";
}
.fi-rr-coin-up-arrow:before {
    content: "\f43f";
}
.fi-rr-coin:before {
    content: "\f440";
}
.fi-rr-coins:before {
    content: "\f441";
}
.fi-rr-colon-sign:before {
    content: "\f442";
}
.fi-rr-columns-3:before {
    content: "\f443";
}
.fi-rr-comet:before {
    content: "\f444";
}
.fi-rr-command:before {
    content: "\f445";
}
.fi-rr-comment-alt-check:before {
    content: "\f446";
}
.fi-rr-comment-alt-dots:before {
    content: "\f447";
}
.fi-rr-comment-alt-edit:before {
    content: "\f448";
}
.fi-rr-comment-alt-medical:before {
    content: "\f449";
}
.fi-rr-comment-alt-middle-top:before {
    content: "\f44a";
}
.fi-rr-comment-alt-middle:before {
    content: "\f44b";
}
.fi-rr-comment-alt-minus:before {
    content: "\f44c";
}
.fi-rr-comment-alt-music:before {
    content: "\f44d";
}
.fi-rr-comment-alt:before {
    content: "\f44e";
}
.fi-rr-comment-arrow-down:before {
    content: "\f44f";
}
.fi-rr-comment-arrow-up-right:before {
    content: "\f450";
}
.fi-rr-comment-arrow-up:before {
    content: "\f451";
}
.fi-rr-comment-check:before {
    content: "\f452";
}
.fi-rr-comment-code:before {
    content: "\f453";
}
.fi-rr-comment-dollar:before {
    content: "\f454";
}
.fi-rr-comment-dots:before {
    content: "\f455";
}
.fi-rr-comment-exclamation:before {
    content: "\f456";
}
.fi-rr-comment-heart:before {
    content: "\f457";
}
.fi-rr-comment-image:before {
    content: "\f458";
}
.fi-rr-comment-info:before {
    content: "\f459";
}
.fi-rr-comment-medical:before {
    content: "\f45a";
}
.fi-rr-comment-minus:before {
    content: "\f45b";
}
.fi-rr-comment-pen:before {
    content: "\f45c";
}
.fi-rr-comment-question:before {
    content: "\f45d";
}
.fi-rr-comment-quote:before {
    content: "\f45e";
}
.fi-rr-comment-slash:before {
    content: "\f45f";
}
.fi-rr-comment-smile:before {
    content: "\f460";
}
.fi-rr-comment-sms:before {
    content: "\f461";
}
.fi-rr-comment-text:before {
    content: "\f462";
}
.fi-rr-comment-user:before {
    content: "\f463";
}
.fi-rr-comment-xmark:before {
    content: "\f464";
}
.fi-rr-comment:before {
    content: "\f465";
}
.fi-rr-comments-dollar:before {
    content: "\f466";
}
.fi-rr-comments-question-check:before {
    content: "\f467";
}
.fi-rr-comments-question:before {
    content: "\f468";
}
.fi-rr-comments:before {
    content: "\f469";
}
.fi-rr-compass-slash:before {
    content: "\f46a";
}
.fi-rr-completed:before {
    content: "\f46b";
}
.fi-rr-compliance-clipboard:before {
    content: "\f46c";
}
.fi-rr-compliance-document:before {
    content: "\f46d";
}
.fi-rr-compliance:before {
    content: "\f46e";
}
.fi-rr-compress-alt:before {
    content: "\f46f";
}
.fi-rr-compress:before {
    content: "\f470";
}
.fi-rr-computer-classic:before {
    content: "\f471";
}
.fi-rr-computer-mouse:before {
    content: "\f472";
}
.fi-rr-computer-speaker:before {
    content: "\f473";
}
.fi-rr-computer:before {
    content: "\f474";
}
.fi-rr-concierge-bell:before {
    content: "\f475";
}
.fi-rr-condition-alt:before {
    content: "\f476";
}
.fi-rr-condition:before {
    content: "\f477";
}
.fi-rr-confetti:before {
    content: "\f478";
}
.fi-rr-constellation:before {
    content: "\f479";
}
.fi-rr-container-storage:before {
    content: "\f47a";
}
.fi-rr-convert-document:before {
    content: "\f47b";
}
.fi-rr-conveyor-belt-alt:before {
    content: "\f47c";
}
.fi-rr-conveyor-belt-empty:before {
    content: "\f47d";
}
.fi-rr-conveyor-belt:before {
    content: "\f47e";
}
.fi-rr-cookie-alt:before {
    content: "\f47f";
}
.fi-rr-cookie:before {
    content: "\f480";
}
.fi-rr-copy-alt:before {
    content: "\f481";
}
.fi-rr-copy-image:before {
    content: "\f482";
}
.fi-rr-copy:before {
    content: "\f483";
}
.fi-rr-copyright:before {
    content: "\f484";
}
.fi-rr-corn:before {
    content: "\f485";
}
.fi-rr-corporate-alt:before {
    content: "\f486";
}
.fi-rr-corporate:before {
    content: "\f487";
}
.fi-rr-couch:before {
    content: "\f488";
}
.fi-rr-cow-alt:before {
    content: "\f489";
}
.fi-rr-cow:before {
    content: "\f48a";
}
.fi-rr-cowbell-circle-plus:before {
    content: "\f48b";
}
.fi-rr-cowbell-more:before {
    content: "\f48c";
}
.fi-rr-cowbell:before {
    content: "\f48d";
}
.fi-rr-crab:before {
    content: "\f48e";
}
.fi-rr-crate-empty:before {
    content: "\f48f";
}
.fi-rr-cream:before {
    content: "\f490";
}
.fi-rr-credit-card:before {
    content: "\f491";
}
.fi-rr-cricket:before {
    content: "\f492";
}
.fi-rr-crm-alt:before {
    content: "\f493";
}
.fi-rr-crm-computer:before {
    content: "\f494";
}
.fi-rr-CRM:before {
    content: "\f495";
}
.fi-rr-croissant:before {
    content: "\f496";
}
.fi-rr-cross-circle:before {
    content: "\f497";
}
.fi-rr-cross-religion:before {
    content: "\f498";
}
.fi-rr-cross-small:before {
    content: "\f499";
}
.fi-rr-cross:before {
    content: "\f49a";
}
.fi-rr-crow:before {
    content: "\f49b";
}
.fi-rr-crown:before {
    content: "\f49c";
}
.fi-rr-crutch:before {
    content: "\f49d";
}
.fi-rr-crutches:before {
    content: "\f49e";
}
.fi-rr-cruzeiro-sign:before {
    content: "\f49f";
}
.fi-rr-cryptocurrency:before {
    content: "\f4a0";
}
.fi-rr-crystal-ball:before {
    content: "\f4a1";
}
.fi-rr-cube:before {
    content: "\f4a2";
}
.fi-rr-cubes-stacked:before {
    content: "\f4a3";
}
.fi-rr-cubes:before {
    content: "\f4a4";
}
.fi-rr-cucumber:before {
    content: "\f4a5";
}
.fi-rr-cup-straw-swoosh:before {
    content: "\f4a6";
}
.fi-rr-cup-straw:before {
    content: "\f4a7";
}
.fi-rr-cup-togo:before {
    content: "\f4a8";
}
.fi-rr-cupcake-alt:before {
    content: "\f4a9";
}
.fi-rr-cupcake:before {
    content: "\f4aa";
}
.fi-rr-curling:before {
    content: "\f4ab";
}
.fi-rr-cursor-finger:before {
    content: "\f4ac";
}
.fi-rr-cursor-plus:before {
    content: "\f4ad";
}
.fi-rr-cursor-text-alt:before {
    content: "\f4ae";
}
.fi-rr-cursor-text:before {
    content: "\f4af";
}
.fi-rr-cursor:before {
    content: "\f4b0";
}
.fi-rr-curve-alt:before {
    content: "\f4b1";
}
.fi-rr-curve-arrow:before {
    content: "\f4b2";
}
.fi-rr-curve:before {
    content: "\f4b3";
}
.fi-rr-custard:before {
    content: "\f4b4";
}
.fi-rr-customer-care:before {
    content: "\f4b5";
}
.fi-rr-customer-service:before {
    content: "\f4b6";
}
.fi-rr-customization-cogwheel:before {
    content: "\f4b7";
}
.fi-rr-customization:before {
    content: "\f4b8";
}
.fi-rr-customize-computer:before {
    content: "\f4b9";
}
.fi-rr-customize-edit:before {
    content: "\f4ba";
}
.fi-rr-customize:before {
    content: "\f4bb";
}
.fi-rr-CV:before {
    content: "\f4bc";
}
.fi-rr-d:before {
    content: "\f4bd";
}
.fi-rr-dagger:before {
    content: "\f4be";
}
.fi-rr-daily-calendar:before {
    content: "\f4bf";
}
.fi-rr-damage:before {
    content: "\f4c0";
}
.fi-rr-dart:before {
    content: "\f4c1";
}
.fi-rr-dashboard:before {
    content: "\f4c2";
}
.fi-rr-data-transfer:before {
    content: "\f4c3";
}
.fi-rr-database:before {
    content: "\f4c4";
}
.fi-rr-debt:before {
    content: "\f4c5";
}
.fi-rr-deer-rudolph:before {
    content: "\f4c6";
}
.fi-rr-deer:before {
    content: "\f4c7";
}
.fi-rr-delete-document:before {
    content: "\f4c8";
}
.fi-rr-delete-right:before {
    content: "\f4c9";
}
.fi-rr-delete-user:before {
    content: "\f4ca";
}
.fi-rr-delete:before {
    content: "\f4cb";
}
.fi-rr-democrat:before {
    content: "\f4cc";
}
.fi-rr-department-structure:before {
    content: "\f4cd";
}
.fi-rr-department:before {
    content: "\f4ce";
}
.fi-rr-deposit-alt:before {
    content: "\f4cf";
}
.fi-rr-deposit:before {
    content: "\f4d0";
}
.fi-rr-description-alt:before {
    content: "\f4d1";
}
.fi-rr-description:before {
    content: "\f4d2";
}
.fi-rr-desktop-arrow-down:before {
    content: "\f4d3";
}
.fi-rr-desktop-wallpaper:before {
    content: "\f4d4";
}
.fi-rr-devices:before {
    content: "\f4d5";
}
.fi-rr-dewpoint:before {
    content: "\f4d6";
}
.fi-rr-dharmachakra:before {
    content: "\f4d7";
}
.fi-rr-diagram-cells:before {
    content: "\f4d8";
}
.fi-rr-diagram-lean-canvas:before {
    content: "\f4d9";
}
.fi-rr-diagram-nested:before {
    content: "\f4da";
}
.fi-rr-diagram-next:before {
    content: "\f4db";
}
.fi-rr-diagram-predecessor:before {
    content: "\f4dc";
}
.fi-rr-diagram-previous:before {
    content: "\f4dd";
}
.fi-rr-diagram-project:before {
    content: "\f4de";
}
.fi-rr-diagram-sankey:before {
    content: "\f4df";
}
.fi-rr-diagram-subtask:before {
    content: "\f4e0";
}
.fi-rr-diagram-successor:before {
    content: "\f4e1";
}
.fi-rr-diagram-venn:before {
    content: "\f4e2";
}
.fi-rr-dial-high:before {
    content: "\f4e3";
}
.fi-rr-dial-low:before {
    content: "\f4e4";
}
.fi-rr-dial-max:before {
    content: "\f4e5";
}
.fi-rr-dial-med-low:before {
    content: "\f4e6";
}
.fi-rr-dial-med:before {
    content: "\f4e7";
}
.fi-rr-dial-min:before {
    content: "\f4e8";
}
.fi-rr-dial-off:before {
    content: "\f4e9";
}
.fi-rr-dial:before {
    content: "\f4ea";
}
.fi-rr-diamond-exclamation:before {
    content: "\f4eb";
}
.fi-rr-diamond-turn-right:before {
    content: "\f4ec";
}
.fi-rr-diamond:before {
    content: "\f4ed";
}
.fi-rr-diary-bookmark-down:before {
    content: "\f4ee";
}
.fi-rr-diary-bookmarks:before {
    content: "\f4ef";
}
.fi-rr-diary-clasp:before {
    content: "\f4f0";
}
.fi-rr-dice-alt:before {
    content: "\f4f1";
}
.fi-rr-dice-d10:before {
    content: "\f4f2";
}
.fi-rr-dice-d12:before {
    content: "\f4f3";
}
.fi-rr-dice-d20:before {
    content: "\f4f4";
}
.fi-rr-dice-d4:before {
    content: "\f4f5";
}
.fi-rr-dice-d6:before {
    content: "\f4f6";
}
.fi-rr-dice-d8:before {
    content: "\f4f7";
}
.fi-rr-dice-four:before {
    content: "\f4f8";
}
.fi-rr-dice-one:before {
    content: "\f4f9";
}
.fi-rr-dice-six:before {
    content: "\f4fa";
}
.fi-rr-dice-three:before {
    content: "\f4fb";
}
.fi-rr-dice-two:before {
    content: "\f4fc";
}
.fi-rr-dice:before {
    content: "\f4fd";
}
.fi-rr-digging:before {
    content: "\f4fe";
}
.fi-rr-digital-tachograph:before {
    content: "\f4ff";
}
.fi-rr-diploma:before {
    content: "\f500";
}
.fi-rr-direction-signal-arrow:before {
    content: "\f501";
}
.fi-rr-direction-signal:before {
    content: "\f502";
}
.fi-rr-disc-drive:before {
    content: "\f503";
}
.fi-rr-disco-ball:before {
    content: "\f504";
}
.fi-rr-discover:before {
    content: "\f505";
}
.fi-rr-disease:before {
    content: "\f506";
}
.fi-rr-disk:before {
    content: "\f507";
}
.fi-rr-display-arrow-down:before {
    content: "\f508";
}
.fi-rr-display-code:before {
    content: "\f509";
}
.fi-rr-display-medical:before {
    content: "\f50a";
}
.fi-rr-display-slash:before {
    content: "\f50b";
}
.fi-rr-distribute-spacing-horizontal:before {
    content: "\f50c";
}
.fi-rr-distribute-spacing-vertical:before {
    content: "\f50d";
}
.fi-rr-divide:before {
    content: "\f50e";
}
.fi-rr-dizzy:before {
    content: "\f50f";
}
.fi-rr-dna:before {
    content: "\f510";
}
.fi-rr-do-not-enter:before {
    content: "\f511";
}
.fi-rr-doctor:before {
    content: "\f512";
}
.fi-rr-document-paid:before {
    content: "\f513";
}
.fi-rr-document-signed:before {
    content: "\f514";
}
.fi-rr-document:before {
    content: "\f515";
}
.fi-rr-dog-leashed:before {
    content: "\f516";
}
.fi-rr-dog:before {
    content: "\f517";
}
.fi-rr-dollar:before {
    content: "\f518";
}
.fi-rr-dolly-flatbed-alt:before {
    content: "\f519";
}
.fi-rr-dolly-flatbed-empty:before {
    content: "\f51a";
}
.fi-rr-dolly-flatbed:before {
    content: "\f51b";
}
.fi-rr-dolphin:before {
    content: "\f51c";
}
.fi-rr-domino-effect:before {
    content: "\f51d";
}
.fi-rr-donate:before {
    content: "\f51e";
}
.fi-rr-dong-sign:before {
    content: "\f51f";
}
.fi-rr-donut:before {
    content: "\f520";
}
.fi-rr-door-closed:before {
    content: "\f521";
}
.fi-rr-door-open:before {
    content: "\f522";
}
.fi-rr-dot-circle:before {
    content: "\f523";
}
.fi-rr-dot-pending:before {
    content: "\f524";
}
.fi-rr-dove:before {
    content: "\f525";
}
.fi-rr-down-left-and-up-right-to-center:before {
    content: "\f526";
}
.fi-rr-down-left:before {
    content: "\f527";
}
.fi-rr-down-right:before {
    content: "\f528";
}
.fi-rr-down-to-line:before {
    content: "\f529";
}
.fi-rr-down:before {
    content: "\f52a";
}
.fi-rr-download:before {
    content: "\f52b";
}
.fi-rr-drafting-compass:before {
    content: "\f52c";
}
.fi-rr-dragon:before {
    content: "\f52d";
}
.fi-rr-draw-polygon:before {
    content: "\f52e";
}
.fi-rr-draw-square:before {
    content: "\f52f";
}
.fi-rr-drawer-alt:before {
    content: "\f530";
}
.fi-rr-drawer:before {
    content: "\f531";
}
.fi-rr-dreidel:before {
    content: "\f532";
}
.fi-rr-drink-alt:before {
    content: "\f533";
}
.fi-rr-driver-man:before {
    content: "\f534";
}
.fi-rr-driver-woman:before {
    content: "\f535";
}
.fi-rr-drone-alt:before {
    content: "\f536";
}
.fi-rr-drone-front:before {
    content: "\f537";
}
.fi-rr-drone:before {
    content: "\f538";
}
.fi-rr-drop-down:before {
    content: "\f539";
}
.fi-rr-drum-steelpan:before {
    content: "\f53a";
}
.fi-rr-drum:before {
    content: "\f53b";
}
.fi-rr-drumstick-bite:before {
    content: "\f53c";
}
.fi-rr-drumstick:before {
    content: "\f53d";
}
.fi-rr-dryer-alt:before {
    content: "\f53e";
}
.fi-rr-dryer:before {
    content: "\f53f";
}
.fi-rr-duck:before {
    content: "\f540";
}
.fi-rr-dumpster-fire:before {
    content: "\f541";
}
.fi-rr-dumpster:before {
    content: "\f542";
}
.fi-rr-dungeon:before {
    content: "\f543";
}
.fi-rr-duplicate:before {
    content: "\f544";
}
.fi-rr-duration-alt:before {
    content: "\f545";
}
.fi-rr-duration:before {
    content: "\f546";
}
.fi-rr-e-learning:before {
    content: "\f547";
}
.fi-rr-e:before {
    content: "\f548";
}
.fi-rr-ear-deaf:before {
    content: "\f549";
}
.fi-rr-ear-muffs:before {
    content: "\f54a";
}
.fi-rr-ear:before {
    content: "\f54b";
}
.fi-rr-earnings:before {
    content: "\f54c";
}
.fi-rr-earth-africa:before {
    content: "\f54d";
}
.fi-rr-earth-americas:before {
    content: "\f54e";
}
.fi-rr-earth-asia:before {
    content: "\f54f";
}
.fi-rr-earth-europa:before {
    content: "\f550";
}
.fi-rr-eclipse-alt:before {
    content: "\f551";
}
.fi-rr-eclipse:before {
    content: "\f552";
}
.fi-rr-edit-alt:before {
    content: "\f553";
}
.fi-rr-edit:before {
    content: "\f554";
}
.fi-rr-effect:before {
    content: "\f555";
}
.fi-rr-egg-fried:before {
    content: "\f556";
}
.fi-rr-egg:before {
    content: "\f557";
}
.fi-rr-eject:before {
    content: "\f558";
}
.fi-rr-elephant:before {
    content: "\f559";
}
.fi-rr-elevator:before {
    content: "\f55a";
}
.fi-rr-employee-alt:before {
    content: "\f55b";
}
.fi-rr-employee-man-alt:before {
    content: "\f55c";
}
.fi-rr-employee-man:before {
    content: "\f55d";
}
.fi-rr-employees-woman-man:before {
    content: "\f55e";
}
.fi-rr-employees:before {
    content: "\f55f";
}
.fi-rr-empty-set:before {
    content: "\f560";
}
.fi-rr-engine-warning:before {
    content: "\f561";
}
.fi-rr-engine:before {
    content: "\f562";
}
.fi-rr-enter:before {
    content: "\f563";
}
.fi-rr-envelope-ban:before {
    content: "\f564";
}
.fi-rr-envelope-bulk:before {
    content: "\f565";
}
.fi-rr-envelope-dot:before {
    content: "\f566";
}
.fi-rr-envelope-download:before {
    content: "\f567";
}
.fi-rr-envelope-marker:before {
    content: "\f568";
}
.fi-rr-envelope-open-dollar:before {
    content: "\f569";
}
.fi-rr-envelope-open-text:before {
    content: "\f56a";
}
.fi-rr-envelope-open:before {
    content: "\f56b";
}
.fi-rr-envelope-plus:before {
    content: "\f56c";
}
.fi-rr-envelope:before {
    content: "\f56d";
}
.fi-rr-envelopes:before {
    content: "\f56e";
}
.fi-rr-equality:before {
    content: "\f56f";
}
.fi-rr-equals:before {
    content: "\f570";
}
.fi-rr-eraser:before {
    content: "\f571";
}
.fi-rr-escalator:before {
    content: "\f572";
}
.fi-rr-ethernet:before {
    content: "\f573";
}
.fi-rr-euro:before {
    content: "\f574";
}
.fi-rr-exchange-alt:before {
    content: "\f575";
}
.fi-rr-exchange-cryptocurrency:before {
    content: "\f576";
}
.fi-rr-exchange:before {
    content: "\f577";
}
.fi-rr-exclamation:before {
    content: "\f578";
}
.fi-rr-exit-alt:before {
    content: "\f579";
}
.fi-rr-exit:before {
    content: "\f57a";
}
.fi-rr-expand-arrows-alt:before {
    content: "\f57b";
}
.fi-rr-expand-arrows:before {
    content: "\f57c";
}
.fi-rr-expand:before {
    content: "\f57d";
}
.fi-rr-expense-bill:before {
    content: "\f57e";
}
.fi-rr-expense:before {
    content: "\f57f";
}
.fi-rr-external-hard-drive:before {
    content: "\f580";
}
.fi-rr-external-world:before {
    content: "\f581";
}
.fi-rr-eye-crossed:before {
    content: "\f582";
}
.fi-rr-eye-dropper-half:before {
    content: "\f583";
}
.fi-rr-eye-dropper:before {
    content: "\f584";
}
.fi-rr-eye:before {
    content: "\f585";
}
.fi-rr-eyes:before {
    content: "\f586";
}
.fi-rr-f:before {
    content: "\f587";
}
.fi-rr-fabric:before {
    content: "\f588";
}
.fi-rr-face-angry-horns:before {
    content: "\f589";
}
.fi-rr-face-anguished:before {
    content: "\f58a";
}
.fi-rr-face-anxious-sweat:before {
    content: "\f58b";
}
.fi-rr-face-astonished:before {
    content: "\f58c";
}
.fi-rr-face-awesome:before {
    content: "\f58d";
}
.fi-rr-face-beam-hand-over-mouth:before {
    content: "\f58e";
}
.fi-rr-face-confounded:before {
    content: "\f58f";
}
.fi-rr-face-confused:before {
    content: "\f590";
}
.fi-rr-face-cowboy-hat:before {
    content: "\f591";
}
.fi-rr-face-disappointed:before {
    content: "\f592";
}
.fi-rr-face-disguise:before {
    content: "\f593";
}
.fi-rr-face-downcast-sweat:before {
    content: "\f594";
}
.fi-rr-face-drooling:before {
    content: "\f595";
}
.fi-rr-face-explode:before {
    content: "\f596";
}
.fi-rr-face-expressionless:before {
    content: "\f597";
}
.fi-rr-face-eyes-xmarks:before {
    content: "\f598";
}
.fi-rr-face-fearful:before {
    content: "\f599";
}
.fi-rr-face-glasses:before {
    content: "\f59a";
}
.fi-rr-face-grin-tongue-wink:before {
    content: "\f59b";
}
.fi-rr-face-hand-yawn:before {
    content: "\f59c";
}
.fi-rr-face-head-bandage:before {
    content: "\f59d";
}
.fi-rr-face-hushed:before {
    content: "\f59e";
}
.fi-rr-face-icicles:before {
    content: "\f59f";
}
.fi-rr-face-lying:before {
    content: "\f5a0";
}
.fi-rr-face-mask:before {
    content: "\f5a1";
}
.fi-rr-face-monocle:before {
    content: "\f5a2";
}
.fi-rr-face-nauseated:before {
    content: "\f5a3";
}
.fi-rr-face-nose-steam:before {
    content: "\f5a4";
}
.fi-rr-face-party:before {
    content: "\f5a5";
}
.fi-rr-face-pensive:before {
    content: "\f5a6";
}
.fi-rr-face-persevering:before {
    content: "\f5a7";
}
.fi-rr-face-pleading:before {
    content: "\f5a8";
}
.fi-rr-face-raised-eyebrow:before {
    content: "\f5a9";
}
.fi-rr-face-relieved:before {
    content: "\f5aa";
}
.fi-rr-face-sad-sweat:before {
    content: "\f5ab";
}
.fi-rr-face-scream:before {
    content: "\f5ac";
}
.fi-rr-face-shush:before {
    content: "\f5ad";
}
.fi-rr-face-sleeping:before {
    content: "\f5ae";
}
.fi-rr-face-sleepy:before {
    content: "\f5af";
}
.fi-rr-face-smile-halo:before {
    content: "\f5b0";
}
.fi-rr-face-smile-hearts:before {
    content: "\f5b1";
}
.fi-rr-face-smile-horns:before {
    content: "\f5b2";
}
.fi-rr-face-smile-tear:before {
    content: "\f5b3";
}
.fi-rr-face-smile-tongue:before {
    content: "\f5b4";
}
.fi-rr-face-smile-upside-down:before {
    content: "\f5b5";
}
.fi-rr-face-smiling-hands:before {
    content: "\f5b6";
}
.fi-rr-face-smirking:before {
    content: "\f5b7";
}
.fi-rr-face-sunglasses-alt:before {
    content: "\f5b8";
}
.fi-rr-face-sunglasses:before {
    content: "\f5b9";
}
.fi-rr-face-swear:before {
    content: "\f5ba";
}
.fi-rr-face-thermometer:before {
    content: "\f5bb";
}
.fi-rr-face-thinking:before {
    content: "\f5bc";
}
.fi-rr-face-tissue:before {
    content: "\f5bd";
}
.fi-rr-face-tongue-money:before {
    content: "\f5be";
}
.fi-rr-face-tongue-sweat:before {
    content: "\f5bf";
}
.fi-rr-face-unamused:before {
    content: "\f5c0";
}
.fi-rr-face-viewfinder:before {
    content: "\f5c1";
}
.fi-rr-face-vomit:before {
    content: "\f5c2";
}
.fi-rr-face-weary:before {
    content: "\f5c3";
}
.fi-rr-face-woozy:before {
    content: "\f5c4";
}
.fi-rr-face-worried:before {
    content: "\f5c5";
}
.fi-rr-face-zany:before {
    content: "\f5c6";
}
.fi-rr-face-zipper:before {
    content: "\f5c7";
}
.fi-rr-fail:before {
    content: "\f5c8";
}
.fi-rr-falafel:before {
    content: "\f5c9";
}
.fi-rr-family-dress:before {
    content: "\f5ca";
}
.fi-rr-family-pants:before {
    content: "\f5cb";
}
.fi-rr-family:before {
    content: "\f5cc";
}
.fi-rr-fan-table:before {
    content: "\f5cd";
}
.fi-rr-fan:before {
    content: "\f5ce";
}
.fi-rr-farm:before {
    content: "\f5cf";
}
.fi-rr-faucet-drip:before {
    content: "\f5d0";
}
.fi-rr-faucet:before {
    content: "\f5d1";
}
.fi-rr-fax:before {
    content: "\f5d2";
}
.fi-rr-feather-pointed:before {
    content: "\f5d3";
}
.fi-rr-feather:before {
    content: "\f5d4";
}
.fi-rr-features-alt:before {
    content: "\f5d5";
}
.fi-rr-features:before {
    content: "\f5d6";
}
.fi-rr-fee-receipt:before {
    content: "\f5d7";
}
.fi-rr-fee:before {
    content: "\f5d8";
}
.fi-rr-feedback-alt:before {
    content: "\f5d9";
}
.fi-rr-feedback-hand:before {
    content: "\f5da";
}
.fi-rr-feedback-review:before {
    content: "\f5db";
}
.fi-rr-feedback:before {
    content: "\f5dc";
}
.fi-rr-fence:before {
    content: "\f5dd";
}
.fi-rr-ferris-wheel:before {
    content: "\f5de";
}
.fi-rr-fighter-jet:before {
    content: "\f5df";
}
.fi-rr-file-ai:before {
    content: "\f5e0";
}
.fi-rr-file-audio:before {
    content: "\f5e1";
}
.fi-rr-file-binary:before {
    content: "\f5e2";
}
.fi-rr-file-chart-line:before {
    content: "\f5e3";
}
.fi-rr-file-chart-pie:before {
    content: "\f5e4";
}
.fi-rr-file-circle-info:before {
    content: "\f5e5";
}
.fi-rr-file-code:before {
    content: "\f5e6";
}
.fi-rr-file-csv:before {
    content: "\f5e7";
}
.fi-rr-file-download:before {
    content: "\f5e8";
}
.fi-rr-file-edit:before {
    content: "\f5e9";
}
.fi-rr-file-eps:before {
    content: "\f5ea";
}
.fi-rr-file-excel:before {
    content: "\f5eb";
}
.fi-rr-file-exclamation:before {
    content: "\f5ec";
}
.fi-rr-file-export:before {
    content: "\f5ed";
}
.fi-rr-file-image:before {
    content: "\f5ee";
}
.fi-rr-file-import:before {
    content: "\f5ef";
}
.fi-rr-file-invoice-dollar:before {
    content: "\f5f0";
}
.fi-rr-file-invoice:before {
    content: "\f5f1";
}
.fi-rr-file-medical-alt:before {
    content: "\f5f2";
}
.fi-rr-file-medical:before {
    content: "\f5f3";
}
.fi-rr-file-minus:before {
    content: "\f5f4";
}
.fi-rr-file-pdf:before {
    content: "\f5f5";
}
.fi-rr-file-powerpoint:before {
    content: "\f5f6";
}
.fi-rr-file-prescription:before {
    content: "\f5f7";
}
.fi-rr-file-psd:before {
    content: "\f5f8";
}
.fi-rr-file-signature:before {
    content: "\f5f9";
}
.fi-rr-file-spreadsheet:before {
    content: "\f5fa";
}
.fi-rr-file-upload:before {
    content: "\f5fb";
}
.fi-rr-file-user:before {
    content: "\f5fc";
}
.fi-rr-file-video:before {
    content: "\f5fd";
}
.fi-rr-file-word:before {
    content: "\f5fe";
}
.fi-rr-file-zipper:before {
    content: "\f5ff";
}
.fi-rr-file:before {
    content: "\f600";
}
.fi-rr-files-medical:before {
    content: "\f601";
}
.fi-rr-fill:before {
    content: "\f602";
}
.fi-rr-film-canister:before {
    content: "\f603";
}
.fi-rr-film-slash:before {
    content: "\f604";
}
.fi-rr-film:before {
    content: "\f605";
}
.fi-rr-films:before {
    content: "\f606";
}
.fi-rr-filter-slash:before {
    content: "\f607";
}
.fi-rr-filter:before {
    content: "\f608";
}
.fi-rr-filters:before {
    content: "\f609";
}
.fi-rr-fingerprint:before {
    content: "\f60a";
}
.fi-rr-fire-burner:before {
    content: "\f60b";
}
.fi-rr-fire-extinguisher:before {
    content: "\f60c";
}
.fi-rr-fire-flame-curved:before {
    content: "\f60d";
}
.fi-rr-fire-flame-simple:before {
    content: "\f60e";
}
.fi-rr-fire-hydrant:before {
    content: "\f60f";
}
.fi-rr-fire-smoke:before {
    content: "\f610";
}
.fi-rr-fireplace:before {
    content: "\f611";
}
.fi-rr-first-award:before {
    content: "\f612";
}
.fi-rr-first-laurel:before {
    content: "\f613";
}
.fi-rr-first-medal:before {
    content: "\f614";
}
.fi-rr-first:before {
    content: "\f615";
}
.fi-rr-fish-bones:before {
    content: "\f616";
}
.fi-rr-fish-cooked:before {
    content: "\f617";
}
.fi-rr-fish:before {
    content: "\f618";
}
.fi-rr-fishing-rod:before {
    content: "\f619";
}
.fi-rr-flag-alt:before {
    content: "\f61a";
}
.fi-rr-flag-checkered:before {
    content: "\f61b";
}
.fi-rr-flag-usa:before {
    content: "\f61c";
}
.fi-rr-flag:before {
    content: "\f61d";
}
.fi-rr-flame:before {
    content: "\f61e";
}
.fi-rr-flashlight:before {
    content: "\f61f";
}
.fi-rr-flask-poison:before {
    content: "\f620";
}
.fi-rr-flask-potion:before {
    content: "\f621";
}
.fi-rr-flask:before {
    content: "\f622";
}
.fi-rr-flatbread-stuffed:before {
    content: "\f623";
}
.fi-rr-flatbread:before {
    content: "\f624";
}
.fi-rr-flip-horizontal:before {
    content: "\f625";
}
.fi-rr-floor-alt:before {
    content: "\f626";
}
.fi-rr-floor-layer:before {
    content: "\f627";
}
.fi-rr-floor:before {
    content: "\f628";
}
.fi-rr-floppy-disk-circle-arrow-right:before {
    content: "\f629";
}
.fi-rr-floppy-disk-circle-xmark:before {
    content: "\f62a";
}
.fi-rr-floppy-disk-pen:before {
    content: "\f62b";
}
.fi-rr-floppy-disks:before {
    content: "\f62c";
}
.fi-rr-florin-sign:before {
    content: "\f62d";
}
.fi-rr-flower-bouquet:before {
    content: "\f62e";
}
.fi-rr-flower-butterfly:before {
    content: "\f62f";
}
.fi-rr-flower-daffodil:before {
    content: "\f630";
}
.fi-rr-flower-tulip:before {
    content: "\f631";
}
.fi-rr-flower:before {
    content: "\f632";
}
.fi-rr-flushed:before {
    content: "\f633";
}
.fi-rr-flute:before {
    content: "\f634";
}
.fi-rr-flux-capacitor:before {
    content: "\f635";
}
.fi-rr-fly-insect:before {
    content: "\f636";
}
.fi-rr-flying-disc:before {
    content: "\f637";
}
.fi-rr-fog:before {
    content: "\f638";
}
.fi-rr-folder-download:before {
    content: "\f639";
}
.fi-rr-folder-minus:before {
    content: "\f63a";
}
.fi-rr-folder-open:before {
    content: "\f63b";
}
.fi-rr-folder-times:before {
    content: "\f63c";
}
.fi-rr-folder-tree:before {
    content: "\f63d";
}
.fi-rr-folder-upload:before {
    content: "\f63e";
}
.fi-rr-folder-xmark:before {
    content: "\f63f";
}
.fi-rr-folder:before {
    content: "\f640";
}
.fi-rr-folders:before {
    content: "\f641";
}
.fi-rr-follow-folder:before {
    content: "\f642";
}
.fi-rr-followcollection:before {
    content: "\f643";
}
.fi-rr-following:before {
    content: "\f644";
}
.fi-rr-fondue-pot:before {
    content: "\f645";
}
.fi-rr-football:before {
    content: "\f646";
}
.fi-rr-fork:before {
    content: "\f647";
}
.fi-rr-forklift:before {
    content: "\f648";
}
.fi-rr-form:before {
    content: "\f649";
}
.fi-rr-fort:before {
    content: "\f64a";
}
.fi-rr-forward-fast:before {
    content: "\f64b";
}
.fi-rr-forward:before {
    content: "\f64c";
}
.fi-rr-fox:before {
    content: "\f64d";
}
.fi-rr-frame:before {
    content: "\f64e";
}
.fi-rr-franc-sign:before {
    content: "\f64f";
}
.fi-rr-french-fries:before {
    content: "\f650";
}
.fi-rr-friday:before {
    content: "\f651";
}
.fi-rr-frog:before {
    content: "\f652";
}
.fi-rr-frown:before {
    content: "\f653";
}
.fi-rr-ftp:before {
    content: "\f654";
}
.fi-rr-function-process:before {
    content: "\f655";
}
.fi-rr-funnel-dollar:before {
    content: "\f656";
}
.fi-rr-g:before {
    content: "\f657";
}
.fi-rr-galaxy-alt:before {
    content: "\f658";
}
.fi-rr-galaxy-planet:before {
    content: "\f659";
}
.fi-rr-galaxy-star:before {
    content: "\f65a";
}
.fi-rr-galaxy:before {
    content: "\f65b";
}
.fi-rr-gallery-thumbnails:before {
    content: "\f65c";
}
.fi-rr-gallery:before {
    content: "\f65d";
}
.fi-rr-game-board-alt:before {
    content: "\f65e";
}
.fi-rr-gamepad:before {
    content: "\f65f";
}
.fi-rr-garage-car:before {
    content: "\f660";
}
.fi-rr-garage-open:before {
    content: "\f661";
}
.fi-rr-garage:before {
    content: "\f662";
}
.fi-rr-garlic-alt:before {
    content: "\f663";
}
.fi-rr-garlic:before {
    content: "\f664";
}
.fi-rr-gas-pump-alt:before {
    content: "\f665";
}
.fi-rr-gas-pump-slash:before {
    content: "\f666";
}
.fi-rr-gas-pump:before {
    content: "\f667";
}
.fi-rr-gauge-circle-bolt:before {
    content: "\f668";
}
.fi-rr-gauge-circle-minus:before {
    content: "\f669";
}
.fi-rr-gauge-circle-plus:before {
    content: "\f66a";
}
.fi-rr-gavel:before {
    content: "\f66b";
}
.fi-rr-gears:before {
    content: "\f66c";
}
.fi-rr-gem:before {
    content: "\f66d";
}
.fi-rr-ghost:before {
    content: "\f66e";
}
.fi-rr-gif-square:before {
    content: "\f66f";
}
.fi-rr-gif:before {
    content: "\f670";
}
.fi-rr-gift-card:before {
    content: "\f671";
}
.fi-rr-gift:before {
    content: "\f672";
}
.fi-rr-gifts:before {
    content: "\f673";
}
.fi-rr-gingerbread-man:before {
    content: "\f674";
}
.fi-rr-glass-champagne:before {
    content: "\f675";
}
.fi-rr-glass-cheers:before {
    content: "\f676";
}
.fi-rr-glass-citrus:before {
    content: "\f677";
}
.fi-rr-glass-empty:before {
    content: "\f678";
}
.fi-rr-glass-half:before {
    content: "\f679";
}
.fi-rr-glass-water-droplet:before {
    content: "\f67a";
}
.fi-rr-glass-whiskey-rocks:before {
    content: "\f67b";
}
.fi-rr-glass-whiskey:before {
    content: "\f67c";
}
.fi-rr-glass:before {
    content: "\f67d";
}
.fi-rr-glasses:before {
    content: "\f67e";
}
.fi-rr-globe-alt:before {
    content: "\f67f";
}
.fi-rr-globe-snow:before {
    content: "\f680";
}
.fi-rr-globe:before {
    content: "\f681";
}
.fi-rr-goal-net:before {
    content: "\f682";
}
.fi-rr-golf-ball:before {
    content: "\f683";
}
.fi-rr-golf:before {
    content: "\f684";
}
.fi-rr-gopuram:before {
    content: "\f685";
}
.fi-rr-government-flag:before {
    content: "\f686";
}
.fi-rr-government-user:before {
    content: "\f687";
}
.fi-rr-graduation-cap:before {
    content: "\f688";
}
.fi-rr-gramophone:before {
    content: "\f689";
}
.fi-rr-grape:before {
    content: "\f68a";
}
.fi-rr-graph-curve:before {
    content: "\f68b";
}
.fi-rr-graphic-style:before {
    content: "\f68c";
}
.fi-rr-graphic-tablet:before {
    content: "\f68d";
}
.fi-rr-grate-droplet:before {
    content: "\f68e";
}
.fi-rr-grate:before {
    content: "\f68f";
}
.fi-rr-greater-than-equal:before {
    content: "\f690";
}
.fi-rr-greater-than:before {
    content: "\f691";
}
.fi-rr-grid-alt:before {
    content: "\f692";
}
.fi-rr-grid-dividers:before {
    content: "\f693";
}
.fi-rr-grid:before {
    content: "\f694";
}
.fi-rr-grill-hot-alt:before {
    content: "\f695";
}
.fi-rr-grill:before {
    content: "\f696";
}
.fi-rr-grimace:before {
    content: "\f697";
}
.fi-rr-grin-alt:before {
    content: "\f698";
}
.fi-rr-grin-beam-sweat:before {
    content: "\f699";
}
.fi-rr-grin-beam:before {
    content: "\f69a";
}
.fi-rr-grin-hearts:before {
    content: "\f69b";
}
.fi-rr-grin-squint-tears:before {
    content: "\f69c";
}
.fi-rr-grin-squint:before {
    content: "\f69d";
}
.fi-rr-grin-stars:before {
    content: "\f69e";
}
.fi-rr-grin-tears:before {
    content: "\f69f";
}
.fi-rr-grin-tongue-squint:before {
    content: "\f6a0";
}
.fi-rr-grin-tongue-wink:before {
    content: "\f6a1";
}
.fi-rr-grin-tongue:before {
    content: "\f6a2";
}
.fi-rr-grin-wink:before {
    content: "\f6a3";
}
.fi-rr-grin:before {
    content: "\f6a4";
}
.fi-rr-grip-dots-vertical:before {
    content: "\f6a5";
}
.fi-rr-grip-dots:before {
    content: "\f6a6";
}
.fi-rr-grip-horizontal:before {
    content: "\f6a7";
}
.fi-rr-grip-lines-vertical:before {
    content: "\f6a8";
}
.fi-rr-grip-lines:before {
    content: "\f6a9";
}
.fi-rr-grip-vertical:before {
    content: "\f6aa";
}
.fi-rr-guarani-sign:before {
    content: "\f6ab";
}
.fi-rr-guide:before {
    content: "\f6ac";
}
.fi-rr-guitar-electric:before {
    content: "\f6ad";
}
.fi-rr-guitar:before {
    content: "\f6ae";
}
.fi-rr-guitars:before {
    content: "\f6af";
}
.fi-rr-gun-squirt:before {
    content: "\f6b0";
}
.fi-rr-gym:before {
    content: "\f6b1";
}
.fi-rr-h-square:before {
    content: "\f6b2";
}
.fi-rr-h:before {
    content: "\f6b3";
}
.fi-rr-h1:before {
    content: "\f6b4";
}
.fi-rr-h2:before {
    content: "\f6b5";
}
.fi-rr-h3:before {
    content: "\f6b6";
}
.fi-rr-h4:before {
    content: "\f6b7";
}
.fi-rr-hair-clipper:before {
    content: "\f6b8";
}
.fi-rr-hamburger-soda:before {
    content: "\f6b9";
}
.fi-rr-hamburger:before {
    content: "\f6ba";
}
.fi-rr-hammer-crash:before {
    content: "\f6bb";
}
.fi-rr-hammer-war:before {
    content: "\f6bc";
}
.fi-rr-hammer:before {
    content: "\f6bd";
}
.fi-rr-hamsa:before {
    content: "\f6be";
}
.fi-rr-hand-back-fist:before {
    content: "\f6bf";
}
.fi-rr-hand-back-point-down:before {
    content: "\f6c0";
}
.fi-rr-hand-back-point-left:before {
    content: "\f6c1";
}
.fi-rr-hand-back-point-ribbon:before {
    content: "\f6c2";
}
.fi-rr-hand-back-point-right:before {
    content: "\f6c3";
}
.fi-rr-hand-bill:before {
    content: "\f6c4";
}
.fi-rr-hand-dots:before {
    content: "\f6c5";
}
.fi-rr-hand-fingers-crossed:before {
    content: "\f6c6";
}
.fi-rr-hand-fist:before {
    content: "\f6c7";
}
.fi-rr-hand-heart:before {
    content: "\f6c8";
}
.fi-rr-hand-holding-box:before {
    content: "\f6c9";
}
.fi-rr-hand-holding-droplet:before {
    content: "\f6ca";
}
.fi-rr-hand-holding-heart:before {
    content: "\f6cb";
}
.fi-rr-hand-holding-magic:before {
    content: "\f6cc";
}
.fi-rr-hand-holding-medical:before {
    content: "\f6cd";
}
.fi-rr-hand-holding-seeding:before {
    content: "\f6ce";
}
.fi-rr-hand-holding-skull:before {
    content: "\f6cf";
}
.fi-rr-hand-holding-usd:before {
    content: "\f6d0";
}
.fi-rr-hand-holding-water:before {
    content: "\f6d1";
}
.fi-rr-hand-horns:before {
    content: "\f6d2";
}
.fi-rr-hand-lizard:before {
    content: "\f6d3";
}
.fi-rr-hand-love:before {
    content: "\f6d4";
}
.fi-rr-hand-middle-finger:before {
    content: "\f6d5";
}
.fi-rr-hand-paper:before {
    content: "\f6d6";
}
.fi-rr-hand-peace:before {
    content: "\f6d7";
}
.fi-rr-hand-point-ribbon:before {
    content: "\f6d8";
}
.fi-rr-hand-scissors:before {
    content: "\f6d9";
}
.fi-rr-hand-sparkles:before {
    content: "\f6da";
}
.fi-rr-hand-spock:before {
    content: "\f6db";
}
.fi-rr-hand-wave:before {
    content: "\f6dc";
}
.fi-rr-hand:before {
    content: "\f6dd";
}
.fi-rr-handmade:before {
    content: "\f6de";
}
.fi-rr-hands-bubbles:before {
    content: "\f6df";
}
.fi-rr-hands-clapping:before {
    content: "\f6e0";
}
.fi-rr-hands-heart:before {
    content: "\f6e1";
}
.fi-rr-hands-holding-diamond:before {
    content: "\f6e2";
}
.fi-rr-hands-holding:before {
    content: "\f6e3";
}
.fi-rr-hands-usd:before {
    content: "\f6e4";
}
.fi-rr-handshake-angle:before {
    content: "\f6e5";
}
.fi-rr-handshake-simple-slash:before {
    content: "\f6e6";
}
.fi-rr-handshake:before {
    content: "\f6e7";
}
.fi-rr-hard-hat:before {
    content: "\f6e8";
}
.fi-rr-hashtag-lock:before {
    content: "\f6e9";
}
.fi-rr-hastag:before {
    content: "\f6ea";
}
.fi-rr-hat-birthday:before {
    content: "\f6eb";
}
.fi-rr-hat-chef:before {
    content: "\f6ec";
}
.fi-rr-hat-cowboy-side:before {
    content: "\f6ed";
}
.fi-rr-hat-cowboy:before {
    content: "\f6ee";
}
.fi-rr-hat-santa:before {
    content: "\f6ef";
}
.fi-rr-hat-winter:before {
    content: "\f6f0";
}
.fi-rr-hat-witch:before {
    content: "\f6f1";
}
.fi-rr-hat-wizard:before {
    content: "\f6f2";
}
.fi-rr-hdd:before {
    content: "\f6f3";
}
.fi-rr-head-side-brain:before {
    content: "\f6f4";
}
.fi-rr-head-side-cough-slash:before {
    content: "\f6f5";
}
.fi-rr-head-side-cough:before {
    content: "\f6f6";
}
.fi-rr-head-side-headphones:before {
    content: "\f6f7";
}
.fi-rr-head-side-heart:before {
    content: "\f6f8";
}
.fi-rr-head-side-mask:before {
    content: "\f6f9";
}
.fi-rr-head-side-medical:before {
    content: "\f6fa";
}
.fi-rr-head-side-thinking:before {
    content: "\f6fb";
}
.fi-rr-head-side-virus:before {
    content: "\f6fc";
}
.fi-rr-head-side:before {
    content: "\f6fd";
}
.fi-rr-head-vr:before {
    content: "\f6fe";
}
.fi-rr-heading:before {
    content: "\f6ff";
}
.fi-rr-headphones:before {
    content: "\f700";
}
.fi-rr-headset:before {
    content: "\f701";
}
.fi-rr-heart-arrow:before {
    content: "\f702";
}
.fi-rr-heart-crack:before {
    content: "\f703";
}
.fi-rr-heart-half-stroke:before {
    content: "\f704";
}
.fi-rr-heart-half:before {
    content: "\f705";
}
.fi-rr-heart-rate:before {
    content: "\f706";
}
.fi-rr-heart:before {
    content: "\f707";
}
.fi-rr-heat:before {
    content: "\f708";
}
.fi-rr-helicopter-side:before {
    content: "\f709";
}
.fi-rr-helmet-battle:before {
    content: "\f70a";
}
.fi-rr-hexagon-check:before {
    content: "\f70b";
}
.fi-rr-hexagon-divide:before {
    content: "\f70c";
}
.fi-rr-hexagon-exclamation:before {
    content: "\f70d";
}
.fi-rr-hexagon:before {
    content: "\f70e";
}
.fi-rr-high-definition:before {
    content: "\f70f";
}
.fi-rr-highlighter-line:before {
    content: "\f710";
}
.fi-rr-highlighter:before {
    content: "\f711";
}
.fi-rr-hiking:before {
    content: "\f712";
}
.fi-rr-hippo:before {
    content: "\f713";
}
.fi-rr-hockey-puck:before {
    content: "\f714";
}
.fi-rr-hockey-stick-puck:before {
    content: "\f715";
}
.fi-rr-hockey-sticks:before {
    content: "\f716";
}
.fi-rr-holly-berry:before {
    content: "\f717";
}
.fi-rr-home-heart:before {
    content: "\f718";
}
.fi-rr-home-location-alt:before {
    content: "\f719";
}
.fi-rr-home-location:before {
    content: "\f71a";
}
.fi-rr-home:before {
    content: "\f71b";
}
.fi-rr-honey-pot:before {
    content: "\f71c";
}
.fi-rr-hood-cloak:before {
    content: "\f71d";
}
.fi-rr-horizontal-rule:before {
    content: "\f71e";
}
.fi-rr-horse-head:before {
    content: "\f71f";
}
.fi-rr-horse-saddle:before {
    content: "\f720";
}
.fi-rr-horse:before {
    content: "\f721";
}
.fi-rr-hose-reel:before {
    content: "\f722";
}
.fi-rr-hose:before {
    content: "\f723";
}
.fi-rr-hospital-symbol:before {
    content: "\f724";
}
.fi-rr-hospital-user:before {
    content: "\f725";
}
.fi-rr-hospital:before {
    content: "\f726";
}
.fi-rr-hospitals:before {
    content: "\f727";
}
.fi-rr-hot-tub:before {
    content: "\f728";
}
.fi-rr-hotdog:before {
    content: "\f729";
}
.fi-rr-hotel:before {
    content: "\f72a";
}
.fi-rr-hourglass-end:before {
    content: "\f72b";
}
.fi-rr-hourglass-start:before {
    content: "\f72c";
}
.fi-rr-hourglass:before {
    content: "\f72d";
}
.fi-rr-house-blank:before {
    content: "\f72e";
}
.fi-rr-house-building:before {
    content: "\f72f";
}
.fi-rr-house-chimney-blank:before {
    content: "\f730";
}
.fi-rr-house-chimney-crack:before {
    content: "\f731";
}
.fi-rr-house-chimney-heart:before {
    content: "\f732";
}
.fi-rr-house-chimney-medical:before {
    content: "\f733";
}
.fi-rr-house-chimney-user:before {
    content: "\f734";
}
.fi-rr-house-chimney-window:before {
    content: "\f735";
}
.fi-rr-house-chimney:before {
    content: "\f736";
}
.fi-rr-house-crack:before {
    content: "\f737";
}
.fi-rr-house-day:before {
    content: "\f738";
}
.fi-rr-house-flood:before {
    content: "\f739";
}
.fi-rr-house-laptop:before {
    content: "\f73a";
}
.fi-rr-house-leave:before {
    content: "\f73b";
}
.fi-rr-house-medical:before {
    content: "\f73c";
}
.fi-rr-house-night:before {
    content: "\f73d";
}
.fi-rr-house-return:before {
    content: "\f73e";
}
.fi-rr-house-signal:before {
    content: "\f73f";
}
.fi-rr-house-tree:before {
    content: "\f740";
}
.fi-rr-house-tsunami:before {
    content: "\f741";
}
.fi-rr-house-turret:before {
    content: "\f742";
}
.fi-rr-house-user:before {
    content: "\f743";
}
.fi-rr-house-window:before {
    content: "\f744";
}
.fi-rr-hr-group:before {
    content: "\f745";
}
.fi-rr-hr-person:before {
    content: "\f746";
}
.fi-rr-hr:before {
    content: "\f747";
}
.fi-rr-hryvnia:before {
    content: "\f748";
}
.fi-rr-humidity:before {
    content: "\f749";
}
.fi-rr-hundred-points:before {
    content: "\f74a";
}
.fi-rr-hurricane:before {
    content: "\f74b";
}
.fi-rr-i:before {
    content: "\f74c";
}
.fi-rr-ice-cream:before {
    content: "\f74d";
}
.fi-rr-ice-skate:before {
    content: "\f74e";
}
.fi-rr-icicles:before {
    content: "\f74f";
}
.fi-rr-icon-star:before {
    content: "\f750";
}
.fi-rr-id-badge:before {
    content: "\f751";
}
.fi-rr-id-card-clip-alt:before {
    content: "\f752";
}
.fi-rr-igloo:before {
    content: "\f753";
}
.fi-rr-image-slash:before {
    content: "\f754";
}
.fi-rr-images-user:before {
    content: "\f755";
}
.fi-rr-images:before {
    content: "\f756";
}
.fi-rr-inbox-in:before {
    content: "\f757";
}
.fi-rr-inbox-out:before {
    content: "\f758";
}
.fi-rr-inbox:before {
    content: "\f759";
}
.fi-rr-inboxes:before {
    content: "\f75a";
}
.fi-rr-incognito:before {
    content: "\f75b";
}
.fi-rr-indent:before {
    content: "\f75c";
}
.fi-rr-indian-rupee-sign:before {
    content: "\f75d";
}
.fi-rr-industry-alt:before {
    content: "\f75e";
}
.fi-rr-industry-windows:before {
    content: "\f75f";
}
.fi-rr-infinity:before {
    content: "\f760";
}
.fi-rr-info:before {
    content: "\f761";
}
.fi-rr-information:before {
    content: "\f762";
}
.fi-rr-inhaler:before {
    content: "\f763";
}
.fi-rr-input-numeric:before {
    content: "\f764";
}
.fi-rr-input-pipe:before {
    content: "\f765";
}
.fi-rr-input-text:before {
    content: "\f766";
}
.fi-rr-insert-alt:before {
    content: "\f767";
}
.fi-rr-insert-arrows:before {
    content: "\f768";
}
.fi-rr-insert-square:before {
    content: "\f769";
}
.fi-rr-insert:before {
    content: "\f76a";
}
.fi-rr-insight-alt:before {
    content: "\f76b";
}
.fi-rr-insight-head:before {
    content: "\f76c";
}
.fi-rr-insight:before {
    content: "\f76d";
}
.fi-rr-integral:before {
    content: "\f76e";
}
.fi-rr-interactive:before {
    content: "\f76f";
}
.fi-rr-interlining:before {
    content: "\f770";
}
.fi-rr-interrogation:before {
    content: "\f771";
}
.fi-rr-intersection:before {
    content: "\f772";
}
.fi-rr-inventory-alt:before {
    content: "\f773";
}
.fi-rr-invest:before {
    content: "\f774";
}
.fi-rr-investment:before {
    content: "\f775";
}
.fi-rr-invite-alt:before {
    content: "\f776";
}
.fi-rr-invite:before {
    content: "\f777";
}
.fi-rr-island-tropical:before {
    content: "\f778";
}
.fi-rr-issue-loupe:before {
    content: "\f779";
}
.fi-rr-it-alt:before {
    content: "\f77a";
}
.fi-rr-it-computer:before {
    content: "\f77b";
}
.fi-rr-it:before {
    content: "\f77c";
}
.fi-rr-italian-lira-sign:before {
    content: "\f77d";
}
.fi-rr-italic:before {
    content: "\f77e";
}
.fi-rr-j:before {
    content: "\f77f";
}
.fi-rr-jam:before {
    content: "\f780";
}
.fi-rr-jar-alt:before {
    content: "\f781";
}
.fi-rr-jar-wheat:before {
    content: "\f782";
}
.fi-rr-javascript:before {
    content: "\f783";
}
.fi-rr-joint:before {
    content: "\f784";
}
.fi-rr-journal-alt:before {
    content: "\f785";
}
.fi-rr-journal:before {
    content: "\f786";
}
.fi-rr-journey:before {
    content: "\f787";
}
.fi-rr-joystick:before {
    content: "\f788";
}
.fi-rr-jpg:before {
    content: "\f789";
}
.fi-rr-jug-alt:before {
    content: "\f78a";
}
.fi-rr-jug-bottle:before {
    content: "\f78b";
}
.fi-rr-jug:before {
    content: "\f78c";
}
.fi-rr-k:before {
    content: "\f78d";
}
.fi-rr-kaaba:before {
    content: "\f78e";
}
.fi-rr-kazoo:before {
    content: "\f78f";
}
.fi-rr-kerning:before {
    content: "\f790";
}
.fi-rr-key-skeleton-left-right:before {
    content: "\f791";
}
.fi-rr-key:before {
    content: "\f792";
}
.fi-rr-keyboard-brightness-low:before {
    content: "\f793";
}
.fi-rr-keyboard-brightness:before {
    content: "\f794";
}
.fi-rr-keyboard-down:before {
    content: "\f795";
}
.fi-rr-keyboard-left:before {
    content: "\f796";
}
.fi-rr-keyboard:before {
    content: "\f797";
}
.fi-rr-keynote:before {
    content: "\f798";
}
.fi-rr-kidneys:before {
    content: "\f799";
}
.fi-rr-kip-sign:before {
    content: "\f79a";
}
.fi-rr-kiss-beam:before {
    content: "\f79b";
}
.fi-rr-kiss-wink-heart:before {
    content: "\f79c";
}
.fi-rr-kiss:before {
    content: "\f79d";
}
.fi-rr-kite:before {
    content: "\f79e";
}
.fi-rr-kiwi-bird:before {
    content: "\f79f";
}
.fi-rr-kiwi-fruit:before {
    content: "\f7a0";
}
.fi-rr-knife-kitchen:before {
    content: "\f7a1";
}
.fi-rr-knife:before {
    content: "\f7a2";
}
.fi-rr-knitting:before {
    content: "\f7a3";
}
.fi-rr-kpi-evaluation:before {
    content: "\f7a4";
}
.fi-rr-kpi:before {
    content: "\f7a5";
}
.fi-rr-l:before {
    content: "\f7a6";
}
.fi-rr-label:before {
    content: "\f7a7";
}
.fi-rr-lacrosse-stick-ball:before {
    content: "\f7a8";
}
.fi-rr-lacrosse-stick:before {
    content: "\f7a9";
}
.fi-rr-lambda:before {
    content: "\f7aa";
}
.fi-rr-lamp-desk:before {
    content: "\f7ab";
}
.fi-rr-lamp-floor:before {
    content: "\f7ac";
}
.fi-rr-lamp-street:before {
    content: "\f7ad";
}
.fi-rr-lamp:before {
    content: "\f7ae";
}
.fi-rr-land-layer-location:before {
    content: "\f7af";
}
.fi-rr-land-layers:before {
    content: "\f7b0";
}
.fi-rr-land-location:before {
    content: "\f7b1";
}
.fi-rr-landmark-alt:before {
    content: "\f7b2";
}
.fi-rr-language:before {
    content: "\f7b3";
}
.fi-rr-laptop-arrow-down:before {
    content: "\f7b4";
}
.fi-rr-laptop-code:before {
    content: "\f7b5";
}
.fi-rr-laptop-medical:before {
    content: "\f7b6";
}
.fi-rr-laptop-mobile:before {
    content: "\f7b7";
}
.fi-rr-laptop-slash:before {
    content: "\f7b8";
}
.fi-rr-laptop:before {
    content: "\f7b9";
}
.fi-rr-lari-sign:before {
    content: "\f7ba";
}
.fi-rr-lasso-sparkles:before {
    content: "\f7bb";
}
.fi-rr-lasso:before {
    content: "\f7bc";
}
.fi-rr-laugh-beam:before {
    content: "\f7bd";
}
.fi-rr-laugh-squint:before {
    content: "\f7be";
}
.fi-rr-laugh-wink:before {
    content: "\f7bf";
}
.fi-rr-laugh:before {
    content: "\f7c0";
}
.fi-rr-lawyer-man:before {
    content: "\f7c1";
}
.fi-rr-lawyer-woman:before {
    content: "\f7c2";
}
.fi-rr-layer-minus:before {
    content: "\f7c3";
}
.fi-rr-layer-plus:before {
    content: "\f7c4";
}
.fi-rr-layers:before {
    content: "\f7c5";
}
.fi-rr-layout-fluid:before {
    content: "\f7c6";
}
.fi-rr-leader-alt:before {
    content: "\f7c7";
}
.fi-rr-leader:before {
    content: "\f7c8";
}
.fi-rr-leaderboard-alt:before {
    content: "\f7c9";
}
.fi-rr-leaderboard-trophy:before {
    content: "\f7ca";
}
.fi-rr-leaderboard:before {
    content: "\f7cb";
}
.fi-rr-leadership-alt:before {
    content: "\f7cc";
}
.fi-rr-leadership:before {
    content: "\f7cd";
}
.fi-rr-leaf-heart:before {
    content: "\f7ce";
}
.fi-rr-leaf-maple:before {
    content: "\f7cf";
}
.fi-rr-leaf-oak:before {
    content: "\f7d0";
}
.fi-rr-leaf:before {
    content: "\f7d1";
}
.fi-rr-leafy-green:before {
    content: "\f7d2";
}
.fi-rr-leave:before {
    content: "\f7d3";
}
.fi-rr-left:before {
    content: "\f7d4";
}
.fi-rr-legal:before {
    content: "\f7d5";
}
.fi-rr-lemon:before {
    content: "\f7d6";
}
.fi-rr-less-than-equal:before {
    content: "\f7d7";
}
.fi-rr-less-than:before {
    content: "\f7d8";
}
.fi-rr-letter-case:before {
    content: "\f7d9";
}
.fi-rr-lettuce:before {
    content: "\f7da";
}
.fi-rr-level-down-alt:before {
    content: "\f7db";
}
.fi-rr-level-down:before {
    content: "\f7dc";
}
.fi-rr-level-up-alt:before {
    content: "\f7dd";
}
.fi-rr-level-up:before {
    content: "\f7de";
}
.fi-rr-license:before {
    content: "\f7df";
}
.fi-rr-life-ring:before {
    content: "\f7e0";
}
.fi-rr-light-ceiling:before {
    content: "\f7e1";
}
.fi-rr-light-emergency-on:before {
    content: "\f7e2";
}
.fi-rr-light-emergency:before {
    content: "\f7e3";
}
.fi-rr-light-switch-off:before {
    content: "\f7e4";
}
.fi-rr-light-switch-on:before {
    content: "\f7e5";
}
.fi-rr-light-switch:before {
    content: "\f7e6";
}
.fi-rr-lightbulb-dollar:before {
    content: "\f7e7";
}
.fi-rr-lightbulb-exclamation:before {
    content: "\f7e8";
}
.fi-rr-lightbulb-head:before {
    content: "\f7e9";
}
.fi-rr-lightbulb-on:before {
    content: "\f7ea";
}
.fi-rr-lightbulb-question:before {
    content: "\f7eb";
}
.fi-rr-lightbulb-setting:before {
    content: "\f7ec";
}
.fi-rr-lightbulb-slash:before {
    content: "\f7ed";
}
.fi-rr-lights-holiday:before {
    content: "\f7ee";
}
.fi-rr-limit-speedometer:before {
    content: "\f7ef";
}
.fi-rr-line-width:before {
    content: "\f7f0";
}
.fi-rr-link-alt:before {
    content: "\f7f1";
}
.fi-rr-link-horizontal-slash:before {
    content: "\f7f2";
}
.fi-rr-link-horizontal:before {
    content: "\f7f3";
}
.fi-rr-link-slash-alt:before {
    content: "\f7f4";
}
.fi-rr-link-slash:before {
    content: "\f7f5";
}
.fi-rr-link:before {
    content: "\f7f6";
}
.fi-rr-lion-head:before {
    content: "\f7f7";
}
.fi-rr-lion:before {
    content: "\f7f8";
}
.fi-rr-lips:before {
    content: "\f7f9";
}
.fi-rr-lipstick:before {
    content: "\f7fa";
}
.fi-rr-lira-sign:before {
    content: "\f7fb";
}
.fi-rr-list-check:before {
    content: "\f7fc";
}
.fi-rr-list-dropdown:before {
    content: "\f7fd";
}
.fi-rr-list-music:before {
    content: "\f7fe";
}
.fi-rr-list-timeline:before {
    content: "\f7ff";
}
.fi-rr-list:before {
    content: "\f800";
}
.fi-rr-litecoin-sign:before {
    content: "\f801";
}
.fi-rr-live-alt:before {
    content: "\f802";
}
.fi-rr-live:before {
    content: "\f803";
}
.fi-rr-loading:before {
    content: "\f804";
}
.fi-rr-loan:before {
    content: "\f805";
}
.fi-rr-lobster:before {
    content: "\f806";
}
.fi-rr-location-alt:before {
    content: "\f807";
}
.fi-rr-location-arrow:before {
    content: "\f808";
}
.fi-rr-location-crosshairs-slash:before {
    content: "\f809";
}
.fi-rr-location-crosshairs:before {
    content: "\f80a";
}
.fi-rr-location-dot-slash:before {
    content: "\f80b";
}
.fi-rr-location-exclamation:before {
    content: "\f80c";
}
.fi-rr-lock-alt:before {
    content: "\f80d";
}
.fi-rr-lock-hashtag:before {
    content: "\f80e";
}
.fi-rr-lock-open-alt:before {
    content: "\f80f";
}
.fi-rr-lock:before {
    content: "\f810";
}
.fi-rr-locust:before {
    content: "\f811";
}
.fi-rr-loveseat:before {
    content: "\f812";
}
.fi-rr-low-vision:before {
    content: "\f813";
}
.fi-rr-luchador:before {
    content: "\f814";
}
.fi-rr-luggage-cart:before {
    content: "\f815";
}
.fi-rr-luggage-rolling:before {
    content: "\f816";
}
.fi-rr-lungs-virus:before {
    content: "\f817";
}
.fi-rr-lungs:before {
    content: "\f818";
}
.fi-rr-m:before {
    content: "\f819";
}
.fi-rr-mace:before {
    content: "\f81a";
}
.fi-rr-magic-wand:before {
    content: "\f81b";
}
.fi-rr-magnet-user:before {
    content: "\f81c";
}
.fi-rr-magnet:before {
    content: "\f81d";
}
.fi-rr-mailbox:before {
    content: "\f81e";
}
.fi-rr-makeup-brush:before {
    content: "\f81f";
}
.fi-rr-man-head:before {
    content: "\f820";
}
.fi-rr-man-scientist:before {
    content: "\f821";
}
.fi-rr-manat-sign:before {
    content: "\f822";
}
.fi-rr-mandolin:before {
    content: "\f823";
}
.fi-rr-mango:before {
    content: "\f824";
}
.fi-rr-manhole:before {
    content: "\f825";
}
.fi-rr-map-marker-check:before {
    content: "\f826";
}
.fi-rr-map-marker-cross:before {
    content: "\f827";
}
.fi-rr-map-marker-edit:before {
    content: "\f828";
}
.fi-rr-map-marker-home:before {
    content: "\f829";
}
.fi-rr-map-marker-minus:before {
    content: "\f82a";
}
.fi-rr-map-marker-plus:before {
    content: "\f82b";
}
.fi-rr-map-marker-question:before {
    content: "\f82c";
}
.fi-rr-map-marker-slash:before {
    content: "\f82d";
}
.fi-rr-map-marker-smile:before {
    content: "\f82e";
}
.fi-rr-map-marker:before {
    content: "\f82f";
}
.fi-rr-map-pin:before {
    content: "\f830";
}
.fi-rr-map:before {
    content: "\f831";
}
.fi-rr-marker-time:before {
    content: "\f832";
}
.fi-rr-marker:before {
    content: "\f833";
}
.fi-rr-marketplace-alt:before {
    content: "\f834";
}
.fi-rr-marketplace-store:before {
    content: "\f835";
}
.fi-rr-marketplace:before {
    content: "\f836";
}
.fi-rr-mars-double:before {
    content: "\f837";
}
.fi-rr-mars-stroke-right:before {
    content: "\f838";
}
.fi-rr-mars-stroke-up:before {
    content: "\f839";
}
.fi-rr-mars:before {
    content: "\f83a";
}
.fi-rr-martini-glass-citrus:before {
    content: "\f83b";
}
.fi-rr-martini-glass-empty:before {
    content: "\f83c";
}
.fi-rr-mask-carnival:before {
    content: "\f83d";
}
.fi-rr-mask-face:before {
    content: "\f83e";
}
.fi-rr-mask-snorkel:before {
    content: "\f83f";
}
.fi-rr-mask:before {
    content: "\f840";
}
.fi-rr-massage:before {
    content: "\f841";
}
.fi-rr-match-fire:before {
    content: "\f842";
}
.fi-rr-mattress-pillow:before {
    content: "\f843";
}
.fi-rr-meat:before {
    content: "\f844";
}
.fi-rr-medal:before {
    content: "\f845";
}
.fi-rr-medical-star:before {
    content: "\f846";
}
.fi-rr-medicine:before {
    content: "\f847";
}
.fi-rr-meeting-alt:before {
    content: "\f848";
}
.fi-rr-meeting:before {
    content: "\f849";
}
.fi-rr-megaphone:before {
    content: "\f84a";
}
.fi-rr-meh-blank:before {
    content: "\f84b";
}
.fi-rr-meh-rolling-eyes:before {
    content: "\f84c";
}
.fi-rr-meh:before {
    content: "\f84d";
}
.fi-rr-melon-alt:before {
    content: "\f84e";
}
.fi-rr-melon:before {
    content: "\f84f";
}
.fi-rr-membership-vip:before {
    content: "\f850";
}
.fi-rr-membership:before {
    content: "\f851";
}
.fi-rr-memo-circle-check:before {
    content: "\f852";
}
.fi-rr-memo-pad:before {
    content: "\f853";
}
.fi-rr-memo:before {
    content: "\f854";
}
.fi-rr-memory:before {
    content: "\f855";
}
.fi-rr-menu-burger:before {
    content: "\f856";
}
.fi-rr-menu-dots-vertical:before {
    content: "\f857";
}
.fi-rr-menu-dots:before {
    content: "\f858";
}
.fi-rr-mercury:before {
    content: "\f859";
}
.fi-rr-message-arrow-down:before {
    content: "\f85a";
}
.fi-rr-message-arrow-up-right:before {
    content: "\f85b";
}
.fi-rr-message-arrow-up:before {
    content: "\f85c";
}
.fi-rr-message-bot:before {
    content: "\f85d";
}
.fi-rr-message-code:before {
    content: "\f85e";
}
.fi-rr-message-dollar:before {
    content: "\f85f";
}
.fi-rr-message-image:before {
    content: "\f860";
}
.fi-rr-message-question:before {
    content: "\f861";
}
.fi-rr-message-quote:before {
    content: "\f862";
}
.fi-rr-message-slash:before {
    content: "\f863";
}
.fi-rr-message-sms:before {
    content: "\f864";
}
.fi-rr-message-text:before {
    content: "\f865";
}
.fi-rr-message-xmark:before {
    content: "\f866";
}
.fi-rr-messages-dollar:before {
    content: "\f867";
}
.fi-rr-messages-question:before {
    content: "\f868";
}
.fi-rr-messages:before {
    content: "\f869";
}
.fi-rr-meteor:before {
    content: "\f86a";
}
.fi-rr-meter-bolt:before {
    content: "\f86b";
}
.fi-rr-meter-droplet:before {
    content: "\f86c";
}
.fi-rr-meter-fire:before {
    content: "\f86d";
}
.fi-rr-meter:before {
    content: "\f86e";
}
.fi-rr-method:before {
    content: "\f86f";
}
.fi-rr-microchip-ai:before {
    content: "\f870";
}
.fi-rr-microchip:before {
    content: "\f871";
}
.fi-rr-microphone-alt:before {
    content: "\f872";
}
.fi-rr-microphone-slash:before {
    content: "\f873";
}
.fi-rr-microphone:before {
    content: "\f874";
}
.fi-rr-microscope:before {
    content: "\f875";
}
.fi-rr-microwave:before {
    content: "\f876";
}
.fi-rr-mill-sign:before {
    content: "\f877";
}
.fi-rr-mind-share:before {
    content: "\f878";
}
.fi-rr-minus-circle:before {
    content: "\f879";
}
.fi-rr-minus-hexagon:before {
    content: "\f87a";
}
.fi-rr-minus-small:before {
    content: "\f87b";
}
.fi-rr-minus:before {
    content: "\f87c";
}
.fi-rr-mistletoe:before {
    content: "\f87d";
}
.fi-rr-mobile-button:before {
    content: "\f87e";
}
.fi-rr-mobile-notch:before {
    content: "\f87f";
}
.fi-rr-mobile:before {
    content: "\f880";
}
.fi-rr-mockup:before {
    content: "\f881";
}
.fi-rr-mode-alt:before {
    content: "\f882";
}
.fi-rr-mode-landscape:before {
    content: "\f883";
}
.fi-rr-mode-portrait:before {
    content: "\f884";
}
.fi-rr-mode:before {
    content: "\f885";
}
.fi-rr-module:before {
    content: "\f886";
}
.fi-rr-monday:before {
    content: "\f887";
}
.fi-rr-money-bill-simple:before {
    content: "\f888";
}
.fi-rr-money-bill-transfer:before {
    content: "\f889";
}
.fi-rr-money-bill-wave-alt:before {
    content: "\f88a";
}
.fi-rr-money-bill-wave:before {
    content: "\f88b";
}
.fi-rr-money-bills-simple:before {
    content: "\f88c";
}
.fi-rr-money-bills:before {
    content: "\f88d";
}
.fi-rr-money-check-edit-alt:before {
    content: "\f88e";
}
.fi-rr-money-check-edit:before {
    content: "\f88f";
}
.fi-rr-money-check:before {
    content: "\f890";
}
.fi-rr-money-coin-transfer:before {
    content: "\f891";
}
.fi-rr-money-from-bracket:before {
    content: "\f892";
}
.fi-rr-money-simple-from-bracket:before {
    content: "\f893";
}
.fi-rr-money-transfer-alt:before {
    content: "\f894";
}
.fi-rr-money-transfer-coin-arrow:before {
    content: "\f895";
}
.fi-rr-money-transfer-smartphone:before {
    content: "\f896";
}
.fi-rr-money:before {
    content: "\f897";
}
.fi-rr-monkey:before {
    content: "\f898";
}
.fi-rr-monument:before {
    content: "\f899";
}
.fi-rr-moon-stars:before {
    content: "\f89a";
}
.fi-rr-moon:before {
    content: "\f89b";
}
.fi-rr-moped:before {
    content: "\f89c";
}
.fi-rr-mortar-pestle:before {
    content: "\f89d";
}
.fi-rr-mosque-alt:before {
    content: "\f89e";
}
.fi-rr-mosque-moon:before {
    content: "\f89f";
}
.fi-rr-mosque:before {
    content: "\f8a0";
}
.fi-rr-mosquito-net:before {
    content: "\f8a1";
}
.fi-rr-mosquito:before {
    content: "\f8a2";
}
.fi-rr-motorcycle:before {
    content: "\f8a3";
}
.fi-rr-mound:before {
    content: "\f8a4";
}
.fi-rr-mountain-city:before {
    content: "\f8a5";
}
.fi-rr-mountain:before {
    content: "\f8a6";
}
.fi-rr-mountains:before {
    content: "\f8a7";
}
.fi-rr-mouse:before {
    content: "\f8a8";
}
.fi-rr-move-to-folder-2:before {
    content: "\f8a9";
}
.fi-rr-move-to-folder:before {
    content: "\f8aa";
}
.fi-rr-mp3-player:before {
    content: "\f8ab";
}
.fi-rr-mug-alt:before {
    content: "\f8ac";
}
.fi-rr-mug-hot-alt:before {
    content: "\f8ad";
}
.fi-rr-mug-hot:before {
    content: "\f8ae";
}
.fi-rr-mug-marshmallows:before {
    content: "\f8af";
}
.fi-rr-mug-tea-saucer:before {
    content: "\f8b0";
}
.fi-rr-mug-tea:before {
    content: "\f8b1";
}
.fi-rr-mug:before {
    content: "\f8b2";
}
.fi-rr-multiple-alt:before {
    content: "\f8b3";
}
.fi-rr-multiple:before {
    content: "\f8b4";
}
.fi-rr-mushroom-alt:before {
    content: "\f8b5";
}
.fi-rr-mushroom:before {
    content: "\f8b6";
}
.fi-rr-music-alt:before {
    content: "\f8b7";
}
.fi-rr-music-file:before {
    content: "\f8b8";
}
.fi-rr-music-note-slash:before {
    content: "\f8b9";
}
.fi-rr-music-note:before {
    content: "\f8ba";
}
.fi-rr-music-slash:before {
    content: "\f8bb";
}
.fi-rr-music:before {
    content: "\f8bc";
}
.fi-rr-n:before {
    content: "\f8bd";
}
.fi-rr-naira-sign:before {
    content: "\f8be";
}
.fi-rr-narwhal:before {
    content: "\f8bf";
}
.fi-rr-navigation:before {
    content: "\f8c0";
}
.fi-rr-nesting-dolls:before {
    content: "\f8c1";
}
.fi-rr-network-analytic:before {
    content: "\f8c2";
}
.fi-rr-network-cloud:before {
    content: "\f8c3";
}
.fi-rr-network:before {
    content: "\f8c4";
}
.fi-rr-neuter:before {
    content: "\f8c5";
}
.fi-rr-newspaper-open:before {
    content: "\f8c6";
}
.fi-rr-newspaper:before {
    content: "\f8c7";
}
.fi-rr-nfc-lock:before {
    content: "\f8c8";
}
.fi-rr-nfc-magnifying-glass:before {
    content: "\f8c9";
}
.fi-rr-nfc-pen:before {
    content: "\f8ca";
}
.fi-rr-nfc-slash:before {
    content: "\f8cb";
}
.fi-rr-nfc-trash:before {
    content: "\f8cc";
}
.fi-rr-nfc:before {
    content: "\f8cd";
}
.fi-rr-no-fee:before {
    content: "\f8ce";
}
.fi-rr-no-people:before {
    content: "\f8cf";
}
.fi-rr-noodles:before {
    content: "\f8d0";
}
.fi-rr-nose:before {
    content: "\f8d1";
}
.fi-rr-not-equal:before {
    content: "\f8d2";
}
.fi-rr-not-found-alt:before {
    content: "\f8d3";
}
.fi-rr-not-found-magnifying-glass:before {
    content: "\f8d4";
}
.fi-rr-not-found:before {
    content: "\f8d5";
}
.fi-rr-notdef:before {
    content: "\f8d6";
}
.fi-rr-note-medical:before {
    content: "\f8d7";
}
.fi-rr-note-sticky:before {
    content: "\f8d8";
}
.fi-rr-note:before {
    content: "\f8d9";
}
.fi-rr-notebook-alt:before {
    content: "\f8da";
}
.fi-rr-notebook:before {
    content: "\f8db";
}
.fi-rr-notes-medical:before {
    content: "\f8dc";
}
.fi-rr-notes:before {
    content: "\f8dd";
}
.fi-rr-o:before {
    content: "\f8de";
}
.fi-rr-object-exclude:before {
    content: "\f8df";
}
.fi-rr-object-group:before {
    content: "\f8e0";
}
.fi-rr-object-intersect:before {
    content: "\f8e1";
}
.fi-rr-object-subtract:before {
    content: "\f8e2";
}
.fi-rr-object-ungroup:before {
    content: "\f8e3";
}
.fi-rr-object-union:before {
    content: "\f8e4";
}
.fi-rr-objects-column:before {
    content: "\f8e5";
}
.fi-rr-octagon-check:before {
    content: "\f8e6";
}
.fi-rr-octagon-divide:before {
    content: "\f8e7";
}
.fi-rr-octagon-exclamation:before {
    content: "\f8e8";
}
.fi-rr-octagon-minus:before {
    content: "\f8e9";
}
.fi-rr-octagon-plus:before {
    content: "\f8ea";
}
.fi-rr-octagon-xmark:before {
    content: "\f8eb";
}
.fi-rr-octagon:before {
    content: "\f8ec";
}
.fi-rr-oil-can:before {
    content: "\f8ed";
}
.fi-rr-oil-temp:before {
    content: "\f8ee";
}
.fi-rr-olive-oil:before {
    content: "\f8ef";
}
.fi-rr-olive:before {
    content: "\f8f0";
}
.fi-rr-olives:before {
    content: "\f8f1";
}
.fi-rr-om:before {
    content: "\f8f2";
}
.fi-rr-omega:before {
    content: "\f8f3";
}
.fi-rr-onboarding:before {
    content: "\f8f4";
}
.fi-rr-onion:before {
    content: "\f8f5";
}
.fi-rr-opacity:before {
    content: "\f8f6";
}
.fi-rr-operation:before {
    content: "\f8f7";
}
.fi-rr-organization-chart:before {
    content: "\f8f8";
}
.fi-rr-ornament:before {
    content: "\f8f9";
}
.fi-rr-otp:before {
    content: "\f8fa";
}
.fi-rr-otter:before {
    content: "\f8fb";
}
.fi-rr-outdent:before {
    content: "\f8fc";
}
.fi-rr-oval-alt:before {
    content: "\f8fd";
}
.fi-rr-oval:before {
    content: "\f8fe";
}
.fi-rr-oven:before {
    content: "\f8ff";
}
.fi-rr-overline:before {
    content: "\f900";
}
.fi-rr-overview:before {
    content: "\f901";
}
.fi-rr-p:before {
    content: "\f902";
}
.fi-rr-package:before {
    content: "\f903";
}
.fi-rr-padlock-check:before {
    content: "\f904";
}
.fi-rr-page-break:before {
    content: "\f905";
}
.fi-rr-pager:before {
    content: "\f906";
}
.fi-rr-paid:before {
    content: "\f907";
}
.fi-rr-paint-brush:before {
    content: "\f908";
}
.fi-rr-paint-roller:before {
    content: "\f909";
}
.fi-rr-paintbrush-pencil:before {
    content: "\f90a";
}
.fi-rr-palette:before {
    content: "\f90b";
}
.fi-rr-pallet-alt:before {
    content: "\f90c";
}
.fi-rr-pallet:before {
    content: "\f90d";
}
.fi-rr-pan-food:before {
    content: "\f90e";
}
.fi-rr-pan-frying:before {
    content: "\f90f";
}
.fi-rr-pan:before {
    content: "\f910";
}
.fi-rr-pancakes:before {
    content: "\f911";
}
.fi-rr-panorama:before {
    content: "\f912";
}
.fi-rr-paper-plane-top:before {
    content: "\f913";
}
.fi-rr-paper-plane:before {
    content: "\f914";
}
.fi-rr-paperclip-vertical:before {
    content: "\f915";
}
.fi-rr-parachute-box:before {
    content: "\f916";
}
.fi-rr-paragraph-left:before {
    content: "\f917";
}
.fi-rr-paragraph:before {
    content: "\f918";
}
.fi-rr-parking-circle-slash:before {
    content: "\f919";
}
.fi-rr-parking-circle:before {
    content: "\f91a";
}
.fi-rr-parking-slash:before {
    content: "\f91b";
}
.fi-rr-parking:before {
    content: "\f91c";
}
.fi-rr-party-bell:before {
    content: "\f91d";
}
.fi-rr-party-horn:before {
    content: "\f91e";
}
.fi-rr-passport:before {
    content: "\f91f";
}
.fi-rr-password-alt:before {
    content: "\f920";
}
.fi-rr-password-computer:before {
    content: "\f921";
}
.fi-rr-password-email:before {
    content: "\f922";
}
.fi-rr-password-smartphone:before {
    content: "\f923";
}
.fi-rr-password:before {
    content: "\f924";
}
.fi-rr-paste:before {
    content: "\f925";
}
.fi-rr-pattern:before {
    content: "\f926";
}
.fi-rr-pause-circle:before {
    content: "\f927";
}
.fi-rr-pause:before {
    content: "\f928";
}
.fi-rr-paw-claws:before {
    content: "\f929";
}
.fi-rr-paw-heart:before {
    content: "\f92a";
}
.fi-rr-paw:before {
    content: "\f92b";
}
.fi-rr-payment-pos:before {
    content: "\f92c";
}
.fi-rr-payroll-calendar:before {
    content: "\f92d";
}
.fi-rr-payroll:before {
    content: "\f92e";
}
.fi-rr-peace:before {
    content: "\f92f";
}
.fi-rr-peach:before {
    content: "\f930";
}
.fi-rr-peanut:before {
    content: "\f931";
}
.fi-rr-peanuts:before {
    content: "\f932";
}
.fi-rr-peapod:before {
    content: "\f933";
}
.fi-rr-pear:before {
    content: "\f934";
}
.fi-rr-pedestal:before {
    content: "\f935";
}
.fi-rr-pen-circle:before {
    content: "\f936";
}
.fi-rr-pen-clip-slash:before {
    content: "\f937";
}
.fi-rr-pen-clip:before {
    content: "\f938";
}
.fi-rr-pen-fancy-slash:before {
    content: "\f939";
}
.fi-rr-pen-fancy:before {
    content: "\f93a";
}
.fi-rr-pen-field:before {
    content: "\f93b";
}
.fi-rr-pen-nib-slash:before {
    content: "\f93c";
}
.fi-rr-pen-nib:before {
    content: "\f93d";
}
.fi-rr-pen-slash:before {
    content: "\f93e";
}
.fi-rr-pen-square:before {
    content: "\f93f";
}
.fi-rr-pen-swirl:before {
    content: "\f940";
}
.fi-rr-pencil-paintbrush:before {
    content: "\f941";
}
.fi-rr-pencil-ruler:before {
    content: "\f942";
}
.fi-rr-pencil-slash:before {
    content: "\f943";
}
.fi-rr-pencil:before {
    content: "\f944";
}
.fi-rr-pending:before {
    content: "\f945";
}
.fi-rr-pennant:before {
    content: "\f946";
}
.fi-rr-people-arrows-left-right:before {
    content: "\f947";
}
.fi-rr-people-carry-box:before {
    content: "\f948";
}
.fi-rr-people-dress:before {
    content: "\f949";
}
.fi-rr-people-pants:before {
    content: "\f94a";
}
.fi-rr-people-poll:before {
    content: "\f94b";
}
.fi-rr-people:before {
    content: "\f94c";
}
.fi-rr-pepper-alt:before {
    content: "\f94d";
}
.fi-rr-pepper-hot:before {
    content: "\f94e";
}
.fi-rr-pepper:before {
    content: "\f94f";
}
.fi-rr-percent-10:before {
    content: "\f950";
}
.fi-rr-percent-100:before {
    content: "\f951";
}
.fi-rr-percent-20:before {
    content: "\f952";
}
.fi-rr-percent-25:before {
    content: "\f953";
}
.fi-rr-percent-30:before {
    content: "\f954";
}
.fi-rr-percent-40:before {
    content: "\f955";
}
.fi-rr-percent-50:before {
    content: "\f956";
}
.fi-rr-percent-60:before {
    content: "\f957";
}
.fi-rr-percent-70:before {
    content: "\f958";
}
.fi-rr-percent-75:before {
    content: "\f959";
}
.fi-rr-percent-80:before {
    content: "\f95a";
}
.fi-rr-percent-90:before {
    content: "\f95b";
}
.fi-rr-percentage:before {
    content: "\f95c";
}
.fi-rr-person-carry-box:before {
    content: "\f95d";
}
.fi-rr-person-circle-check:before {
    content: "\f95e";
}
.fi-rr-person-circle-exclamation:before {
    content: "\f95f";
}
.fi-rr-person-circle-minus:before {
    content: "\f960";
}
.fi-rr-person-circle-plus:before {
    content: "\f961";
}
.fi-rr-person-circle-question:before {
    content: "\f962";
}
.fi-rr-person-circle-xmark:before {
    content: "\f963";
}
.fi-rr-person-cv:before {
    content: "\f964";
}
.fi-rr-person-dolly-empty:before {
    content: "\f965";
}
.fi-rr-person-dolly:before {
    content: "\f966";
}
.fi-rr-person-dress-simple:before {
    content: "\f967";
}
.fi-rr-person-dress:before {
    content: "\f968";
}
.fi-rr-person-praying:before {
    content: "\f969";
}
.fi-rr-person-pregnant:before {
    content: "\f96a";
}
.fi-rr-person-seat-reclined:before {
    content: "\f96b";
}
.fi-rr-person-seat:before {
    content: "\f96c";
}
.fi-rr-person-sign:before {
    content: "\f96d";
}
.fi-rr-person-simple:before {
    content: "\f96e";
}
.fi-rr-person-walking-with-cane:before {
    content: "\f96f";
}
.fi-rr-peseta-sign:before {
    content: "\f970";
}
.fi-rr-peso-sign:before {
    content: "\f971";
}
.fi-rr-pets:before {
    content: "\f972";
}
.fi-rr-pharmacy:before {
    content: "\f973";
}
.fi-rr-phone-call:before {
    content: "\f974";
}
.fi-rr-phone-cross:before {
    content: "\f975";
}
.fi-rr-phone-flip:before {
    content: "\f976";
}
.fi-rr-phone-office:before {
    content: "\f977";
}
.fi-rr-phone-pause:before {
    content: "\f978";
}
.fi-rr-phone-plus:before {
    content: "\f979";
}
.fi-rr-phone-rotary:before {
    content: "\f97a";
}
.fi-rr-phone-slash:before {
    content: "\f97b";
}
.fi-rr-photo-capture:before {
    content: "\f97c";
}
.fi-rr-photo-film-music:before {
    content: "\f97d";
}
.fi-rr-photo-video:before {
    content: "\f97e";
}
.fi-rr-physics:before {
    content: "\f97f";
}
.fi-rr-Pi:before {
    content: "\f980";
}
.fi-rr-piano-keyboard:before {
    content: "\f981";
}
.fi-rr-piano:before {
    content: "\f982";
}
.fi-rr-pickaxe:before {
    content: "\f983";
}
.fi-rr-picking:before {
    content: "\f984";
}
.fi-rr-picnic:before {
    content: "\f985";
}
.fi-rr-picpeople-filled:before {
    content: "\f986";
}
.fi-rr-picpeople:before {
    content: "\f987";
}
.fi-rr-picture:before {
    content: "\f988";
}
.fi-rr-pie:before {
    content: "\f989";
}
.fi-rr-piece:before {
    content: "\f98a";
}
.fi-rr-pig-face:before {
    content: "\f98b";
}
.fi-rr-pig:before {
    content: "\f98c";
}
.fi-rr-piggy-bank:before {
    content: "\f98d";
}
.fi-rr-pills:before {
    content: "\f98e";
}
.fi-rr-pinata:before {
    content: "\f98f";
}
.fi-rr-pineapple-alt:before {
    content: "\f990";
}
.fi-rr-pineapple:before {
    content: "\f991";
}
.fi-rr-ping-pong:before {
    content: "\f992";
}
.fi-rr-pipe-smoking:before {
    content: "\f993";
}
.fi-rr-pizza-slice:before {
    content: "\f994";
}
.fi-rr-place-of-worship:before {
    content: "\f995";
}
.fi-rr-plan-strategy:before {
    content: "\f996";
}
.fi-rr-plan:before {
    content: "\f997";
}
.fi-rr-plane-alt:before {
    content: "\f998";
}
.fi-rr-plane-arrival:before {
    content: "\f999";
}
.fi-rr-plane-departure:before {
    content: "\f99a";
}
.fi-rr-plane-prop:before {
    content: "\f99b";
}
.fi-rr-plane-slash:before {
    content: "\f99c";
}
.fi-rr-plane-tail:before {
    content: "\f99d";
}
.fi-rr-plane:before {
    content: "\f99e";
}
.fi-rr-planet-moon:before {
    content: "\f99f";
}
.fi-rr-planet-ringed:before {
    content: "\f9a0";
}
.fi-rr-plant-wilt:before {
    content: "\f9a1";
}
.fi-rr-plate-wheat:before {
    content: "\f9a2";
}
.fi-rr-plate:before {
    content: "\f9a3";
}
.fi-rr-play-alt:before {
    content: "\f9a4";
}
.fi-rr-play-circle:before {
    content: "\f9a5";
}
.fi-rr-play-pause:before {
    content: "\f9a6";
}
.fi-rr-play:before {
    content: "\f9a7";
}
.fi-rr-playing-cards:before {
    content: "\f9a8";
}
.fi-rr-plug-alt:before {
    content: "\f9a9";
}
.fi-rr-plug-cable:before {
    content: "\f9aa";
}
.fi-rr-plug-connection:before {
    content: "\f9ab";
}
.fi-rr-plug:before {
    content: "\f9ac";
}
.fi-rr-plus-hexagon:before {
    content: "\f9ad";
}
.fi-rr-plus-minus:before {
    content: "\f9ae";
}
.fi-rr-plus-small:before {
    content: "\f9af";
}
.fi-rr-plus:before {
    content: "\f9b0";
}
.fi-rr-podcast:before {
    content: "\f9b1";
}
.fi-rr-podium-star:before {
    content: "\f9b2";
}
.fi-rr-podium:before {
    content: "\f9b3";
}
.fi-rr-point-of-sale-bill:before {
    content: "\f9b4";
}
.fi-rr-point-of-sale-signal:before {
    content: "\f9b5";
}
.fi-rr-point-of-sale:before {
    content: "\f9b6";
}
.fi-rr-poker-chip:before {
    content: "\f9b7";
}
.fi-rr-police-box:before {
    content: "\f9b8";
}
.fi-rr-poll-h:before {
    content: "\f9b9";
}
.fi-rr-pollution:before {
    content: "\f9ba";
}
.fi-rr-pompebled:before {
    content: "\f9bb";
}
.fi-rr-poo-bolt:before {
    content: "\f9bc";
}
.fi-rr-poo:before {
    content: "\f9bd";
}
.fi-rr-pool-8-ball:before {
    content: "\f9be";
}
.fi-rr-poop:before {
    content: "\f9bf";
}
.fi-rr-popcorn:before {
    content: "\f9c0";
}
.fi-rr-popsicle:before {
    content: "\f9c1";
}
.fi-rr-portal-enter:before {
    content: "\f9c2";
}
.fi-rr-portal-exit:before {
    content: "\f9c3";
}
.fi-rr-portrait:before {
    content: "\f9c4";
}
.fi-rr-pot:before {
    content: "\f9c5";
}
.fi-rr-potato:before {
    content: "\f9c6";
}
.fi-rr-pound:before {
    content: "\f9c7";
}
.fi-rr-power:before {
    content: "\f9c8";
}
.fi-rr-practice:before {
    content: "\f9c9";
}
.fi-rr-praying-hands:before {
    content: "\f9ca";
}
.fi-rr-prescription-bottle-alt:before {
    content: "\f9cb";
}
.fi-rr-prescription-bottle-pill:before {
    content: "\f9cc";
}
.fi-rr-prescription-bottle:before {
    content: "\f9cd";
}
.fi-rr-prescription:before {
    content: "\f9ce";
}
.fi-rr-presentation:before {
    content: "\f9cf";
}
.fi-rr-preview:before {
    content: "\f9d0";
}
.fi-rr-print-magnifying-glass:before {
    content: "\f9d1";
}
.fi-rr-print-slash:before {
    content: "\f9d2";
}
.fi-rr-print:before {
    content: "\f9d3";
}
.fi-rr-priority-arrow:before {
    content: "\f9d4";
}
.fi-rr-priority-arrows:before {
    content: "\f9d5";
}
.fi-rr-priority-importance:before {
    content: "\f9d6";
}
.fi-rr-problem-solving:before {
    content: "\f9d7";
}
.fi-rr-procedures:before {
    content: "\f9d8";
}
.fi-rr-process:before {
    content: "\f9d9";
}
.fi-rr-productivity:before {
    content: "\f9da";
}
.fi-rr-projector:before {
    content: "\f9db";
}
.fi-rr-protractor:before {
    content: "\f9dc";
}
.fi-rr-pulse:before {
    content: "\f9dd";
}
.fi-rr-pump-medical:before {
    content: "\f9de";
}
.fi-rr-pump:before {
    content: "\f9df";
}
.fi-rr-pumpkin-alt-2:before {
    content: "\f9e0";
}
.fi-rr-pumpkin-alt:before {
    content: "\f9e1";
}
.fi-rr-pumpkin:before {
    content: "\f9e2";
}
.fi-rr-puzzle-alt:before {
    content: "\f9e3";
}
.fi-rr-puzzle-pieces:before {
    content: "\f9e4";
}
.fi-rr-puzzle:before {
    content: "\f9e5";
}
.fi-rr-pyramid:before {
    content: "\f9e6";
}
.fi-rr-q:before {
    content: "\f9e7";
}
.fi-rr-qr-scan:before {
    content: "\f9e8";
}
.fi-rr-QR:before {
    content: "\f9e9";
}
.fi-rr-qrcode:before {
    content: "\f9ea";
}
.fi-rr-question-square:before {
    content: "\f9eb";
}
.fi-rr-question:before {
    content: "\f9ec";
}
.fi-rr-queue-alt:before {
    content: "\f9ed";
}
.fi-rr-queue-line:before {
    content: "\f9ee";
}
.fi-rr-queue-signal:before {
    content: "\f9ef";
}
.fi-rr-queue:before {
    content: "\f9f0";
}
.fi-rr-quiz-alt:before {
    content: "\f9f1";
}
.fi-rr-quiz:before {
    content: "\f9f2";
}
.fi-rr-quote-right:before {
    content: "\f9f3";
}
.fi-rr-r:before {
    content: "\f9f4";
}
.fi-rr-rabbit-fast:before {
    content: "\f9f5";
}
.fi-rr-rabbit:before {
    content: "\f9f6";
}
.fi-rr-racquet:before {
    content: "\f9f7";
}
.fi-rr-radar:before {
    content: "\f9f8";
}
.fi-rr-radiation-alt:before {
    content: "\f9f9";
}
.fi-rr-radiation:before {
    content: "\f9fa";
}
.fi-rr-radio-alt:before {
    content: "\f9fb";
}
.fi-rr-radio:before {
    content: "\f9fc";
}
.fi-rr-radish:before {
    content: "\f9fd";
}
.fi-rr-rainbow:before {
    content: "\f9fe";
}
.fi-rr-raindrops:before {
    content: "\f9ff";
}
.fi-rr-ram:before {
    content: "\fa00";
}
.fi-rr-ramp-loading:before {
    content: "\fa01";
}
.fi-rr-rank:before {
    content: "\fa02";
}
.fi-rr-ranking-podium-empty:before {
    content: "\fa03";
}
.fi-rr-ranking-podium:before {
    content: "\fa04";
}
.fi-rr-ranking-star:before {
    content: "\fa05";
}
.fi-rr-ranking-stars:before {
    content: "\fa06";
}
.fi-rr-raygun:before {
    content: "\fa07";
}
.fi-rr-razor-barber:before {
    content: "\fa08";
}
.fi-rr-react:before {
    content: "\fa09";
}
.fi-rr-rec:before {
    content: "\fa0a";
}
.fi-rr-receipt:before {
    content: "\fa0b";
}
.fi-rr-record-vinyl:before {
    content: "\fa0c";
}
.fi-rr-rectabgle-vertical:before {
    content: "\fa0d";
}
.fi-rr-rectangle-barcode:before {
    content: "\fa0e";
}
.fi-rr-rectangle-code:before {
    content: "\fa0f";
}
.fi-rr-rectangle-history-circle-plus:before {
    content: "\fa10";
}
.fi-rr-rectangle-horizontal:before {
    content: "\fa11";
}
.fi-rr-rectangle-list:before {
    content: "\fa12";
}
.fi-rr-rectangle-panoramic:before {
    content: "\fa13";
}
.fi-rr-rectangle-pro:before {
    content: "\fa14";
}
.fi-rr-rectangle-vertical-history:before {
    content: "\fa15";
}
.fi-rr-rectangle-vertical:before {
    content: "\fa16";
}
.fi-rr-rectangle-xmark:before {
    content: "\fa17";
}
.fi-rr-rectangles-mixed:before {
    content: "\fa18";
}
.fi-rr-recycle:before {
    content: "\fa19";
}
.fi-rr-redo-alt:before {
    content: "\fa1a";
}
.fi-rr-redo:before {
    content: "\fa1b";
}
.fi-rr-reel:before {
    content: "\fa1c";
}
.fi-rr-refer-arrow:before {
    content: "\fa1d";
}
.fi-rr-refer:before {
    content: "\fa1e";
}
.fi-rr-reflect-horizontal:before {
    content: "\fa1f";
}
.fi-rr-reflect-vertical:before {
    content: "\fa20";
}
.fi-rr-reflect:before {
    content: "\fa21";
}
.fi-rr-refresh:before {
    content: "\fa22";
}
.fi-rr-refrigerator:before {
    content: "\fa23";
}
.fi-rr-refund-alt:before {
    content: "\fa24";
}
.fi-rr-refund:before {
    content: "\fa25";
}
.fi-rr-region-pin-alt:before {
    content: "\fa26";
}
.fi-rr-region-pin:before {
    content: "\fa27";
}
.fi-rr-registered:before {
    content: "\fa28";
}
.fi-rr-registration-paper:before {
    content: "\fa29";
}
.fi-rr-remove-folder:before {
    content: "\fa2a";
}
.fi-rr-remove-user:before {
    content: "\fa2b";
}
.fi-rr-rent-signal:before {
    content: "\fa2c";
}
.fi-rr-rent:before {
    content: "\fa2d";
}
.fi-rr-replace:before {
    content: "\fa2e";
}
.fi-rr-reply-all:before {
    content: "\fa2f";
}
.fi-rr-republican:before {
    content: "\fa30";
}
.fi-rr-reservation-smartphone:before {
    content: "\fa31";
}
.fi-rr-reservation-table:before {
    content: "\fa32";
}
.fi-rr-resize:before {
    content: "\fa33";
}
.fi-rr-resources:before {
    content: "\fa34";
}
.fi-rr-restaurant:before {
    content: "\fa35";
}
.fi-rr-restock:before {
    content: "\fa36";
}
.fi-rr-restroom-simple:before {
    content: "\fa37";
}
.fi-rr-revenue-alt:before {
    content: "\fa38";
}
.fi-rr-Revenue:before {
    content: "\fa39";
}
.fi-rr-review:before {
    content: "\fa3a";
}
.fi-rr-rewind:before {
    content: "\fa3b";
}
.fi-rr-rhombus:before {
    content: "\fa3c";
}
.fi-rr-ribbon:before {
    content: "\fa3d";
}
.fi-rr-right:before {
    content: "\fa3e";
}
.fi-rr-ring-diamond:before {
    content: "\fa3f";
}
.fi-rr-ring:before {
    content: "\fa40";
}
.fi-rr-rings-wedding:before {
    content: "\fa41";
}
.fi-rr-risk-alt:before {
    content: "\fa42";
}
.fi-rr-risk:before {
    content: "\fa43";
}
.fi-rr-road:before {
    content: "\fa44";
}
.fi-rr-robot:before {
    content: "\fa45";
}
.fi-rr-rocket-lunch:before {
    content: "\fa46";
}
.fi-rr-rocket:before {
    content: "\fa47";
}
.fi-rr-roller-coaster:before {
    content: "\fa48";
}
.fi-rr-room-service:before {
    content: "\fa49";
}
.fi-rr-rotate-exclamation:before {
    content: "\fa4a";
}
.fi-rr-rotate-left:before {
    content: "\fa4b";
}
.fi-rr-rotate-right:before {
    content: "\fa4c";
}
.fi-rr-rotate-square:before {
    content: "\fa4d";
}
.fi-rr-route-highway:before {
    content: "\fa4e";
}
.fi-rr-route-interstate:before {
    content: "\fa4f";
}
.fi-rr-route:before {
    content: "\fa50";
}
.fi-rr-router:before {
    content: "\fa51";
}
.fi-rr-rss-alt:before {
    content: "\fa52";
}
.fi-rr-rss:before {
    content: "\fa53";
}
.fi-rr-ruble-sign:before {
    content: "\fa54";
}
.fi-rr-rugby:before {
    content: "\fa55";
}
.fi-rr-ruler-combined:before {
    content: "\fa56";
}
.fi-rr-ruler-horizontal:before {
    content: "\fa57";
}
.fi-rr-ruler-triangle:before {
    content: "\fa58";
}
.fi-rr-ruler-vertical:before {
    content: "\fa59";
}
.fi-rr-running:before {
    content: "\fa5a";
}
.fi-rr-rupee-sign:before {
    content: "\fa5b";
}
.fi-rr-rupiah-sign:before {
    content: "\fa5c";
}
.fi-rr-rv:before {
    content: "\fa5d";
}
.fi-rr-s:before {
    content: "\fa5e";
}
.fi-rr-sack-dollar:before {
    content: "\fa5f";
}
.fi-rr-sack:before {
    content: "\fa60";
}
.fi-rr-sad-cry:before {
    content: "\fa61";
}
.fi-rr-sad-tear:before {
    content: "\fa62";
}
.fi-rr-sad:before {
    content: "\fa63";
}
.fi-rr-safe-box:before {
    content: "\fa64";
}
.fi-rr-sailboat:before {
    content: "\fa65";
}
.fi-rr-salad:before {
    content: "\fa66";
}
.fi-rr-salary-alt:before {
    content: "\fa67";
}
.fi-rr-salt-pepper:before {
    content: "\fa68";
}
.fi-rr-salt-shaker:before {
    content: "\fa69";
}
.fi-rr-sandwich-alt:before {
    content: "\fa6a";
}
.fi-rr-sandwich:before {
    content: "\fa6b";
}
.fi-rr-satellite-dish:before {
    content: "\fa6c";
}
.fi-rr-satellite:before {
    content: "\fa6d";
}
.fi-rr-saturday:before {
    content: "\fa6e";
}
.fi-rr-sauce:before {
    content: "\fa6f";
}
.fi-rr-sausage:before {
    content: "\fa70";
}
.fi-rr-sax-hot:before {
    content: "\fa71";
}
.fi-rr-saxophone:before {
    content: "\fa72";
}
.fi-rr-scale-comparison-alt:before {
    content: "\fa73";
}
.fi-rr-scale-comparison:before {
    content: "\fa74";
}
.fi-rr-scale:before {
    content: "\fa75";
}
.fi-rr-scalpel-path:before {
    content: "\fa76";
}
.fi-rr-scalpel:before {
    content: "\fa77";
}
.fi-rr-scanner-gun:before {
    content: "\fa78";
}
.fi-rr-scanner-image:before {
    content: "\fa79";
}
.fi-rr-scanner-keyboard:before {
    content: "\fa7a";
}
.fi-rr-scanner-touchscreen:before {
    content: "\fa7b";
}
.fi-rr-scarecrow:before {
    content: "\fa7c";
}
.fi-rr-scarf:before {
    content: "\fa7d";
}
.fi-rr-school-bus:before {
    content: "\fa7e";
}
.fi-rr-school:before {
    content: "\fa7f";
}
.fi-rr-scissors:before {
    content: "\fa80";
}
.fi-rr-screen:before {
    content: "\fa81";
}
.fi-rr-screencast:before {
    content: "\fa82";
}
.fi-rr-screwdriver:before {
    content: "\fa83";
}
.fi-rr-scribble:before {
    content: "\fa84";
}
.fi-rr-script:before {
    content: "\fa85";
}
.fi-rr-scroll-old:before {
    content: "\fa86";
}
.fi-rr-scroll-torah:before {
    content: "\fa87";
}
.fi-rr-scroll:before {
    content: "\fa88";
}
.fi-rr-scrubber:before {
    content: "\fa89";
}
.fi-rr-scythe:before {
    content: "\fa8a";
}
.fi-rr-sd-card:before {
    content: "\fa8b";
}
.fi-rr-sd-cards:before {
    content: "\fa8c";
}
.fi-rr-seal-exclamation:before {
    content: "\fa8d";
}
.fi-rr-seal-question:before {
    content: "\fa8e";
}
.fi-rr-seal:before {
    content: "\fa8f";
}
.fi-rr-search-alt:before {
    content: "\fa90";
}
.fi-rr-search-dollar:before {
    content: "\fa91";
}
.fi-rr-search-heart:before {
    content: "\fa92";
}
.fi-rr-search-location:before {
    content: "\fa93";
}
.fi-rr-search:before {
    content: "\fa94";
}
.fi-rr-seat-airline:before {
    content: "\fa95";
}
.fi-rr-second-award:before {
    content: "\fa96";
}
.fi-rr-second-laurel:before {
    content: "\fa97";
}
.fi-rr-second-medal:before {
    content: "\fa98";
}
.fi-rr-second:before {
    content: "\fa99";
}
.fi-rr-security:before {
    content: "\fa9a";
}
.fi-rr-seedling:before {
    content: "\fa9b";
}
.fi-rr-selection:before {
    content: "\fa9c";
}
.fi-rr-sell:before {
    content: "\fa9d";
}
.fi-rr-seller-store:before {
    content: "\fa9e";
}
.fi-rr-seller:before {
    content: "\fa9f";
}
.fi-rr-selling:before {
    content: "\faa0";
}
.fi-rr-send-back:before {
    content: "\faa1";
}
.fi-rr-send-backward:before {
    content: "\faa2";
}
.fi-rr-send-money-smartphone:before {
    content: "\faa3";
}
.fi-rr-send-money:before {
    content: "\faa4";
}
.fi-rr-sensor-alert:before {
    content: "\faa5";
}
.fi-rr-sensor-fire:before {
    content: "\faa6";
}
.fi-rr-sensor-on:before {
    content: "\faa7";
}
.fi-rr-sensor-smoke:before {
    content: "\faa8";
}
.fi-rr-sensor:before {
    content: "\faa9";
}
.fi-rr-settings-sliders:before {
    content: "\faaa";
}
.fi-rr-settings:before {
    content: "\faab";
}
.fi-rr-share-alt-square:before {
    content: "\faac";
}
.fi-rr-share-square:before {
    content: "\faad";
}
.fi-rr-share:before {
    content: "\faae";
}
.fi-rr-sheep:before {
    content: "\faaf";
}
.fi-rr-shekel-sign:before {
    content: "\fab0";
}
.fi-rr-shelves:before {
    content: "\fab1";
}
.fi-rr-shield-alt:before {
    content: "\fab2";
}
.fi-rr-shield-cat:before {
    content: "\fab3";
}
.fi-rr-shield-check:before {
    content: "\fab4";
}
.fi-rr-shield-cross:before {
    content: "\fab5";
}
.fi-rr-shield-dog:before {
    content: "\fab6";
}
.fi-rr-shield-exclamation:before {
    content: "\fab7";
}
.fi-rr-shield-interrogation:before {
    content: "\fab8";
}
.fi-rr-shield-keyhole:before {
    content: "\fab9";
}
.fi-rr-shield-minus:before {
    content: "\faba";
}
.fi-rr-shield-plus:before {
    content: "\fabb";
}
.fi-rr-shield-slash:before {
    content: "\fabc";
}
.fi-rr-shield-virus:before {
    content: "\fabd";
}
.fi-rr-shield-xmark:before {
    content: "\fabe";
}
.fi-rr-shield:before {
    content: "\fabf";
}
.fi-rr-ship-side:before {
    content: "\fac0";
}
.fi-rr-ship:before {
    content: "\fac1";
}
.fi-rr-shipping-fast:before {
    content: "\fac2";
}
.fi-rr-shipping-timed:before {
    content: "\fac3";
}
.fi-rr-shirt-long-sleeve:before {
    content: "\fac4";
}
.fi-rr-shirt-running:before {
    content: "\fac5";
}
.fi-rr-shirt-tank-top:before {
    content: "\fac6";
}
.fi-rr-shirt:before {
    content: "\fac7";
}
.fi-rr-shish-kebab:before {
    content: "\fac8";
}
.fi-rr-shoe-prints:before {
    content: "\fac9";
}
.fi-rr-shop-lock:before {
    content: "\faca";
}
.fi-rr-shop-slash:before {
    content: "\facb";
}
.fi-rr-shop:before {
    content: "\facc";
}
.fi-rr-shopping-bag-add:before {
    content: "\facd";
}
.fi-rr-shopping-bag:before {
    content: "\face";
}
.fi-rr-shopping-basket:before {
    content: "\facf";
}
.fi-rr-shopping-cart-add:before {
    content: "\fad0";
}
.fi-rr-shopping-cart-check:before {
    content: "\fad1";
}
.fi-rr-shopping-cart:before {
    content: "\fad2";
}
.fi-rr-shovel-snow:before {
    content: "\fad3";
}
.fi-rr-shovel:before {
    content: "\fad4";
}
.fi-rr-shower-down:before {
    content: "\fad5";
}
.fi-rr-shower:before {
    content: "\fad6";
}
.fi-rr-shredder:before {
    content: "\fad7";
}
.fi-rr-shrimp:before {
    content: "\fad8";
}
.fi-rr-shuffle:before {
    content: "\fad9";
}
.fi-rr-shuttle-van:before {
    content: "\fada";
}
.fi-rr-shuttlecock:before {
    content: "\fadb";
}
.fi-rr-Sickle:before {
    content: "\fadc";
}
.fi-rr-sidebar-flip:before {
    content: "\fadd";
}
.fi-rr-sidebar:before {
    content: "\fade";
}
.fi-rr-sigma:before {
    content: "\fadf";
}
.fi-rr-sign-hanging:before {
    content: "\fae0";
}
.fi-rr-sign-in-alt:before {
    content: "\fae1";
}
.fi-rr-sign-out-alt:before {
    content: "\fae2";
}
.fi-rr-sign-up:before {
    content: "\fae3";
}
.fi-rr-signal-alt-1:before {
    content: "\fae4";
}
.fi-rr-signal-alt-2:before {
    content: "\fae5";
}
.fi-rr-signal-alt-slash:before {
    content: "\fae6";
}
.fi-rr-signal-alt:before {
    content: "\fae7";
}
.fi-rr-signal-bars-fair:before {
    content: "\fae8";
}
.fi-rr-signal-bars-good:before {
    content: "\fae9";
}
.fi-rr-signal-bars-weak:before {
    content: "\faea";
}
.fi-rr-signal-stream-slash:before {
    content: "\faeb";
}
.fi-rr-signal-stream:before {
    content: "\faec";
}
.fi-rr-signature-lock:before {
    content: "\faed";
}
.fi-rr-signature-slash:before {
    content: "\faee";
}
.fi-rr-signature:before {
    content: "\faef";
}
.fi-rr-sim-card:before {
    content: "\faf0";
}
.fi-rr-sim-cards:before {
    content: "\faf1";
}
.fi-rr-sink:before {
    content: "\faf2";
}
.fi-rr-siren-on:before {
    content: "\faf3";
}
.fi-rr-Siren:before {
    content: "\faf4";
}
.fi-rr-site-alt:before {
    content: "\faf5";
}
.fi-rr-site-browser:before {
    content: "\faf6";
}
.fi-rr-site:before {
    content: "\faf7";
}
.fi-rr-sitemap:before {
    content: "\faf8";
}
.fi-rr-skateboard:before {
    content: "\faf9";
}
.fi-rr-skating:before {
    content: "\fafa";
}
.fi-rr-skeleton:before {
    content: "\fafb";
}
.fi-rr-skewer:before {
    content: "\fafc";
}
.fi-rr-ski-boot-ski:before {
    content: "\fafd";
}
.fi-rr-ski-jump:before {
    content: "\fafe";
}
.fi-rr-ski-lift:before {
    content: "\faff";
}
.fi-rr-skiing-nordic:before {
    content: "\fb00";
}
.fi-rr-skiing:before {
    content: "\fb01";
}
.fi-rr-skull-cow:before {
    content: "\fb02";
}
.fi-rr-skull-crossbones:before {
    content: "\fb03";
}
.fi-rr-skull:before {
    content: "\fb04";
}
.fi-rr-slash:before {
    content: "\fb05";
}
.fi-rr-sledding:before {
    content: "\fb06";
}
.fi-rr-sleigh:before {
    content: "\fb07";
}
.fi-rr-sliders-h-square:before {
    content: "\fb08";
}
.fi-rr-sliders-v-square:before {
    content: "\fb09";
}
.fi-rr-sliders-v:before {
    content: "\fb0a";
}
.fi-rr-smartphone:before {
    content: "\fb0b";
}
.fi-rr-smile-beam:before {
    content: "\fb0c";
}
.fi-rr-smile-plus:before {
    content: "\fb0d";
}
.fi-rr-smile-wink:before {
    content: "\fb0e";
}
.fi-rr-smile:before {
    content: "\fb0f";
}
.fi-rr-smiley-comment-alt:before {
    content: "\fb10";
}
.fi-rr-smog:before {
    content: "\fb11";
}
.fi-rr-smoke:before {
    content: "\fb12";
}
.fi-rr-smoking-ban:before {
    content: "\fb13";
}
.fi-rr-smoking:before {
    content: "\fb14";
}
.fi-rr-snake:before {
    content: "\fb15";
}
.fi-rr-snooze:before {
    content: "\fb16";
}
.fi-rr-snow-blowing:before {
    content: "\fb17";
}
.fi-rr-snowboarding:before {
    content: "\fb18";
}
.fi-rr-snowflake-droplets:before {
    content: "\fb19";
}
.fi-rr-snowflake:before {
    content: "\fb1a";
}
.fi-rr-snowflakes:before {
    content: "\fb1b";
}
.fi-rr-snowman-alt:before {
    content: "\fb1c";
}
.fi-rr-snowman-head:before {
    content: "\fb1d";
}
.fi-rr-snowmobile:before {
    content: "\fb1e";
}
.fi-rr-snowplow:before {
    content: "\fb1f";
}
.fi-rr-soap-alt:before {
    content: "\fb20";
}
.fi-rr-soap:before {
    content: "\fb21";
}
.fi-rr-social-network:before {
    content: "\fb22";
}
.fi-rr-socks:before {
    content: "\fb23";
}
.fi-rr-sofa-size:before {
    content: "\fb24";
}
.fi-rr-sofa:before {
    content: "\fb25";
}
.fi-rr-solar-panel:before {
    content: "\fb26";
}
.fi-rr-solar-system:before {
    content: "\fb27";
}
.fi-rr-sold-house:before {
    content: "\fb28";
}
.fi-rr-sold-signal:before {
    content: "\fb29";
}
.fi-rr-sort-alpha-down-alt:before {
    content: "\fb2a";
}
.fi-rr-sort-alpha-down:before {
    content: "\fb2b";
}
.fi-rr-sort-alpha-up-alt:before {
    content: "\fb2c";
}
.fi-rr-sort-alpha-up:before {
    content: "\fb2d";
}
.fi-rr-sort-alt:before {
    content: "\fb2e";
}
.fi-rr-sort-amount-down-alt:before {
    content: "\fb2f";
}
.fi-rr-sort-amount-down:before {
    content: "\fb30";
}
.fi-rr-sort-amount-up-alt:before {
    content: "\fb31";
}
.fi-rr-sort-amount-up:before {
    content: "\fb32";
}
.fi-rr-sort-circle-down:before {
    content: "\fb33";
}
.fi-rr-sort-circle-up:before {
    content: "\fb34";
}
.fi-rr-sort-circle:before {
    content: "\fb35";
}
.fi-rr-sort-down:before {
    content: "\fb36";
}
.fi-rr-sort-numeric-down-alt:before {
    content: "\fb37";
}
.fi-rr-sort-numeric-down:before {
    content: "\fb38";
}
.fi-rr-sort-shapes-down:before {
    content: "\fb39";
}
.fi-rr-sort-shapes-up:before {
    content: "\fb3a";
}
.fi-rr-sort-size-down:before {
    content: "\fb3b";
}
.fi-rr-sort-size-up:before {
    content: "\fb3c";
}
.fi-rr-sort:before {
    content: "\fb3d";
}
.fi-rr-soup:before {
    content: "\fb3e";
}
.fi-rr-source-data:before {
    content: "\fb3f";
}
.fi-rr-spa:before {
    content: "\fb40";
}
.fi-rr-space-shuttle:before {
    content: "\fb41";
}
.fi-rr-space-station-moon-alt:before {
    content: "\fb42";
}
.fi-rr-space-station-moon:before {
    content: "\fb43";
}
.fi-rr-spade:before {
    content: "\fb44";
}
.fi-rr-spaghetti-monster-flying:before {
    content: "\fb45";
}
.fi-rr-sparkles:before {
    content: "\fb46";
}
.fi-rr-speaker:before {
    content: "\fb47";
}
.fi-rr-speakers:before {
    content: "\fb48";
}
.fi-rr-speedometer-arrow:before {
    content: "\fb49";
}
.fi-rr-speedometer-kpi:before {
    content: "\fb4a";
}
.fi-rr-sphere:before {
    content: "\fb4b";
}
.fi-rr-spider-black-widow:before {
    content: "\fb4c";
}
.fi-rr-spider-web:before {
    content: "\fb4d";
}
.fi-rr-spider:before {
    content: "\fb4e";
}
.fi-rr-spinner:before {
    content: "\fb4f";
}
.fi-rr-split:before {
    content: "\fb50";
}
.fi-rr-splotch:before {
    content: "\fb51";
}
.fi-rr-spoon:before {
    content: "\fb52";
}
.fi-rr-sport:before {
    content: "\fb53";
}
.fi-rr-spray-can-sparkles:before {
    content: "\fb54";
}
.fi-rr-spray-can:before {
    content: "\fb55";
}
.fi-rr-spring-calendar:before {
    content: "\fb56";
}
.fi-rr-sprinkler:before {
    content: "\fb57";
}
.fi-rr-spy:before {
    content: "\fb58";
}
.fi-rr-square-0:before {
    content: "\fb59";
}
.fi-rr-square-1:before {
    content: "\fb5a";
}
.fi-rr-square-2:before {
    content: "\fb5b";
}
.fi-rr-square-3:before {
    content: "\fb5c";
}
.fi-rr-square-4:before {
    content: "\fb5d";
}
.fi-rr-square-5:before {
    content: "\fb5e";
}
.fi-rr-square-6:before {
    content: "\fb5f";
}
.fi-rr-square-7:before {
    content: "\fb60";
}
.fi-rr-square-8:before {
    content: "\fb61";
}
.fi-rr-square-9:before {
    content: "\fb62";
}
.fi-rr-square-a:before {
    content: "\fb63";
}
.fi-rr-square-b:before {
    content: "\fb64";
}
.fi-rr-square-bolt:before {
    content: "\fb65";
}
.fi-rr-square-c:before {
    content: "\fb66";
}
.fi-rr-square-code:before {
    content: "\fb67";
}
.fi-rr-square-d:before {
    content: "\fb68";
}
.fi-rr-square-dashed:before {
    content: "\fb69";
}
.fi-rr-square-divide:before {
    content: "\fb6a";
}
.fi-rr-square-e:before {
    content: "\fb6b";
}
.fi-rr-square-ellipsis-vertical:before {
    content: "\fb6c";
}
.fi-rr-square-ellipsis:before {
    content: "\fb6d";
}
.fi-rr-square-exclamation:before {
    content: "\fb6e";
}
.fi-rr-square-f:before {
    content: "\fb6f";
}
.fi-rr-square-g:before {
    content: "\fb70";
}
.fi-rr-square-h:before {
    content: "\fb71";
}
.fi-rr-square-heart:before {
    content: "\fb72";
}
.fi-rr-square-i:before {
    content: "\fb73";
}
.fi-rr-square-info:before {
    content: "\fb74";
}
.fi-rr-square-j:before {
    content: "\fb75";
}
.fi-rr-square-k:before {
    content: "\fb76";
}
.fi-rr-square-kanban:before {
    content: "\fb77";
}
.fi-rr-square-l:before {
    content: "\fb78";
}
.fi-rr-square-m:before {
    content: "\fb79";
}
.fi-rr-square-minus:before {
    content: "\fb7a";
}
.fi-rr-square-n:before {
    content: "\fb7b";
}
.fi-rr-square-o:before {
    content: "\fb7c";
}
.fi-rr-square-p:before {
    content: "\fb7d";
}
.fi-rr-square-phone-hangup:before {
    content: "\fb7e";
}
.fi-rr-square-plus:before {
    content: "\fb7f";
}
.fi-rr-square-poll-horizontal:before {
    content: "\fb80";
}
.fi-rr-square-poll-vertical:before {
    content: "\fb81";
}
.fi-rr-square-q:before {
    content: "\fb82";
}
.fi-rr-square-quote:before {
    content: "\fb83";
}
.fi-rr-square-r:before {
    content: "\fb84";
}
.fi-rr-square-root:before {
    content: "\fb85";
}
.fi-rr-square-s:before {
    content: "\fb86";
}
.fi-rr-square-small:before {
    content: "\fb87";
}
.fi-rr-square-star:before {
    content: "\fb88";
}
.fi-rr-square-t:before {
    content: "\fb89";
}
.fi-rr-square-terminal:before {
    content: "\fb8a";
}
.fi-rr-square-u:before {
    content: "\fb8b";
}
.fi-rr-square-up-right:before {
    content: "\fb8c";
}
.fi-rr-square-v:before {
    content: "\fb8d";
}
.fi-rr-square-w:before {
    content: "\fb8e";
}
.fi-rr-square-x:before {
    content: "\fb8f";
}
.fi-rr-square-y:before {
    content: "\fb90";
}
.fi-rr-square-z:before {
    content: "\fb91";
}
.fi-rr-square:before {
    content: "\fb92";
}
.fi-rr-squid:before {
    content: "\fb93";
}
.fi-rr-squircle:before {
    content: "\fb94";
}
.fi-rr-squirrel:before {
    content: "\fb95";
}
.fi-rr-staff:before {
    content: "\fb96";
}
.fi-rr-stage-concert:before {
    content: "\fb97";
}
.fi-rr-stage-theatre:before {
    content: "\fb98";
}
.fi-rr-stage:before {
    content: "\fb99";
}
.fi-rr-stairs:before {
    content: "\fb9a";
}
.fi-rr-stamp:before {
    content: "\fb9b";
}
.fi-rr-standard-definition:before {
    content: "\fb9c";
}
.fi-rr-star-and-crescent:before {
    content: "\fb9d";
}
.fi-rr-star-christmas:before {
    content: "\fb9e";
}
.fi-rr-star-comment-alt:before {
    content: "\fb9f";
}
.fi-rr-star-exclamation:before {
    content: "\fba0";
}
.fi-rr-star-octogram:before {
    content: "\fba1";
}
.fi-rr-star-of-david:before {
    content: "\fba2";
}
.fi-rr-star-sharp-half-stroke:before {
    content: "\fba3";
}
.fi-rr-star-sharp-half:before {
    content: "\fba4";
}
.fi-rr-star-shooting:before {
    content: "\fba5";
}
.fi-rr-star:before {
    content: "\fba6";
}
.fi-rr-starfighter:before {
    content: "\fba7";
}
.fi-rr-stars:before {
    content: "\fba8";
}
.fi-rr-state-country:before {
    content: "\fba9";
}
.fi-rr-stats:before {
    content: "\fbaa";
}
.fi-rr-steak:before {
    content: "\fbab";
}
.fi-rr-steering-wheel:before {
    content: "\fbac";
}
.fi-rr-step-backward:before {
    content: "\fbad";
}
.fi-rr-step-forward:before {
    content: "\fbae";
}
.fi-rr-sterling-sign:before {
    content: "\fbaf";
}
.fi-rr-stethoscope:before {
    content: "\fbb0";
}
.fi-rr-sticker:before {
    content: "\fbb1";
}
.fi-rr-stocking:before {
    content: "\fbb2";
}
.fi-rr-stomach:before {
    content: "\fbb3";
}
.fi-rr-stop-circle:before {
    content: "\fbb4";
}
.fi-rr-stop:before {
    content: "\fbb5";
}
.fi-rr-stopwatch:before {
    content: "\fbb6";
}
.fi-rr-store-alt:before {
    content: "\fbb7";
}
.fi-rr-store-lock:before {
    content: "\fbb8";
}
.fi-rr-store-slash:before {
    content: "\fbb9";
}
.fi-rr-strawberry:before {
    content: "\fbba";
}
.fi-rr-street-view:before {
    content: "\fbbb";
}
.fi-rr-stretcher:before {
    content: "\fbbc";
}
.fi-rr-strikethrough:before {
    content: "\fbbd";
}
.fi-rr-stroopwafel:before {
    content: "\fbbe";
}
.fi-rr-subscript:before {
    content: "\fbbf";
}
.fi-rr-subscription-alt:before {
    content: "\fbc0";
}
.fi-rr-subscription:before {
    content: "\fbc1";
}
.fi-rr-subtitles:before {
    content: "\fbc2";
}
.fi-rr-subway:before {
    content: "\fbc3";
}
.fi-rr-suitcase-alt:before {
    content: "\fbc4";
}
.fi-rr-summer:before {
    content: "\fbc5";
}
.fi-rr-sun-dust:before {
    content: "\fbc6";
}
.fi-rr-sun-plant-wilt:before {
    content: "\fbc7";
}
.fi-rr-sun:before {
    content: "\fbc8";
}
.fi-rr-sunday:before {
    content: "\fbc9";
}
.fi-rr-sunglasses-alt:before {
    content: "\fbca";
}
.fi-rr-sunglasses:before {
    content: "\fbcb";
}
.fi-rr-sunrise-alt:before {
    content: "\fbcc";
}
.fi-rr-sunrise:before {
    content: "\fbcd";
}
.fi-rr-sunset:before {
    content: "\fbce";
}
.fi-rr-superscript:before {
    content: "\fbcf";
}
.fi-rr-supplier-alt:before {
    content: "\fbd0";
}
.fi-rr-supplier:before {
    content: "\fbd1";
}
.fi-rr-surfing:before {
    content: "\fbd2";
}
.fi-rr-surprise:before {
    content: "\fbd3";
}
.fi-rr-sushi-alt:before {
    content: "\fbd4";
}
.fi-rr-sushi-roll:before {
    content: "\fbd5";
}
.fi-rr-sushi:before {
    content: "\fbd6";
}
.fi-rr-svg:before {
    content: "\fbd7";
}
.fi-rr-swatchbook:before {
    content: "\fbd8";
}
.fi-rr-swimmer:before {
    content: "\fbd9";
}
.fi-rr-swimming-pool:before {
    content: "\fbda";
}
.fi-rr-swipe-down:before {
    content: "\fbdb";
}
.fi-rr-swipe-left:before {
    content: "\fbdc";
}
.fi-rr-swipe-right:before {
    content: "\fbdd";
}
.fi-rr-swipe-up:before {
    content: "\fbde";
}
.fi-rr-sword:before {
    content: "\fbdf";
}
.fi-rr-symbol:before {
    content: "\fbe0";
}
.fi-rr-symbols:before {
    content: "\fbe1";
}
.fi-rr-synagogue:before {
    content: "\fbe2";
}
.fi-rr-syringe:before {
    content: "\fbe3";
}
.fi-rr-t:before {
    content: "\fbe4";
}
.fi-rr-tab-folder:before {
    content: "\fbe5";
}
.fi-rr-table-columns:before {
    content: "\fbe6";
}
.fi-rr-table-layout:before {
    content: "\fbe7";
}
.fi-rr-table-list:before {
    content: "\fbe8";
}
.fi-rr-table-picnic:before {
    content: "\fbe9";
}
.fi-rr-table-pivot:before {
    content: "\fbea";
}
.fi-rr-table-rows:before {
    content: "\fbeb";
}
.fi-rr-table-tree:before {
    content: "\fbec";
}
.fi-rr-table:before {
    content: "\fbed";
}
.fi-rr-tablet-android-alt:before {
    content: "\fbee";
}
.fi-rr-tablet-android:before {
    content: "\fbef";
}
.fi-rr-tablet-rugged:before {
    content: "\fbf0";
}
.fi-rr-tablet:before {
    content: "\fbf1";
}
.fi-rr-tachometer-alt-average:before {
    content: "\fbf2";
}
.fi-rr-tachometer-alt-fastest:before {
    content: "\fbf3";
}
.fi-rr-tachometer-alt-slow:before {
    content: "\fbf4";
}
.fi-rr-tachometer-alt-slowest:before {
    content: "\fbf5";
}
.fi-rr-tachometer-average:before {
    content: "\fbf6";
}
.fi-rr-tachometer-fast:before {
    content: "\fbf7";
}
.fi-rr-tachometer-fastest:before {
    content: "\fbf8";
}
.fi-rr-tachometer-slow:before {
    content: "\fbf9";
}
.fi-rr-tachometer-slowest:before {
    content: "\fbfa";
}
.fi-rr-tachometer:before {
    content: "\fbfb";
}
.fi-rr-taco:before {
    content: "\fbfc";
}
.fi-rr-tags:before {
    content: "\fbfd";
}
.fi-rr-tally-1:before {
    content: "\fbfe";
}
.fi-rr-tally-2:before {
    content: "\fbff";
}
.fi-rr-tally-3:before {
    content: "\fc00";
}
.fi-rr-tally-4:before {
    content: "\fc01";
}
.fi-rr-tally:before {
    content: "\fc02";
}
.fi-rr-tamale:before {
    content: "\fc03";
}
.fi-rr-tank-water:before {
    content: "\fc04";
}
.fi-rr-tap:before {
    content: "\fc05";
}
.fi-rr-tape:before {
    content: "\fc06";
}
.fi-rr-target-audience:before {
    content: "\fc07";
}
.fi-rr-target:before {
    content: "\fc08";
}
.fi-rr-tax-alt:before {
    content: "\fc09";
}
.fi-rr-tax:before {
    content: "\fc0a";
}
.fi-rr-taxi-bus:before {
    content: "\fc0b";
}
.fi-rr-taxi:before {
    content: "\fc0c";
}
.fi-rr-team-check-alt:before {
    content: "\fc0d";
}
.fi-rr-team-check:before {
    content: "\fc0e";
}
.fi-rr-teddy-bear:before {
    content: "\fc0f";
}
.fi-rr-teeth-open:before {
    content: "\fc10";
}
.fi-rr-telescope:before {
    content: "\fc11";
}
.fi-rr-temperature-down:before {
    content: "\fc12";
}
.fi-rr-temperature-frigid:before {
    content: "\fc13";
}
.fi-rr-temperature-high:before {
    content: "\fc14";
}
.fi-rr-temperature-list:before {
    content: "\fc15";
}
.fi-rr-temperature-low:before {
    content: "\fc16";
}
.fi-rr-temperature-up:before {
    content: "\fc17";
}
.fi-rr-template-alt:before {
    content: "\fc18";
}
.fi-rr-template:before {
    content: "\fc19";
}
.fi-rr-tenge:before {
    content: "\fc1a";
}
.fi-rr-tennis:before {
    content: "\fc1b";
}
.fi-rr-tent-arrow-down-to-line:before {
    content: "\fc1c";
}
.fi-rr-tent-arrow-left-right:before {
    content: "\fc1d";
}
.fi-rr-tent-arrow-turn-left:before {
    content: "\fc1e";
}
.fi-rr-tent-arrows-down:before {
    content: "\fc1f";
}
.fi-rr-tents:before {
    content: "\fc20";
}
.fi-rr-terminal:before {
    content: "\fc21";
}
.fi-rr-terrace:before {
    content: "\fc22";
}
.fi-rr-test-tube:before {
    content: "\fc23";
}
.fi-rr-test:before {
    content: "\fc24";
}
.fi-rr-text-box-dots:before {
    content: "\fc25";
}
.fi-rr-text-box-edit:before {
    content: "\fc26";
}
.fi-rr-text-box:before {
    content: "\fc27";
}
.fi-rr-text-check:before {
    content: "\fc28";
}
.fi-rr-text-height:before {
    content: "\fc29";
}
.fi-rr-text-shadow:before {
    content: "\fc2a";
}
.fi-rr-text-size:before {
    content: "\fc2b";
}
.fi-rr-text-slash:before {
    content: "\fc2c";
}
.fi-rr-text-width:before {
    content: "\fc2d";
}
.fi-rr-text:before {
    content: "\fc2e";
}
.fi-rr-theater-masks:before {
    content: "\fc2f";
}
.fi-rr-thermometer-alt:before {
    content: "\fc30";
}
.fi-rr-thermometer-empty:before {
    content: "\fc31";
}
.fi-rr-thermometer-full:before {
    content: "\fc32";
}
.fi-rr-thermometer-half:before {
    content: "\fc33";
}
.fi-rr-thermometer-quarter:before {
    content: "\fc34";
}
.fi-rr-thermometer-three-quarters:before {
    content: "\fc35";
}
.fi-rr-theta:before {
    content: "\fc36";
}
.fi-rr-third-award:before {
    content: "\fc37";
}
.fi-rr-third-laurel:before {
    content: "\fc38";
}
.fi-rr-third-medal:before {
    content: "\fc39";
}
.fi-rr-third:before {
    content: "\fc3a";
}
.fi-rr-thought-bubble:before {
    content: "\fc3b";
}
.fi-rr-thumbtack:before {
    content: "\fc3c";
}
.fi-rr-thunderstorm-moon:before {
    content: "\fc3d";
}
.fi-rr-thunderstorm-sun:before {
    content: "\fc3e";
}
.fi-rr-thunderstorm:before {
    content: "\fc3f";
}
.fi-rr-thursday:before {
    content: "\fc40";
}
.fi-rr-ticket-airline:before {
    content: "\fc41";
}
.fi-rr-ticket-alt:before {
    content: "\fc42";
}
.fi-rr-ticket:before {
    content: "\fc43";
}
.fi-rr-tickets-airline:before {
    content: "\fc44";
}
.fi-rr-tie:before {
    content: "\fc45";
}
.fi-rr-tilde:before {
    content: "\fc46";
}
.fi-rr-time-add:before {
    content: "\fc47";
}
.fi-rr-time-check:before {
    content: "\fc48";
}
.fi-rr-time-delete:before {
    content: "\fc49";
}
.fi-rr-time-fast:before {
    content: "\fc4a";
}
.fi-rr-time-forward-sixty:before {
    content: "\fc4b";
}
.fi-rr-time-forward-ten:before {
    content: "\fc4c";
}
.fi-rr-time-forward:before {
    content: "\fc4d";
}
.fi-rr-time-half-past:before {
    content: "\fc4e";
}
.fi-rr-time-oclock:before {
    content: "\fc4f";
}
.fi-rr-time-past:before {
    content: "\fc50";
}
.fi-rr-time-quarter-past:before {
    content: "\fc51";
}
.fi-rr-time-quarter-to:before {
    content: "\fc52";
}
.fi-rr-time-twenty-four:before {
    content: "\fc53";
}
.fi-rr-times-hexagon:before {
    content: "\fc54";
}
.fi-rr-tint-slash:before {
    content: "\fc55";
}
.fi-rr-tip-coin:before {
    content: "\fc56";
}
.fi-rr-tire-flat:before {
    content: "\fc57";
}
.fi-rr-tire-pressure-warning:before {
    content: "\fc58";
}
.fi-rr-tire-rugged:before {
    content: "\fc59";
}
.fi-rr-tire:before {
    content: "\fc5a";
}
.fi-rr-tired:before {
    content: "\fc5b";
}
.fi-rr-to-do-alt:before {
    content: "\fc5c";
}
.fi-rr-to-do:before {
    content: "\fc5d";
}
.fi-rr-together-people:before {
    content: "\fc5e";
}
.fi-rr-toggle-off:before {
    content: "\fc5f";
}
.fi-rr-toggle-on:before {
    content: "\fc60";
}
.fi-rr-toilet-paper-blank-under:before {
    content: "\fc61";
}
.fi-rr-toilet-paper-blank:before {
    content: "\fc62";
}
.fi-rr-toilet-paper-slash:before {
    content: "\fc63";
}
.fi-rr-toilet-paper-under-slash:before {
    content: "\fc64";
}
.fi-rr-toilet-paper-under:before {
    content: "\fc65";
}
.fi-rr-toilet:before {
    content: "\fc66";
}
.fi-rr-token:before {
    content: "\fc67";
}
.fi-rr-tomato:before {
    content: "\fc68";
}
.fi-rr-tombstone-alt:before {
    content: "\fc69";
}
.fi-rr-tombstone:before {
    content: "\fc6a";
}
.fi-rr-tool-box:before {
    content: "\fc6b";
}
.fi-rr-tool-crop:before {
    content: "\fc6c";
}
.fi-rr-tool-marquee:before {
    content: "\fc6d";
}
.fi-rr-tools:before {
    content: "\fc6e";
}
.fi-rr-tooth:before {
    content: "\fc6f";
}
.fi-rr-toothbrush:before {
    content: "\fc70";
}
.fi-rr-torii-gate:before {
    content: "\fc71";
}
.fi-rr-tornado:before {
    content: "\fc72";
}
.fi-rr-total:before {
    content: "\fc73";
}
.fi-rr-tour-guide-people:before {
    content: "\fc74";
}
.fi-rr-tour-virtual:before {
    content: "\fc75";
}
.fi-rr-tower-control:before {
    content: "\fc76";
}
.fi-rr-track:before {
    content: "\fc77";
}
.fi-rr-tractor:before {
    content: "\fc78";
}
.fi-rr-trademark:before {
    content: "\fc79";
}
.fi-rr-traffic-cone:before {
    content: "\fc7a";
}
.fi-rr-traffic-light-go:before {
    content: "\fc7b";
}
.fi-rr-traffic-light-slow:before {
    content: "\fc7c";
}
.fi-rr-traffic-light-stop:before {
    content: "\fc7d";
}
.fi-rr-traffic-light:before {
    content: "\fc7e";
}
.fi-rr-trailer:before {
    content: "\fc7f";
}
.fi-rr-train-journey:before {
    content: "\fc80";
}
.fi-rr-train-side:before {
    content: "\fc81";
}
.fi-rr-train-station-building:before {
    content: "\fc82";
}
.fi-rr-train-station:before {
    content: "\fc83";
}
.fi-rr-train-subway-tunnel:before {
    content: "\fc84";
}
.fi-rr-train-tram:before {
    content: "\fc85";
}
.fi-rr-train:before {
    content: "\fc86";
}
.fi-rr-tram:before {
    content: "\fc87";
}
.fi-rr-transform:before {
    content: "\fc88";
}
.fi-rr-transformation-block:before {
    content: "\fc89";
}
.fi-rr-transformation-circle:before {
    content: "\fc8a";
}
.fi-rr-transformation-design:before {
    content: "\fc8b";
}
.fi-rr-transformation-shapes:before {
    content: "\fc8c";
}
.fi-rr-transformer-bolt:before {
    content: "\fc8d";
}
.fi-rr-transgender:before {
    content: "\fc8e";
}
.fi-rr-transporter-1:before {
    content: "\fc8f";
}
.fi-rr-transporter-2:before {
    content: "\fc90";
}
.fi-rr-transporter-3:before {
    content: "\fc91";
}
.fi-rr-transporter-4:before {
    content: "\fc92";
}
.fi-rr-transporter-5:before {
    content: "\fc93";
}
.fi-rr-transporter-6:before {
    content: "\fc94";
}
.fi-rr-transporter-7:before {
    content: "\fc95";
}
.fi-rr-transporter-empty:before {
    content: "\fc96";
}
.fi-rr-transporter:before {
    content: "\fc97";
}
.fi-rr-trash-can-check:before {
    content: "\fc98";
}
.fi-rr-trash-can-clock:before {
    content: "\fc99";
}
.fi-rr-trash-can-list:before {
    content: "\fc9a";
}
.fi-rr-trash-can-plus:before {
    content: "\fc9b";
}
.fi-rr-trash-can-slash:before {
    content: "\fc9c";
}
.fi-rr-trash-check:before {
    content: "\fc9d";
}
.fi-rr-trash-clock:before {
    content: "\fc9e";
}
.fi-rr-trash-list:before {
    content: "\fc9f";
}
.fi-rr-trash-plus:before {
    content: "\fca0";
}
.fi-rr-trash-restore-alt:before {
    content: "\fca1";
}
.fi-rr-trash-restore:before {
    content: "\fca2";
}
.fi-rr-trash-slash:before {
    content: "\fca3";
}
.fi-rr-trash-undo-alt:before {
    content: "\fca4";
}
.fi-rr-trash-undo:before {
    content: "\fca5";
}
.fi-rr-trash-xmark:before {
    content: "\fca6";
}
.fi-rr-trash:before {
    content: "\fca7";
}
.fi-rr-treasure-chest:before {
    content: "\fca8";
}
.fi-rr-treatment:before {
    content: "\fca9";
}
.fi-rr-tree-alt:before {
    content: "\fcaa";
}
.fi-rr-tree-christmas:before {
    content: "\fcab";
}
.fi-rr-tree-deciduous:before {
    content: "\fcac";
}
.fi-rr-tree:before {
    content: "\fcad";
}
.fi-rr-trees-alt:before {
    content: "\fcae";
}
.fi-rr-trees:before {
    content: "\fcaf";
}
.fi-rr-triangle-music:before {
    content: "\fcb0";
}
.fi-rr-triangle-person-digging:before {
    content: "\fcb1";
}
.fi-rr-triangle-warning:before {
    content: "\fcb2";
}
.fi-rr-triangle:before {
    content: "\fcb3";
}
.fi-rr-trillium:before {
    content: "\fcb4";
}
.fi-rr-trophy-star:before {
    content: "\fcb5";
}
.fi-rr-trophy:before {
    content: "\fcb6";
}
.fi-rr-truck-arrow-left:before {
    content: "\fcb7";
}
.fi-rr-truck-arrow-right:before {
    content: "\fcb8";
}
.fi-rr-truck-bolt:before {
    content: "\fcb9";
}
.fi-rr-truck-box:before {
    content: "\fcba";
}
.fi-rr-truck-check:before {
    content: "\fcbb";
}
.fi-rr-truck-container-empty:before {
    content: "\fcbc";
}
.fi-rr-truck-container:before {
    content: "\fcbd";
}
.fi-rr-truck-couch:before {
    content: "\fcbe";
}
.fi-rr-truck-flatbed:before {
    content: "\fcbf";
}
.fi-rr-truck-front:before {
    content: "\fcc0";
}
.fi-rr-truck-loading:before {
    content: "\fcc1";
}
.fi-rr-truck-medical:before {
    content: "\fcc2";
}
.fi-rr-truck-monster:before {
    content: "\fcc3";
}
.fi-rr-truck-moving:before {
    content: "\fcc4";
}
.fi-rr-truck-pickup:before {
    content: "\fcc5";
}
.fi-rr-truck-plow:before {
    content: "\fcc6";
}
.fi-rr-truck-ramp:before {
    content: "\fcc7";
}
.fi-rr-truck-side:before {
    content: "\fcc8";
}
.fi-rr-truck-tow:before {
    content: "\fcc9";
}
.fi-rr-trumpet:before {
    content: "\fcca";
}
.fi-rr-tshirt:before {
    content: "\fccb";
}
.fi-rr-tty-answer:before {
    content: "\fccc";
}
.fi-rr-tty:before {
    content: "\fccd";
}
.fi-rr-tubes:before {
    content: "\fcce";
}
.fi-rr-tuesday:before {
    content: "\fccf";
}
.fi-rr-tugrik-sign:before {
    content: "\fcd0";
}
.fi-rr-turkey:before {
    content: "\fcd1";
}
.fi-rr-turntable:before {
    content: "\fcd2";
}
.fi-rr-turtle:before {
    content: "\fcd3";
}
.fi-rr-tv-music:before {
    content: "\fcd4";
}
.fi-rr-tv-retro:before {
    content: "\fcd5";
}
.fi-rr-typewriter:before {
    content: "\fcd6";
}
.fi-rr-u:before {
    content: "\fcd7";
}
.fi-rr-ufo-beam:before {
    content: "\fcd8";
}
.fi-rr-ufo:before {
    content: "\fcd9";
}
.fi-rr-ui-ux:before {
    content: "\fcda";
}
.fi-rr-umbrella-beach:before {
    content: "\fcdb";
}
.fi-rr-umbrella:before {
    content: "\fcdc";
}
.fi-rr-underline:before {
    content: "\fcdd";
}
.fi-rr-undo-alt:before {
    content: "\fcde";
}
.fi-rr-undo:before {
    content: "\fcdf";
}
.fi-rr-unicorn:before {
    content: "\fce0";
}
.fi-rr-uniform-martial-arts:before {
    content: "\fce1";
}
.fi-rr-universal-access:before {
    content: "\fce2";
}
.fi-rr-unlock:before {
    content: "\fce3";
}
.fi-rr-up-left:before {
    content: "\fce4";
}
.fi-rr-up-right-from-square:before {
    content: "\fce5";
}
.fi-rr-up-right:before {
    content: "\fce6";
}
.fi-rr-up:before {
    content: "\fce7";
}
.fi-rr-upload:before {
    content: "\fce8";
}
.fi-rr-url:before {
    content: "\fce9";
}
.fi-rr-usb-pendrive:before {
    content: "\fcea";
}
.fi-rr-usd-circle:before {
    content: "\fceb";
}
.fi-rr-usd-square:before {
    content: "\fcec";
}
.fi-rr-user-add:before {
    content: "\fced";
}
.fi-rr-user-alien:before {
    content: "\fcee";
}
.fi-rr-user-astronaut:before {
    content: "\fcef";
}
.fi-rr-user-check:before {
    content: "\fcf0";
}
.fi-rr-user-chef:before {
    content: "\fcf1";
}
.fi-rr-user-cowboy:before {
    content: "\fcf2";
}
.fi-rr-user-crown:before {
    content: "\fcf3";
}
.fi-rr-user-gear:before {
    content: "\fcf4";
}
.fi-rr-user-graduate:before {
    content: "\fcf5";
}
.fi-rr-user-headset:before {
    content: "\fcf6";
}
.fi-rr-user-helmet-safety:before {
    content: "\fcf7";
}
.fi-rr-user-injured:before {
    content: "\fcf8";
}
.fi-rr-user-interface-ui:before {
    content: "\fcf9";
}
.fi-rr-user-key:before {
    content: "\fcfa";
}
.fi-rr-user-lock:before {
    content: "\fcfb";
}
.fi-rr-user-md-chat:before {
    content: "\fcfc";
}
.fi-rr-user-md:before {
    content: "\fcfd";
}
.fi-rr-user-minus:before {
    content: "\fcfe";
}
.fi-rr-user-music:before {
    content: "\fcff";
}
.fi-rr-user-ninja:before {
    content: "\fd00";
}
.fi-rr-user-nurse:before {
    content: "\fd01";
}
.fi-rr-user-pen:before {
    content: "\fd02";
}
.fi-rr-user-pilot-tie:before {
    content: "\fd03";
}
.fi-rr-user-pilot:before {
    content: "\fd04";
}
.fi-rr-user-police:before {
    content: "\fd05";
}
.fi-rr-user-robot-xmarks:before {
    content: "\fd06";
}
.fi-rr-user-robot:before {
    content: "\fd07";
}
.fi-rr-user-salary:before {
    content: "\fd08";
}
.fi-rr-user-shield:before {
    content: "\fd09";
}
.fi-rr-user-slash:before {
    content: "\fd0a";
}
.fi-rr-user-tag:before {
    content: "\fd0b";
}
.fi-rr-user-time:before {
    content: "\fd0c";
}
.fi-rr-user-unlock:before {
    content: "\fd0d";
}
.fi-rr-user-visor:before {
    content: "\fd0e";
}
.fi-rr-user-xmark:before {
    content: "\fd0f";
}
.fi-rr-user:before {
    content: "\fd10";
}
.fi-rr-users-alt:before {
    content: "\fd11";
}
.fi-rr-users-class:before {
    content: "\fd12";
}
.fi-rr-users-gear:before {
    content: "\fd13";
}
.fi-rr-users-medical:before {
    content: "\fd14";
}
.fi-rr-users-slash:before {
    content: "\fd15";
}
.fi-rr-users:before {
    content: "\fd16";
}
.fi-rr-utensils:before {
    content: "\fd17";
}
.fi-rr-utility-pole-double:before {
    content: "\fd18";
}
.fi-rr-utility-pole:before {
    content: "\fd19";
}
.fi-rr-ux-browser:before {
    content: "\fd1a";
}
.fi-rr-ux:before {
    content: "\fd1b";
}
.fi-rr-v:before {
    content: "\fd1c";
}
.fi-rr-vacuum-robot:before {
    content: "\fd1d";
}
.fi-rr-vacuum:before {
    content: "\fd1e";
}
.fi-rr-value-absolute:before {
    content: "\fd1f";
}
.fi-rr-vault:before {
    content: "\fd20";
}
.fi-rr-vector-alt:before {
    content: "\fd21";
}
.fi-rr-vector-circle:before {
    content: "\fd22";
}
.fi-rr-vector-polygon:before {
    content: "\fd23";
}
.fi-rr-vector:before {
    content: "\fd24";
}
.fi-rr-venus-double:before {
    content: "\fd25";
}
.fi-rr-venus-mars:before {
    content: "\fd26";
}
.fi-rr-venus:before {
    content: "\fd27";
}
.fi-rr-vest-patches:before {
    content: "\fd28";
}
.fi-rr-vest:before {
    content: "\fd29";
}
.fi-rr-video-arrow-down-left:before {
    content: "\fd2a";
}
.fi-rr-video-arrow-up-right:before {
    content: "\fd2b";
}
.fi-rr-video-camera-alt:before {
    content: "\fd2c";
}
.fi-rr-video-camera:before {
    content: "\fd2d";
}
.fi-rr-video-duration:before {
    content: "\fd2e";
}
.fi-rr-video-plus:before {
    content: "\fd2f";
}
.fi-rr-video-slash:before {
    content: "\fd30";
}
.fi-rr-videoconference:before {
    content: "\fd31";
}
.fi-rr-vihara:before {
    content: "\fd32";
}
.fi-rr-violin:before {
    content: "\fd33";
}
.fi-rr-virus-slash:before {
    content: "\fd34";
}
.fi-rr-virus:before {
    content: "\fd35";
}
.fi-rr-viruses:before {
    content: "\fd36";
}
.fi-rr-visit:before {
    content: "\fd37";
}
.fi-rr-voicemail:before {
    content: "\fd38";
}
.fi-rr-volcano:before {
    content: "\fd39";
}
.fi-rr-volleyball:before {
    content: "\fd3a";
}
.fi-rr-volume-down:before {
    content: "\fd3b";
}
.fi-rr-volume-mute:before {
    content: "\fd3c";
}
.fi-rr-volume-off:before {
    content: "\fd3d";
}
.fi-rr-volume-slash:before {
    content: "\fd3e";
}
.fi-rr-volume:before {
    content: "\fd3f";
}
.fi-rr-vote-nay:before {
    content: "\fd40";
}
.fi-rr-vote-yea:before {
    content: "\fd41";
}
.fi-rr-vr-cardboard:before {
    content: "\fd42";
}
.fi-rr-w:before {
    content: "\fd43";
}
.fi-rr-waffle:before {
    content: "\fd44";
}
.fi-rr-wagon-covered:before {
    content: "\fd45";
}
.fi-rr-walker:before {
    content: "\fd46";
}
.fi-rr-walkie-talkie:before {
    content: "\fd47";
}
.fi-rr-walking:before {
    content: "\fd48";
}
.fi-rr-wallet-arrow:before {
    content: "\fd49";
}
.fi-rr-wallet:before {
    content: "\fd4a";
}
.fi-rr-warehouse-alt:before {
    content: "\fd4b";
}
.fi-rr-warranty:before {
    content: "\fd4c";
}
.fi-rr-washer:before {
    content: "\fd4d";
}
.fi-rr-waste-pollution:before {
    content: "\fd4e";
}
.fi-rr-waste:before {
    content: "\fd4f";
}
.fi-rr-watch-calculator:before {
    content: "\fd50";
}
.fi-rr-watch-fitness:before {
    content: "\fd51";
}
.fi-rr-watch-smart:before {
    content: "\fd52";
}
.fi-rr-watch:before {
    content: "\fd53";
}
.fi-rr-water-bottle:before {
    content: "\fd54";
}
.fi-rr-water-ladder:before {
    content: "\fd55";
}
.fi-rr-water-lower:before {
    content: "\fd56";
}
.fi-rr-water-rise:before {
    content: "\fd57";
}
.fi-rr-water:before {
    content: "\fd58";
}
.fi-rr-watermelon:before {
    content: "\fd59";
}
.fi-rr-wave-sine:before {
    content: "\fd5a";
}
.fi-rr-wave-square:before {
    content: "\fd5b";
}
.fi-rr-wave-triangle:before {
    content: "\fd5c";
}
.fi-rr-waveform-path:before {
    content: "\fd5d";
}
.fi-rr-waveform:before {
    content: "\fd5e";
}
.fi-rr-webcam-slash:before {
    content: "\fd5f";
}
.fi-rr-webcam:before {
    content: "\fd60";
}
.fi-rr-wednesday:before {
    content: "\fd61";
}
.fi-rr-whale:before {
    content: "\fd62";
}
.fi-rr-wheat-awn-circle-exclamation:before {
    content: "\fd63";
}
.fi-rr-wheat-awn-slash:before {
    content: "\fd64";
}
.fi-rr-wheat-awn:before {
    content: "\fd65";
}
.fi-rr-wheat-slash:before {
    content: "\fd66";
}
.fi-rr-wheat:before {
    content: "\fd67";
}
.fi-rr-wheelchair-move:before {
    content: "\fd68";
}
.fi-rr-wheelchair:before {
    content: "\fd69";
}
.fi-rr-whistle:before {
    content: "\fd6a";
}
.fi-rr-wifi-1:before {
    content: "\fd6b";
}
.fi-rr-wifi-2:before {
    content: "\fd6c";
}
.fi-rr-wifi-alt:before {
    content: "\fd6d";
}
.fi-rr-wifi-exclamation:before {
    content: "\fd6e";
}
.fi-rr-wifi-slash:before {
    content: "\fd6f";
}
.fi-rr-wifi:before {
    content: "\fd70";
}
.fi-rr-wind-turbine:before {
    content: "\fd71";
}
.fi-rr-wind-warning:before {
    content: "\fd72";
}
.fi-rr-wind:before {
    content: "\fd73";
}
.fi-rr-window-alt:before {
    content: "\fd74";
}
.fi-rr-window-frame-open:before {
    content: "\fd75";
}
.fi-rr-window-frame:before {
    content: "\fd76";
}
.fi-rr-window-maximize:before {
    content: "\fd77";
}
.fi-rr-window-minimize:before {
    content: "\fd78";
}
.fi-rr-window-restore:before {
    content: "\fd79";
}
.fi-rr-windsock:before {
    content: "\fd7a";
}
.fi-rr-wine-bottle:before {
    content: "\fd7b";
}
.fi-rr-wine-glass-crack:before {
    content: "\fd7c";
}
.fi-rr-wine-glass-empty:before {
    content: "\fd7d";
}
.fi-rr-wishlist-heart:before {
    content: "\fd7e";
}
.fi-rr-wishlist-star:before {
    content: "\fd7f";
}
.fi-rr-woman-head:before {
    content: "\fd80";
}
.fi-rr-woman-scientist:before {
    content: "\fd81";
}
.fi-rr-won-sign:before {
    content: "\fd82";
}
.fi-rr-work-in-progress:before {
    content: "\fd83";
}
.fi-rr-workflow-alt:before {
    content: "\fd84";
}
.fi-rr-workflow-setting-alt:before {
    content: "\fd85";
}
.fi-rr-workflow-setting:before {
    content: "\fd86";
}
.fi-rr-workflow:before {
    content: "\fd87";
}
.fi-rr-workshop:before {
    content: "\fd88";
}
.fi-rr-world:before {
    content: "\fd89";
}
.fi-rr-worm:before {
    content: "\fd8a";
}
.fi-rr-wreath:before {
    content: "\fd8b";
}
.fi-rr-wrench-alt:before {
    content: "\fd8c";
}
.fi-rr-wrench-simple:before {
    content: "\fd8d";
}
.fi-rr-x-ray:before {
    content: "\fd8e";
}
.fi-rr-x:before {
    content: "\fd8f";
}
.fi-rr-y:before {
    content: "\fd90";
}
.fi-rr-yen:before {
    content: "\fd91";
}
.fi-rr-yin-yang:before {
    content: "\fd92";
}
.fi-rr-z:before {
    content: "\fd93";
}
.fi-rr-zoom-in:before {
    content: "\fd94";
}
.fi-rr-zoom-out:before {
    content: "\fd95";
}
// Thin Rounded
.fi-tr-0:before {
    content: "\f101";
}
.fi-tr-1:before {
    content: "\f102";
}
.fi-tr-2:before {
    content: "\f103";
}
.fi-tr-3:before {
    content: "\f104";
}
.fi-tr-360-degrees:before {
    content: "\f105";
}
.fi-tr-4:before {
    content: "\f106";
}
.fi-tr-404:before {
    content: "\f107";
}
.fi-tr-5:before {
    content: "\f108";
}
.fi-tr-6:before {
    content: "\f109";
}
.fi-tr-7:before {
    content: "\f10a";
}
.fi-tr-8:before {
    content: "\f10b";
}
.fi-tr-9:before {
    content: "\f10c";
}
.fi-tr-a:before {
    content: "\f10d";
}
.fi-tr-accident:before {
    content: "\f10e";
}
.fi-tr-acorn:before {
    content: "\f10f";
}
.fi-tr-ad-paid:before {
    content: "\f110";
}
.fi-tr-ad:before {
    content: "\f111";
}
.fi-tr-add-image:before {
    content: "\f112";
}
.fi-tr-address-book:before {
    content: "\f113";
}
.fi-tr-address-card:before {
    content: "\f114";
}
.fi-tr-admin-alt:before {
    content: "\f115";
}
.fi-tr-admin:before {
    content: "\f116";
}
.fi-tr-age-restriction-eighteen:before {
    content: "\f117";
}
.fi-tr-age-restriction-seven:before {
    content: "\f118";
}
.fi-tr-age-restriction-six:before {
    content: "\f119";
}
.fi-tr-age-restriction-sixteen:before {
    content: "\f11a";
}
.fi-tr-age-restriction-thirteen:before {
    content: "\f11b";
}
.fi-tr-age-restriction-three:before {
    content: "\f11c";
}
.fi-tr-age-restriction-twelve:before {
    content: "\f11d";
}
.fi-tr-age-restriction-twenty-one:before {
    content: "\f11e";
}
.fi-tr-age-restriction-zero:before {
    content: "\f11f";
}
.fi-tr-air-conditioner:before {
    content: "\f120";
}
.fi-tr-air-freshener:before {
    content: "\f121";
}
.fi-tr-air-pollution:before {
    content: "\f122";
}
.fi-tr-airplane-journey:before {
    content: "\f123";
}
.fi-tr-airplay:before {
    content: "\f124";
}
.fi-tr-alarm-exclamation:before {
    content: "\f125";
}
.fi-tr-alarm-plus:before {
    content: "\f126";
}
.fi-tr-alarm-snooze:before {
    content: "\f127";
}
.fi-tr-album-circle-plus:before {
    content: "\f128";
}
.fi-tr-album-circle-user:before {
    content: "\f129";
}
.fi-tr-album-collection:before {
    content: "\f12a";
}
.fi-tr-album:before {
    content: "\f12b";
}
.fi-tr-algorithm:before {
    content: "\f12c";
}
.fi-tr-alicorn:before {
    content: "\f12d";
}
.fi-tr-alien:before {
    content: "\f12e";
}
.fi-tr-align-slash:before {
    content: "\f12f";
}
.fi-tr-analyse-alt:before {
    content: "\f130";
}
.fi-tr-analyse:before {
    content: "\f131";
}
.fi-tr-anchor:before {
    content: "\f132";
}
.fi-tr-angel:before {
    content: "\f133";
}
.fi-tr-angle-90:before {
    content: "\f134";
}
.fi-tr-angle-circle-down:before {
    content: "\f135";
}
.fi-tr-angle-circle-left:before {
    content: "\f136";
}
.fi-tr-angle-circle-right:before {
    content: "\f137";
}
.fi-tr-angle-circle-up:before {
    content: "\f138";
}
.fi-tr-angle-double-left:before {
    content: "\f139";
}
.fi-tr-angle-double-right:before {
    content: "\f13a";
}
.fi-tr-angle-double-small-down:before {
    content: "\f13b";
}
.fi-tr-angle-double-small-left:before {
    content: "\f13c";
}
.fi-tr-angle-double-small-right:before {
    content: "\f13d";
}
.fi-tr-angle-double-small-up:before {
    content: "\f13e";
}
.fi-tr-angle-down:before {
    content: "\f13f";
}
.fi-tr-angle-left:before {
    content: "\f140";
}
.fi-tr-angle-right:before {
    content: "\f141";
}
.fi-tr-angle-small-down:before {
    content: "\f142";
}
.fi-tr-angle-small-left:before {
    content: "\f143";
}
.fi-tr-angle-small-right:before {
    content: "\f144";
}
.fi-tr-angle-small-up:before {
    content: "\f145";
}
.fi-tr-angle-square-down:before {
    content: "\f146";
}
.fi-tr-angle-square-left:before {
    content: "\f147";
}
.fi-tr-angle-square-right:before {
    content: "\f148";
}
.fi-tr-angle-square-up:before {
    content: "\f149";
}
.fi-tr-angle-up:before {
    content: "\f14a";
}
.fi-tr-angle:before {
    content: "\f14b";
}
.fi-tr-angry:before {
    content: "\f14c";
}
.fi-tr-animated-icon:before {
    content: "\f14d";
}
.fi-tr-answer-alt:before {
    content: "\f14e";
}
.fi-tr-answer:before {
    content: "\f14f";
}
.fi-tr-apartment:before {
    content: "\f150";
}
.fi-tr-aperture:before {
    content: "\f151";
}
.fi-tr-api:before {
    content: "\f152";
}
.fi-tr-apple-core:before {
    content: "\f153";
}
.fi-tr-apple-crate:before {
    content: "\f154";
}
.fi-tr-apple:before {
    content: "\f155";
}
.fi-tr-archway:before {
    content: "\f156";
}
.fi-tr-arrow-alt-circle-down:before {
    content: "\f157";
}
.fi-tr-arrow-alt-circle-left:before {
    content: "\f158";
}
.fi-tr-arrow-alt-circle-right:before {
    content: "\f159";
}
.fi-tr-arrow-alt-circle-up:before {
    content: "\f15a";
}
.fi-tr-arrow-alt-down:before {
    content: "\f15b";
}
.fi-tr-arrow-alt-from-bottom:before {
    content: "\f15c";
}
.fi-tr-arrow-alt-from-left:before {
    content: "\f15d";
}
.fi-tr-arrow-alt-from-right:before {
    content: "\f15e";
}
.fi-tr-arrow-alt-from-top:before {
    content: "\f15f";
}
.fi-tr-arrow-alt-left:before {
    content: "\f160";
}
.fi-tr-arrow-alt-right:before {
    content: "\f161";
}
.fi-tr-arrow-alt-square-down:before {
    content: "\f162";
}
.fi-tr-arrow-alt-square-left:before {
    content: "\f163";
}
.fi-tr-arrow-alt-square-right:before {
    content: "\f164";
}
.fi-tr-arrow-alt-square-up:before {
    content: "\f165";
}
.fi-tr-arrow-alt-to-bottom:before {
    content: "\f166";
}
.fi-tr-arrow-alt-to-left:before {
    content: "\f167";
}
.fi-tr-arrow-alt-to-right:before {
    content: "\f168";
}
.fi-tr-arrow-alt-to-top:before {
    content: "\f169";
}
.fi-tr-arrow-alt-up:before {
    content: "\f16a";
}
.fi-tr-arrow-circle-down:before {
    content: "\f16b";
}
.fi-tr-arrow-circle-left:before {
    content: "\f16c";
}
.fi-tr-arrow-circle-right:before {
    content: "\f16d";
}
.fi-tr-arrow-circle-up:before {
    content: "\f16e";
}
.fi-tr-arrow-comparison:before {
    content: "\f16f";
}
.fi-tr-arrow-down-from-dotted-line:before {
    content: "\f170";
}
.fi-tr-arrow-down-left:before {
    content: "\f171";
}
.fi-tr-arrow-down-small-big:before {
    content: "\f172";
}
.fi-tr-arrow-down-to-dotted-line:before {
    content: "\f173";
}
.fi-tr-arrow-down-to-square:before {
    content: "\f174";
}
.fi-tr-arrow-down-triangle-square:before {
    content: "\f175";
}
.fi-tr-arrow-down:before {
    content: "\f176";
}
.fi-tr-arrow-from-bottom:before {
    content: "\f177";
}
.fi-tr-arrow-from-left:before {
    content: "\f178";
}
.fi-tr-arrow-from-right:before {
    content: "\f179";
}
.fi-tr-arrow-from-top:before {
    content: "\f17a";
}
.fi-tr-arrow-left-from-line:before {
    content: "\f17b";
}
.fi-tr-arrow-left:before {
    content: "\f17c";
}
.fi-tr-arrow-progress:before {
    content: "\f17d";
}
.fi-tr-arrow-right-to-bracket:before {
    content: "\f17e";
}
.fi-tr-arrow-right:before {
    content: "\f17f";
}
.fi-tr-arrow-small-down:before {
    content: "\f180";
}
.fi-tr-arrow-small-left:before {
    content: "\f181";
}
.fi-tr-arrow-small-right:before {
    content: "\f182";
}
.fi-tr-arrow-small-up:before {
    content: "\f183";
}
.fi-tr-arrow-square-down:before {
    content: "\f184";
}
.fi-tr-arrow-square-left:before {
    content: "\f185";
}
.fi-tr-arrow-square-right:before {
    content: "\f186";
}
.fi-tr-arrow-square-up:before {
    content: "\f187";
}
.fi-tr-arrow-to-bottom:before {
    content: "\f188";
}
.fi-tr-arrow-to-left:before {
    content: "\f189";
}
.fi-tr-arrow-to-right:before {
    content: "\f18a";
}
.fi-tr-arrow-to-top:before {
    content: "\f18b";
}
.fi-tr-arrow-trend-down:before {
    content: "\f18c";
}
.fi-tr-arrow-trend-up:before {
    content: "\f18d";
}
.fi-tr-arrow-turn-down-left:before {
    content: "\f18e";
}
.fi-tr-arrow-turn-down-right:before {
    content: "\f18f";
}
.fi-tr-arrow-up-from-dotted-line:before {
    content: "\f190";
}
.fi-tr-arrow-up-from-square:before {
    content: "\f191";
}
.fi-tr-arrow-up-left-from-circle:before {
    content: "\f192";
}
.fi-tr-arrow-up-left:before {
    content: "\f193";
}
.fi-tr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f194";
}
.fi-tr-arrow-up-right-from-square:before {
    content: "\f195";
}
.fi-tr-arrow-up-right:before {
    content: "\f196";
}
.fi-tr-arrow-up-small-big:before {
    content: "\f197";
}
.fi-tr-arrow-up-square-triangle:before {
    content: "\f198";
}
.fi-tr-arrow-up-to-dotted-line:before {
    content: "\f199";
}
.fi-tr-arrow-up:before {
    content: "\f19a";
}
.fi-tr-arrows-alt-h:before {
    content: "\f19b";
}
.fi-tr-arrows-alt-v:before {
    content: "\f19c";
}
.fi-tr-arrows-alt:before {
    content: "\f19d";
}
.fi-tr-arrows-cross:before {
    content: "\f19e";
}
.fi-tr-arrows-from-dotted-line:before {
    content: "\f19f";
}
.fi-tr-arrows-from-line:before {
    content: "\f1a0";
}
.fi-tr-arrows-h-copy:before {
    content: "\f1a1";
}
.fi-tr-arrows-h:before {
    content: "\f1a2";
}
.fi-tr-arrows-repeat-1:before {
    content: "\f1a3";
}
.fi-tr-arrows-repeat:before {
    content: "\f1a4";
}
.fi-tr-arrows-retweet:before {
    content: "\f1a5";
}
.fi-tr-arrows-to-dotted-line:before {
    content: "\f1a6";
}
.fi-tr-arrows-to-line:before {
    content: "\f1a7";
}
.fi-tr-arrows:before {
    content: "\f1a8";
}
.fi-tr-artificial-intelligence:before {
    content: "\f1a9";
}
.fi-tr-assessment-alt:before {
    content: "\f1aa";
}
.fi-tr-assessment:before {
    content: "\f1ab";
}
.fi-tr-assign:before {
    content: "\f1ac";
}
.fi-tr-assistive-listening-systems:before {
    content: "\f1ad";
}
.fi-tr-at:before {
    content: "\f1ae";
}
.fi-tr-attribution-pen:before {
    content: "\f1af";
}
.fi-tr-attribution-pencil:before {
    content: "\f1b0";
}
.fi-tr-aubergine:before {
    content: "\f1b1";
}
.fi-tr-auction:before {
    content: "\f1b2";
}
.fi-tr-audio-description-slash:before {
    content: "\f1b3";
}
.fi-tr-audit-alt:before {
    content: "\f1b4";
}
.fi-tr-audit:before {
    content: "\f1b5";
}
.fi-tr-austral-sign:before {
    content: "\f1b6";
}
.fi-tr-avocado:before {
    content: "\f1b7";
}
.fi-tr-award:before {
    content: "\f1b8";
}
.fi-tr-axe-battle:before {
    content: "\f1b9";
}
.fi-tr-axe:before {
    content: "\f1ba";
}
.fi-tr-b:before {
    content: "\f1bb";
}
.fi-tr-baby-carriage:before {
    content: "\f1bc";
}
.fi-tr-baby:before {
    content: "\f1bd";
}
.fi-tr-background:before {
    content: "\f1be";
}
.fi-tr-bacon:before {
    content: "\f1bf";
}
.fi-tr-bacteria:before {
    content: "\f1c0";
}
.fi-tr-bacterium:before {
    content: "\f1c1";
}
.fi-tr-badge-check:before {
    content: "\f1c2";
}
.fi-tr-badge-dollar:before {
    content: "\f1c3";
}
.fi-tr-badge-leaf:before {
    content: "\f1c4";
}
.fi-tr-badge-percent:before {
    content: "\f1c5";
}
.fi-tr-badge-sheriff:before {
    content: "\f1c6";
}
.fi-tr-badger-honey:before {
    content: "\f1c7";
}
.fi-tr-badget-check-alt:before {
    content: "\f1c8";
}
.fi-tr-badminton:before {
    content: "\f1c9";
}
.fi-tr-bag-seedling:before {
    content: "\f1ca";
}
.fi-tr-bags-shopping:before {
    content: "\f1cb";
}
.fi-tr-baguette:before {
    content: "\f1cc";
}
.fi-tr-bahai:before {
    content: "\f1cd";
}
.fi-tr-baht-sign:before {
    content: "\f1ce";
}
.fi-tr-balance-scale-left:before {
    content: "\f1cf";
}
.fi-tr-balance-scale-right:before {
    content: "\f1d0";
}
.fi-tr-ball-pile:before {
    content: "\f1d1";
}
.fi-tr-balloon:before {
    content: "\f1d2";
}
.fi-tr-balloons:before {
    content: "\f1d3";
}
.fi-tr-ballot-check:before {
    content: "\f1d4";
}
.fi-tr-ballot:before {
    content: "\f1d5";
}
.fi-tr-ban-bug:before {
    content: "\f1d6";
}
.fi-tr-banana:before {
    content: "\f1d7";
}
.fi-tr-bangladeshi-taka-sign:before {
    content: "\f1d8";
}
.fi-tr-banjo:before {
    content: "\f1d9";
}
.fi-tr-banner-2:before {
    content: "\f1da";
}
.fi-tr-banner-3:before {
    content: "\f1db";
}
.fi-tr-banner-4:before {
    content: "\f1dc";
}
.fi-tr-banner-5:before {
    content: "\f1dd";
}
.fi-tr-banner:before {
    content: "\f1de";
}
.fi-tr-barber-pole:before {
    content: "\f1df";
}
.fi-tr-barcode-read:before {
    content: "\f1e0";
}
.fi-tr-barcode-scan:before {
    content: "\f1e1";
}
.fi-tr-barcode:before {
    content: "\f1e2";
}
.fi-tr-bars-filter:before {
    content: "\f1e3";
}
.fi-tr-bars-progress:before {
    content: "\f1e4";
}
.fi-tr-bars-sort:before {
    content: "\f1e5";
}
.fi-tr-bars-staggered:before {
    content: "\f1e6";
}
.fi-tr-baseball-alt:before {
    content: "\f1e7";
}
.fi-tr-basket-shopping-simple:before {
    content: "\f1e8";
}
.fi-tr-basketball-hoop:before {
    content: "\f1e9";
}
.fi-tr-bat:before {
    content: "\f1ea";
}
.fi-tr-bath:before {
    content: "\f1eb";
}
.fi-tr-battery-bolt:before {
    content: "\f1ec";
}
.fi-tr-battery-empty:before {
    content: "\f1ed";
}
.fi-tr-battery-exclamation:before {
    content: "\f1ee";
}
.fi-tr-battery-full:before {
    content: "\f1ef";
}
.fi-tr-battery-half:before {
    content: "\f1f0";
}
.fi-tr-battery-quarter:before {
    content: "\f1f1";
}
.fi-tr-battery-slash:before {
    content: "\f1f2";
}
.fi-tr-battery-three-quarters:before {
    content: "\f1f3";
}
.fi-tr-beacon:before {
    content: "\f1f4";
}
.fi-tr-bed-alt:before {
    content: "\f1f5";
}
.fi-tr-bed-bunk:before {
    content: "\f1f6";
}
.fi-tr-bed-empty:before {
    content: "\f1f7";
}
.fi-tr-bee:before {
    content: "\f1f8";
}
.fi-tr-beer-mug-empty:before {
    content: "\f1f9";
}
.fi-tr-beer:before {
    content: "\f1fa";
}
.fi-tr-bell-concierge:before {
    content: "\f1fb";
}
.fi-tr-bell-slash:before {
    content: "\f1fc";
}
.fi-tr-bells:before {
    content: "\f1fd";
}
.fi-tr-bench-tree:before {
    content: "\f1fe";
}
.fi-tr-benefit-porcent:before {
    content: "\f1ff";
}
.fi-tr-betamax:before {
    content: "\f200";
}
.fi-tr-bicycle-journey:before {
    content: "\f201";
}
.fi-tr-biking-mountain:before {
    content: "\f202";
}
.fi-tr-biking:before {
    content: "\f203";
}
.fi-tr-bill-arrow:before {
    content: "\f204";
}
.fi-tr-binary-circle-check:before {
    content: "\f205";
}
.fi-tr-binary-lock:before {
    content: "\f206";
}
.fi-tr-binary-slash:before {
    content: "\f207";
}
.fi-tr-binary:before {
    content: "\f208";
}
.fi-tr-binoculars:before {
    content: "\f209";
}
.fi-tr-bio-leaves:before {
    content: "\f20a";
}
.fi-tr-bio:before {
    content: "\f20b";
}
.fi-tr-biohazard:before {
    content: "\f20c";
}
.fi-tr-bird:before {
    content: "\f20d";
}
.fi-tr-bitcoin-sign:before {
    content: "\f20e";
}
.fi-tr-blanket:before {
    content: "\f20f";
}
.fi-tr-blender-phone:before {
    content: "\f210";
}
.fi-tr-blender:before {
    content: "\f211";
}
.fi-tr-blinds-open:before {
    content: "\f212";
}
.fi-tr-blinds-raised:before {
    content: "\f213";
}
.fi-tr-blinds:before {
    content: "\f214";
}
.fi-tr-block-brick:before {
    content: "\f215";
}
.fi-tr-block-question:before {
    content: "\f216";
}
.fi-tr-block-quote:before {
    content: "\f217";
}
.fi-tr-block:before {
    content: "\f218";
}
.fi-tr-blockchain-3:before {
    content: "\f219";
}
.fi-tr-blog-pencil:before {
    content: "\f21a";
}
.fi-tr-blog-text:before {
    content: "\f21b";
}
.fi-tr-blueberries:before {
    content: "\f21c";
}
.fi-tr-blueprint:before {
    content: "\f21d";
}
.fi-tr-bolt-auto:before {
    content: "\f21e";
}
.fi-tr-bolt-slash:before {
    content: "\f21f";
}
.fi-tr-bolt:before {
    content: "\f220";
}
.fi-tr-bomb:before {
    content: "\f221";
}
.fi-tr-bone-break:before {
    content: "\f222";
}
.fi-tr-bone:before {
    content: "\f223";
}
.fi-tr-bong:before {
    content: "\f224";
}
.fi-tr-bonus-star:before {
    content: "\f225";
}
.fi-tr-bonus:before {
    content: "\f226";
}
.fi-tr-book-arrow-right:before {
    content: "\f227";
}
.fi-tr-book-arrow-up:before {
    content: "\f228";
}
.fi-tr-book-atlas:before {
    content: "\f229";
}
.fi-tr-book-bookmark:before {
    content: "\f22a";
}
.fi-tr-book-circle-arrow-right:before {
    content: "\f22b";
}
.fi-tr-book-circle-arrow-up:before {
    content: "\f22c";
}
.fi-tr-book-copy:before {
    content: "\f22d";
}
.fi-tr-book-dead:before {
    content: "\f22e";
}
.fi-tr-book-font:before {
    content: "\f22f";
}
.fi-tr-book-medical:before {
    content: "\f230";
}
.fi-tr-book-open-cover:before {
    content: "\f231";
}
.fi-tr-book-open-reader:before {
    content: "\f232";
}
.fi-tr-book-quran:before {
    content: "\f233";
}
.fi-tr-book-section:before {
    content: "\f234";
}
.fi-tr-book-spells:before {
    content: "\f235";
}
.fi-tr-book-tanakh:before {
    content: "\f236";
}
.fi-tr-book-user:before {
    content: "\f237";
}
.fi-tr-Booking:before {
    content: "\f238";
}
.fi-tr-bookmark-slash:before {
    content: "\f239";
}
.fi-tr-books-medical:before {
    content: "\f23a";
}
.fi-tr-books:before {
    content: "\f23b";
}
.fi-tr-boot-heeled:before {
    content: "\f23c";
}
.fi-tr-boot:before {
    content: "\f23d";
}
.fi-tr-booth-curtain:before {
    content: "\f23e";
}
.fi-tr-border-all:before {
    content: "\f23f";
}
.fi-tr-border-bottom:before {
    content: "\f240";
}
.fi-tr-border-center-h:before {
    content: "\f241";
}
.fi-tr-border-center-v:before {
    content: "\f242";
}
.fi-tr-border-inner:before {
    content: "\f243";
}
.fi-tr-border-left:before {
    content: "\f244";
}
.fi-tr-border-none:before {
    content: "\f245";
}
.fi-tr-border-outer:before {
    content: "\f246";
}
.fi-tr-border-right:before {
    content: "\f247";
}
.fi-tr-border-style-alt:before {
    content: "\f248";
}
.fi-tr-border-style:before {
    content: "\f249";
}
.fi-tr-border-top:before {
    content: "\f24a";
}
.fi-tr-boss:before {
    content: "\f24b";
}
.fi-tr-bottle-droplet:before {
    content: "\f24c";
}
.fi-tr-bottle:before {
    content: "\f24d";
}
.fi-tr-bow-arrow:before {
    content: "\f24e";
}
.fi-tr-bowl-chopsticks-noodles:before {
    content: "\f24f";
}
.fi-tr-bowl-chopsticks:before {
    content: "\f250";
}
.fi-tr-bowl-rice:before {
    content: "\f251";
}
.fi-tr-bowl-scoop:before {
    content: "\f252";
}
.fi-tr-bowl-scoops:before {
    content: "\f253";
}
.fi-tr-bowl-soft-serve:before {
    content: "\f254";
}
.fi-tr-bowl-spoon:before {
    content: "\f255";
}
.fi-tr-bowling-ball:before {
    content: "\f256";
}
.fi-tr-bowling-pins:before {
    content: "\f257";
}
.fi-tr-box-ballot:before {
    content: "\f258";
}
.fi-tr-box-check:before {
    content: "\f259";
}
.fi-tr-box-circle-check:before {
    content: "\f25a";
}
.fi-tr-box-dollar:before {
    content: "\f25b";
}
.fi-tr-box-fragile:before {
    content: "\f25c";
}
.fi-tr-box-heart:before {
    content: "\f25d";
}
.fi-tr-box-open-full:before {
    content: "\f25e";
}
.fi-tr-box-open:before {
    content: "\f25f";
}
.fi-tr-box-tissue:before {
    content: "\f260";
}
.fi-tr-box-up:before {
    content: "\f261";
}
.fi-tr-boxes:before {
    content: "\f262";
}
.fi-tr-boxing-glove:before {
    content: "\f263";
}
.fi-tr-bracket-curly-right:before {
    content: "\f264";
}
.fi-tr-bracket-curly:before {
    content: "\f265";
}
.fi-tr-bracket-round-right:before {
    content: "\f266";
}
.fi-tr-bracket-round:before {
    content: "\f267";
}
.fi-tr-bracket-square-right:before {
    content: "\f268";
}
.fi-tr-bracket-square:before {
    content: "\f269";
}
.fi-tr-brackets-curly:before {
    content: "\f26a";
}
.fi-tr-brackets-round:before {
    content: "\f26b";
}
.fi-tr-brackets-square:before {
    content: "\f26c";
}
.fi-tr-braille-a:before {
    content: "\f26d";
}
.fi-tr-braille-b:before {
    content: "\f26e";
}
.fi-tr-braille-c:before {
    content: "\f26f";
}
.fi-tr-braille-d:before {
    content: "\f270";
}
.fi-tr-braille-e:before {
    content: "\f271";
}
.fi-tr-braille-g:before {
    content: "\f272";
}
.fi-tr-braille-h:before {
    content: "\f273";
}
.fi-tr-braille-i:before {
    content: "\f274";
}
.fi-tr-braille-j:before {
    content: "\f275";
}
.fi-tr-braille-k:before {
    content: "\f276";
}
.fi-tr-braille-l:before {
    content: "\f277";
}
.fi-tr-braille-m:before {
    content: "\f278";
}
.fi-tr-braille-n-alt:before {
    content: "\f279";
}
.fi-tr-braille-n:before {
    content: "\f27a";
}
.fi-tr-braille-o:before {
    content: "\f27b";
}
.fi-tr-braille-p:before {
    content: "\f27c";
}
.fi-tr-braille-q:before {
    content: "\f27d";
}
.fi-tr-braille-r:before {
    content: "\f27e";
}
.fi-tr-braille-s:before {
    content: "\f27f";
}
.fi-tr-braille-t:before {
    content: "\f280";
}
.fi-tr-braille-u:before {
    content: "\f281";
}
.fi-tr-braille-v:before {
    content: "\f282";
}
.fi-tr-braille-w:before {
    content: "\f283";
}
.fi-tr-braille-x:before {
    content: "\f284";
}
.fi-tr-braille-y:before {
    content: "\f285";
}
.fi-tr-braille-z:before {
    content: "\f286";
}
.fi-tr-braille:before {
    content: "\f287";
}
.fi-tr-brain-circuit:before {
    content: "\f288";
}
.fi-tr-brain:before {
    content: "\f289";
}
.fi-tr-brand:before {
    content: "\f28a";
}
.fi-tr-branding:before {
    content: "\f28b";
}
.fi-tr-bread-loaf:before {
    content: "\f28c";
}
.fi-tr-bread-slice-butter:before {
    content: "\f28d";
}
.fi-tr-bread-slice:before {
    content: "\f28e";
}
.fi-tr-bread:before {
    content: "\f28f";
}
.fi-tr-briefcase-arrow-right:before {
    content: "\f290";
}
.fi-tr-briefcase-blank:before {
    content: "\f291";
}
.fi-tr-brightness-low:before {
    content: "\f292";
}
.fi-tr-brightness:before {
    content: "\f293";
}
.fi-tr-bring-forward:before {
    content: "\f294";
}
.fi-tr-bring-front:before {
    content: "\f295";
}
.fi-tr-broadcast-tower:before {
    content: "\f296";
}
.fi-tr-broccoli:before {
    content: "\f297";
}
.fi-tr-broken-image:before {
    content: "\f298";
}
.fi-tr-broom-ball:before {
    content: "\f299";
}
.fi-tr-browser-ui:before {
    content: "\f29a";
}
.fi-tr-browser:before {
    content: "\f29b";
}
.fi-tr-browsers:before {
    content: "\f29c";
}
.fi-tr-bucket:before {
    content: "\f29d";
}
.fi-tr-budget-alt:before {
    content: "\f29e";
}
.fi-tr-budget:before {
    content: "\f29f";
}
.fi-tr-bug-slash:before {
    content: "\f2a0";
}
.fi-tr-bugs:before {
    content: "\f2a1";
}
.fi-tr-build-alt:before {
    content: "\f2a2";
}
.fi-tr-build:before {
    content: "\f2a3";
}
.fi-tr-builder:before {
    content: "\f2a4";
}
.fi-tr-building-circle-arrow-right:before {
    content: "\f2a5";
}
.fi-tr-building-ngo:before {
    content: "\f2a6";
}
.fi-tr-bullet:before {
    content: "\f2a7";
}
.fi-tr-bullhorn:before {
    content: "\f2a8";
}
.fi-tr-bullseye-arrow:before {
    content: "\f2a9";
}
.fi-tr-bullseye-pointer:before {
    content: "\f2aa";
}
.fi-tr-bullseye:before {
    content: "\f2ab";
}
.fi-tr-burger-alt:before {
    content: "\f2ac";
}
.fi-tr-burger-fries:before {
    content: "\f2ad";
}
.fi-tr-burger-glass:before {
    content: "\f2ae";
}
.fi-tr-burrito:before {
    content: "\f2af";
}
.fi-tr-bus-alt:before {
    content: "\f2b0";
}
.fi-tr-bus:before {
    content: "\f2b1";
}
.fi-tr-business-time:before {
    content: "\f2b2";
}
.fi-tr-Butter:before {
    content: "\f2b3";
}
.fi-tr-butterfly:before {
    content: "\f2b4";
}
.fi-tr-c:before {
    content: "\f2b5";
}
.fi-tr-cabin:before {
    content: "\f2b6";
}
.fi-tr-cactus:before {
    content: "\f2b7";
}
.fi-tr-cake-birthday:before {
    content: "\f2b8";
}
.fi-tr-cake-slice:before {
    content: "\f2b9";
}
.fi-tr-cake-wedding:before {
    content: "\f2ba";
}
.fi-tr-calculator-bill:before {
    content: "\f2bb";
}
.fi-tr-calculator-money:before {
    content: "\f2bc";
}
.fi-tr-calculator-simple:before {
    content: "\f2bd";
}
.fi-tr-calendar-arrow-down:before {
    content: "\f2be";
}
.fi-tr-calendar-arrow-up:before {
    content: "\f2bf";
}
.fi-tr-calendar-check:before {
    content: "\f2c0";
}
.fi-tr-calendar-clock:before {
    content: "\f2c1";
}
.fi-tr-calendar-day:before {
    content: "\f2c2";
}
.fi-tr-calendar-days:before {
    content: "\f2c3";
}
.fi-tr-calendar-exclamation:before {
    content: "\f2c4";
}
.fi-tr-calendar-heart:before {
    content: "\f2c5";
}
.fi-tr-calendar-image:before {
    content: "\f2c6";
}
.fi-tr-calendar-lines-pen:before {
    content: "\f2c7";
}
.fi-tr-calendar-lines:before {
    content: "\f2c8";
}
.fi-tr-calendar-minus:before {
    content: "\f2c9";
}
.fi-tr-calendar-pen:before {
    content: "\f2ca";
}
.fi-tr-calendar-plus:before {
    content: "\f2cb";
}
.fi-tr-calendar-salary:before {
    content: "\f2cc";
}
.fi-tr-calendar-star:before {
    content: "\f2cd";
}
.fi-tr-calendar-week:before {
    content: "\f2ce";
}
.fi-tr-calendar-xmark:before {
    content: "\f2cf";
}
.fi-tr-calendars:before {
    content: "\f2d0";
}
.fi-tr-call-duration:before {
    content: "\f2d1";
}
.fi-tr-call-history:before {
    content: "\f2d2";
}
.fi-tr-call-incoming:before {
    content: "\f2d3";
}
.fi-tr-call-missed:before {
    content: "\f2d4";
}
.fi-tr-call-outgoing:before {
    content: "\f2d5";
}
.fi-tr-camcorder:before {
    content: "\f2d6";
}
.fi-tr-camera-cctv:before {
    content: "\f2d7";
}
.fi-tr-camera-movie:before {
    content: "\f2d8";
}
.fi-tr-camera-retro:before {
    content: "\f2d9";
}
.fi-tr-camera-rotate:before {
    content: "\f2da";
}
.fi-tr-camera-security:before {
    content: "\f2db";
}
.fi-tr-camera-slash:before {
    content: "\f2dc";
}
.fi-tr-camera-viewfinder:before {
    content: "\f2dd";
}
.fi-tr-campfire:before {
    content: "\f2de";
}
.fi-tr-can-food:before {
    content: "\f2df";
}
.fi-tr-candle-holder:before {
    content: "\f2e0";
}
.fi-tr-candy-alt:before {
    content: "\f2e1";
}
.fi-tr-candy-bar:before {
    content: "\f2e2";
}
.fi-tr-candy-cane:before {
    content: "\f2e3";
}
.fi-tr-candy-corn:before {
    content: "\f2e4";
}
.fi-tr-candy-sweet:before {
    content: "\f2e5";
}
.fi-tr-candy:before {
    content: "\f2e6";
}
.fi-tr-Cannabis:before {
    content: "\f2e7";
}
.fi-tr-canned-food:before {
    content: "\f2e8";
}
.fi-tr-capsules:before {
    content: "\f2e9";
}
.fi-tr-car-alt:before {
    content: "\f2ea";
}
.fi-tr-car-battery:before {
    content: "\f2eb";
}
.fi-tr-car-bolt:before {
    content: "\f2ec";
}
.fi-tr-car-building:before {
    content: "\f2ed";
}
.fi-tr-car-bump:before {
    content: "\f2ee";
}
.fi-tr-car-bus:before {
    content: "\f2ef";
}
.fi-tr-car-circle-bolt:before {
    content: "\f2f0";
}
.fi-tr-car-crash:before {
    content: "\f2f1";
}
.fi-tr-car-garage:before {
    content: "\f2f2";
}
.fi-tr-car-journey:before {
    content: "\f2f3";
}
.fi-tr-car-mechanic:before {
    content: "\f2f4";
}
.fi-tr-car-rear:before {
    content: "\f2f5";
}
.fi-tr-car-side-bolt:before {
    content: "\f2f6";
}
.fi-tr-car-side:before {
    content: "\f2f7";
}
.fi-tr-car-tilt:before {
    content: "\f2f8";
}
.fi-tr-car-wash:before {
    content: "\f2f9";
}
.fi-tr-caravan-alt:before {
    content: "\f2fa";
}
.fi-tr-caravan:before {
    content: "\f2fb";
}
.fi-tr-card-club:before {
    content: "\f2fc";
}
.fi-tr-card-diamond:before {
    content: "\f2fd";
}
.fi-tr-card-heart:before {
    content: "\f2fe";
}
.fi-tr-card-spade:before {
    content: "\f2ff";
}
.fi-tr-caret-circle-down:before {
    content: "\f300";
}
.fi-tr-caret-circle-right:before {
    content: "\f301";
}
.fi-tr-caret-circle-up:before {
    content: "\f302";
}
.fi-tr-caret-down:before {
    content: "\f303";
}
.fi-tr-caret-left:before {
    content: "\f304";
}
.fi-tr-caret-right:before {
    content: "\f305";
}
.fi-tr-caret-square-down:before {
    content: "\f306";
}
.fi-tr-caret-square-left_1:before {
    content: "\f307";
}
.fi-tr-caret-square-left:before {
    content: "\f308";
}
.fi-tr-caret-square-right:before {
    content: "\f309";
}
.fi-tr-caret-square-up:before {
    content: "\f30a";
}
.fi-tr-caret-up:before {
    content: "\f30b";
}
.fi-tr-carrot:before {
    content: "\f30c";
}
.fi-tr-cars-crash:before {
    content: "\f30d";
}
.fi-tr-cars:before {
    content: "\f30e";
}
.fi-tr-cart-arrow-down:before {
    content: "\f30f";
}
.fi-tr-cart-minus:before {
    content: "\f310";
}
.fi-tr-cart-shopping-fast:before {
    content: "\f311";
}
.fi-tr-cash-register:before {
    content: "\f312";
}
.fi-tr-cassette-tape:before {
    content: "\f313";
}
.fi-tr-cassette-vhs:before {
    content: "\f314";
}
.fi-tr-castle:before {
    content: "\f315";
}
.fi-tr-cat-head:before {
    content: "\f316";
}
.fi-tr-cat-space:before {
    content: "\f317";
}
.fi-tr-cat:before {
    content: "\f318";
}
.fi-tr-catalog-alt:before {
    content: "\f319";
}
.fi-tr-catalog-magazine:before {
    content: "\f31a";
}
.fi-tr-catalog:before {
    content: "\f31b";
}
.fi-tr-category-alt:before {
    content: "\f31c";
}
.fi-tr-category:before {
    content: "\f31d";
}
.fi-tr-cauldron:before {
    content: "\f31e";
}
.fi-tr-cedi-sign:before {
    content: "\f31f";
}
.fi-tr-cello:before {
    content: "\f320";
}
.fi-tr-cent-sign:before {
    content: "\f321";
}
.fi-tr-chair-office:before {
    content: "\f322";
}
.fi-tr-chair:before {
    content: "\f323";
}
.fi-tr-chalkboard-user:before {
    content: "\f324";
}
.fi-tr-challenge-alt:before {
    content: "\f325";
}
.fi-tr-challenge:before {
    content: "\f326";
}
.fi-tr-channel:before {
    content: "\f327";
}
.fi-tr-charging-station:before {
    content: "\f328";
}
.fi-tr-chart-area:before {
    content: "\f329";
}
.fi-tr-chart-arrow-down:before {
    content: "\f32a";
}
.fi-tr-chart-arrow-grow:before {
    content: "\f32b";
}
.fi-tr-chart-bullet:before {
    content: "\f32c";
}
.fi-tr-chart-candlestick:before {
    content: "\f32d";
}
.fi-tr-chart-connected:before {
    content: "\f32e";
}
.fi-tr-chart-gantt:before {
    content: "\f32f";
}
.fi-tr-chart-histogram:before {
    content: "\f330";
}
.fi-tr-chart-line-up:before {
    content: "\f331";
}
.fi-tr-chart-mixed:before {
    content: "\f332";
}
.fi-tr-chart-network:before {
    content: "\f333";
}
.fi-tr-chart-pie-alt:before {
    content: "\f334";
}
.fi-tr-chart-pie:before {
    content: "\f335";
}
.fi-tr-chart-pyramid:before {
    content: "\f336";
}
.fi-tr-chart-radar:before {
    content: "\f337";
}
.fi-tr-chart-scatter-3d:before {
    content: "\f338";
}
.fi-tr-chart-scatter-bubble:before {
    content: "\f339";
}
.fi-tr-chart-scatter:before {
    content: "\f33a";
}
.fi-tr-chart-set-theory:before {
    content: "\f33b";
}
.fi-tr-chart-simple-horizontal:before {
    content: "\f33c";
}
.fi-tr-chart-simple:before {
    content: "\f33d";
}
.fi-tr-chart-tree-map:before {
    content: "\f33e";
}
.fi-tr-chart-tree:before {
    content: "\f33f";
}
.fi-tr-chart-user:before {
    content: "\f340";
}
.fi-tr-chart-waterfall:before {
    content: "\f341";
}
.fi-tr-chatbot-speech-bubble:before {
    content: "\f342";
}
.fi-tr-chatbot:before {
    content: "\f343";
}
.fi-tr-cheap-bill:before {
    content: "\f344";
}
.fi-tr-cheap-dollar:before {
    content: "\f345";
}
.fi-tr-cheap-stack-dollar:before {
    content: "\f346";
}
.fi-tr-cheap-stack:before {
    content: "\f347";
}
.fi-tr-cheap:before {
    content: "\f348";
}
.fi-tr-check-circle:before {
    content: "\f349";
}
.fi-tr-check-double:before {
    content: "\f34a";
}
.fi-tr-check-in-calendar:before {
    content: "\f34b";
}
.fi-tr-check-out-calendar:before {
    content: "\f34c";
}
.fi-tr-cheese-alt:before {
    content: "\f34d";
}
.fi-tr-cheese:before {
    content: "\f34e";
}
.fi-tr-cheeseburger:before {
    content: "\f34f";
}
.fi-tr-cherry:before {
    content: "\f350";
}
.fi-tr-chess-bishop:before {
    content: "\f351";
}
.fi-tr-chess-board:before {
    content: "\f352";
}
.fi-tr-chess-clock-alt:before {
    content: "\f353";
}
.fi-tr-chess-clock:before {
    content: "\f354";
}
.fi-tr-chess-king-alt:before {
    content: "\f355";
}
.fi-tr-chess-king:before {
    content: "\f356";
}
.fi-tr-chess-knight-alt:before {
    content: "\f357";
}
.fi-tr-chess-knight:before {
    content: "\f358";
}
.fi-tr-chess-pawn-alt:before {
    content: "\f359";
}
.fi-tr-chess-pawn:before {
    content: "\f35a";
}
.fi-tr-chess-queen-alt:before {
    content: "\f35b";
}
.fi-tr-chess-queen:before {
    content: "\f35c";
}
.fi-tr-chess-rook-alt:before {
    content: "\f35d";
}
.fi-tr-chess-rook:before {
    content: "\f35e";
}
.fi-tr-chess:before {
    content: "\f35f";
}
.fi-tr-chevron-double-down:before {
    content: "\f360";
}
.fi-tr-chevron-double-up:before {
    content: "\f361";
}
.fi-tr-child-head:before {
    content: "\f362";
}
.fi-tr-child:before {
    content: "\f363";
}
.fi-tr-chimney:before {
    content: "\f364";
}
.fi-tr-chocolate-bar:before {
    content: "\f365";
}
.fi-tr-choose-alt:before {
    content: "\f366";
}
.fi-tr-choose:before {
    content: "\f367";
}
.fi-tr-circle-0:before {
    content: "\f368";
}
.fi-tr-circle-1:before {
    content: "\f369";
}
.fi-tr-circle-2:before {
    content: "\f36a";
}
.fi-tr-circle-3:before {
    content: "\f36b";
}
.fi-tr-circle-4:before {
    content: "\f36c";
}
.fi-tr-circle-5:before {
    content: "\f36d";
}
.fi-tr-circle-6:before {
    content: "\f36e";
}
.fi-tr-circle-7:before {
    content: "\f36f";
}
.fi-tr-circle-8:before {
    content: "\f370";
}
.fi-tr-circle-9:before {
    content: "\f371";
}
.fi-tr-circle-a:before {
    content: "\f372";
}
.fi-tr-circle-b:before {
    content: "\f373";
}
.fi-tr-circle-bolt:before {
    content: "\f374";
}
.fi-tr-circle-book-open:before {
    content: "\f375";
}
.fi-tr-circle-bookmark:before {
    content: "\f376";
}
.fi-tr-circle-c:before {
    content: "\f377";
}
.fi-tr-circle-calendar:before {
    content: "\f378";
}
.fi-tr-circle-camera:before {
    content: "\f379";
}
.fi-tr-circle-d:before {
    content: "\f37a";
}
.fi-tr-circle-dashed:before {
    content: "\f37b";
}
.fi-tr-circle-e:before {
    content: "\f37c";
}
.fi-tr-circle-ellipsis-vertical:before {
    content: "\f37d";
}
.fi-tr-circle-ellipsis:before {
    content: "\f37e";
}
.fi-tr-circle-envelope:before {
    content: "\f37f";
}
.fi-tr-circle-exclamation-check:before {
    content: "\f380";
}
.fi-tr-circle-f:before {
    content: "\f381";
}
.fi-tr-circle-g:before {
    content: "\f382";
}
.fi-tr-circle-h:before {
    content: "\f383";
}
.fi-tr-circle-half-stroke:before {
    content: "\f384";
}
.fi-tr-circle-half:before {
    content: "\f385";
}
.fi-tr-circle-heart:before {
    content: "\f386";
}
.fi-tr-circle-i:before {
    content: "\f387";
}
.fi-tr-circle-j:before {
    content: "\f388";
}
.fi-tr-circle-k:before {
    content: "\f389";
}
.fi-tr-circle-l:before {
    content: "\f38a";
}
.fi-tr-circle-m:before {
    content: "\f38b";
}
.fi-tr-circle-microphone-lines:before {
    content: "\f38c";
}
.fi-tr-circle-microphone:before {
    content: "\f38d";
}
.fi-tr-circle-n:before {
    content: "\f38e";
}
.fi-tr-circle-o:before {
    content: "\f38f";
}
.fi-tr-circle-p:before {
    content: "\f390";
}
.fi-tr-circle-phone-flip:before {
    content: "\f391";
}
.fi-tr-circle-phone-hangup:before {
    content: "\f392";
}
.fi-tr-circle-phone:before {
    content: "\f393";
}
.fi-tr-circle-q:before {
    content: "\f394";
}
.fi-tr-circle-quarters-alt:before {
    content: "\f395";
}
.fi-tr-circle-quarters:before {
    content: "\f396";
}
.fi-tr-circle-r:before {
    content: "\f397";
}
.fi-tr-circle-s:before {
    content: "\f398";
}
.fi-tr-circle-small:before {
    content: "\f399";
}
.fi-tr-circle-star:before {
    content: "\f39a";
}
.fi-tr-circle-t:before {
    content: "\f39b";
}
.fi-tr-circle-three-quarters:before {
    content: "\f39c";
}
.fi-tr-circle-trash:before {
    content: "\f39d";
}
.fi-tr-circle-u:before {
    content: "\f39e";
}
.fi-tr-circle-user:before {
    content: "\f39f";
}
.fi-tr-circle-v:before {
    content: "\f3a0";
}
.fi-tr-circle-video:before {
    content: "\f3a1";
}
.fi-tr-circle-w:before {
    content: "\f3a2";
}
.fi-tr-circle-waveform-lines:before {
    content: "\f3a3";
}
.fi-tr-circle-x:before {
    content: "\f3a4";
}
.fi-tr-circle-xmark:before {
    content: "\f3a5";
}
.fi-tr-circle-y:before {
    content: "\f3a6";
}
.fi-tr-circle-z:before {
    content: "\f3a7";
}
.fi-tr-circle:before {
    content: "\f3a8";
}
.fi-tr-citrus-slice:before {
    content: "\f3a9";
}
.fi-tr-citrus:before {
    content: "\f3aa";
}
.fi-tr-city:before {
    content: "\f3ab";
}
.fi-tr-clapperboard-play:before {
    content: "\f3ac";
}
.fi-tr-clapperboard:before {
    content: "\f3ad";
}
.fi-tr-clarinet:before {
    content: "\f3ae";
}
.fi-tr-claw-marks:before {
    content: "\f3af";
}
.fi-tr-clear-alt:before {
    content: "\f3b0";
}
.fi-tr-clipboard-check:before {
    content: "\f3b1";
}
.fi-tr-clipboard-exclamation:before {
    content: "\f3b2";
}
.fi-tr-clipboard-list-check:before {
    content: "\f3b3";
}
.fi-tr-clipboard-list:before {
    content: "\f3b4";
}
.fi-tr-clipboard-prescription:before {
    content: "\f3b5";
}
.fi-tr-clipboard-user:before {
    content: "\f3b6";
}
.fi-tr-clipboard:before {
    content: "\f3b7";
}
.fi-tr-clock-desk:before {
    content: "\f3b8";
}
.fi-tr-clock-eight-thirty:before {
    content: "\f3b9";
}
.fi-tr-clock-eleven-thirty:before {
    content: "\f3ba";
}
.fi-tr-clock-eleven:before {
    content: "\f3bb";
}
.fi-tr-clock-five-thirty:before {
    content: "\f3bc";
}
.fi-tr-clock-five:before {
    content: "\f3bd";
}
.fi-tr-clock-four-thirty:before {
    content: "\f3be";
}
.fi-tr-clock-nine-thirty:before {
    content: "\f3bf";
}
.fi-tr-clock-nine:before {
    content: "\f3c0";
}
.fi-tr-clock-one-thirty:before {
    content: "\f3c1";
}
.fi-tr-clock-one:before {
    content: "\f3c2";
}
.fi-tr-clock-seven-thirty:before {
    content: "\f3c3";
}
.fi-tr-clock-seven:before {
    content: "\f3c4";
}
.fi-tr-clock-six-thirty:before {
    content: "\f3c5";
}
.fi-tr-clock-six:before {
    content: "\f3c6";
}
.fi-tr-clock-ten-thirty:before {
    content: "\f3c7";
}
.fi-tr-clock-ten:before {
    content: "\f3c8";
}
.fi-tr-clock-three-thirty:before {
    content: "\f3c9";
}
.fi-tr-clock-three:before {
    content: "\f3ca";
}
.fi-tr-clock-twelve-thirty:before {
    content: "\f3cb";
}
.fi-tr-clock-twelve:before {
    content: "\f3cc";
}
.fi-tr-clock-two-thirty:before {
    content: "\f3cd";
}
.fi-tr-clock-two:before {
    content: "\f3ce";
}
.fi-tr-clock-up-arrow:before {
    content: "\f3cf";
}
.fi-tr-clone:before {
    content: "\f3d0";
}
.fi-tr-closed-captioning-slash:before {
    content: "\f3d1";
}
.fi-tr-clothes-hanger:before {
    content: "\f3d2";
}
.fi-tr-cloud-check:before {
    content: "\f3d3";
}
.fi-tr-cloud-code:before {
    content: "\f3d4";
}
.fi-tr-cloud-disabled:before {
    content: "\f3d5";
}
.fi-tr-cloud-download-alt:before {
    content: "\f3d6";
}
.fi-tr-cloud-download:before {
    content: "\f3d7";
}
.fi-tr-cloud-drizzle:before {
    content: "\f3d8";
}
.fi-tr-cloud-exclamation:before {
    content: "\f3d9";
}
.fi-tr-cloud-hail-mixed:before {
    content: "\f3da";
}
.fi-tr-cloud-hail:before {
    content: "\f3db";
}
.fi-tr-cloud-meatball:before {
    content: "\f3dc";
}
.fi-tr-cloud-moon-rain:before {
    content: "\f3dd";
}
.fi-tr-cloud-moon:before {
    content: "\f3de";
}
.fi-tr-cloud-question:before {
    content: "\f3df";
}
.fi-tr-cloud-rain:before {
    content: "\f3e0";
}
.fi-tr-cloud-rainbow:before {
    content: "\f3e1";
}
.fi-tr-cloud-share:before {
    content: "\f3e2";
}
.fi-tr-cloud-showers-heavy:before {
    content: "\f3e3";
}
.fi-tr-cloud-showers:before {
    content: "\f3e4";
}
.fi-tr-cloud-sleet:before {
    content: "\f3e5";
}
.fi-tr-cloud-snow:before {
    content: "\f3e6";
}
.fi-tr-cloud-sun-rain:before {
    content: "\f3e7";
}
.fi-tr-cloud-sun:before {
    content: "\f3e8";
}
.fi-tr-cloud-upload-alt:before {
    content: "\f3e9";
}
.fi-tr-clouds-moon:before {
    content: "\f3ea";
}
.fi-tr-clouds-sun:before {
    content: "\f3eb";
}
.fi-tr-clouds:before {
    content: "\f3ec";
}
.fi-tr-clover-alt:before {
    content: "\f3ed";
}
.fi-tr-club:before {
    content: "\f3ee";
}
.fi-tr-cocktail:before {
    content: "\f3ef";
}
.fi-tr-coconut:before {
    content: "\f3f0";
}
.fi-tr-code-branch:before {
    content: "\f3f1";
}
.fi-tr-code-commit:before {
    content: "\f3f2";
}
.fi-tr-code-compare:before {
    content: "\f3f3";
}
.fi-tr-code-fork:before {
    content: "\f3f4";
}
.fi-tr-code-merge:before {
    content: "\f3f5";
}
.fi-tr-code-pull-request-closed:before {
    content: "\f3f6";
}
.fi-tr-code-pull-request-draft:before {
    content: "\f3f7";
}
.fi-tr-code-pull-request:before {
    content: "\f3f8";
}
.fi-tr-code-simple:before {
    content: "\f3f9";
}
.fi-tr-coffee-bean:before {
    content: "\f3fa";
}
.fi-tr-coffee-beans:before {
    content: "\f3fb";
}
.fi-tr-coffee-pot:before {
    content: "\f3fc";
}
.fi-tr-coffee:before {
    content: "\f3fd";
}
.fi-tr-coffin-cross:before {
    content: "\f3fe";
}
.fi-tr-coffin:before {
    content: "\f3ff";
}
.fi-tr-coin-up-arrow:before {
    content: "\f400";
}
.fi-tr-coin:before {
    content: "\f401";
}
.fi-tr-coins:before {
    content: "\f402";
}
.fi-tr-colon-sign:before {
    content: "\f403";
}
.fi-tr-columns-3:before {
    content: "\f404";
}
.fi-tr-comet:before {
    content: "\f405";
}
.fi-tr-command:before {
    content: "\f406";
}
.fi-tr-comment-alt-check:before {
    content: "\f407";
}
.fi-tr-comment-alt-dots:before {
    content: "\f408";
}
.fi-tr-comment-alt-edit:before {
    content: "\f409";
}
.fi-tr-comment-alt-medical:before {
    content: "\f40a";
}
.fi-tr-comment-alt-middle-top:before {
    content: "\f40b";
}
.fi-tr-comment-alt-middle:before {
    content: "\f40c";
}
.fi-tr-comment-alt-minus:before {
    content: "\f40d";
}
.fi-tr-comment-alt-music:before {
    content: "\f40e";
}
.fi-tr-comment-arrow-down:before {
    content: "\f40f";
}
.fi-tr-comment-arrow-up-right:before {
    content: "\f410";
}
.fi-tr-comment-arrow-up:before {
    content: "\f411";
}
.fi-tr-comment-check:before {
    content: "\f412";
}
.fi-tr-comment-code:before {
    content: "\f413";
}
.fi-tr-comment-dollar:before {
    content: "\f414";
}
.fi-tr-comment-dots:before {
    content: "\f415";
}
.fi-tr-comment-exclamation:before {
    content: "\f416";
}
.fi-tr-comment-heart:before {
    content: "\f417";
}
.fi-tr-comment-image:before {
    content: "\f418";
}
.fi-tr-comment-info:before {
    content: "\f419";
}
.fi-tr-comment-medical:before {
    content: "\f41a";
}
.fi-tr-comment-minus:before {
    content: "\f41b";
}
.fi-tr-comment-pen:before {
    content: "\f41c";
}
.fi-tr-comment-question:before {
    content: "\f41d";
}
.fi-tr-comment-quote:before {
    content: "\f41e";
}
.fi-tr-comment-slash:before {
    content: "\f41f";
}
.fi-tr-comment-smile:before {
    content: "\f420";
}
.fi-tr-comment-sms:before {
    content: "\f421";
}
.fi-tr-comment-text:before {
    content: "\f422";
}
.fi-tr-comment-user:before {
    content: "\f423";
}
.fi-tr-comment-xmark:before {
    content: "\f424";
}
.fi-tr-comments-dollar:before {
    content: "\f425";
}
.fi-tr-comments-question-check:before {
    content: "\f426";
}
.fi-tr-comments-question:before {
    content: "\f427";
}
.fi-tr-comments:before {
    content: "\f428";
}
.fi-tr-compass-slash:before {
    content: "\f429";
}
.fi-tr-completed:before {
    content: "\f42a";
}
.fi-tr-compliance-clipboard:before {
    content: "\f42b";
}
.fi-tr-compliance-document:before {
    content: "\f42c";
}
.fi-tr-compliance:before {
    content: "\f42d";
}
.fi-tr-compress-alt:before {
    content: "\f42e";
}
.fi-tr-computer-classic:before {
    content: "\f42f";
}
.fi-tr-computer-mouse:before {
    content: "\f430";
}
.fi-tr-computer-speaker:before {
    content: "\f431";
}
.fi-tr-concierge-bell:before {
    content: "\f432";
}
.fi-tr-confetti:before {
    content: "\f433";
}
.fi-tr-constellation:before {
    content: "\f434";
}
.fi-tr-container-storage:before {
    content: "\f435";
}
.fi-tr-convert-document:before {
    content: "\f436";
}
.fi-tr-conveyor-belt-alt:before {
    content: "\f437";
}
.fi-tr-conveyor-belt-empty:before {
    content: "\f438";
}
.fi-tr-conveyor-belt:before {
    content: "\f439";
}
.fi-tr-cookie-alt:before {
    content: "\f43a";
}
.fi-tr-copy-image:before {
    content: "\f43b";
}
.fi-tr-corn:before {
    content: "\f43c";
}
.fi-tr-corporate-alt:before {
    content: "\f43d";
}
.fi-tr-corporate:before {
    content: "\f43e";
}
.fi-tr-couch:before {
    content: "\f43f";
}
.fi-tr-cow-alt:before {
    content: "\f440";
}
.fi-tr-cow:before {
    content: "\f441";
}
.fi-tr-cowbell-circle-plus:before {
    content: "\f442";
}
.fi-tr-cowbell-more:before {
    content: "\f443";
}
.fi-tr-cowbell:before {
    content: "\f444";
}
.fi-tr-crab:before {
    content: "\f445";
}
.fi-tr-crate-empty:before {
    content: "\f446";
}
.fi-tr-cricket:before {
    content: "\f447";
}
.fi-tr-crm-alt:before {
    content: "\f448";
}
.fi-tr-crm-computer:before {
    content: "\f449";
}
.fi-tr-CRM:before {
    content: "\f44a";
}
.fi-tr-croissant:before {
    content: "\f44b";
}
.fi-tr-cross-religion:before {
    content: "\f44c";
}
.fi-tr-crow:before {
    content: "\f44d";
}
.fi-tr-crutch:before {
    content: "\f44e";
}
.fi-tr-crutches:before {
    content: "\f44f";
}
.fi-tr-cruzeiro-sign:before {
    content: "\f450";
}
.fi-tr-cryptocurrency:before {
    content: "\f451";
}
.fi-tr-crystal-ball:before {
    content: "\f452";
}
.fi-tr-cube:before {
    content: "\f453";
}
.fi-tr-cubes-stacked:before {
    content: "\f454";
}
.fi-tr-cubes:before {
    content: "\f455";
}
.fi-tr-cucumber:before {
    content: "\f456";
}
.fi-tr-cup-straw-swoosh:before {
    content: "\f457";
}
.fi-tr-cup-straw:before {
    content: "\f458";
}
.fi-tr-cup-togo:before {
    content: "\f459";
}
.fi-tr-cupcake-alt:before {
    content: "\f45a";
}
.fi-tr-cupcake:before {
    content: "\f45b";
}
.fi-tr-curling:before {
    content: "\f45c";
}
.fi-tr-curve-alt:before {
    content: "\f45d";
}
.fi-tr-curve-arrow:before {
    content: "\f45e";
}
.fi-tr-curve:before {
    content: "\f45f";
}
.fi-tr-custard:before {
    content: "\f460";
}
.fi-tr-customer-care:before {
    content: "\f461";
}
.fi-tr-customer-service:before {
    content: "\f462";
}
.fi-tr-customization-cogwheel:before {
    content: "\f463";
}
.fi-tr-customization:before {
    content: "\f464";
}
.fi-tr-customize-computer:before {
    content: "\f465";
}
.fi-tr-customize-edit:before {
    content: "\f466";
}
.fi-tr-customize:before {
    content: "\f467";
}
.fi-tr-CV:before {
    content: "\f468";
}
.fi-tr-d:before {
    content: "\f469";
}
.fi-tr-dagger:before {
    content: "\f46a";
}
.fi-tr-daily-calendar:before {
    content: "\f46b";
}
.fi-tr-damage:before {
    content: "\f46c";
}
.fi-tr-dashboard:before {
    content: "\f46d";
}
.fi-tr-database:before {
    content: "\f46e";
}
.fi-tr-debt:before {
    content: "\f46f";
}
.fi-tr-deer-rudolph:before {
    content: "\f470";
}
.fi-tr-deer:before {
    content: "\f471";
}
.fi-tr-delete-right:before {
    content: "\f472";
}
.fi-tr-democrat:before {
    content: "\f473";
}
.fi-tr-department-structure:before {
    content: "\f474";
}
.fi-tr-department:before {
    content: "\f475";
}
.fi-tr-deposit-alt:before {
    content: "\f476";
}
.fi-tr-deposit:before {
    content: "\f477";
}
.fi-tr-description-alt:before {
    content: "\f478";
}
.fi-tr-description:before {
    content: "\f479";
}
.fi-tr-desktop-arrow-down:before {
    content: "\f47a";
}
.fi-tr-desktop-wallpaper:before {
    content: "\f47b";
}
.fi-tr-devices:before {
    content: "\f47c";
}
.fi-tr-dewpoint:before {
    content: "\f47d";
}
.fi-tr-dharmachakra:before {
    content: "\f47e";
}
.fi-tr-diagram-cells:before {
    content: "\f47f";
}
.fi-tr-diagram-lean-canvas:before {
    content: "\f480";
}
.fi-tr-diagram-nested:before {
    content: "\f481";
}
.fi-tr-diagram-next:before {
    content: "\f482";
}
.fi-tr-diagram-predecessor:before {
    content: "\f483";
}
.fi-tr-diagram-previous:before {
    content: "\f484";
}
.fi-tr-diagram-project:before {
    content: "\f485";
}
.fi-tr-diagram-sankey:before {
    content: "\f486";
}
.fi-tr-diagram-subtask:before {
    content: "\f487";
}
.fi-tr-diagram-successor:before {
    content: "\f488";
}
.fi-tr-diagram-venn:before {
    content: "\f489";
}
.fi-tr-dial-high:before {
    content: "\f48a";
}
.fi-tr-dial-low:before {
    content: "\f48b";
}
.fi-tr-dial-max:before {
    content: "\f48c";
}
.fi-tr-dial-med-low:before {
    content: "\f48d";
}
.fi-tr-dial-med:before {
    content: "\f48e";
}
.fi-tr-dial-min:before {
    content: "\f48f";
}
.fi-tr-dial-off:before {
    content: "\f490";
}
.fi-tr-dial:before {
    content: "\f491";
}
.fi-tr-diamond-exclamation:before {
    content: "\f492";
}
.fi-tr-diamond-turn-right:before {
    content: "\f493";
}
.fi-tr-diary-bookmark-down:before {
    content: "\f494";
}
.fi-tr-diary-bookmarks:before {
    content: "\f495";
}
.fi-tr-diary-clasp:before {
    content: "\f496";
}
.fi-tr-dice-alt:before {
    content: "\f497";
}
.fi-tr-dice-d10:before {
    content: "\f498";
}
.fi-tr-dice-d12:before {
    content: "\f499";
}
.fi-tr-dice-d20:before {
    content: "\f49a";
}
.fi-tr-dice-d4:before {
    content: "\f49b";
}
.fi-tr-dice-d6:before {
    content: "\f49c";
}
.fi-tr-dice-d8:before {
    content: "\f49d";
}
.fi-tr-dice-four:before {
    content: "\f49e";
}
.fi-tr-dice-one:before {
    content: "\f49f";
}
.fi-tr-dice-six:before {
    content: "\f4a0";
}
.fi-tr-dice-three:before {
    content: "\f4a1";
}
.fi-tr-dice-two:before {
    content: "\f4a2";
}
.fi-tr-digging:before {
    content: "\f4a3";
}
.fi-tr-digital-tachograph:before {
    content: "\f4a4";
}
.fi-tr-dinner:before {
    content: "\f4a5";
}
.fi-tr-disc-drive:before {
    content: "\f4a6";
}
.fi-tr-disco-ball:before {
    content: "\f4a7";
}
.fi-tr-discover:before {
    content: "\f4a8";
}
.fi-tr-disease:before {
    content: "\f4a9";
}
.fi-tr-display-arrow-down:before {
    content: "\f4aa";
}
.fi-tr-display-code:before {
    content: "\f4ab";
}
.fi-tr-display-medical:before {
    content: "\f4ac";
}
.fi-tr-display-slash:before {
    content: "\f4ad";
}
.fi-tr-distribute-spacing-horizontal:before {
    content: "\f4ae";
}
.fi-tr-distribute-spacing-vertical:before {
    content: "\f4af";
}
.fi-tr-dizzy:before {
    content: "\f4b0";
}
.fi-tr-dna:before {
    content: "\f4b1";
}
.fi-tr-do-not-enter:before {
    content: "\f4b2";
}
.fi-tr-document-paid:before {
    content: "\f4b3";
}
.fi-tr-dog-leashed:before {
    content: "\f4b4";
}
.fi-tr-dog:before {
    content: "\f4b5";
}
.fi-tr-dolly-flatbed-alt:before {
    content: "\f4b6";
}
.fi-tr-dolly-flatbed-empty:before {
    content: "\f4b7";
}
.fi-tr-dolly-flatbed:before {
    content: "\f4b8";
}
.fi-tr-dolphin:before {
    content: "\f4b9";
}
.fi-tr-domino-effect:before {
    content: "\f4ba";
}
.fi-tr-donate:before {
    content: "\f4bb";
}
.fi-tr-dong-sign:before {
    content: "\f4bc";
}
.fi-tr-donut:before {
    content: "\f4bd";
}
.fi-tr-door-closed:before {
    content: "\f4be";
}
.fi-tr-door-open:before {
    content: "\f4bf";
}
.fi-tr-dot-circle:before {
    content: "\f4c0";
}
.fi-tr-dot-pending:before {
    content: "\f4c1";
}
.fi-tr-dove:before {
    content: "\f4c2";
}
.fi-tr-down-left:before {
    content: "\f4c3";
}
.fi-tr-down-right:before {
    content: "\f4c4";
}
.fi-tr-down-to-line:before {
    content: "\f4c5";
}
.fi-tr-down:before {
    content: "\f4c6";
}
.fi-tr-drafting-compass:before {
    content: "\f4c7";
}
.fi-tr-dragon:before {
    content: "\f4c8";
}
.fi-tr-draw-polygon:before {
    content: "\f4c9";
}
.fi-tr-draw-square:before {
    content: "\f4ca";
}
.fi-tr-drawer-alt:before {
    content: "\f4cb";
}
.fi-tr-drawer:before {
    content: "\f4cc";
}
.fi-tr-dreidel:before {
    content: "\f4cd";
}
.fi-tr-drink-alt:before {
    content: "\f4ce";
}
.fi-tr-drink:before {
    content: "\f4cf";
}
.fi-tr-driver-man:before {
    content: "\f4d0";
}
.fi-tr-driver-woman:before {
    content: "\f4d1";
}
.fi-tr-drone-alt:before {
    content: "\f4d2";
}
.fi-tr-drone-front:before {
    content: "\f4d3";
}
.fi-tr-drone:before {
    content: "\f4d4";
}
.fi-tr-drop-down:before {
    content: "\f4d5";
}
.fi-tr-drum-steelpan:before {
    content: "\f4d6";
}
.fi-tr-drum:before {
    content: "\f4d7";
}
.fi-tr-drumstick-bite:before {
    content: "\f4d8";
}
.fi-tr-drumstick:before {
    content: "\f4d9";
}
.fi-tr-dryer-alt:before {
    content: "\f4da";
}
.fi-tr-dryer:before {
    content: "\f4db";
}
.fi-tr-duck:before {
    content: "\f4dc";
}
.fi-tr-dumpster-fire:before {
    content: "\f4dd";
}
.fi-tr-dumpster:before {
    content: "\f4de";
}
.fi-tr-dungeon:before {
    content: "\f4df";
}
.fi-tr-duration-alt:before {
    content: "\f4e0";
}
.fi-tr-duration:before {
    content: "\f4e1";
}
.fi-tr-e:before {
    content: "\f4e2";
}
.fi-tr-ear-deaf:before {
    content: "\f4e3";
}
.fi-tr-ear-muffs:before {
    content: "\f4e4";
}
.fi-tr-ear:before {
    content: "\f4e5";
}
.fi-tr-earth-africa:before {
    content: "\f4e6";
}
.fi-tr-earth-americas:before {
    content: "\f4e7";
}
.fi-tr-earth-asia:before {
    content: "\f4e8";
}
.fi-tr-earth-europa:before {
    content: "\f4e9";
}
.fi-tr-eclipse-alt:before {
    content: "\f4ea";
}
.fi-tr-eclipse:before {
    content: "\f4eb";
}
.fi-tr-effect:before {
    content: "\f4ec";
}
.fi-tr-egg-fried:before {
    content: "\f4ed";
}
.fi-tr-egg:before {
    content: "\f4ee";
}
.fi-tr-eject:before {
    content: "\f4ef";
}
.fi-tr-elephant:before {
    content: "\f4f0";
}
.fi-tr-elevator:before {
    content: "\f4f1";
}
.fi-tr-employee-alt:before {
    content: "\f4f2";
}
.fi-tr-employee-man-alt:before {
    content: "\f4f3";
}
.fi-tr-employee-man:before {
    content: "\f4f4";
}
.fi-tr-employees-woman-man:before {
    content: "\f4f5";
}
.fi-tr-employees:before {
    content: "\f4f6";
}
.fi-tr-engine-warning:before {
    content: "\f4f7";
}
.fi-tr-engine:before {
    content: "\f4f8";
}
.fi-tr-envelope-ban:before {
    content: "\f4f9";
}
.fi-tr-envelope-bulk:before {
    content: "\f4fa";
}
.fi-tr-envelope-dot:before {
    content: "\f4fb";
}
.fi-tr-envelope-download:before {
    content: "\f4fc";
}
.fi-tr-envelope-marker:before {
    content: "\f4fd";
}
.fi-tr-envelope-open-dollar:before {
    content: "\f4fe";
}
.fi-tr-envelope-open-text:before {
    content: "\f4ff";
}
.fi-tr-envelope-open:before {
    content: "\f500";
}
.fi-tr-envelope-plus:before {
    content: "\f501";
}
.fi-tr-envelopes:before {
    content: "\f502";
}
.fi-tr-equality:before {
    content: "\f503";
}
.fi-tr-eraser:before {
    content: "\f504";
}
.fi-tr-escalator:before {
    content: "\f505";
}
.fi-tr-ethernet:before {
    content: "\f506";
}
.fi-tr-exchange-alt:before {
    content: "\f507";
}
.fi-tr-exchange-cryptocurrency:before {
    content: "\f508";
}
.fi-tr-exchange:before {
    content: "\f509";
}
.fi-tr-exit-alt:before {
    content: "\f50a";
}
.fi-tr-expand-arrows-alt:before {
    content: "\f50b";
}
.fi-tr-expand-arrows:before {
    content: "\f50c";
}
.fi-tr-expense-bill:before {
    content: "\f50d";
}
.fi-tr-expense:before {
    content: "\f50e";
}
.fi-tr-external-hard-drive:before {
    content: "\f50f";
}
.fi-tr-external-world:before {
    content: "\f510";
}
.fi-tr-eye-dropper-half:before {
    content: "\f511";
}
.fi-tr-eyes:before {
    content: "\f512";
}
.fi-tr-f:before {
    content: "\f513";
}
.fi-tr-fabric:before {
    content: "\f514";
}
.fi-tr-face-angry-horns:before {
    content: "\f515";
}
.fi-tr-face-anguished:before {
    content: "\f516";
}
.fi-tr-face-anxious-sweat:before {
    content: "\f517";
}
.fi-tr-face-astonished:before {
    content: "\f518";
}
.fi-tr-face-awesome:before {
    content: "\f519";
}
.fi-tr-face-beam-hand-over-mouth:before {
    content: "\f51a";
}
.fi-tr-face-confounded:before {
    content: "\f51b";
}
.fi-tr-face-confused:before {
    content: "\f51c";
}
.fi-tr-face-cowboy-hat:before {
    content: "\f51d";
}
.fi-tr-face-disappointed:before {
    content: "\f51e";
}
.fi-tr-face-disguise:before {
    content: "\f51f";
}
.fi-tr-face-downcast-sweat:before {
    content: "\f520";
}
.fi-tr-face-drooling:before {
    content: "\f521";
}
.fi-tr-face-explode:before {
    content: "\f522";
}
.fi-tr-face-expressionless:before {
    content: "\f523";
}
.fi-tr-face-eyes-xmarks:before {
    content: "\f524";
}
.fi-tr-face-fearful:before {
    content: "\f525";
}
.fi-tr-face-glasses:before {
    content: "\f526";
}
.fi-tr-face-grin-tongue-wink:before {
    content: "\f527";
}
.fi-tr-face-hand-yawn:before {
    content: "\f528";
}
.fi-tr-face-head-bandage:before {
    content: "\f529";
}
.fi-tr-face-hushed:before {
    content: "\f52a";
}
.fi-tr-face-icicles:before {
    content: "\f52b";
}
.fi-tr-face-lying:before {
    content: "\f52c";
}
.fi-tr-face-mask:before {
    content: "\f52d";
}
.fi-tr-face-monocle:before {
    content: "\f52e";
}
.fi-tr-face-nauseated:before {
    content: "\f52f";
}
.fi-tr-face-nose-steam:before {
    content: "\f530";
}
.fi-tr-face-party:before {
    content: "\f531";
}
.fi-tr-face-pensive:before {
    content: "\f532";
}
.fi-tr-face-persevering:before {
    content: "\f533";
}
.fi-tr-face-pleading:before {
    content: "\f534";
}
.fi-tr-face-raised-eyebrow:before {
    content: "\f535";
}
.fi-tr-face-relieved:before {
    content: "\f536";
}
.fi-tr-face-sad-sweat:before {
    content: "\f537";
}
.fi-tr-face-scream:before {
    content: "\f538";
}
.fi-tr-face-shush:before {
    content: "\f539";
}
.fi-tr-face-sleeping:before {
    content: "\f53a";
}
.fi-tr-face-sleepy:before {
    content: "\f53b";
}
.fi-tr-face-smile-halo:before {
    content: "\f53c";
}
.fi-tr-face-smile-hearts:before {
    content: "\f53d";
}
.fi-tr-face-smile-horns:before {
    content: "\f53e";
}
.fi-tr-face-smile-tear:before {
    content: "\f53f";
}
.fi-tr-face-smile-tongue:before {
    content: "\f540";
}
.fi-tr-face-smile-upside-down:before {
    content: "\f541";
}
.fi-tr-face-smiling-hands:before {
    content: "\f542";
}
.fi-tr-face-smirking:before {
    content: "\f543";
}
.fi-tr-face-sunglasses-alt:before {
    content: "\f544";
}
.fi-tr-face-sunglasses:before {
    content: "\f545";
}
.fi-tr-face-swear:before {
    content: "\f546";
}
.fi-tr-face-thermometer:before {
    content: "\f547";
}
.fi-tr-face-thinking:before {
    content: "\f548";
}
.fi-tr-face-tissue:before {
    content: "\f549";
}
.fi-tr-face-tongue-money:before {
    content: "\f54a";
}
.fi-tr-face-tongue-sweat:before {
    content: "\f54b";
}
.fi-tr-face-unamused:before {
    content: "\f54c";
}
.fi-tr-face-viewfinder:before {
    content: "\f54d";
}
.fi-tr-face-vomit:before {
    content: "\f54e";
}
.fi-tr-face-weary:before {
    content: "\f54f";
}
.fi-tr-face-woozy:before {
    content: "\f550";
}
.fi-tr-face-worried:before {
    content: "\f551";
}
.fi-tr-face-zany:before {
    content: "\f552";
}
.fi-tr-face-zipper:before {
    content: "\f553";
}
.fi-tr-fail:before {
    content: "\f554";
}
.fi-tr-falafel:before {
    content: "\f555";
}
.fi-tr-family-dress:before {
    content: "\f556";
}
.fi-tr-family-pants:before {
    content: "\f557";
}
.fi-tr-family:before {
    content: "\f558";
}
.fi-tr-fan-table:before {
    content: "\f559";
}
.fi-tr-fan:before {
    content: "\f55a";
}
.fi-tr-farm:before {
    content: "\f55b";
}
.fi-tr-faucet-drip:before {
    content: "\f55c";
}
.fi-tr-faucet:before {
    content: "\f55d";
}
.fi-tr-fax:before {
    content: "\f55e";
}
.fi-tr-feather-pointed:before {
    content: "\f55f";
}
.fi-tr-features-alt:before {
    content: "\f560";
}
.fi-tr-features:before {
    content: "\f561";
}
.fi-tr-fee-receipt:before {
    content: "\f562";
}
.fi-tr-fee:before {
    content: "\f563";
}
.fi-tr-feedback-alt:before {
    content: "\f564";
}
.fi-tr-feedback-hand:before {
    content: "\f565";
}
.fi-tr-feedback-review:before {
    content: "\f566";
}
.fi-tr-feedback:before {
    content: "\f567";
}
.fi-tr-fence:before {
    content: "\f568";
}
.fi-tr-ferris-wheel:before {
    content: "\f569";
}
.fi-tr-field-hockey:before {
    content: "\f56a";
}
.fi-tr-fighter-jet:before {
    content: "\f56b";
}
.fi-tr-file-audio:before {
    content: "\f56c";
}
.fi-tr-file-binary:before {
    content: "\f56d";
}
.fi-tr-file-chart-line:before {
    content: "\f56e";
}
.fi-tr-file-chart-pie:before {
    content: "\f56f";
}
.fi-tr-file-circle-info:before {
    content: "\f570";
}
.fi-tr-file-code:before {
    content: "\f571";
}
.fi-tr-file-csv:before {
    content: "\f572";
}
.fi-tr-file-download:before {
    content: "\f573";
}
.fi-tr-file-edit:before {
    content: "\f574";
}
.fi-tr-file-excel:before {
    content: "\f575";
}
.fi-tr-file-exclamation:before {
    content: "\f576";
}
.fi-tr-file-export:before {
    content: "\f577";
}
.fi-tr-file-image:before {
    content: "\f578";
}
.fi-tr-file-import:before {
    content: "\f579";
}
.fi-tr-file-invoice-dollar:before {
    content: "\f57a";
}
.fi-tr-file-invoice:before {
    content: "\f57b";
}
.fi-tr-file-medical-alt:before {
    content: "\f57c";
}
.fi-tr-file-medical:before {
    content: "\f57d";
}
.fi-tr-file-minus:before {
    content: "\f57e";
}
.fi-tr-file-pdf:before {
    content: "\f57f";
}
.fi-tr-file-powerpoint:before {
    content: "\f580";
}
.fi-tr-file-prescription:before {
    content: "\f581";
}
.fi-tr-file-signature:before {
    content: "\f582";
}
.fi-tr-file-spreadsheet:before {
    content: "\f583";
}
.fi-tr-file-upload:before {
    content: "\f584";
}
.fi-tr-file-user:before {
    content: "\f585";
}
.fi-tr-file-video:before {
    content: "\f586";
}
.fi-tr-file-word:before {
    content: "\f587";
}
.fi-tr-file-zipper:before {
    content: "\f588";
}
.fi-tr-files-medical:before {
    content: "\f589";
}
.fi-tr-film-canister:before {
    content: "\f58a";
}
.fi-tr-film-slash:before {
    content: "\f58b";
}
.fi-tr-films:before {
    content: "\f58c";
}
.fi-tr-filter-slash:before {
    content: "\f58d";
}
.fi-tr-filters:before {
    content: "\f58e";
}
.fi-tr-fingerprint:before {
    content: "\f58f";
}
.fi-tr-fire-burner:before {
    content: "\f590";
}
.fi-tr-fire-extinguisher:before {
    content: "\f591";
}
.fi-tr-fire-flame-curved:before {
    content: "\f592";
}
.fi-tr-fire-flame-simple:before {
    content: "\f593";
}
.fi-tr-fire-hydrant:before {
    content: "\f594";
}
.fi-tr-fire-smoke:before {
    content: "\f595";
}
.fi-tr-fireplace:before {
    content: "\f596";
}
.fi-tr-first-award:before {
    content: "\f597";
}
.fi-tr-first-laurel:before {
    content: "\f598";
}
.fi-tr-first-medal:before {
    content: "\f599";
}
.fi-tr-first:before {
    content: "\f59a";
}
.fi-tr-fish-bones:before {
    content: "\f59b";
}
.fi-tr-fish-cooked:before {
    content: "\f59c";
}
.fi-tr-fish:before {
    content: "\f59d";
}
.fi-tr-fishing-rod:before {
    content: "\f59e";
}
.fi-tr-flag-alt:before {
    content: "\f59f";
}
.fi-tr-flag-checkered:before {
    content: "\f5a0";
}
.fi-tr-flag-usa:before {
    content: "\f5a1";
}
.fi-tr-flashlight:before {
    content: "\f5a2";
}
.fi-tr-flask-poison:before {
    content: "\f5a3";
}
.fi-tr-flask-potion:before {
    content: "\f5a4";
}
.fi-tr-flask:before {
    content: "\f5a5";
}
.fi-tr-flatbread-stuffed:before {
    content: "\f5a6";
}
.fi-tr-flatbread:before {
    content: "\f5a7";
}
.fi-tr-floor-alt:before {
    content: "\f5a8";
}
.fi-tr-floor-layer:before {
    content: "\f5a9";
}
.fi-tr-floor:before {
    content: "\f5aa";
}
.fi-tr-floppy-disk-circle-arrow-right:before {
    content: "\f5ab";
}
.fi-tr-floppy-disk-circle-xmark:before {
    content: "\f5ac";
}
.fi-tr-floppy-disk-pen:before {
    content: "\f5ad";
}
.fi-tr-floppy-disks:before {
    content: "\f5ae";
}
.fi-tr-florin-sign:before {
    content: "\f5af";
}
.fi-tr-flower-bouquet:before {
    content: "\f5b0";
}
.fi-tr-flower-butterfly:before {
    content: "\f5b1";
}
.fi-tr-flower-daffodil:before {
    content: "\f5b2";
}
.fi-tr-flower-tulip:before {
    content: "\f5b3";
}
.fi-tr-flower:before {
    content: "\f5b4";
}
.fi-tr-flushed:before {
    content: "\f5b5";
}
.fi-tr-flute:before {
    content: "\f5b6";
}
.fi-tr-flux-capacitor:before {
    content: "\f5b7";
}
.fi-tr-fly-insect:before {
    content: "\f5b8";
}
.fi-tr-flying-disc:before {
    content: "\f5b9";
}
.fi-tr-fog:before {
    content: "\f5ba";
}
.fi-tr-folder-download:before {
    content: "\f5bb";
}
.fi-tr-folder-minus:before {
    content: "\f5bc";
}
.fi-tr-folder-open:before {
    content: "\f5bd";
}
.fi-tr-folder-times:before {
    content: "\f5be";
}
.fi-tr-folder-tree:before {
    content: "\f5bf";
}
.fi-tr-folder-upload:before {
    content: "\f5c0";
}
.fi-tr-folder-xmark:before {
    content: "\f5c1";
}
.fi-tr-folders:before {
    content: "\f5c2";
}
.fi-tr-follow-folder:before {
    content: "\f5c3";
}
.fi-tr-followcollection:before {
    content: "\f5c4";
}
.fi-tr-fondue-pot:before {
    content: "\f5c5";
}
.fi-tr-fork:before {
    content: "\f5c6";
}
.fi-tr-forklift:before {
    content: "\f5c7";
}
.fi-tr-fort:before {
    content: "\f5c8";
}
.fi-tr-forward-fast:before {
    content: "\f5c9";
}
.fi-tr-fox:before {
    content: "\f5ca";
}
.fi-tr-frame:before {
    content: "\f5cb";
}
.fi-tr-franc-sign:before {
    content: "\f5cc";
}
.fi-tr-french-fries:before {
    content: "\f5cd";
}
.fi-tr-friday:before {
    content: "\f5ce";
}
.fi-tr-frog:before {
    content: "\f5cf";
}
.fi-tr-frown:before {
    content: "\f5d0";
}
.fi-tr-ftp:before {
    content: "\f5d1";
}
.fi-tr-function-process:before {
    content: "\f5d2";
}
.fi-tr-funnel-dollar:before {
    content: "\f5d3";
}
.fi-tr-g:before {
    content: "\f5d4";
}
.fi-tr-galaxy-alt:before {
    content: "\f5d5";
}
.fi-tr-galaxy-planet:before {
    content: "\f5d6";
}
.fi-tr-galaxy-star:before {
    content: "\f5d7";
}
.fi-tr-galaxy:before {
    content: "\f5d8";
}
.fi-tr-gallery-thumbnails:before {
    content: "\f5d9";
}
.fi-tr-game-board-alt:before {
    content: "\f5da";
}
.fi-tr-gamepad:before {
    content: "\f5db";
}
.fi-tr-garage-car:before {
    content: "\f5dc";
}
.fi-tr-garage-open:before {
    content: "\f5dd";
}
.fi-tr-garage:before {
    content: "\f5de";
}
.fi-tr-garlic-alt:before {
    content: "\f5df";
}
.fi-tr-garlic:before {
    content: "\f5e0";
}
.fi-tr-gas-pump-slash:before {
    content: "\f5e1";
}
.fi-tr-gas-pump:before {
    content: "\f5e2";
}
.fi-tr-gauge-circle-bolt:before {
    content: "\f5e3";
}
.fi-tr-gauge-circle-minus:before {
    content: "\f5e4";
}
.fi-tr-gauge-circle-plus:before {
    content: "\f5e5";
}
.fi-tr-gavel:before {
    content: "\f5e6";
}
.fi-tr-gears:before {
    content: "\f5e7";
}
.fi-tr-gem:before {
    content: "\f5e8";
}
.fi-tr-ghost:before {
    content: "\f5e9";
}
.fi-tr-gif-square:before {
    content: "\f5ea";
}
.fi-tr-gift-card:before {
    content: "\f5eb";
}
.fi-tr-gift:before {
    content: "\f5ec";
}
.fi-tr-gifts:before {
    content: "\f5ed";
}
.fi-tr-gingerbread-man:before {
    content: "\f5ee";
}
.fi-tr-glass-champagne:before {
    content: "\f5ef";
}
.fi-tr-glass-cheers:before {
    content: "\f5f0";
}
.fi-tr-glass-citrus:before {
    content: "\f5f1";
}
.fi-tr-glass-empty:before {
    content: "\f5f2";
}
.fi-tr-glass-half:before {
    content: "\f5f3";
}
.fi-tr-glass-water-droplet:before {
    content: "\f5f4";
}
.fi-tr-glass-whiskey-rocks:before {
    content: "\f5f5";
}
.fi-tr-glass-whiskey:before {
    content: "\f5f6";
}
.fi-tr-glass:before {
    content: "\f5f7";
}
.fi-tr-globe-snow:before {
    content: "\f5f8";
}
.fi-tr-goal-net:before {
    content: "\f5f9";
}
.fi-tr-golf-ball:before {
    content: "\f5fa";
}
.fi-tr-golf-club:before {
    content: "\f5fb";
}
.fi-tr-government-flag:before {
    content: "\f5fc";
}
.fi-tr-government-user:before {
    content: "\f5fd";
}
.fi-tr-gramophone:before {
    content: "\f5fe";
}
.fi-tr-grape:before {
    content: "\f5ff";
}
.fi-tr-graph-curve:before {
    content: "\f600";
}
.fi-tr-graphic-style:before {
    content: "\f601";
}
.fi-tr-grate-droplet:before {
    content: "\f602";
}
.fi-tr-grate:before {
    content: "\f603";
}
.fi-tr-grid-dividers:before {
    content: "\f604";
}
.fi-tr-grill-hot-alt:before {
    content: "\f605";
}
.fi-tr-grill:before {
    content: "\f606";
}
.fi-tr-grimace:before {
    content: "\f607";
}
.fi-tr-grin-alt:before {
    content: "\f608";
}
.fi-tr-grin-beam-sweat:before {
    content: "\f609";
}
.fi-tr-grin-beam:before {
    content: "\f60a";
}
.fi-tr-grin-hearts:before {
    content: "\f60b";
}
.fi-tr-grin-squint-tears:before {
    content: "\f60c";
}
.fi-tr-grin-squint:before {
    content: "\f60d";
}
.fi-tr-grin-stars:before {
    content: "\f60e";
}
.fi-tr-grin-tears:before {
    content: "\f60f";
}
.fi-tr-grin-tongue-squint:before {
    content: "\f610";
}
.fi-tr-grin-tongue-wink:before {
    content: "\f611";
}
.fi-tr-grin-tongue:before {
    content: "\f612";
}
.fi-tr-grin-wink:before {
    content: "\f613";
}
.fi-tr-grin:before {
    content: "\f614";
}
.fi-tr-grip-dots-vertical:before {
    content: "\f615";
}
.fi-tr-grip-dots:before {
    content: "\f616";
}
.fi-tr-grip-horizontal:before {
    content: "\f617";
}
.fi-tr-grip-lines-vertical:before {
    content: "\f618";
}
.fi-tr-grip-lines:before {
    content: "\f619";
}
.fi-tr-grip-vertical:before {
    content: "\f61a";
}
.fi-tr-grocery-bag:before {
    content: "\f61b";
}
.fi-tr-grocery-basket:before {
    content: "\f61c";
}
.fi-tr-guarani-sign:before {
    content: "\f61d";
}
.fi-tr-guide:before {
    content: "\f61e";
}
.fi-tr-guitar-electric:before {
    content: "\f61f";
}
.fi-tr-guitar:before {
    content: "\f620";
}
.fi-tr-guitars:before {
    content: "\f621";
}
.fi-tr-gun-squirt:before {
    content: "\f622";
}
.fi-tr-h-square:before {
    content: "\f623";
}
.fi-tr-h:before {
    content: "\f624";
}
.fi-tr-h1:before {
    content: "\f625";
}
.fi-tr-h2:before {
    content: "\f626";
}
.fi-tr-h3:before {
    content: "\f627";
}
.fi-tr-h4:before {
    content: "\f628";
}
.fi-tr-hair-clipper:before {
    content: "\f629";
}
.fi-tr-hamburger-soda:before {
    content: "\f62a";
}
.fi-tr-hamburger:before {
    content: "\f62b";
}
.fi-tr-hammer-crash:before {
    content: "\f62c";
}
.fi-tr-hammer-war:before {
    content: "\f62d";
}
.fi-tr-hammer:before {
    content: "\f62e";
}
.fi-tr-hand-back-fist:before {
    content: "\f62f";
}
.fi-tr-hand-back-point-down:before {
    content: "\f630";
}
.fi-tr-hand-back-point-left:before {
    content: "\f631";
}
.fi-tr-hand-back-point-ribbon:before {
    content: "\f632";
}
.fi-tr-hand-back-point-right:before {
    content: "\f633";
}
.fi-tr-hand-bill:before {
    content: "\f634";
}
.fi-tr-hand-dots:before {
    content: "\f635";
}
.fi-tr-hand-fingers-crossed:before {
    content: "\f636";
}
.fi-tr-hand-fist:before {
    content: "\f637";
}
.fi-tr-hand-heart:before {
    content: "\f638";
}
.fi-tr-hand-holding-box:before {
    content: "\f639";
}
.fi-tr-hand-holding-droplet:before {
    content: "\f63a";
}
.fi-tr-hand-holding-heart:before {
    content: "\f63b";
}
.fi-tr-hand-holding-magic:before {
    content: "\f63c";
}
.fi-tr-hand-holding-medical:before {
    content: "\f63d";
}
.fi-tr-hand-holding-seeding:before {
    content: "\f63e";
}
.fi-tr-hand-holding-skull:before {
    content: "\f63f";
}
.fi-tr-hand-holding-usd:before {
    content: "\f640";
}
.fi-tr-hand-holding-water:before {
    content: "\f641";
}
.fi-tr-hand-horns:before {
    content: "\f642";
}
.fi-tr-hand-lizard:before {
    content: "\f643";
}
.fi-tr-hand-love:before {
    content: "\f644";
}
.fi-tr-hand-middle-finger:before {
    content: "\f645";
}
.fi-tr-hand-paper:before {
    content: "\f646";
}
.fi-tr-hand-peace:before {
    content: "\f647";
}
.fi-tr-hand-point-ribbon:before {
    content: "\f648";
}
.fi-tr-hand-scissors:before {
    content: "\f649";
}
.fi-tr-hand-sparkles:before {
    content: "\f64a";
}
.fi-tr-hand-spock:before {
    content: "\f64b";
}
.fi-tr-hand-wave:before {
    content: "\f64c";
}
.fi-tr-handmade:before {
    content: "\f64d";
}
.fi-tr-hands-bubbles:before {
    content: "\f64e";
}
.fi-tr-hands-clapping:before {
    content: "\f64f";
}
.fi-tr-hands-heart:before {
    content: "\f650";
}
.fi-tr-hands-holding-diamond:before {
    content: "\f651";
}
.fi-tr-hands-holding:before {
    content: "\f652";
}
.fi-tr-hands-usd:before {
    content: "\f653";
}
.fi-tr-handshake-angle:before {
    content: "\f654";
}
.fi-tr-handshake-simple-slash:before {
    content: "\f655";
}
.fi-tr-handshake:before {
    content: "\f656";
}
.fi-tr-hard-hat:before {
    content: "\f657";
}
.fi-tr-hashtag-lock:before {
    content: "\f658";
}
.fi-tr-hashtag:before {
    content: "\f659";
}
.fi-tr-hat-birthday:before {
    content: "\f65a";
}
.fi-tr-hat-chef:before {
    content: "\f65b";
}
.fi-tr-hat-cowboy-side:before {
    content: "\f65c";
}
.fi-tr-hat-cowboy:before {
    content: "\f65d";
}
.fi-tr-hat-santa:before {
    content: "\f65e";
}
.fi-tr-hat-winter:before {
    content: "\f65f";
}
.fi-tr-hat-witch:before {
    content: "\f660";
}
.fi-tr-hat-wizard:before {
    content: "\f661";
}
.fi-tr-hdd:before {
    content: "\f662";
}
.fi-tr-head-side-brain:before {
    content: "\f663";
}
.fi-tr-head-side-cough-slash:before {
    content: "\f664";
}
.fi-tr-head-side-cough:before {
    content: "\f665";
}
.fi-tr-head-side-headphones:before {
    content: "\f666";
}
.fi-tr-head-side-heart:before {
    content: "\f667";
}
.fi-tr-head-side-mask:before {
    content: "\f668";
}
.fi-tr-head-side-medical:before {
    content: "\f669";
}
.fi-tr-head-side-thinking:before {
    content: "\f66a";
}
.fi-tr-head-side-virus:before {
    content: "\f66b";
}
.fi-tr-head-side:before {
    content: "\f66c";
}
.fi-tr-head-vr:before {
    content: "\f66d";
}
.fi-tr-heading:before {
    content: "\f66e";
}
.fi-tr-heart-arrow:before {
    content: "\f66f";
}
.fi-tr-heart-crack:before {
    content: "\f670";
}
.fi-tr-heart-half-stroke:before {
    content: "\f671";
}
.fi-tr-heart-half:before {
    content: "\f672";
}
.fi-tr-heart-rate:before {
    content: "\f673";
}
.fi-tr-heat:before {
    content: "\f674";
}
.fi-tr-helicopter-side:before {
    content: "\f675";
}
.fi-tr-helmet-battle:before {
    content: "\f676";
}
.fi-tr-hexagon-check:before {
    content: "\f677";
}
.fi-tr-hexagon-exclamation:before {
    content: "\f678";
}
.fi-tr-hexagon:before {
    content: "\f679";
}
.fi-tr-high-definition:before {
    content: "\f67a";
}
.fi-tr-highlighter-line:before {
    content: "\f67b";
}
.fi-tr-highlighter:before {
    content: "\f67c";
}
.fi-tr-hiking:before {
    content: "\f67d";
}
.fi-tr-hippo:before {
    content: "\f67e";
}
.fi-tr-hockey-mask:before {
    content: "\f67f";
}
.fi-tr-hockey-puck:before {
    content: "\f680";
}
.fi-tr-hockey-stick-puck:before {
    content: "\f681";
}
.fi-tr-hockey-sticks:before {
    content: "\f682";
}
.fi-tr-holly-berry:before {
    content: "\f683";
}
.fi-tr-home-heart:before {
    content: "\f684";
}
.fi-tr-honey-pot:before {
    content: "\f685";
}
.fi-tr-hood-cloak:before {
    content: "\f686";
}
.fi-tr-horizontal-rule:before {
    content: "\f687";
}
.fi-tr-horse-head:before {
    content: "\f688";
}
.fi-tr-horse-saddle:before {
    content: "\f689";
}
.fi-tr-horse:before {
    content: "\f68a";
}
.fi-tr-hose-reel:before {
    content: "\f68b";
}
.fi-tr-hose:before {
    content: "\f68c";
}
.fi-tr-hospital-symbol:before {
    content: "\f68d";
}
.fi-tr-hospital-user:before {
    content: "\f68e";
}
.fi-tr-hospital:before {
    content: "\f68f";
}
.fi-tr-hospitals:before {
    content: "\f690";
}
.fi-tr-hot-tub:before {
    content: "\f691";
}
.fi-tr-hotdog:before {
    content: "\f692";
}
.fi-tr-hotel:before {
    content: "\f693";
}
.fi-tr-hourglass-start:before {
    content: "\f694";
}
.fi-tr-house-blank:before {
    content: "\f695";
}
.fi-tr-house-building:before {
    content: "\f696";
}
.fi-tr-house-chimney-blank:before {
    content: "\f697";
}
.fi-tr-house-chimney-crack:before {
    content: "\f698";
}
.fi-tr-house-chimney-heart:before {
    content: "\f699";
}
.fi-tr-house-chimney-medical:before {
    content: "\f69a";
}
.fi-tr-house-chimney-user:before {
    content: "\f69b";
}
.fi-tr-house-chimney-window:before {
    content: "\f69c";
}
.fi-tr-house-chimney:before {
    content: "\f69d";
}
.fi-tr-house-crack:before {
    content: "\f69e";
}
.fi-tr-house-day:before {
    content: "\f69f";
}
.fi-tr-house-flood:before {
    content: "\f6a0";
}
.fi-tr-house-laptop:before {
    content: "\f6a1";
}
.fi-tr-house-leave:before {
    content: "\f6a2";
}
.fi-tr-house-medical:before {
    content: "\f6a3";
}
.fi-tr-house-night:before {
    content: "\f6a4";
}
.fi-tr-house-return:before {
    content: "\f6a5";
}
.fi-tr-house-signal:before {
    content: "\f6a6";
}
.fi-tr-house-tree:before {
    content: "\f6a7";
}
.fi-tr-house-tsunami:before {
    content: "\f6a8";
}
.fi-tr-house-turret:before {
    content: "\f6a9";
}
.fi-tr-house-user:before {
    content: "\f6aa";
}
.fi-tr-house-window:before {
    content: "\f6ab";
}
.fi-tr-hr-group:before {
    content: "\f6ac";
}
.fi-tr-hr-person:before {
    content: "\f6ad";
}
.fi-tr-hr:before {
    content: "\f6ae";
}
.fi-tr-hryvnia:before {
    content: "\f6af";
}
.fi-tr-humidity:before {
    content: "\f6b0";
}
.fi-tr-hundred-points:before {
    content: "\f6b1";
}
.fi-tr-hurricane:before {
    content: "\f6b2";
}
.fi-tr-i:before {
    content: "\f6b3";
}
.fi-tr-ice-cream:before {
    content: "\f6b4";
}
.fi-tr-icicles:before {
    content: "\f6b5";
}
.fi-tr-icon-star:before {
    content: "\f6b6";
}
.fi-tr-id-badge:before {
    content: "\f6b7";
}
.fi-tr-id-card-clip-alt:before {
    content: "\f6b8";
}
.fi-tr-igloo:before {
    content: "\f6b9";
}
.fi-tr-image-slash:before {
    content: "\f6ba";
}
.fi-tr-images-user:before {
    content: "\f6bb";
}
.fi-tr-images:before {
    content: "\f6bc";
}
.fi-tr-inbox-in:before {
    content: "\f6bd";
}
.fi-tr-inbox-out:before {
    content: "\f6be";
}
.fi-tr-inboxes:before {
    content: "\f6bf";
}
.fi-tr-incognito:before {
    content: "\f6c0";
}
.fi-tr-indian-rupee-sign:before {
    content: "\f6c1";
}
.fi-tr-industry-alt:before {
    content: "\f6c2";
}
.fi-tr-industry-windows:before {
    content: "\f6c3";
}
.fi-tr-information:before {
    content: "\f6c4";
}
.fi-tr-inhaler:before {
    content: "\f6c5";
}
.fi-tr-input-numeric:before {
    content: "\f6c6";
}
.fi-tr-input-pipe:before {
    content: "\f6c7";
}
.fi-tr-input-text:before {
    content: "\f6c8";
}
.fi-tr-insert-alt:before {
    content: "\f6c9";
}
.fi-tr-insert-arrows:before {
    content: "\f6ca";
}
.fi-tr-insert-square:before {
    content: "\f6cb";
}
.fi-tr-insert:before {
    content: "\f6cc";
}
.fi-tr-insight-alt:before {
    content: "\f6cd";
}
.fi-tr-insight-head:before {
    content: "\f6ce";
}
.fi-tr-insight:before {
    content: "\f6cf";
}
.fi-tr-inventory-alt:before {
    content: "\f6d0";
}
.fi-tr-invest:before {
    content: "\f6d1";
}
.fi-tr-investment:before {
    content: "\f6d2";
}
.fi-tr-invite-alt:before {
    content: "\f6d3";
}
.fi-tr-invite:before {
    content: "\f6d4";
}
.fi-tr-island-tropical:before {
    content: "\f6d5";
}
.fi-tr-issue-loupe:before {
    content: "\f6d6";
}
.fi-tr-it-alt:before {
    content: "\f6d7";
}
.fi-tr-it-computer:before {
    content: "\f6d8";
}
.fi-tr-it:before {
    content: "\f6d9";
}
.fi-tr-italian-lira-sign:before {
    content: "\f6da";
}
.fi-tr-j:before {
    content: "\f6db";
}
.fi-tr-jam:before {
    content: "\f6dc";
}
.fi-tr-jar-alt:before {
    content: "\f6dd";
}
.fi-tr-jar-wheat:before {
    content: "\f6de";
}
.fi-tr-javascript:before {
    content: "\f6df";
}
.fi-tr-joint:before {
    content: "\f6e0";
}
.fi-tr-journal-alt:before {
    content: "\f6e1";
}
.fi-tr-journal:before {
    content: "\f6e2";
}
.fi-tr-journey:before {
    content: "\f6e3";
}
.fi-tr-joystick:before {
    content: "\f6e4";
}
.fi-tr-jug-alt:before {
    content: "\f6e5";
}
.fi-tr-jug-bottle:before {
    content: "\f6e6";
}
.fi-tr-jug:before {
    content: "\f6e7";
}
.fi-tr-k:before {
    content: "\f6e8";
}
.fi-tr-kazoo:before {
    content: "\f6e9";
}
.fi-tr-kerning:before {
    content: "\f6ea";
}
.fi-tr-key-skeleton-left-right:before {
    content: "\f6eb";
}
.fi-tr-key:before {
    content: "\f6ec";
}
.fi-tr-keyboard-brightness-low:before {
    content: "\f6ed";
}
.fi-tr-keyboard-brightness:before {
    content: "\f6ee";
}
.fi-tr-keyboard-down:before {
    content: "\f6ef";
}
.fi-tr-keyboard-left:before {
    content: "\f6f0";
}
.fi-tr-keynote:before {
    content: "\f6f1";
}
.fi-tr-kidneys:before {
    content: "\f6f2";
}
.fi-tr-kip-sign:before {
    content: "\f6f3";
}
.fi-tr-kiss-beam:before {
    content: "\f6f4";
}
.fi-tr-kiss-wink-heart:before {
    content: "\f6f5";
}
.fi-tr-kiss:before {
    content: "\f6f6";
}
.fi-tr-kite:before {
    content: "\f6f7";
}
.fi-tr-kiwi-bird:before {
    content: "\f6f8";
}
.fi-tr-kiwi-fruit:before {
    content: "\f6f9";
}
.fi-tr-knife-kitchen:before {
    content: "\f6fa";
}
.fi-tr-knife:before {
    content: "\f6fb";
}
.fi-tr-knitting:before {
    content: "\f6fc";
}
.fi-tr-kpi-evaluation:before {
    content: "\f6fd";
}
.fi-tr-kpi:before {
    content: "\f6fe";
}
.fi-tr-l:before {
    content: "\f6ff";
}
.fi-tr-lacrosse-stick-ball:before {
    content: "\f700";
}
.fi-tr-lacrosse-stick:before {
    content: "\f701";
}
.fi-tr-lamp-desk:before {
    content: "\f702";
}
.fi-tr-lamp-floor:before {
    content: "\f703";
}
.fi-tr-lamp-street:before {
    content: "\f704";
}
.fi-tr-lamp:before {
    content: "\f705";
}
.fi-tr-land-layer-location:before {
    content: "\f706";
}
.fi-tr-land-layers:before {
    content: "\f707";
}
.fi-tr-land-location:before {
    content: "\f708";
}
.fi-tr-landmark-alt:before {
    content: "\f709";
}
.fi-tr-language:before {
    content: "\f70a";
}
.fi-tr-laptop-arrow-down:before {
    content: "\f70b";
}
.fi-tr-laptop-code:before {
    content: "\f70c";
}
.fi-tr-laptop-medical:before {
    content: "\f70d";
}
.fi-tr-laptop-mobile:before {
    content: "\f70e";
}
.fi-tr-laptop-slash:before {
    content: "\f70f";
}
.fi-tr-lari-sign:before {
    content: "\f710";
}
.fi-tr-lasso-sparkles:before {
    content: "\f711";
}
.fi-tr-laugh-beam:before {
    content: "\f712";
}
.fi-tr-laugh-squint:before {
    content: "\f713";
}
.fi-tr-laugh-wink:before {
    content: "\f714";
}
.fi-tr-laugh:before {
    content: "\f715";
}
.fi-tr-lawyer-man:before {
    content: "\f716";
}
.fi-tr-lawyer-woman:before {
    content: "\f717";
}
.fi-tr-layer-minus:before {
    content: "\f718";
}
.fi-tr-layer-plus:before {
    content: "\f719";
}
.fi-tr-leader-alt:before {
    content: "\f71a";
}
.fi-tr-leader:before {
    content: "\f71b";
}
.fi-tr-leaderboard-alt:before {
    content: "\f71c";
}
.fi-tr-leaderboard-trophy:before {
    content: "\f71d";
}
.fi-tr-leaderboard:before {
    content: "\f71e";
}
.fi-tr-leadership-alt:before {
    content: "\f71f";
}
.fi-tr-leadership:before {
    content: "\f720";
}
.fi-tr-leaf-heart:before {
    content: "\f721";
}
.fi-tr-leaf-maple:before {
    content: "\f722";
}
.fi-tr-leaf-oak:before {
    content: "\f723";
}
.fi-tr-leaf:before {
    content: "\f724";
}
.fi-tr-leafy-green:before {
    content: "\f725";
}
.fi-tr-leave:before {
    content: "\f726";
}
.fi-tr-left:before {
    content: "\f727";
}
.fi-tr-legal:before {
    content: "\f728";
}
.fi-tr-lemon:before {
    content: "\f729";
}
.fi-tr-lettuce:before {
    content: "\f72a";
}
.fi-tr-level-down-alt:before {
    content: "\f72b";
}
.fi-tr-level-down:before {
    content: "\f72c";
}
.fi-tr-level-up-alt:before {
    content: "\f72d";
}
.fi-tr-level-up:before {
    content: "\f72e";
}
.fi-tr-license:before {
    content: "\f72f";
}
.fi-tr-light-ceiling:before {
    content: "\f730";
}
.fi-tr-light-emergency-on:before {
    content: "\f731";
}
.fi-tr-light-emergency:before {
    content: "\f732";
}
.fi-tr-light-switch-off:before {
    content: "\f733";
}
.fi-tr-light-switch-on:before {
    content: "\f734";
}
.fi-tr-light-switch:before {
    content: "\f735";
}
.fi-tr-lightbulb-dollar:before {
    content: "\f736";
}
.fi-tr-lightbulb-exclamation:before {
    content: "\f737";
}
.fi-tr-lightbulb-head:before {
    content: "\f738";
}
.fi-tr-lightbulb-on:before {
    content: "\f739";
}
.fi-tr-lightbulb-question:before {
    content: "\f73a";
}
.fi-tr-lightbulb-setting:before {
    content: "\f73b";
}
.fi-tr-lightbulb-slash:before {
    content: "\f73c";
}
.fi-tr-lights-holiday:before {
    content: "\f73d";
}
.fi-tr-limit-speedometer:before {
    content: "\f73e";
}
.fi-tr-link-alt:before {
    content: "\f73f";
}
.fi-tr-link-horizontal-slash:before {
    content: "\f740";
}
.fi-tr-link-horizontal:before {
    content: "\f741";
}
.fi-tr-link-slash-alt:before {
    content: "\f742";
}
.fi-tr-link-slash:before {
    content: "\f743";
}
.fi-tr-lion-head:before {
    content: "\f744";
}
.fi-tr-lion:before {
    content: "\f745";
}
.fi-tr-lips:before {
    content: "\f746";
}
.fi-tr-lira-sign:before {
    content: "\f747";
}
.fi-tr-list-dropdown:before {
    content: "\f748";
}
.fi-tr-list-music:before {
    content: "\f749";
}
.fi-tr-list-timeline:before {
    content: "\f74a";
}
.fi-tr-litecoin-sign:before {
    content: "\f74b";
}
.fi-tr-live-alt:before {
    content: "\f74c";
}
.fi-tr-live:before {
    content: "\f74d";
}
.fi-tr-loan:before {
    content: "\f74e";
}
.fi-tr-lobster:before {
    content: "\f74f";
}
.fi-tr-location-arrow:before {
    content: "\f750";
}
.fi-tr-location-crosshairs-slash:before {
    content: "\f751";
}
.fi-tr-location-crosshairs:before {
    content: "\f752";
}
.fi-tr-location-dot-slash:before {
    content: "\f753";
}
.fi-tr-location-exclamation:before {
    content: "\f754";
}
.fi-tr-lock-alt:before {
    content: "\f755";
}
.fi-tr-lock-hashtag:before {
    content: "\f756";
}
.fi-tr-lock-open-alt:before {
    content: "\f757";
}
.fi-tr-locust:before {
    content: "\f758";
}
.fi-tr-loveseat:before {
    content: "\f759";
}
.fi-tr-low-vision:before {
    content: "\f75a";
}
.fi-tr-luchador:before {
    content: "\f75b";
}
.fi-tr-luggage-cart:before {
    content: "\f75c";
}
.fi-tr-lungs-virus:before {
    content: "\f75d";
}
.fi-tr-lungs:before {
    content: "\f75e";
}
.fi-tr-m:before {
    content: "\f75f";
}
.fi-tr-mace:before {
    content: "\f760";
}
.fi-tr-magnet-user:before {
    content: "\f761";
}
.fi-tr-magnet:before {
    content: "\f762";
}
.fi-tr-mailbox:before {
    content: "\f763";
}
.fi-tr-man-head:before {
    content: "\f764";
}
.fi-tr-manat-sign:before {
    content: "\f765";
}
.fi-tr-mandolin:before {
    content: "\f766";
}
.fi-tr-mango:before {
    content: "\f767";
}
.fi-tr-manhole:before {
    content: "\f768";
}
.fi-tr-map-marker-check:before {
    content: "\f769";
}
.fi-tr-map-marker-edit:before {
    content: "\f76a";
}
.fi-tr-map-marker-question:before {
    content: "\f76b";
}
.fi-tr-map-marker-slash:before {
    content: "\f76c";
}
.fi-tr-map-marker-smile:before {
    content: "\f76d";
}
.fi-tr-map-pin:before {
    content: "\f76e";
}
.fi-tr-marketplace-alt:before {
    content: "\f76f";
}
.fi-tr-marketplace-store:before {
    content: "\f770";
}
.fi-tr-marketplace:before {
    content: "\f771";
}
.fi-tr-mars-double:before {
    content: "\f772";
}
.fi-tr-mars-stroke-right:before {
    content: "\f773";
}
.fi-tr-mars-stroke-up:before {
    content: "\f774";
}
.fi-tr-mars:before {
    content: "\f775";
}
.fi-tr-martini-glass-citrus:before {
    content: "\f776";
}
.fi-tr-martini-glass-empty:before {
    content: "\f777";
}
.fi-tr-mask-carnival:before {
    content: "\f778";
}
.fi-tr-mask-face:before {
    content: "\f779";
}
.fi-tr-mask-snorkel:before {
    content: "\f77a";
}
.fi-tr-massage:before {
    content: "\f77b";
}
.fi-tr-match-fire:before {
    content: "\f77c";
}
.fi-tr-mattress-pillow:before {
    content: "\f77d";
}
.fi-tr-meat:before {
    content: "\f77e";
}
.fi-tr-medal:before {
    content: "\f77f";
}
.fi-tr-medical-star:before {
    content: "\f780";
}
.fi-tr-meeting-alt:before {
    content: "\f781";
}
.fi-tr-meeting:before {
    content: "\f782";
}
.fi-tr-meh-blank:before {
    content: "\f783";
}
.fi-tr-meh-rolling-eyes:before {
    content: "\f784";
}
.fi-tr-meh:before {
    content: "\f785";
}
.fi-tr-melon-alt:before {
    content: "\f786";
}
.fi-tr-melon:before {
    content: "\f787";
}
.fi-tr-membership-vip:before {
    content: "\f788";
}
.fi-tr-membership:before {
    content: "\f789";
}
.fi-tr-memo-circle-check:before {
    content: "\f78a";
}
.fi-tr-memo-pad:before {
    content: "\f78b";
}
.fi-tr-memo:before {
    content: "\f78c";
}
.fi-tr-memory:before {
    content: "\f78d";
}
.fi-tr-mercury:before {
    content: "\f78e";
}
.fi-tr-message-arrow-down:before {
    content: "\f78f";
}
.fi-tr-message-arrow-up-right:before {
    content: "\f790";
}
.fi-tr-message-arrow-up:before {
    content: "\f791";
}
.fi-tr-message-bot:before {
    content: "\f792";
}
.fi-tr-message-code:before {
    content: "\f793";
}
.fi-tr-message-dollar:before {
    content: "\f794";
}
.fi-tr-message-image:before {
    content: "\f795";
}
.fi-tr-message-question:before {
    content: "\f796";
}
.fi-tr-message-quote:before {
    content: "\f797";
}
.fi-tr-message-slash:before {
    content: "\f798";
}
.fi-tr-message-sms:before {
    content: "\f799";
}
.fi-tr-message-text:before {
    content: "\f79a";
}
.fi-tr-message-xmark:before {
    content: "\f79b";
}
.fi-tr-messages-dollar:before {
    content: "\f79c";
}
.fi-tr-messages-question:before {
    content: "\f79d";
}
.fi-tr-messages:before {
    content: "\f79e";
}
.fi-tr-meteor:before {
    content: "\f79f";
}
.fi-tr-meter-bolt:before {
    content: "\f7a0";
}
.fi-tr-meter-droplet:before {
    content: "\f7a1";
}
.fi-tr-meter-fire:before {
    content: "\f7a2";
}
.fi-tr-meter:before {
    content: "\f7a3";
}
.fi-tr-method:before {
    content: "\f7a4";
}
.fi-tr-microchip-ai:before {
    content: "\f7a5";
}
.fi-tr-microchip:before {
    content: "\f7a6";
}
.fi-tr-microphone-alt:before {
    content: "\f7a7";
}
.fi-tr-microphone-slash:before {
    content: "\f7a8";
}
.fi-tr-microscope:before {
    content: "\f7a9";
}
.fi-tr-microwave:before {
    content: "\f7aa";
}
.fi-tr-milk-alt:before {
    content: "\f7ab";
}
.fi-tr-milk:before {
    content: "\f7ac";
}
.fi-tr-mill-sign:before {
    content: "\f7ad";
}
.fi-tr-mind-share:before {
    content: "\f7ae";
}
.fi-tr-minus-circle:before {
    content: "\f7af";
}
.fi-tr-minus-hexagon:before {
    content: "\f7b0";
}
.fi-tr-mistletoe:before {
    content: "\f7b1";
}
.fi-tr-mix:before {
    content: "\f7b2";
}
.fi-tr-mobile-button:before {
    content: "\f7b3";
}
.fi-tr-mobile-notch:before {
    content: "\f7b4";
}
.fi-tr-mockup:before {
    content: "\f7b5";
}
.fi-tr-mode-alt:before {
    content: "\f7b6";
}
.fi-tr-mode:before {
    content: "\f7b7";
}
.fi-tr-module:before {
    content: "\f7b8";
}
.fi-tr-monday:before {
    content: "\f7b9";
}
.fi-tr-money-bill-simple:before {
    content: "\f7ba";
}
.fi-tr-money-bill-transfer:before {
    content: "\f7bb";
}
.fi-tr-money-bill-wave-alt:before {
    content: "\f7bc";
}
.fi-tr-money-bill-wave:before {
    content: "\f7bd";
}
.fi-tr-money-bills-simple:before {
    content: "\f7be";
}
.fi-tr-money-bills:before {
    content: "\f7bf";
}
.fi-tr-money-check-edit-alt:before {
    content: "\f7c0";
}
.fi-tr-money-check-edit:before {
    content: "\f7c1";
}
.fi-tr-money-check:before {
    content: "\f7c2";
}
.fi-tr-money-coin-transfer:before {
    content: "\f7c3";
}
.fi-tr-money-from-bracket:before {
    content: "\f7c4";
}
.fi-tr-money-simple-from-bracket:before {
    content: "\f7c5";
}
.fi-tr-money-transfer-alt:before {
    content: "\f7c6";
}
.fi-tr-money-transfer-coin-arrow:before {
    content: "\f7c7";
}
.fi-tr-money-transfer-smartphone:before {
    content: "\f7c8";
}
.fi-tr-monkey:before {
    content: "\f7c9";
}
.fi-tr-monument:before {
    content: "\f7ca";
}
.fi-tr-moon-stars:before {
    content: "\f7cb";
}
.fi-tr-moon:before {
    content: "\f7cc";
}
.fi-tr-moped:before {
    content: "\f7cd";
}
.fi-tr-mortar-pestle:before {
    content: "\f7ce";
}
.fi-tr-mosque-alt:before {
    content: "\f7cf";
}
.fi-tr-mosque-moon:before {
    content: "\f7d0";
}
.fi-tr-mosquito-net:before {
    content: "\f7d1";
}
.fi-tr-mosquito:before {
    content: "\f7d2";
}
.fi-tr-motorcycle:before {
    content: "\f7d3";
}
.fi-tr-mound:before {
    content: "\f7d4";
}
.fi-tr-mountain-city:before {
    content: "\f7d5";
}
.fi-tr-mountain:before {
    content: "\f7d6";
}
.fi-tr-mountains:before {
    content: "\f7d7";
}
.fi-tr-move-to-folder-2:before {
    content: "\f7d8";
}
.fi-tr-move-to-folder:before {
    content: "\f7d9";
}
.fi-tr-mp3-player:before {
    content: "\f7da";
}
.fi-tr-mug-alt:before {
    content: "\f7db";
}
.fi-tr-mug-hot-alt:before {
    content: "\f7dc";
}
.fi-tr-mug-hot:before {
    content: "\f7dd";
}
.fi-tr-mug-marshmallows:before {
    content: "\f7de";
}
.fi-tr-mug-tea-saucer:before {
    content: "\f7df";
}
.fi-tr-mug-tea:before {
    content: "\f7e0";
}
.fi-tr-mug:before {
    content: "\f7e1";
}
.fi-tr-multiple-alt:before {
    content: "\f7e2";
}
.fi-tr-multiple:before {
    content: "\f7e3";
}
.fi-tr-mushroom-alt:before {
    content: "\f7e4";
}
.fi-tr-mushroom:before {
    content: "\f7e5";
}
.fi-tr-music-note-slash:before {
    content: "\f7e6";
}
.fi-tr-music-note:before {
    content: "\f7e7";
}
.fi-tr-music-slash:before {
    content: "\f7e8";
}
.fi-tr-n:before {
    content: "\f7e9";
}
.fi-tr-naira-sign:before {
    content: "\f7ea";
}
.fi-tr-narwhal:before {
    content: "\f7eb";
}
.fi-tr-nesting-dolls:before {
    content: "\f7ec";
}
.fi-tr-network-analytic:before {
    content: "\f7ed";
}
.fi-tr-network-cloud:before {
    content: "\f7ee";
}
.fi-tr-network:before {
    content: "\f7ef";
}
.fi-tr-neuter:before {
    content: "\f7f0";
}
.fi-tr-newspaper-open:before {
    content: "\f7f1";
}
.fi-tr-newspaper:before {
    content: "\f7f2";
}
.fi-tr-nfc-lock:before {
    content: "\f7f3";
}
.fi-tr-nfc-magnifying-glass:before {
    content: "\f7f4";
}
.fi-tr-nfc-pen:before {
    content: "\f7f5";
}
.fi-tr-nfc-slash:before {
    content: "\f7f6";
}
.fi-tr-nfc-trash:before {
    content: "\f7f7";
}
.fi-tr-nfc:before {
    content: "\f7f8";
}
.fi-tr-no-fee:before {
    content: "\f7f9";
}
.fi-tr-no-people:before {
    content: "\f7fa";
}
.fi-tr-noodles:before {
    content: "\f7fb";
}
.fi-tr-nose:before {
    content: "\f7fc";
}
.fi-tr-not-found-alt:before {
    content: "\f7fd";
}
.fi-tr-not-found-magnifying-glass:before {
    content: "\f7fe";
}
.fi-tr-not-found:before {
    content: "\f7ff";
}
.fi-tr-notdef:before {
    content: "\f800";
}
.fi-tr-note-medical:before {
    content: "\f801";
}
.fi-tr-note-sticky:before {
    content: "\f802";
}
.fi-tr-note:before {
    content: "\f803";
}
.fi-tr-notebook-alt:before {
    content: "\f804";
}
.fi-tr-notes-medical:before {
    content: "\f805";
}
.fi-tr-notes:before {
    content: "\f806";
}
.fi-tr-o:before {
    content: "\f807";
}
.fi-tr-object-exclude:before {
    content: "\f808";
}
.fi-tr-object-group:before {
    content: "\f809";
}
.fi-tr-object-intersect:before {
    content: "\f80a";
}
.fi-tr-object-subtract:before {
    content: "\f80b";
}
.fi-tr-object-ungroup:before {
    content: "\f80c";
}
.fi-tr-object-union:before {
    content: "\f80d";
}
.fi-tr-objects-column:before {
    content: "\f80e";
}
.fi-tr-octagon:before {
    content: "\f80f";
}
.fi-tr-oil-can:before {
    content: "\f810";
}
.fi-tr-oil-temp:before {
    content: "\f811";
}
.fi-tr-olive-oil:before {
    content: "\f812";
}
.fi-tr-olive:before {
    content: "\f813";
}
.fi-tr-olives:before {
    content: "\f814";
}
.fi-tr-onboarding:before {
    content: "\f815";
}
.fi-tr-onion:before {
    content: "\f816";
}
.fi-tr-operation:before {
    content: "\f817";
}
.fi-tr-organization-chart:before {
    content: "\f818";
}
.fi-tr-ornament:before {
    content: "\f819";
}
.fi-tr-otp:before {
    content: "\f81a";
}
.fi-tr-otter:before {
    content: "\f81b";
}
.fi-tr-outdent:before {
    content: "\f81c";
}
.fi-tr-oval-alt:before {
    content: "\f81d";
}
.fi-tr-oval:before {
    content: "\f81e";
}
.fi-tr-oven:before {
    content: "\f81f";
}
.fi-tr-overline:before {
    content: "\f820";
}
.fi-tr-overview:before {
    content: "\f821";
}
.fi-tr-p:before {
    content: "\f822";
}
.fi-tr-padlock-check:before {
    content: "\f823";
}
.fi-tr-page-break:before {
    content: "\f824";
}
.fi-tr-pager:before {
    content: "\f825";
}
.fi-tr-paid:before {
    content: "\f826";
}
.fi-tr-paint-roller:before {
    content: "\f827";
}
.fi-tr-paintbrush-pencil:before {
    content: "\f828";
}
.fi-tr-pallet-alt:before {
    content: "\f829";
}
.fi-tr-pallet:before {
    content: "\f82a";
}
.fi-tr-pan-food:before {
    content: "\f82b";
}
.fi-tr-pan-frying:before {
    content: "\f82c";
}
.fi-tr-pan:before {
    content: "\f82d";
}
.fi-tr-pancakes:before {
    content: "\f82e";
}
.fi-tr-panorama:before {
    content: "\f82f";
}
.fi-tr-paper-plane-top:before {
    content: "\f830";
}
.fi-tr-paperclip-vertical:before {
    content: "\f831";
}
.fi-tr-parachute-box:before {
    content: "\f832";
}
.fi-tr-paragraph-left:before {
    content: "\f833";
}
.fi-tr-paragraph:before {
    content: "\f834";
}
.fi-tr-parking-circle-slash:before {
    content: "\f835";
}
.fi-tr-parking-circle:before {
    content: "\f836";
}
.fi-tr-parking-slash:before {
    content: "\f837";
}
.fi-tr-parking:before {
    content: "\f838";
}
.fi-tr-party-bell:before {
    content: "\f839";
}
.fi-tr-party-horn:before {
    content: "\f83a";
}
.fi-tr-passport:before {
    content: "\f83b";
}
.fi-tr-password-alt:before {
    content: "\f83c";
}
.fi-tr-password-computer:before {
    content: "\f83d";
}
.fi-tr-password-email:before {
    content: "\f83e";
}
.fi-tr-password-smartphone:before {
    content: "\f83f";
}
.fi-tr-password:before {
    content: "\f840";
}
.fi-tr-paste:before {
    content: "\f841";
}
.fi-tr-pattern:before {
    content: "\f842";
}
.fi-tr-pause-circle:before {
    content: "\f843";
}
.fi-tr-paw-claws:before {
    content: "\f844";
}
.fi-tr-paw-heart:before {
    content: "\f845";
}
.fi-tr-paw:before {
    content: "\f846";
}
.fi-tr-payment-pos:before {
    content: "\f847";
}
.fi-tr-payroll-calendar:before {
    content: "\f848";
}
.fi-tr-payroll:before {
    content: "\f849";
}
.fi-tr-peace:before {
    content: "\f84a";
}
.fi-tr-peach:before {
    content: "\f84b";
}
.fi-tr-peanut:before {
    content: "\f84c";
}
.fi-tr-peanuts:before {
    content: "\f84d";
}
.fi-tr-peapod:before {
    content: "\f84e";
}
.fi-tr-pear:before {
    content: "\f84f";
}
.fi-tr-pedestal:before {
    content: "\f850";
}
.fi-tr-pen-circle:before {
    content: "\f851";
}
.fi-tr-pen-clip-slash:before {
    content: "\f852";
}
.fi-tr-pen-clip:before {
    content: "\f853";
}
.fi-tr-pen-fancy-slash:before {
    content: "\f854";
}
.fi-tr-pen-fancy:before {
    content: "\f855";
}
.fi-tr-pen-field:before {
    content: "\f856";
}
.fi-tr-pen-nib-slash:before {
    content: "\f857";
}
.fi-tr-pen-nib:before {
    content: "\f858";
}
.fi-tr-pen-slash:before {
    content: "\f859";
}
.fi-tr-pen-square:before {
    content: "\f85a";
}
.fi-tr-pen-swirl:before {
    content: "\f85b";
}
.fi-tr-pencil-paintbrush:before {
    content: "\f85c";
}
.fi-tr-pencil-ruler:before {
    content: "\f85d";
}
.fi-tr-pencil-slash:before {
    content: "\f85e";
}
.fi-tr-pending:before {
    content: "\f85f";
}
.fi-tr-pennant:before {
    content: "\f860";
}
.fi-tr-people-arrows-left-right:before {
    content: "\f861";
}
.fi-tr-people-carry-box:before {
    content: "\f862";
}
.fi-tr-people-dress:before {
    content: "\f863";
}
.fi-tr-people-pants:before {
    content: "\f864";
}
.fi-tr-people-poll:before {
    content: "\f865";
}
.fi-tr-people:before {
    content: "\f866";
}
.fi-tr-pepper-alt:before {
    content: "\f867";
}
.fi-tr-pepper-hot:before {
    content: "\f868";
}
.fi-tr-pepper:before {
    content: "\f869";
}
.fi-tr-percent-10:before {
    content: "\f86a";
}
.fi-tr-percent-100:before {
    content: "\f86b";
}
.fi-tr-percent-20:before {
    content: "\f86c";
}
.fi-tr-percent-25:before {
    content: "\f86d";
}
.fi-tr-percent-30:before {
    content: "\f86e";
}
.fi-tr-percent-40:before {
    content: "\f86f";
}
.fi-tr-percent-50:before {
    content: "\f870";
}
.fi-tr-percent-60:before {
    content: "\f871";
}
.fi-tr-percent-70:before {
    content: "\f872";
}
.fi-tr-percent-75:before {
    content: "\f873";
}
.fi-tr-percent-80:before {
    content: "\f874";
}
.fi-tr-percent-90:before {
    content: "\f875";
}
.fi-tr-percentage:before {
    content: "\f876";
}
.fi-tr-person-carry-box:before {
    content: "\f877";
}
.fi-tr-person-circle-check:before {
    content: "\f878";
}
.fi-tr-person-circle-exclamation:before {
    content: "\f879";
}
.fi-tr-person-circle-minus:before {
    content: "\f87a";
}
.fi-tr-person-circle-plus:before {
    content: "\f87b";
}
.fi-tr-person-circle-question:before {
    content: "\f87c";
}
.fi-tr-person-circle-xmark:before {
    content: "\f87d";
}
.fi-tr-person-cv:before {
    content: "\f87e";
}
.fi-tr-person-dolly-empty:before {
    content: "\f87f";
}
.fi-tr-person-dolly:before {
    content: "\f880";
}
.fi-tr-person-dress-simple:before {
    content: "\f881";
}
.fi-tr-person-dress:before {
    content: "\f882";
}
.fi-tr-person-praying:before {
    content: "\f883";
}
.fi-tr-person-pregnant:before {
    content: "\f884";
}
.fi-tr-person-seat-reclined:before {
    content: "\f885";
}
.fi-tr-person-seat:before {
    content: "\f886";
}
.fi-tr-person-sign:before {
    content: "\f887";
}
.fi-tr-person-simple:before {
    content: "\f888";
}
.fi-tr-person-walking-with-cane:before {
    content: "\f889";
}
.fi-tr-peseta-sign:before {
    content: "\f88a";
}
.fi-tr-peso-sign:before {
    content: "\f88b";
}
.fi-tr-pets:before {
    content: "\f88c";
}
.fi-tr-phone-call:before {
    content: "\f88d";
}
.fi-tr-phone-cross:before {
    content: "\f88e";
}
.fi-tr-phone-flip:before {
    content: "\f88f";
}
.fi-tr-phone-office:before {
    content: "\f890";
}
.fi-tr-phone-pause:before {
    content: "\f891";
}
.fi-tr-phone-plus:before {
    content: "\f892";
}
.fi-tr-phone-rotary:before {
    content: "\f893";
}
.fi-tr-phone-slash:before {
    content: "\f894";
}
.fi-tr-photo-capture:before {
    content: "\f895";
}
.fi-tr-photo-film-music:before {
    content: "\f896";
}
.fi-tr-photo-video:before {
    content: "\f897";
}
.fi-tr-Pi:before {
    content: "\f898";
}
.fi-tr-piano-keyboard:before {
    content: "\f899";
}
.fi-tr-piano:before {
    content: "\f89a";
}
.fi-tr-pickaxe:before {
    content: "\f89b";
}
.fi-tr-picking:before {
    content: "\f89c";
}
.fi-tr-picnic:before {
    content: "\f89d";
}
.fi-tr-picpeople-filled:before {
    content: "\f89e";
}
.fi-tr-picpeople:before {
    content: "\f89f";
}
.fi-tr-pie:before {
    content: "\f8a0";
}
.fi-tr-pig-face:before {
    content: "\f8a1";
}
.fi-tr-pig:before {
    content: "\f8a2";
}
.fi-tr-piggy-bank:before {
    content: "\f8a3";
}
.fi-tr-pills:before {
    content: "\f8a4";
}
.fi-tr-pinata:before {
    content: "\f8a5";
}
.fi-tr-pineapple-alt:before {
    content: "\f8a6";
}
.fi-tr-pineapple:before {
    content: "\f8a7";
}
.fi-tr-pipe-smoking:before {
    content: "\f8a8";
}
.fi-tr-pizza-slice:before {
    content: "\f8a9";
}
.fi-tr-plan-strategy:before {
    content: "\f8aa";
}
.fi-tr-plan:before {
    content: "\f8ab";
}
.fi-tr-plane-alt:before {
    content: "\f8ac";
}
.fi-tr-plane-arrival:before {
    content: "\f8ad";
}
.fi-tr-plane-departure:before {
    content: "\f8ae";
}
.fi-tr-plane-prop:before {
    content: "\f8af";
}
.fi-tr-plane-slash:before {
    content: "\f8b0";
}
.fi-tr-plane-tail:before {
    content: "\f8b1";
}
.fi-tr-planet-moon:before {
    content: "\f8b2";
}
.fi-tr-planet-ringed:before {
    content: "\f8b3";
}
.fi-tr-plant-wilt:before {
    content: "\f8b4";
}
.fi-tr-plate-utensils:before {
    content: "\f8b5";
}
.fi-tr-plate-wheat:before {
    content: "\f8b6";
}
.fi-tr-plate:before {
    content: "\f8b7";
}
.fi-tr-play-circle:before {
    content: "\f8b8";
}
.fi-tr-play-pause:before {
    content: "\f8b9";
}
.fi-tr-plug-alt:before {
    content: "\f8ba";
}
.fi-tr-plug-cable:before {
    content: "\f8bb";
}
.fi-tr-plug-connection:before {
    content: "\f8bc";
}
.fi-tr-plug:before {
    content: "\f8bd";
}
.fi-tr-plus-hexagon:before {
    content: "\f8be";
}
.fi-tr-plus-minus:before {
    content: "\f8bf";
}
.fi-tr-podcast:before {
    content: "\f8c0";
}
.fi-tr-podium-star:before {
    content: "\f8c1";
}
.fi-tr-podium:before {
    content: "\f8c2";
}
.fi-tr-point-of-sale-bill:before {
    content: "\f8c3";
}
.fi-tr-point-of-sale-signal:before {
    content: "\f8c4";
}
.fi-tr-point-of-sale:before {
    content: "\f8c5";
}
.fi-tr-police-box:before {
    content: "\f8c6";
}
.fi-tr-poll-h:before {
    content: "\f8c7";
}
.fi-tr-pollution:before {
    content: "\f8c8";
}
.fi-tr-pompebled:before {
    content: "\f8c9";
}
.fi-tr-poo-bolt:before {
    content: "\f8ca";
}
.fi-tr-poo:before {
    content: "\f8cb";
}
.fi-tr-pool-8-ball:before {
    content: "\f8cc";
}
.fi-tr-poop:before {
    content: "\f8cd";
}
.fi-tr-popcorn:before {
    content: "\f8ce";
}
.fi-tr-popsicle:before {
    content: "\f8cf";
}
.fi-tr-portal-enter:before {
    content: "\f8d0";
}
.fi-tr-portal-exit:before {
    content: "\f8d1";
}
.fi-tr-pot:before {
    content: "\f8d2";
}
.fi-tr-potato:before {
    content: "\f8d3";
}
.fi-tr-prescription-bottle-alt:before {
    content: "\f8d4";
}
.fi-tr-prescription-bottle-pill:before {
    content: "\f8d5";
}
.fi-tr-prescription-bottle:before {
    content: "\f8d6";
}
.fi-tr-prescription:before {
    content: "\f8d7";
}
.fi-tr-print-magnifying-glass:before {
    content: "\f8d8";
}
.fi-tr-print-slash:before {
    content: "\f8d9";
}
.fi-tr-problem-solving:before {
    content: "\f8da";
}
.fi-tr-procedures:before {
    content: "\f8db";
}
.fi-tr-process:before {
    content: "\f8dc";
}
.fi-tr-productivity:before {
    content: "\f8dd";
}
.fi-tr-projector:before {
    content: "\f8de";
}
.fi-tr-pump-medical:before {
    content: "\f8df";
}
.fi-tr-pump:before {
    content: "\f8e0";
}
.fi-tr-pumpkin-alt-2:before {
    content: "\f8e1";
}
.fi-tr-pumpkin-alt:before {
    content: "\f8e2";
}
.fi-tr-pumpkin:before {
    content: "\f8e3";
}
.fi-tr-puzzle-alt:before {
    content: "\f8e4";
}
.fi-tr-puzzle-piece:before {
    content: "\f8e5";
}
.fi-tr-puzzle-pieces:before {
    content: "\f8e6";
}
.fi-tr-pyramid:before {
    content: "\f8e7";
}
.fi-tr-q:before {
    content: "\f8e8";
}
.fi-tr-qr-scan:before {
    content: "\f8e9";
}
.fi-tr-QR:before {
    content: "\f8ea";
}
.fi-tr-qrcode:before {
    content: "\f8eb";
}
.fi-tr-question-square:before {
    content: "\f8ec";
}
.fi-tr-question:before {
    content: "\f8ed";
}
.fi-tr-queue-alt:before {
    content: "\f8ee";
}
.fi-tr-queue-line:before {
    content: "\f8ef";
}
.fi-tr-queue-signal:before {
    content: "\f8f0";
}
.fi-tr-queue:before {
    content: "\f8f1";
}
.fi-tr-quiz-alt:before {
    content: "\f8f2";
}
.fi-tr-quiz:before {
    content: "\f8f3";
}
.fi-tr-r:before {
    content: "\f8f4";
}
.fi-tr-rabbit-fast:before {
    content: "\f8f5";
}
.fi-tr-rabbit:before {
    content: "\f8f6";
}
.fi-tr-racquet:before {
    content: "\f8f7";
}
.fi-tr-radar:before {
    content: "\f8f8";
}
.fi-tr-radiation-alt:before {
    content: "\f8f9";
}
.fi-tr-radiation:before {
    content: "\f8fa";
}
.fi-tr-radio-alt:before {
    content: "\f8fb";
}
.fi-tr-radio:before {
    content: "\f8fc";
}
.fi-tr-radish:before {
    content: "\f8fd";
}
.fi-tr-rainbow:before {
    content: "\f8fe";
}
.fi-tr-raindrops:before {
    content: "\f8ff";
}
.fi-tr-ram:before {
    content: "\f900";
}
.fi-tr-ramp-loading:before {
    content: "\f901";
}
.fi-tr-rank:before {
    content: "\f902";
}
.fi-tr-ranking-podium-empty:before {
    content: "\f903";
}
.fi-tr-ranking-podium:before {
    content: "\f904";
}
.fi-tr-ranking-star:before {
    content: "\f905";
}
.fi-tr-ranking-stars:before {
    content: "\f906";
}
.fi-tr-raygun:before {
    content: "\f907";
}
.fi-tr-razor-barber:before {
    content: "\f908";
}
.fi-tr-react:before {
    content: "\f909";
}
.fi-tr-recipe-book:before {
    content: "\f90a";
}
.fi-tr-recipe:before {
    content: "\f90b";
}
.fi-tr-record-vinyl:before {
    content: "\f90c";
}
.fi-tr-rectangle-barcode:before {
    content: "\f90d";
}
.fi-tr-rectangle-code:before {
    content: "\f90e";
}
.fi-tr-rectangle-history-circle-plus:before {
    content: "\f90f";
}
.fi-tr-rectangle-horizontal:before {
    content: "\f910";
}
.fi-tr-rectangle-list:before {
    content: "\f911";
}
.fi-tr-rectangle-panoramic:before {
    content: "\f912";
}
.fi-tr-rectangle-pro:before {
    content: "\f913";
}
.fi-tr-rectangle-vertical-history:before {
    content: "\f914";
}
.fi-tr-rectangle-vertical:before {
    content: "\f915";
}
.fi-tr-rectangle-xmark:before {
    content: "\f916";
}
.fi-tr-rectangles-mixed:before {
    content: "\f917";
}
.fi-tr-recycle:before {
    content: "\f918";
}
.fi-tr-redo-alt:before {
    content: "\f919";
}
.fi-tr-reel:before {
    content: "\f91a";
}
.fi-tr-reflect-horizontal:before {
    content: "\f91b";
}
.fi-tr-reflect-vertical:before {
    content: "\f91c";
}
.fi-tr-refrigerator:before {
    content: "\f91d";
}
.fi-tr-refund-alt:before {
    content: "\f91e";
}
.fi-tr-refund:before {
    content: "\f91f";
}
.fi-tr-region-pin-alt:before {
    content: "\f920";
}
.fi-tr-region-pin:before {
    content: "\f921";
}
.fi-tr-registered:before {
    content: "\f922";
}
.fi-tr-registration-paper:before {
    content: "\f923";
}
.fi-tr-remove-folder:before {
    content: "\f924";
}
.fi-tr-rent-signal:before {
    content: "\f925";
}
.fi-tr-rent:before {
    content: "\f926";
}
.fi-tr-replace:before {
    content: "\f927";
}
.fi-tr-reply-all:before {
    content: "\f928";
}
.fi-tr-republican:before {
    content: "\f929";
}
.fi-tr-reservation-smartphone:before {
    content: "\f92a";
}
.fi-tr-reservation-table:before {
    content: "\f92b";
}
.fi-tr-resources:before {
    content: "\f92c";
}
.fi-tr-restaurant:before {
    content: "\f92d";
}
.fi-tr-restock:before {
    content: "\f92e";
}
.fi-tr-restroom-simple:before {
    content: "\f92f";
}
.fi-tr-revenue-alt:before {
    content: "\f930";
}
.fi-tr-Revenue:before {
    content: "\f931";
}
.fi-tr-review:before {
    content: "\f932";
}
.fi-tr-rhombus:before {
    content: "\f933";
}
.fi-tr-ribbon:before {
    content: "\f934";
}
.fi-tr-right:before {
    content: "\f935";
}
.fi-tr-ring-diamond:before {
    content: "\f936";
}
.fi-tr-ring:before {
    content: "\f937";
}
.fi-tr-rings-wedding:before {
    content: "\f938";
}
.fi-tr-risk-alt:before {
    content: "\f939";
}
.fi-tr-risk:before {
    content: "\f93a";
}
.fi-tr-robot:before {
    content: "\f93b";
}
.fi-tr-rocket-lunch:before {
    content: "\f93c";
}
.fi-tr-roller-coaster:before {
    content: "\f93d";
}
.fi-tr-room-service:before {
    content: "\f93e";
}
.fi-tr-rotate-exclamation:before {
    content: "\f93f";
}
.fi-tr-rotate-square:before {
    content: "\f940";
}
.fi-tr-route-highway:before {
    content: "\f941";
}
.fi-tr-route-interstate:before {
    content: "\f942";
}
.fi-tr-route:before {
    content: "\f943";
}
.fi-tr-router:before {
    content: "\f944";
}
.fi-tr-rss-alt:before {
    content: "\f945";
}
.fi-tr-rss:before {
    content: "\f946";
}
.fi-tr-ruble-sign:before {
    content: "\f947";
}
.fi-tr-rugby-helmet:before {
    content: "\f948";
}
.fi-tr-ruler-combined:before {
    content: "\f949";
}
.fi-tr-ruler-horizontal:before {
    content: "\f94a";
}
.fi-tr-ruler-triangle:before {
    content: "\f94b";
}
.fi-tr-ruler-vertical:before {
    content: "\f94c";
}
.fi-tr-running:before {
    content: "\f94d";
}
.fi-tr-rupee-sign:before {
    content: "\f94e";
}
.fi-tr-rupiah-sign:before {
    content: "\f94f";
}
.fi-tr-rv:before {
    content: "\f950";
}
.fi-tr-s:before {
    content: "\f951";
}
.fi-tr-sack-dollar:before {
    content: "\f952";
}
.fi-tr-sack:before {
    content: "\f953";
}
.fi-tr-sad-cry:before {
    content: "\f954";
}
.fi-tr-sad-tear:before {
    content: "\f955";
}
.fi-tr-sad:before {
    content: "\f956";
}
.fi-tr-safe-box:before {
    content: "\f957";
}
.fi-tr-sailboat:before {
    content: "\f958";
}
.fi-tr-salad:before {
    content: "\f959";
}
.fi-tr-salary-alt:before {
    content: "\f95a";
}
.fi-tr-salt-pepper:before {
    content: "\f95b";
}
.fi-tr-salt-shaker:before {
    content: "\f95c";
}
.fi-tr-sandwich-alt:before {
    content: "\f95d";
}
.fi-tr-sandwich:before {
    content: "\f95e";
}
.fi-tr-satellite-dish:before {
    content: "\f95f";
}
.fi-tr-satellite:before {
    content: "\f960";
}
.fi-tr-saturday:before {
    content: "\f961";
}
.fi-tr-sauce:before {
    content: "\f962";
}
.fi-tr-sausage:before {
    content: "\f963";
}
.fi-tr-sax-hot:before {
    content: "\f964";
}
.fi-tr-saxophone:before {
    content: "\f965";
}
.fi-tr-scale-comparison-alt:before {
    content: "\f966";
}
.fi-tr-scale-comparison:before {
    content: "\f967";
}
.fi-tr-scalpel-path:before {
    content: "\f968";
}
.fi-tr-scalpel:before {
    content: "\f969";
}
.fi-tr-scanner-gun:before {
    content: "\f96a";
}
.fi-tr-scanner-image:before {
    content: "\f96b";
}
.fi-tr-scanner-keyboard:before {
    content: "\f96c";
}
.fi-tr-scanner-touchscreen:before {
    content: "\f96d";
}
.fi-tr-scarecrow:before {
    content: "\f96e";
}
.fi-tr-scarf:before {
    content: "\f96f";
}
.fi-tr-screencast:before {
    content: "\f970";
}
.fi-tr-screwdriver:before {
    content: "\f971";
}
.fi-tr-scribble:before {
    content: "\f972";
}
.fi-tr-scroll-old:before {
    content: "\f973";
}
.fi-tr-scroll-torah:before {
    content: "\f974";
}
.fi-tr-scroll:before {
    content: "\f975";
}
.fi-tr-scrubber:before {
    content: "\f976";
}
.fi-tr-scythe:before {
    content: "\f977";
}
.fi-tr-sd-card:before {
    content: "\f978";
}
.fi-tr-sd-cards:before {
    content: "\f979";
}
.fi-tr-seal-exclamation:before {
    content: "\f97a";
}
.fi-tr-seal-question:before {
    content: "\f97b";
}
.fi-tr-seal:before {
    content: "\f97c";
}
.fi-tr-search-dollar:before {
    content: "\f97d";
}
.fi-tr-search-heart:before {
    content: "\f97e";
}
.fi-tr-search-location:before {
    content: "\f97f";
}
.fi-tr-seat-airline:before {
    content: "\f980";
}
.fi-tr-second-award:before {
    content: "\f981";
}
.fi-tr-second-laurel:before {
    content: "\f982";
}
.fi-tr-second-medal:before {
    content: "\f983";
}
.fi-tr-second:before {
    content: "\f984";
}
.fi-tr-seedling:before {
    content: "\f985";
}
.fi-tr-selection:before {
    content: "\f986";
}
.fi-tr-sell:before {
    content: "\f987";
}
.fi-tr-seller-store:before {
    content: "\f988";
}
.fi-tr-seller:before {
    content: "\f989";
}
.fi-tr-selling:before {
    content: "\f98a";
}
.fi-tr-send-back:before {
    content: "\f98b";
}
.fi-tr-send-backward:before {
    content: "\f98c";
}
.fi-tr-send-money-smartphone:before {
    content: "\f98d";
}
.fi-tr-send-money:before {
    content: "\f98e";
}
.fi-tr-sensor-alert:before {
    content: "\f98f";
}
.fi-tr-sensor-fire:before {
    content: "\f990";
}
.fi-tr-sensor-on:before {
    content: "\f991";
}
.fi-tr-sensor-smoke:before {
    content: "\f992";
}
.fi-tr-sensor:before {
    content: "\f993";
}
.fi-tr-share-alt-square:before {
    content: "\f994";
}
.fi-tr-share-square:before {
    content: "\f995";
}
.fi-tr-sheep:before {
    content: "\f996";
}
.fi-tr-shekel-sign:before {
    content: "\f997";
}
.fi-tr-shelves:before {
    content: "\f998";
}
.fi-tr-shield-alt:before {
    content: "\f999";
}
.fi-tr-shield-cat:before {
    content: "\f99a";
}
.fi-tr-shield-cross:before {
    content: "\f99b";
}
.fi-tr-shield-dog:before {
    content: "\f99c";
}
.fi-tr-shield-exclamation:before {
    content: "\f99d";
}
.fi-tr-shield-interrogation:before {
    content: "\f99e";
}
.fi-tr-shield-keyhole:before {
    content: "\f99f";
}
.fi-tr-shield-minus:before {
    content: "\f9a0";
}
.fi-tr-shield-slash:before {
    content: "\f9a1";
}
.fi-tr-shield-virus:before {
    content: "\f9a2";
}
.fi-tr-shield-xmark:before {
    content: "\f9a3";
}
.fi-tr-shipping-fast:before {
    content: "\f9a4";
}
.fi-tr-shipping-timed:before {
    content: "\f9a5";
}
.fi-tr-shirt-long-sleeve:before {
    content: "\f9a6";
}
.fi-tr-shirt-running:before {
    content: "\f9a7";
}
.fi-tr-shirt-tank-top:before {
    content: "\f9a8";
}
.fi-tr-shirt:before {
    content: "\f9a9";
}
.fi-tr-shish-kebab:before {
    content: "\f9aa";
}
.fi-tr-shoe-prints:before {
    content: "\f9ab";
}
.fi-tr-shop-lock:before {
    content: "\f9ac";
}
.fi-tr-shop-slash:before {
    content: "\f9ad";
}
.fi-tr-shopping-basket:before {
    content: "\f9ae";
}
.fi-tr-shovel-snow:before {
    content: "\f9af";
}
.fi-tr-shovel:before {
    content: "\f9b0";
}
.fi-tr-shower-down:before {
    content: "\f9b1";
}
.fi-tr-shower:before {
    content: "\f9b2";
}
.fi-tr-shredder:before {
    content: "\f9b3";
}
.fi-tr-shrimp:before {
    content: "\f9b4";
}
.fi-tr-shuttle-van:before {
    content: "\f9b5";
}
.fi-tr-shuttlecock:before {
    content: "\f9b6";
}
.fi-tr-Sickle:before {
    content: "\f9b7";
}
.fi-tr-sidebar-flip:before {
    content: "\f9b8";
}
.fi-tr-sidebar:before {
    content: "\f9b9";
}
.fi-tr-sigma:before {
    content: "\f9ba";
}
.fi-tr-sign-hanging:before {
    content: "\f9bb";
}
.fi-tr-sign-in-alt:before {
    content: "\f9bc";
}
.fi-tr-sign-out-alt:before {
    content: "\f9bd";
}
.fi-tr-sign-up:before {
    content: "\f9be";
}
.fi-tr-signal-alt-1:before {
    content: "\f9bf";
}
.fi-tr-signal-alt-2:before {
    content: "\f9c0";
}
.fi-tr-signal-alt-slash:before {
    content: "\f9c1";
}
.fi-tr-signal-alt:before {
    content: "\f9c2";
}
.fi-tr-signal-bars-fair:before {
    content: "\f9c3";
}
.fi-tr-signal-bars-good:before {
    content: "\f9c4";
}
.fi-tr-signal-bars-weak:before {
    content: "\f9c5";
}
.fi-tr-signal-stream-slash:before {
    content: "\f9c6";
}
.fi-tr-signal-stream:before {
    content: "\f9c7";
}
.fi-tr-signature-lock:before {
    content: "\f9c8";
}
.fi-tr-signature-slash:before {
    content: "\f9c9";
}
.fi-tr-signature:before {
    content: "\f9ca";
}
.fi-tr-sim-card:before {
    content: "\f9cb";
}
.fi-tr-sim-cards:before {
    content: "\f9cc";
}
.fi-tr-sink:before {
    content: "\f9cd";
}
.fi-tr-siren-on:before {
    content: "\f9ce";
}
.fi-tr-Siren:before {
    content: "\f9cf";
}
.fi-tr-site-alt:before {
    content: "\f9d0";
}
.fi-tr-site-browser:before {
    content: "\f9d1";
}
.fi-tr-site:before {
    content: "\f9d2";
}
.fi-tr-sitemap:before {
    content: "\f9d3";
}
.fi-tr-skating:before {
    content: "\f9d4";
}
.fi-tr-skeleton:before {
    content: "\f9d5";
}
.fi-tr-skewer:before {
    content: "\f9d6";
}
.fi-tr-ski-boot-ski:before {
    content: "\f9d7";
}
.fi-tr-ski-jump:before {
    content: "\f9d8";
}
.fi-tr-ski-lift:before {
    content: "\f9d9";
}
.fi-tr-skiing-nordic:before {
    content: "\f9da";
}
.fi-tr-skiing:before {
    content: "\f9db";
}
.fi-tr-skull-cow:before {
    content: "\f9dc";
}
.fi-tr-skull-crossbones:before {
    content: "\f9dd";
}
.fi-tr-skull:before {
    content: "\f9de";
}
.fi-tr-slash:before {
    content: "\f9df";
}
.fi-tr-sledding:before {
    content: "\f9e0";
}
.fi-tr-sleigh:before {
    content: "\f9e1";
}
.fi-tr-sliders-h-square:before {
    content: "\f9e2";
}
.fi-tr-sliders-v-square:before {
    content: "\f9e3";
}
.fi-tr-sliders-v:before {
    content: "\f9e4";
}
.fi-tr-smile-beam:before {
    content: "\f9e5";
}
.fi-tr-smile-plus:before {
    content: "\f9e6";
}
.fi-tr-smile-wink:before {
    content: "\f9e7";
}
.fi-tr-smile:before {
    content: "\f9e8";
}
.fi-tr-smiley-comment-alt:before {
    content: "\f9e9";
}
.fi-tr-smog:before {
    content: "\f9ea";
}
.fi-tr-smoke:before {
    content: "\f9eb";
}
.fi-tr-smoking-ban:before {
    content: "\f9ec";
}
.fi-tr-smoking:before {
    content: "\f9ed";
}
.fi-tr-snake:before {
    content: "\f9ee";
}
.fi-tr-snooze:before {
    content: "\f9ef";
}
.fi-tr-snow-blowing:before {
    content: "\f9f0";
}
.fi-tr-snowboarding:before {
    content: "\f9f1";
}
.fi-tr-snowflake-droplets:before {
    content: "\f9f2";
}
.fi-tr-snowflake:before {
    content: "\f9f3";
}
.fi-tr-snowflakes:before {
    content: "\f9f4";
}
.fi-tr-snowman-alt:before {
    content: "\f9f5";
}
.fi-tr-snowman-head:before {
    content: "\f9f6";
}
.fi-tr-snowmobile:before {
    content: "\f9f7";
}
.fi-tr-snowplow:before {
    content: "\f9f8";
}
.fi-tr-soap-alt:before {
    content: "\f9f9";
}
.fi-tr-socks:before {
    content: "\f9fa";
}
.fi-tr-sofa-size:before {
    content: "\f9fb";
}
.fi-tr-sofa:before {
    content: "\f9fc";
}
.fi-tr-solar-panel:before {
    content: "\f9fd";
}
.fi-tr-solar-system:before {
    content: "\f9fe";
}
.fi-tr-sold-house:before {
    content: "\f9ff";
}
.fi-tr-sold-signal:before {
    content: "\fa00";
}
.fi-tr-sort-alpha-down-alt:before {
    content: "\fa01";
}
.fi-tr-sort-alpha-down:before {
    content: "\fa02";
}
.fi-tr-sort-alpha-up-alt:before {
    content: "\fa03";
}
.fi-tr-sort-alpha-up:before {
    content: "\fa04";
}
.fi-tr-sort-alt:before {
    content: "\fa05";
}
.fi-tr-sort-amount-down-alt:before {
    content: "\fa06";
}
.fi-tr-sort-amount-down:before {
    content: "\fa07";
}
.fi-tr-sort-amount-up-alt:before {
    content: "\fa08";
}
.fi-tr-sort-amount-up:before {
    content: "\fa09";
}
.fi-tr-sort-circle-down:before {
    content: "\fa0a";
}
.fi-tr-sort-circle-up:before {
    content: "\fa0b";
}
.fi-tr-sort-circle:before {
    content: "\fa0c";
}
.fi-tr-sort-down:before {
    content: "\fa0d";
}
.fi-tr-sort-numeric-down-alt:before {
    content: "\fa0e";
}
.fi-tr-sort-numeric-down:before {
    content: "\fa0f";
}
.fi-tr-sort-shapes-down:before {
    content: "\fa10";
}
.fi-tr-sort-shapes-up:before {
    content: "\fa11";
}
.fi-tr-sort-size-down:before {
    content: "\fa12";
}
.fi-tr-sort-size-up:before {
    content: "\fa13";
}
.fi-tr-sort:before {
    content: "\fa14";
}
.fi-tr-soup:before {
    content: "\fa15";
}
.fi-tr-source-data:before {
    content: "\fa16";
}
.fi-tr-space-shuttle:before {
    content: "\fa17";
}
.fi-tr-space-station-moon-alt:before {
    content: "\fa18";
}
.fi-tr-space-station-moon:before {
    content: "\fa19";
}
.fi-tr-spade:before {
    content: "\fa1a";
}
.fi-tr-spaghetti-monster-flying:before {
    content: "\fa1b";
}
.fi-tr-sparkles:before {
    content: "\fa1c";
}
.fi-tr-speakers:before {
    content: "\fa1d";
}
.fi-tr-speedometer-arrow:before {
    content: "\fa1e";
}
.fi-tr-speedometer-kpi:before {
    content: "\fa1f";
}
.fi-tr-sphere:before {
    content: "\fa20";
}
.fi-tr-spider-black-widow:before {
    content: "\fa21";
}
.fi-tr-spider-web:before {
    content: "\fa22";
}
.fi-tr-spider:before {
    content: "\fa23";
}
.fi-tr-split:before {
    content: "\fa24";
}
.fi-tr-splotch:before {
    content: "\fa25";
}
.fi-tr-spoon:before {
    content: "\fa26";
}
.fi-tr-spray-can-sparkles:before {
    content: "\fa27";
}
.fi-tr-spray-can:before {
    content: "\fa28";
}
.fi-tr-spring-calendar:before {
    content: "\fa29";
}
.fi-tr-sprinkler:before {
    content: "\fa2a";
}
.fi-tr-spy:before {
    content: "\fa2b";
}
.fi-tr-square-0:before {
    content: "\fa2c";
}
.fi-tr-square-1:before {
    content: "\fa2d";
}
.fi-tr-square-2:before {
    content: "\fa2e";
}
.fi-tr-square-3:before {
    content: "\fa2f";
}
.fi-tr-square-4:before {
    content: "\fa30";
}
.fi-tr-square-5:before {
    content: "\fa31";
}
.fi-tr-square-6:before {
    content: "\fa32";
}
.fi-tr-square-7:before {
    content: "\fa33";
}
.fi-tr-square-8:before {
    content: "\fa34";
}
.fi-tr-square-9:before {
    content: "\fa35";
}
.fi-tr-square-a:before {
    content: "\fa36";
}
.fi-tr-square-b:before {
    content: "\fa37";
}
.fi-tr-square-bolt:before {
    content: "\fa38";
}
.fi-tr-square-c:before {
    content: "\fa39";
}
.fi-tr-square-code:before {
    content: "\fa3a";
}
.fi-tr-square-d:before {
    content: "\fa3b";
}
.fi-tr-square-dashed:before {
    content: "\fa3c";
}
.fi-tr-square-e:before {
    content: "\fa3d";
}
.fi-tr-square-ellipsis-vertical:before {
    content: "\fa3e";
}
.fi-tr-square-ellipsis:before {
    content: "\fa3f";
}
.fi-tr-square-exclamation:before {
    content: "\fa40";
}
.fi-tr-square-f:before {
    content: "\fa41";
}
.fi-tr-square-g:before {
    content: "\fa42";
}
.fi-tr-square-h:before {
    content: "\fa43";
}
.fi-tr-square-heart:before {
    content: "\fa44";
}
.fi-tr-square-i:before {
    content: "\fa45";
}
.fi-tr-square-info:before {
    content: "\fa46";
}
.fi-tr-square-j:before {
    content: "\fa47";
}
.fi-tr-square-k:before {
    content: "\fa48";
}
.fi-tr-square-kanban:before {
    content: "\fa49";
}
.fi-tr-square-l:before {
    content: "\fa4a";
}
.fi-tr-square-m:before {
    content: "\fa4b";
}
.fi-tr-square-minus:before {
    content: "\fa4c";
}
.fi-tr-square-n:before {
    content: "\fa4d";
}
.fi-tr-square-o:before {
    content: "\fa4e";
}
.fi-tr-square-p:before {
    content: "\fa4f";
}
.fi-tr-square-phone-hangup:before {
    content: "\fa50";
}
.fi-tr-square-plus:before {
    content: "\fa51";
}
.fi-tr-square-poll-horizontal:before {
    content: "\fa52";
}
.fi-tr-square-poll-vertical:before {
    content: "\fa53";
}
.fi-tr-square-q:before {
    content: "\fa54";
}
.fi-tr-square-quote:before {
    content: "\fa55";
}
.fi-tr-square-r:before {
    content: "\fa56";
}
.fi-tr-square-s:before {
    content: "\fa57";
}
.fi-tr-square-small:before {
    content: "\fa58";
}
.fi-tr-square-star:before {
    content: "\fa59";
}
.fi-tr-square-t:before {
    content: "\fa5a";
}
.fi-tr-square-terminal:before {
    content: "\fa5b";
}
.fi-tr-square-u:before {
    content: "\fa5c";
}
.fi-tr-square-up-right:before {
    content: "\fa5d";
}
.fi-tr-square-v:before {
    content: "\fa5e";
}
.fi-tr-square-w:before {
    content: "\fa5f";
}
.fi-tr-square-x:before {
    content: "\fa60";
}
.fi-tr-square-y:before {
    content: "\fa61";
}
.fi-tr-square-z:before {
    content: "\fa62";
}
.fi-tr-square:before {
    content: "\fa63";
}
.fi-tr-squid:before {
    content: "\fa64";
}
.fi-tr-squircle:before {
    content: "\fa65";
}
.fi-tr-squirrel:before {
    content: "\fa66";
}
.fi-tr-staff:before {
    content: "\fa67";
}
.fi-tr-stage-concert:before {
    content: "\fa68";
}
.fi-tr-stage-theatre:before {
    content: "\fa69";
}
.fi-tr-stage:before {
    content: "\fa6a";
}
.fi-tr-stairs:before {
    content: "\fa6b";
}
.fi-tr-stamp:before {
    content: "\fa6c";
}
.fi-tr-standard-definition:before {
    content: "\fa6d";
}
.fi-tr-star-and-crescent:before {
    content: "\fa6e";
}
.fi-tr-star-christmas:before {
    content: "\fa6f";
}
.fi-tr-star-comment-alt:before {
    content: "\fa70";
}
.fi-tr-star-exclamation:before {
    content: "\fa71";
}
.fi-tr-star-octogram:before {
    content: "\fa72";
}
.fi-tr-star-of-david:before {
    content: "\fa73";
}
.fi-tr-star-sharp-half-stroke:before {
    content: "\fa74";
}
.fi-tr-star-sharp-half:before {
    content: "\fa75";
}
.fi-tr-star-shooting:before {
    content: "\fa76";
}
.fi-tr-starfighter:before {
    content: "\fa77";
}
.fi-tr-stars:before {
    content: "\fa78";
}
.fi-tr-state-country:before {
    content: "\fa79";
}
.fi-tr-stats:before {
    content: "\fa7a";
}
.fi-tr-steak:before {
    content: "\fa7b";
}
.fi-tr-steering-wheel:before {
    content: "\fa7c";
}
.fi-tr-step-backward:before {
    content: "\fa7d";
}
.fi-tr-step-forward:before {
    content: "\fa7e";
}
.fi-tr-sterling-sign:before {
    content: "\fa7f";
}
.fi-tr-stocking:before {
    content: "\fa80";
}
.fi-tr-stomach:before {
    content: "\fa81";
}
.fi-tr-stop-circle:before {
    content: "\fa82";
}
.fi-tr-store-alt:before {
    content: "\fa83";
}
.fi-tr-store-lock:before {
    content: "\fa84";
}
.fi-tr-store-slash:before {
    content: "\fa85";
}
.fi-tr-strawberry:before {
    content: "\fa86";
}
.fi-tr-street-view:before {
    content: "\fa87";
}
.fi-tr-stretcher:before {
    content: "\fa88";
}
.fi-tr-strikethrough:before {
    content: "\fa89";
}
.fi-tr-stroopwafel:before {
    content: "\fa8a";
}
.fi-tr-subscript:before {
    content: "\fa8b";
}
.fi-tr-subscription-alt:before {
    content: "\fa8c";
}
.fi-tr-subscription:before {
    content: "\fa8d";
}
.fi-tr-subway:before {
    content: "\fa8e";
}
.fi-tr-suitcase-alt:before {
    content: "\fa8f";
}
.fi-tr-summer:before {
    content: "\fa90";
}
.fi-tr-sun-dust:before {
    content: "\fa91";
}
.fi-tr-sun-plant-wilt:before {
    content: "\fa92";
}
.fi-tr-sun:before {
    content: "\fa93";
}
.fi-tr-sunday:before {
    content: "\fa94";
}
.fi-tr-sunglasses-alt:before {
    content: "\fa95";
}
.fi-tr-sunglasses:before {
    content: "\fa96";
}
.fi-tr-sunrise-alt:before {
    content: "\fa97";
}
.fi-tr-sunrise:before {
    content: "\fa98";
}
.fi-tr-sunset:before {
    content: "\fa99";
}
.fi-tr-superscript:before {
    content: "\fa9a";
}
.fi-tr-supplier-alt:before {
    content: "\fa9b";
}
.fi-tr-supplier:before {
    content: "\fa9c";
}
.fi-tr-surprise:before {
    content: "\fa9d";
}
.fi-tr-sushi-alt:before {
    content: "\fa9e";
}
.fi-tr-sushi-roll:before {
    content: "\fa9f";
}
.fi-tr-sushi:before {
    content: "\faa0";
}
.fi-tr-svg:before {
    content: "\faa1";
}
.fi-tr-swatchbook:before {
    content: "\faa2";
}
.fi-tr-swimmer:before {
    content: "\faa3";
}
.fi-tr-swimming-pool:before {
    content: "\faa4";
}
.fi-tr-swipe-down:before {
    content: "\faa5";
}
.fi-tr-swipe-left:before {
    content: "\faa6";
}
.fi-tr-swipe-right:before {
    content: "\faa7";
}
.fi-tr-swipe-up:before {
    content: "\faa8";
}
.fi-tr-symbols:before {
    content: "\faa9";
}
.fi-tr-t:before {
    content: "\faaa";
}
.fi-tr-tab-folder:before {
    content: "\faab";
}
.fi-tr-table-columns:before {
    content: "\faac";
}
.fi-tr-table-layout:before {
    content: "\faad";
}
.fi-tr-table-list:before {
    content: "\faae";
}
.fi-tr-table-picnic:before {
    content: "\faaf";
}
.fi-tr-table-pivot:before {
    content: "\fab0";
}
.fi-tr-table-rows:before {
    content: "\fab1";
}
.fi-tr-table-tree:before {
    content: "\fab2";
}
.fi-tr-table:before {
    content: "\fab3";
}
.fi-tr-tablet-android-alt:before {
    content: "\fab4";
}
.fi-tr-tablet-android:before {
    content: "\fab5";
}
.fi-tr-tablet-rugged:before {
    content: "\fab6";
}
.fi-tr-tachometer-alt-average:before {
    content: "\fab7";
}
.fi-tr-tachometer-alt-fastest:before {
    content: "\fab8";
}
.fi-tr-tachometer-alt-slow:before {
    content: "\fab9";
}
.fi-tr-tachometer-alt-slowest:before {
    content: "\faba";
}
.fi-tr-tachometer-average:before {
    content: "\fabb";
}
.fi-tr-tachometer-fast:before {
    content: "\fabc";
}
.fi-tr-tachometer-fastest:before {
    content: "\fabd";
}
.fi-tr-tachometer-slow:before {
    content: "\fabe";
}
.fi-tr-tachometer-slowest:before {
    content: "\fabf";
}
.fi-tr-tachometer:before {
    content: "\fac0";
}
.fi-tr-taco:before {
    content: "\fac1";
}
.fi-tr-tags:before {
    content: "\fac2";
}
.fi-tr-tally-1:before {
    content: "\fac3";
}
.fi-tr-tally-2:before {
    content: "\fac4";
}
.fi-tr-tally-3:before {
    content: "\fac5";
}
.fi-tr-tally-4:before {
    content: "\fac6";
}
.fi-tr-tally:before {
    content: "\fac7";
}
.fi-tr-tamale:before {
    content: "\fac8";
}
.fi-tr-tank-water:before {
    content: "\fac9";
}
.fi-tr-tap:before {
    content: "\faca";
}
.fi-tr-tape:before {
    content: "\facb";
}
.fi-tr-target-audience:before {
    content: "\facc";
}
.fi-tr-tax-alt:before {
    content: "\facd";
}
.fi-tr-tax:before {
    content: "\face";
}
.fi-tr-taxi-bus:before {
    content: "\facf";
}
.fi-tr-team-check-alt:before {
    content: "\fad0";
}
.fi-tr-team-check:before {
    content: "\fad1";
}
.fi-tr-teddy-bear:before {
    content: "\fad2";
}
.fi-tr-teeth-open:before {
    content: "\fad3";
}
.fi-tr-telescope:before {
    content: "\fad4";
}
.fi-tr-temperature-down:before {
    content: "\fad5";
}
.fi-tr-temperature-frigid:before {
    content: "\fad6";
}
.fi-tr-temperature-high:before {
    content: "\fad7";
}
.fi-tr-temperature-list:before {
    content: "\fad8";
}
.fi-tr-temperature-low:before {
    content: "\fad9";
}
.fi-tr-temperature-up:before {
    content: "\fada";
}
.fi-tr-template-alt:before {
    content: "\fadb";
}
.fi-tr-template:before {
    content: "\fadc";
}
.fi-tr-tenge:before {
    content: "\fadd";
}
.fi-tr-tent-arrow-down-to-line:before {
    content: "\fade";
}
.fi-tr-tent-arrow-left-right:before {
    content: "\fadf";
}
.fi-tr-tent-arrow-turn-left:before {
    content: "\fae0";
}
.fi-tr-tent-arrows-down:before {
    content: "\fae1";
}
.fi-tr-tents:before {
    content: "\fae2";
}
.fi-tr-terminal:before {
    content: "\fae3";
}
.fi-tr-terrace:before {
    content: "\fae4";
}
.fi-tr-text-box-dots:before {
    content: "\fae5";
}
.fi-tr-text-box-edit:before {
    content: "\fae6";
}
.fi-tr-text-box:before {
    content: "\fae7";
}
.fi-tr-text-height:before {
    content: "\fae8";
}
.fi-tr-text-shadow:before {
    content: "\fae9";
}
.fi-tr-text-size:before {
    content: "\faea";
}
.fi-tr-text-slash:before {
    content: "\faeb";
}
.fi-tr-text-width:before {
    content: "\faec";
}
.fi-tr-theater-masks:before {
    content: "\faed";
}
.fi-tr-thermometer-alt:before {
    content: "\faee";
}
.fi-tr-thermometer-empty:before {
    content: "\faef";
}
.fi-tr-thermometer-full:before {
    content: "\faf0";
}
.fi-tr-thermometer-half:before {
    content: "\faf1";
}
.fi-tr-thermometer-quarter:before {
    content: "\faf2";
}
.fi-tr-thermometer-three-quarters:before {
    content: "\faf3";
}
.fi-tr-theta:before {
    content: "\faf4";
}
.fi-tr-third-award:before {
    content: "\faf5";
}
.fi-tr-third-laurel:before {
    content: "\faf6";
}
.fi-tr-third-medal:before {
    content: "\faf7";
}
.fi-tr-third:before {
    content: "\faf8";
}
.fi-tr-thought-bubble:before {
    content: "\faf9";
}
.fi-tr-thunderstorm-moon:before {
    content: "\fafa";
}
.fi-tr-thunderstorm-sun:before {
    content: "\fafb";
}
.fi-tr-thunderstorm:before {
    content: "\fafc";
}
.fi-tr-thursday:before {
    content: "\fafd";
}
.fi-tr-ticket-airline:before {
    content: "\fafe";
}
.fi-tr-ticket-alt:before {
    content: "\faff";
}
.fi-tr-tickets-airline:before {
    content: "\fb00";
}
.fi-tr-tie:before {
    content: "\fb01";
}
.fi-tr-tilde:before {
    content: "\fb02";
}
.fi-tr-times-hexagon:before {
    content: "\fb03";
}
.fi-tr-tint-slash:before {
    content: "\fb04";
}
.fi-tr-tip-coin:before {
    content: "\fb05";
}
.fi-tr-tire-flat:before {
    content: "\fb06";
}
.fi-tr-tire-pressure-warning:before {
    content: "\fb07";
}
.fi-tr-tire-rugged:before {
    content: "\fb08";
}
.fi-tr-tire:before {
    content: "\fb09";
}
.fi-tr-tired:before {
    content: "\fb0a";
}
.fi-tr-to-do-alt:before {
    content: "\fb0b";
}
.fi-tr-to-do:before {
    content: "\fb0c";
}
.fi-tr-together-people:before {
    content: "\fb0d";
}
.fi-tr-toggle-off:before {
    content: "\fb0e";
}
.fi-tr-toggle-on:before {
    content: "\fb0f";
}
.fi-tr-toilet-paper-blank-under:before {
    content: "\fb10";
}
.fi-tr-toilet-paper-blank:before {
    content: "\fb11";
}
.fi-tr-toilet-paper-slash:before {
    content: "\fb12";
}
.fi-tr-toilet-paper-under-slash:before {
    content: "\fb13";
}
.fi-tr-toilet-paper-under:before {
    content: "\fb14";
}
.fi-tr-toilet:before {
    content: "\fb15";
}
.fi-tr-token:before {
    content: "\fb16";
}
.fi-tr-tomato:before {
    content: "\fb17";
}
.fi-tr-tombstone-alt:before {
    content: "\fb18";
}
.fi-tr-tombstone:before {
    content: "\fb19";
}
.fi-tr-tool-box:before {
    content: "\fb1a";
}
.fi-tr-tools:before {
    content: "\fb1b";
}
.fi-tr-toothbrush:before {
    content: "\fb1c";
}
.fi-tr-tornado:before {
    content: "\fb1d";
}
.fi-tr-total:before {
    content: "\fb1e";
}
.fi-tr-tour-guide-people:before {
    content: "\fb1f";
}
.fi-tr-tour-virtual:before {
    content: "\fb20";
}
.fi-tr-tower-control:before {
    content: "\fb21";
}
.fi-tr-track:before {
    content: "\fb22";
}
.fi-tr-tractor:before {
    content: "\fb23";
}
.fi-tr-trademark:before {
    content: "\fb24";
}
.fi-tr-traffic-cone:before {
    content: "\fb25";
}
.fi-tr-traffic-light-go:before {
    content: "\fb26";
}
.fi-tr-traffic-light-slow:before {
    content: "\fb27";
}
.fi-tr-traffic-light-stop:before {
    content: "\fb28";
}
.fi-tr-traffic-light:before {
    content: "\fb29";
}
.fi-tr-trailer:before {
    content: "\fb2a";
}
.fi-tr-train-journey:before {
    content: "\fb2b";
}
.fi-tr-train-station-building:before {
    content: "\fb2c";
}
.fi-tr-train-station:before {
    content: "\fb2d";
}
.fi-tr-train-subway-tunnel:before {
    content: "\fb2e";
}
.fi-tr-train-tram:before {
    content: "\fb2f";
}
.fi-tr-tram:before {
    content: "\fb30";
}
.fi-tr-transformation-block:before {
    content: "\fb31";
}
.fi-tr-transformation-circle:before {
    content: "\fb32";
}
.fi-tr-transformation-design:before {
    content: "\fb33";
}
.fi-tr-transformation-shapes:before {
    content: "\fb34";
}
.fi-tr-transformer-bolt:before {
    content: "\fb35";
}
.fi-tr-transgender:before {
    content: "\fb36";
}
.fi-tr-transporter-1:before {
    content: "\fb37";
}
.fi-tr-transporter-2:before {
    content: "\fb38";
}
.fi-tr-transporter-3:before {
    content: "\fb39";
}
.fi-tr-transporter-4:before {
    content: "\fb3a";
}
.fi-tr-transporter-5:before {
    content: "\fb3b";
}
.fi-tr-transporter-6:before {
    content: "\fb3c";
}
.fi-tr-transporter-7:before {
    content: "\fb3d";
}
.fi-tr-transporter-empty:before {
    content: "\fb3e";
}
.fi-tr-transporter:before {
    content: "\fb3f";
}
.fi-tr-trash-can-check:before {
    content: "\fb40";
}
.fi-tr-trash-can-clock:before {
    content: "\fb41";
}
.fi-tr-trash-can-list:before {
    content: "\fb42";
}
.fi-tr-trash-can-plus:before {
    content: "\fb43";
}
.fi-tr-trash-can-slash:before {
    content: "\fb44";
}
.fi-tr-trash-check:before {
    content: "\fb45";
}
.fi-tr-trash-clock:before {
    content: "\fb46";
}
.fi-tr-trash-list:before {
    content: "\fb47";
}
.fi-tr-trash-plus:before {
    content: "\fb48";
}
.fi-tr-trash-restore-alt:before {
    content: "\fb49";
}
.fi-tr-trash-restore:before {
    content: "\fb4a";
}
.fi-tr-trash-slash:before {
    content: "\fb4b";
}
.fi-tr-trash-undo-alt:before {
    content: "\fb4c";
}
.fi-tr-trash-undo:before {
    content: "\fb4d";
}
.fi-tr-trash-xmark:before {
    content: "\fb4e";
}
.fi-tr-treasure-chest:before {
    content: "\fb4f";
}
.fi-tr-tree-alt:before {
    content: "\fb50";
}
.fi-tr-tree-christmas:before {
    content: "\fb51";
}
.fi-tr-tree-deciduous:before {
    content: "\fb52";
}
.fi-tr-tree:before {
    content: "\fb53";
}
.fi-tr-trees-alt:before {
    content: "\fb54";
}
.fi-tr-trees:before {
    content: "\fb55";
}
.fi-tr-triangle-music:before {
    content: "\fb56";
}
.fi-tr-triangle-person-digging:before {
    content: "\fb57";
}
.fi-tr-triangle-warning:before {
    content: "\fb58";
}
.fi-tr-triangle:before {
    content: "\fb59";
}
.fi-tr-trillium:before {
    content: "\fb5a";
}
.fi-tr-trophy-star:before {
    content: "\fb5b";
}
.fi-tr-truck-arrow-left:before {
    content: "\fb5c";
}
.fi-tr-truck-arrow-right:before {
    content: "\fb5d";
}
.fi-tr-truck-bolt:before {
    content: "\fb5e";
}
.fi-tr-truck-box:before {
    content: "\fb5f";
}
.fi-tr-truck-check:before {
    content: "\fb60";
}
.fi-tr-truck-container-empty:before {
    content: "\fb61";
}
.fi-tr-truck-container:before {
    content: "\fb62";
}
.fi-tr-truck-couch:before {
    content: "\fb63";
}
.fi-tr-truck-flatbed:before {
    content: "\fb64";
}
.fi-tr-truck-front:before {
    content: "\fb65";
}
.fi-tr-truck-loading:before {
    content: "\fb66";
}
.fi-tr-truck-medical:before {
    content: "\fb67";
}
.fi-tr-truck-monster:before {
    content: "\fb68";
}
.fi-tr-truck-moving:before {
    content: "\fb69";
}
.fi-tr-truck-pickup:before {
    content: "\fb6a";
}
.fi-tr-truck-plow:before {
    content: "\fb6b";
}
.fi-tr-truck-ramp:before {
    content: "\fb6c";
}
.fi-tr-truck-tow:before {
    content: "\fb6d";
}
.fi-tr-trumpet:before {
    content: "\fb6e";
}
.fi-tr-tshirt:before {
    content: "\fb6f";
}
.fi-tr-tty-answer:before {
    content: "\fb70";
}
.fi-tr-tty:before {
    content: "\fb71";
}
.fi-tr-tubes:before {
    content: "\fb72";
}
.fi-tr-tuesday:before {
    content: "\fb73";
}
.fi-tr-tugrik-sign:before {
    content: "\fb74";
}
.fi-tr-turkey:before {
    content: "\fb75";
}
.fi-tr-turntable:before {
    content: "\fb76";
}
.fi-tr-turtle:before {
    content: "\fb77";
}
.fi-tr-tv-music:before {
    content: "\fb78";
}
.fi-tr-tv-retro:before {
    content: "\fb79";
}
.fi-tr-typewriter:before {
    content: "\fb7a";
}
.fi-tr-u:before {
    content: "\fb7b";
}
.fi-tr-ufo-beam:before {
    content: "\fb7c";
}
.fi-tr-ufo:before {
    content: "\fb7d";
}
.fi-tr-ui-ux:before {
    content: "\fb7e";
}
.fi-tr-umbrella-beach:before {
    content: "\fb7f";
}
.fi-tr-undo-alt:before {
    content: "\fb80";
}
.fi-tr-undo:before {
    content: "\fb81";
}
.fi-tr-unicorn:before {
    content: "\fb82";
}
.fi-tr-uniform-martial-arts:before {
    content: "\fb83";
}
.fi-tr-universal-access:before {
    content: "\fb84";
}
.fi-tr-up-left:before {
    content: "\fb85";
}
.fi-tr-up-right-from-square:before {
    content: "\fb86";
}
.fi-tr-up-right:before {
    content: "\fb87";
}
.fi-tr-up:before {
    content: "\fb88";
}
.fi-tr-url:before {
    content: "\fb89";
}
.fi-tr-usd-circle:before {
    content: "\fb8a";
}
.fi-tr-usd-square:before {
    content: "\fb8b";
}
.fi-tr-user-alien:before {
    content: "\fb8c";
}
.fi-tr-user-astronaut:before {
    content: "\fb8d";
}
.fi-tr-user-check:before {
    content: "\fb8e";
}
.fi-tr-user-chef:before {
    content: "\fb8f";
}
.fi-tr-user-cowboy:before {
    content: "\fb90";
}
.fi-tr-user-crown:before {
    content: "\fb91";
}
.fi-tr-user-gear:before {
    content: "\fb92";
}
.fi-tr-user-headset:before {
    content: "\fb93";
}
.fi-tr-user-helmet-safety:before {
    content: "\fb94";
}
.fi-tr-user-injured:before {
    content: "\fb95";
}
.fi-tr-user-interface-ui:before {
    content: "\fb96";
}
.fi-tr-user-key:before {
    content: "\fb97";
}
.fi-tr-user-lock:before {
    content: "\fb98";
}
.fi-tr-user-md-chat:before {
    content: "\fb99";
}
.fi-tr-user-md:before {
    content: "\fb9a";
}
.fi-tr-user-minus:before {
    content: "\fb9b";
}
.fi-tr-user-music:before {
    content: "\fb9c";
}
.fi-tr-user-ninja:before {
    content: "\fb9d";
}
.fi-tr-user-nurse:before {
    content: "\fb9e";
}
.fi-tr-user-pen:before {
    content: "\fb9f";
}
.fi-tr-user-pilot-tie:before {
    content: "\fba0";
}
.fi-tr-user-pilot:before {
    content: "\fba1";
}
.fi-tr-user-police:before {
    content: "\fba2";
}
.fi-tr-user-robot-xmarks:before {
    content: "\fba3";
}
.fi-tr-user-robot:before {
    content: "\fba4";
}
.fi-tr-user-salary:before {
    content: "\fba5";
}
.fi-tr-user-shield:before {
    content: "\fba6";
}
.fi-tr-user-slash:before {
    content: "\fba7";
}
.fi-tr-user-tag:before {
    content: "\fba8";
}
.fi-tr-user-unlock:before {
    content: "\fba9";
}
.fi-tr-user-visor:before {
    content: "\fbaa";
}
.fi-tr-user-xmark:before {
    content: "\fbab";
}
.fi-tr-users-alt:before {
    content: "\fbac";
}
.fi-tr-users-gear:before {
    content: "\fbad";
}
.fi-tr-users-medical:before {
    content: "\fbae";
}
.fi-tr-users-slash:before {
    content: "\fbaf";
}
.fi-tr-utensils-slash:before {
    content: "\fbb0";
}
.fi-tr-utensils:before {
    content: "\fbb1";
}
.fi-tr-utility-pole-double:before {
    content: "\fbb2";
}
.fi-tr-utility-pole:before {
    content: "\fbb3";
}
.fi-tr-ux-browser:before {
    content: "\fbb4";
}
.fi-tr-ux:before {
    content: "\fbb5";
}
.fi-tr-v:before {
    content: "\fbb6";
}
.fi-tr-vacuum-robot:before {
    content: "\fbb7";
}
.fi-tr-vacuum:before {
    content: "\fbb8";
}
.fi-tr-value-absolute:before {
    content: "\fbb9";
}
.fi-tr-vault:before {
    content: "\fbba";
}
.fi-tr-vector-circle:before {
    content: "\fbbb";
}
.fi-tr-vector-polygon:before {
    content: "\fbbc";
}
.fi-tr-venus-double:before {
    content: "\fbbd";
}
.fi-tr-venus-mars:before {
    content: "\fbbe";
}
.fi-tr-venus:before {
    content: "\fbbf";
}
.fi-tr-vest-patches:before {
    content: "\fbc0";
}
.fi-tr-vest:before {
    content: "\fbc1";
}
.fi-tr-video-arrow-down-left:before {
    content: "\fbc2";
}
.fi-tr-video-arrow-up-right:before {
    content: "\fbc3";
}
.fi-tr-video-camera-alt:before {
    content: "\fbc4";
}
.fi-tr-video-duration:before {
    content: "\fbc5";
}
.fi-tr-video-plus:before {
    content: "\fbc6";
}
.fi-tr-video-slash:before {
    content: "\fbc7";
}
.fi-tr-videoconference:before {
    content: "\fbc8";
}
.fi-tr-violin:before {
    content: "\fbc9";
}
.fi-tr-virus-slash:before {
    content: "\fbca";
}
.fi-tr-virus:before {
    content: "\fbcb";
}
.fi-tr-viruses:before {
    content: "\fbcc";
}
.fi-tr-visit:before {
    content: "\fbcd";
}
.fi-tr-voicemail:before {
    content: "\fbce";
}
.fi-tr-volcano:before {
    content: "\fbcf";
}
.fi-tr-volume-down:before {
    content: "\fbd0";
}
.fi-tr-volume-mute:before {
    content: "\fbd1";
}
.fi-tr-volume-off:before {
    content: "\fbd2";
}
.fi-tr-volume-slash:before {
    content: "\fbd3";
}
.fi-tr-vote-nay:before {
    content: "\fbd4";
}
.fi-tr-vote-yea:before {
    content: "\fbd5";
}
.fi-tr-vr-cardboard:before {
    content: "\fbd6";
}
.fi-tr-w:before {
    content: "\fbd7";
}
.fi-tr-waffle:before {
    content: "\fbd8";
}
.fi-tr-wagon-covered:before {
    content: "\fbd9";
}
.fi-tr-walker:before {
    content: "\fbda";
}
.fi-tr-walkie-talkie:before {
    content: "\fbdb";
}
.fi-tr-walking:before {
    content: "\fbdc";
}
.fi-tr-wallet-arrow:before {
    content: "\fbdd";
}
.fi-tr-wallet:before {
    content: "\fbde";
}
.fi-tr-warehouse-alt:before {
    content: "\fbdf";
}
.fi-tr-warranty:before {
    content: "\fbe0";
}
.fi-tr-washer:before {
    content: "\fbe1";
}
.fi-tr-waste-pollution:before {
    content: "\fbe2";
}
.fi-tr-waste:before {
    content: "\fbe3";
}
.fi-tr-watch-calculator:before {
    content: "\fbe4";
}
.fi-tr-watch-fitness:before {
    content: "\fbe5";
}
.fi-tr-watch-smart:before {
    content: "\fbe6";
}
.fi-tr-watch:before {
    content: "\fbe7";
}
.fi-tr-water-bottle:before {
    content: "\fbe8";
}
.fi-tr-water-ladder:before {
    content: "\fbe9";
}
.fi-tr-water-lower:before {
    content: "\fbea";
}
.fi-tr-water-rise:before {
    content: "\fbeb";
}
.fi-tr-water:before {
    content: "\fbec";
}
.fi-tr-watermelon:before {
    content: "\fbed";
}
.fi-tr-wave-sine:before {
    content: "\fbee";
}
.fi-tr-wave-square:before {
    content: "\fbef";
}
.fi-tr-wave-triangle:before {
    content: "\fbf0";
}
.fi-tr-waveform-path:before {
    content: "\fbf1";
}
.fi-tr-waveform:before {
    content: "\fbf2";
}
.fi-tr-web:before {
    content: "\fbf3";
}
.fi-tr-webcam-slash:before {
    content: "\fbf4";
}
.fi-tr-webcam:before {
    content: "\fbf5";
}
.fi-tr-wednesday:before {
    content: "\fbf6";
}
.fi-tr-whale:before {
    content: "\fbf7";
}
.fi-tr-wheat-awn-circle-exclamation:before {
    content: "\fbf8";
}
.fi-tr-wheat-awn-slash:before {
    content: "\fbf9";
}
.fi-tr-wheat-awn:before {
    content: "\fbfa";
}
.fi-tr-wheat-slash:before {
    content: "\fbfb";
}
.fi-tr-wheat:before {
    content: "\fbfc";
}
.fi-tr-wheelchair-move:before {
    content: "\fbfd";
}
.fi-tr-wheelchair:before {
    content: "\fbfe";
}
.fi-tr-whistle:before {
    content: "\fbff";
}
.fi-tr-wifi-1:before {
    content: "\fc00";
}
.fi-tr-wifi-2:before {
    content: "\fc01";
}
.fi-tr-wifi-alt:before {
    content: "\fc02";
}
.fi-tr-wifi-exclamation:before {
    content: "\fc03";
}
.fi-tr-wifi-slash:before {
    content: "\fc04";
}
.fi-tr-wifi:before {
    content: "\fc05";
}
.fi-tr-wind-turbine:before {
    content: "\fc06";
}
.fi-tr-wind-warning:before {
    content: "\fc07";
}
.fi-tr-wind:before {
    content: "\fc08";
}
.fi-tr-window-alt:before {
    content: "\fc09";
}
.fi-tr-window-frame-open:before {
    content: "\fc0a";
}
.fi-tr-window-frame:before {
    content: "\fc0b";
}
.fi-tr-window-maximize:before {
    content: "\fc0c";
}
.fi-tr-window-minimize:before {
    content: "\fc0d";
}
.fi-tr-window-restore:before {
    content: "\fc0e";
}
.fi-tr-windsock:before {
    content: "\fc0f";
}
.fi-tr-wine-bottle:before {
    content: "\fc10";
}
.fi-tr-wine-glass-crack:before {
    content: "\fc11";
}
.fi-tr-wine-glass-empty:before {
    content: "\fc12";
}
.fi-tr-wishlist-heart:before {
    content: "\fc13";
}
.fi-tr-wishlist-star:before {
    content: "\fc14";
}
.fi-tr-woman-head:before {
    content: "\fc15";
}
.fi-tr-won-sign:before {
    content: "\fc16";
}
.fi-tr-work-in-progress:before {
    content: "\fc17";
}
.fi-tr-worm:before {
    content: "\fc18";
}
.fi-tr-wreath:before {
    content: "\fc19";
}
.fi-tr-wrench-alt:before {
    content: "\fc1a";
}
.fi-tr-wrench-simple:before {
    content: "\fc1b";
}
.fi-tr-x-ray:before {
    content: "\fc1c";
}
.fi-tr-x:before {
    content: "\fc1d";
}
.fi-tr-y:before {
    content: "\fc1e";
}
.fi-tr-yin-yang:before {
    content: "\fc1f";
}
.fi-tr-z:before {
    content: "\fc20";
}
