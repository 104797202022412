@import './variables';
@import './bootstrap_essentials';

/* Fonts */

$font-silka: 'Silka';

body {
    font-family: $font-silka;
    color: $color-black;
}

p,
address {
    margin-bottom: 25px;
    line-height: 1.75;
}

.normal-line-height {
    line-height: 1.3;
}

a {
    color: $color-black;
    text-decoration: none;

    &:hover {
        color: $color-black;
    }
}

li {
    color: $color-black;
}

strong {
    color: $color-black;
    font-weight: 600;
}

/* Headlines */

.text-size-2xl {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
        font-size: 6rem;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 8rem;
    }
}

.text-size-xxl {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 1;

    @include media-breakpoint-up(sm) {
        font-size: 5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 4.5rem;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 5.3rem;
    }
}

h1,
.text-size-xl {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2em;

    @include media-breakpoint-down(xl) {
        font-size: 3rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }
}

h2,
.text-size-l {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.3em;

    @include media-breakpoint-down(md) {
        font-size: 1.6rem;
    }
}

h3,
.text-size-m {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
        font-size: 1.4rem;
    }
}

h4,
.text-size-s {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
}

.text-size-xs {
    font-size: 13px;

    @include media-breakpoint-down(md) {
        font-size: 0.75rem;
    }
}

.heading {
    color: var(--tenant-color);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
}
